import React, { useState, useEffect } from 'react';
import { UserInfo } from 'core/Profile/UserInfo';
import { AboutMyself } from 'core/Profile/AboutMyself';
import ProfBackground from 'core/Profile/ProfBackground';
import PartialLoader from '../../../components/layout/PartialLoader';
import NavbarPageContent from '../../../components/NavbarPageContent';
import { PROFILE_MENU_CURRENT_USER_EXPERT } from 'constants/profilePage';

const ProfilePageContent = ({
  activeMenuItem,
  userProps,
  isCurrentUser,
  setUserProps,
  selectUser,
  setActiveMenuItem,
}) => {
  const [contentLayout, setContentLayout] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getContentLayout = (activeItem, userData) => {
    let layout = null;
    if (activeItem === 'About' && selectUser) {
      layout = (
        <>
          <UserInfo
            userProps={userData}
            isCurrentUser={isCurrentUser}
            setUserProps={setUserProps}
            changeIsLoading={setIsLoading}
            selectUser={selectUser}
          />
          <NavbarPageContent
            menuItems={PROFILE_MENU_CURRENT_USER_EXPERT}
            setActiveMenuItem={setActiveMenuItem}
            activeMenuItem={activeMenuItem}
          />
          <AboutMyself
            userProps={userData}
            setUserProps={setUserProps}
            isCurrentUser={isCurrentUser}
            changeIsLoading={setIsLoading}
            selectUser={selectUser}
          />
        </>
      );
    } else if (activeItem === 'Professional background') {
      layout = (
        <>
          <UserInfo
            userProps={userData}
            isCurrentUser={isCurrentUser}
            setUserProps={setUserProps}
            changeIsLoading={setIsLoading}
            selectUser={selectUser}
          />
          <NavbarPageContent
            menuItems={PROFILE_MENU_CURRENT_USER_EXPERT}
            setActiveMenuItem={setActiveMenuItem}
            activeMenuItem={activeMenuItem}
          />
          <ProfBackground
            userProps={userData}
            isCurrentUser={isCurrentUser}
            setUserData={setUserProps}
          />
        </>
      );
    }
    return layout;
  };

  useEffect(() => {
    if (activeMenuItem) {
      setContentLayout(getContentLayout(activeMenuItem, userProps));
    }
  }, [activeMenuItem, userProps]);

  return (
    <>
      {contentLayout && <>{contentLayout}</>}
      <PartialLoader loading={isLoading} isLightContainer />
    </>
  );
};

export default ProfilePageContent;
