import PageContainer from 'components/layout/PageContainer';
import JoinPage from 'core/views/JoinPage';
import { TITLES } from '../../constants/pagesTitles';

const Join = () => (
  <PageContainer title={TITLES.joinPage}>
    <JoinPage />
  </PageContainer>
);

export default Join;
