import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import NavigateNext from '@mui/icons-material/NavigateNext';
import styles from './DraftBlock.module.scss';
import { Button } from 'tt-ui-kit';
import { ReactComponent as DeleteSvg } from '../../../../assets/svg/delete.svg';
import { ReactComponent as EditSvg } from '../../../../assets/svg/edit.svg';
import { Grid } from '@mui/material';

const DraftBlock = ({ props }) => {
  const {
    draft,
    targetWidth,
    calcLogo,
    editId,
    setEditId,
    onProceedDraft,
    onDelete,
    onRenameDraft,
    onBuyCredits,
  } = props;
  const [draftName, setDraftName] = useState(null);

  useEffect(() => {
    if (!draft) return;
    setDraftName(draft?.name);
    return () => {
      setDraftName(null);
    };
  }, [draft]);

  const onChangeDraftName = (e) => {
    setDraftName(e.target.value);
    onRenameDraft(e);
  };

  return (
    <div
      key={draft.id}
      className={clsx(styles.rowWrapper, targetWidth < 940 && styles.shortFormat)}
    >
      <div className={styles.summary}>
        <Grid item container alignItems="center" className={styles.draftName}>
          {calcLogo && <div className={styles.logo}>{calcLogo}</div>}
          <div className={styles.horizontal}>
            {editId === draft.id ? (
              <input
                type="text"
                placeholder="Enter the draft name"
                defaultValue={draft.name}
                onBlur={onChangeDraftName}
                className={styles.rename}
                maxLength={150}
              />
            ) : (
              <div
                className={styles.title}
                title={draftName ?? draft.name ?? '-'}
                style={{ maxWidth: targetWidth > 940 ? 180 : targetWidth - 350 }}
              >
                {draftName ?? draft.name ?? '-'}
              </div>
            )}
            <EditSvg onClick={() => setEditId(editId === draft.id ? null : draft.id)} />
          </div>
        </Grid>
        <div className={clsx(styles.dataBlock, styles.info)}>
          <div className={styles.dataBlock}>
            <div className={styles.container}>
              <p className={styles.data}>Reporting period:</p>
            </div>
            <div className={styles.container}>
              <p className={styles.dataValue}>
                {draft?.reportDate ? draft.reportDate.getFullYear() : ''}
              </p>
            </div>
          </div>
          <div className={styles.dataBlock}>
            <div className={styles.container}>
              <p className={styles.dataSecond}>Edited:</p>
              <p className={styles.dataValue}>
                {draft?.updatedAt ? draft.updatedAt.toLocaleDateString() : ''}
              </p>
            </div>
            <div className={styles.container}>
              <p className={styles.dataSecond}>Created:</p>
              <p className={styles.dataValue}>
                {draft?.createdAt ? draft.createdAt.toLocaleDateString() : ''}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx(styles.dataBlock, styles.buttonCont)}>
        <div>
          {!!onDelete && (
            <Button type="icon" onClick={() => onDelete(draft)}>
              <DeleteSvg />
            </Button>
          )}
          <Button type="link" onClick={() => onProceedDraft(draft.id)}>
            Proceed
            <NavigateNext />
          </Button>
        </div>
        <Button
          className={styles.payBtn}
          onClick={() => onBuyCredits(draft.id)}
          // TODO uncomment when buy credits will be ready
          disabled={!draft.canPay}
        >
          Pay
        </Button>
      </div>
    </div>
  );
};

export default DraftBlock;
