import React, { useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router';
import { Link } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import {
  ROUTE_CALCS,
  ROUTE_CALCS_DRAFTS,
  ROUTE_CALCS_EXTERNAL,
  ROUTE_CALCS_NEW,
  ROUTE_CALCS_SCORE,
  ROUTE_CUSTOM_REPORTS,
  ROUTE_CUSTOM_REPORTS_DRAFTS,
  ROUTE_CUSTOM_REPORTS_METHODOLOGY,
  ROUTE_CUSTOM_REPORTS_NEW,
  ROUTE_CUSTOM_REPORTS_REPORTS,
  ROUTE_CUSTOM_REPORTS_TEMPLATES,
  ROUTE_SUST_REPORTS,
  ROUTE_SUST_REPORTS_DRAFTS,
  ROUTE_SUST_REPORTS_NEW,
  ROUTE_SUST_REPORTS_REPORTS,
  SR_URL,
  TAB_URLS,
  URL_ANSWERS_DETAILS,
  URL_CALCS_DRAFTS,
  URL_CALCS_NEW,
  URL_CALCS_SCORE,
  URL_CUSTOM_REPORTS,
  URL_CUSTOM_REPORTS_NEW,
  URL_REPORT_DRAFT_DETAILS,
  URL_REPORT_RESULT_DETAILS,
  URL_SCORE_DETAILS,
  URL_SUST_REPORTS_NEW,
} from 'constants/calculatorsPage';
import { IS_ANY_DRATF_OR_RESULT_EXISTS, GET_CURRENT_USER } from '../../../api';
import NavbarNavigateTabs from 'components/NavbarNavigateTabs/NavbarNavigateTabs';
import External from '../Pages/Benchmark/External/External';
import BenchmarkDrafts from '../Pages/Benchmark/Drafts/Drafts';
import Score from '../Pages/Benchmark/Score/Score';
import BenchmarkStartNew from '../Pages/Benchmark/StartNew/StartNew';
import SusReports from '../Pages/Sustainability/Reports/Reports';
import SusDrafts from '../Pages/Sustainability/Drafts/Drafts';
import SusStartNew from '../Pages/Sustainability/StartNew/StartNew';
import CustomReports from '../Pages/Customizable/Reports/Reports';
import CustomDrafts from '../Pages/Customizable/Drafts/Drafts';
import CustomTemplates from '../Pages/Customizable/Templates/Templates';
import CustomMethodology from '../Pages/Customizable/Methodology/Methodology';
import CustomStartNew from '../Pages/Customizable/StartNew/StartNew';
import { FourOhFour } from 'views/FourOhFour';
import { useTargetBlockSize } from 'utils/useTargetBlockSize';

/**
 * Represents the CalcsPage component.
 * This component displays the calculations page with tabs and subtabs.
 * It retrieves and displays assessments, drafts, and other data based on the selected tabs.
 */
const ScoreAndReportingMenuRouter = () => {
  const targetRef = useRef(null);
  const { targetWidth } = useTargetBlockSize(targetRef);

  const SCORE_AND_REPORTING_ROUTES_INFO = [
    {
      path: '/',
      element: <Navigate to={URL_CALCS_NEW} />,
    },
    {
      path: ROUTE_SUST_REPORTS,
      element: <Navigate to={URL_SUST_REPORTS_NEW} />,
    },
    {
      path: ROUTE_CUSTOM_REPORTS,
      element: <Navigate to={URL_CUSTOM_REPORTS_NEW} />,
    },
    {
      path: ROUTE_CALCS,
      element: <Navigate to={URL_CALCS_NEW} />,
    },
    {
      path: ROUTE_CALCS_DRAFTS,
      element: <BenchmarkDrafts targetWidth={targetWidth} />,
    },
    {
      path: ROUTE_CALCS_EXTERNAL,
      element: <External />,
    },
    {
      path: ROUTE_CALCS_SCORE,
      element: <Score targetWidth={targetWidth} />,
    },
    {
      path: ROUTE_CALCS_NEW,
      element: <BenchmarkStartNew targetWidth={targetWidth} />,
    },
    {
      path: ROUTE_SUST_REPORTS_REPORTS,
      element: <SusReports targetWidth={targetWidth} />,
    },
    {
      path: ROUTE_SUST_REPORTS_DRAFTS,
      element: <SusDrafts targetWidth={targetWidth} />,
    },
    {
      path: ROUTE_SUST_REPORTS_NEW,
      element: <SusStartNew targetWidth={targetWidth} />,
    },
    {
      path: ROUTE_CUSTOM_REPORTS_REPORTS,
      element: <CustomReports targetWidth={targetWidth} />,
    },
    {
      path: ROUTE_CUSTOM_REPORTS_DRAFTS,
      element: <CustomDrafts targetWidth={targetWidth} />,
    },
    {
      path: ROUTE_CUSTOM_REPORTS_TEMPLATES,
      element: <CustomTemplates />,
    },
    {
      path: ROUTE_CUSTOM_REPORTS_METHODOLOGY,
      element: <CustomMethodology />,
    },
    {
      path: ROUTE_CUSTOM_REPORTS_NEW,
      element: <CustomStartNew targetWidth={targetWidth} />,
    },
    {
      path: '*',
      name: 'Not Found',
      element: <FourOhFour />,
    },
  ];

  const navigate = useNavigate();
  const [firstLoading, setFirstLoading] = useState(true);

  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'no-cache', // Doesn't check cache before making a network request
  });
  const [isExistsData] = useLazyQuery(IS_ANY_DRATF_OR_RESULT_EXISTS);

  const [currentUser, setCurrentUser] = useState(null);

  const getDataForRedirect = async () => {
    await isExistsData()
      .then((res) => {
        const { data } = res;
        setFirstLoading(false);
        const { drafts, results } = JSON.parse(data?.isDraftOrResultExists ?? '');
        if (results) navigate(URL_CALCS_SCORE);
        else if (drafts) navigate(URL_CALCS_DRAFTS);
        else if (!drafts && !results) navigate(URL_CALCS_NEW);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // redirect to the start page if the user is not logged in or getting the results
  useEffect(() => {
    if (!firstLoading) return;
    if (!currentUser?.role_name) return;
    if (currentUser && currentUser.role_name === 'guest') {
      navigate(URL_CALCS_NEW);
      // } else {
      // getDataForRedirect();
    }
    setFirstLoading(false);
  }, [currentUser]);

  useEffect(() => {
    getCurrentUser().then((res) => {
      const { data } = res;
      setCurrentUser({ ...data?.currentUser });
    });
  }, []);

  return (
    <div style={{ width: '100%' }} ref={targetRef}>
      <>
        <div style={{ width: '100%' }}>
          <NavbarNavigateTabs options={TAB_URLS} />
        </div>
        {!!currentUser?.role_name && (
          <Routes>
            {SCORE_AND_REPORTING_ROUTES_INFO.map((routeInfo) => (
              <Route exact path={routeInfo.path} element={routeInfo.element} key={routeInfo.path} />
            ))}
          </Routes>
        )}
      </>
    </div>
  );
};

export default ScoreAndReportingMenuRouter;
