import React, { useEffect, useState } from 'react';
import { ButtonBase, TextField, InputAdornment, Box } from '@mui/material';
import ReactPhoneInput from 'react-phone-input-2';
import TimezoneSelect from 'react-timezone-select';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Image } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import LocationModal from 'core/views/JoinPage/SignUpPage/Modals/LocationModal';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { getValidationSchema } from 'utils/validationUserInfoSchemaGenerator';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_USER_PROFILE, UPDATE_USER_AVATAR } from 'api';
import PasswordModal from './PasswordModal';
import styles from './UserInfoGeneralEditing.module.scss';
import AvatarUploader from './AvatarUploader';
import '../../../../styles/react-phone-input.css';
import { useSelectedContext } from 'context/contextSelector';
import { GET_COUNTRIES } from '../../../../api';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const FORM_ID = 'generalInfo';
const LOCATION_MODAL_TITLE = 'Map';
const KYC_PENDING = 'pending';
const KYC_SUCCESS = 'success';

// const DropdownIndicator = (props) => (
//   <components.DropdownIndicator {...props}>
//     <Image className={styles.arrowWrapper} src="/images/templates/arrow.png" />
//   </components.DropdownIndicator>
// );

const UserInfoGeneralEditing = ({ userProps, setIsEditing, changeIsLoading, setUserProps }) => {
  const { changeUserData, user } = useSelectedContext((context) => context.user);

  const [address, setAddress] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [location, setLocation] = useState('');
  const [isNameInputDisable, setIsNameInputDisable] = useState(false);
  const [isPasswordModal, setIsPasswordModal] = useState(false);
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [phoneInputLabel, setPhoneInputLabel] = useState('');
  const [avatarPath, setAvatarPath] = useState(null);
  const [countriesList, setCountriesList] = useState([]);
  const { data: countriesData } = useQuery(GET_COUNTRIES);

  useEffect(() => {
    if (countriesData) {
      setCountriesList(countriesData.showCountries);
    }
  }, [countriesData]);

  useEffect(() => {
    setAddress(userProps?.location?.address);
    setLocationData({
      country: userProps?.location?.country,
      postcode: userProps?.location?.postcode,
      locality: userProps?.location?.locality,
      latLng: userProps?.location?.latLng,
      country_code: userProps?.location?.country_code,
    });
  }, [userProps]);

  const validationSchema = getValidationSchema();
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: userProps?.first_name,
      lastName: userProps?.last_name,
      email: userProps?.email,
      reserveEmail: userProps?.reserve_email ?? '',
      tel: userProps?.tel ?? '',
      avatar: userProps?.avatar ?? '',
      location: {
        address: userProps?.location?.address,
        data: {
          ...userProps?.location,
          ...{
            address: undefined,
            latLng: { ...userProps?.location?.latLng, __typename: undefined },
            __typename: undefined,
          },
        },
      },
      timezone: userProps?.timezone ?? '',
    },
  };
  const formMethods = useForm(formOptions, { mode: 'all' });

  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE, {
    variables: { input: {} },
  });
  const [updateUserAvatar] = useMutation(UPDATE_USER_AVATAR, {
    variables: { input: {} },
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = formMethods;

  const onClose = (setModal, loading) => {
    if (!loading) {
      setModal(false);
    }
  };

  const onSubmit = async (data) => {
    const formData = {
      email: data.email,
      reserve_email: data.reserveEmail,
      tel: data.tel,
      timezone: data.timezone.value,
      location: {
        address: data.location.address,
        data: { ...data.location.data },
      },
    };
    if (!isNameInputDisable) {
      formData.first_name = data.firstName;
      formData.last_name = data.lastName;
    }
    try {
      changeIsLoading(true);
      const userProfile = await updateUserProfile({ variables: { input: formData } });
      if (userProfile.data && userProfile.data.updateUserProfile) {
        changeUserData({
          ...user,
          ...userProfile.data.updateUserProfile,
        });
        setUserProps(userProfile.data.updateUserProfile);
      }
      if (avatarPath) {
        const userAvatar = await updateUserAvatar({ variables: { input: { avatar: avatarPath } } });
        if (userAvatar.data && userAvatar.data.updateUserAvatar) {
          changeUserData({
            ...user,
            avatar: userAvatar.data.updateUserAvatar.avatar,
          });
          setUserProps((current) => ({
            ...current,
            avatar: userAvatar.data.updateUserAvatar.avatar,
          }));
        }
      }
      changeIsLoading(false);
      setIsEditing(false);
      openNotification({
        message: 'Profile information changed successfully.',
        type: 'success',
      });
    } catch (e) {
      changeIsLoading(false);
      openNotification({
        message: 'Error while updating profile information.',
        type: 'error',
      });
    }
  };

  const onTelChange = async (value) => {
    setValue('tel', value);
  };

  const onChangeAvatar = async (value) => {
    setAvatarPath(value);
    setValue('avatar', value);
  };

  const getLocation = (fullAddress) =>
    fullAddress
      .split(',')
      .reduce((res, item) => `${res && res.length ? `${res},` : res} ${item}`, '');

  useEffect(() => {
    if (userProps?.location && userProps?.location.latLng) {
      setCoordinates({
        lat: parseFloat(userProps?.location.latLng.lat),
        lng: parseFloat(userProps?.location.latLng.lng),
      });
    }
    if (userProps && userProps?.kyc) {
      setIsNameInputDisable(userProps?.kyc === KYC_PENDING || userProps?.kyc === KYC_SUCCESS);
    }
  }, [userProps]);

  useEffect(() => {
    if (address && locationData && countriesList.length) {
      setLocation(getLocation(address));
      let country = '';
      if (locationData.country_code) {
        const countryItem = countriesList.find(
          (item) =>
            item.iso_3166_2 === locationData.country_code ||
            item.iso_3166_3 === locationData.country_code
        );
        country = countryItem?.name || '';
      }
      setValue('location', {
        address: address,
        data: {
          latLng: {
            lat: coordinates?.lat.toString(),
            lng: coordinates?.lng.toString(),
          },
          country: country,
          postcode: locationData.postcode || '',
          locality: locationData.locality || '',
          country_code: locationData.country_code || '',
        },
      });
    }
  }, [address, locationData, countriesList]);

  return (
    <FormProvider {...formMethods}>
      <form id={FORM_ID} className={styles.mainEdit} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.topContent}>
          <AvatarUploader
            avatarSrc={userProps?.avatar}
            userId={userProps?.id}
            changeAvatar={(value) => onChangeAvatar(value)}
          />
          <div className={styles.infoDiv}>
            <div className={styles.nameDiv}>
              <div className={styles.inputWrapper}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <TextField
                      {...field}
                      className={styles.modal_input}
                      label="First Name"
                      disabled={isNameInputDisable}
                      disableUnderline
                      value={value}
                      onChange={onChange}
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                    />
                  )}
                />
              </div>
              <div className={styles.inputWrapper}>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <TextField
                      {...field}
                      className={styles.modal_input}
                      label="Last Name"
                      disabled={isNameInputDisable}
                      disableUnderline
                      onChange={onChange}
                      value={value}
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                    />
                  )}
                />
              </div>
            </div>
            <div className={styles.emailPasswordDiv}>
              <div className={styles.inputWrapper}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <TextField
                      {...field}
                      className={styles.modal_input}
                      label="Email"
                      disabled
                      disableUnderline
                      value={value}
                      onChange={onChange}
                      error={!!errors.email}
                      helperText={errors.lastName?.email}
                    />
                  )}
                />
              </div>
              <div className={styles.inputWrapper}>
                <TextField
                  className={styles.modal_input}
                  label="Password"
                  type="password"
                  disabled
                  autoComplete="current-password"
                  defaultValue={userProps?.password}
                />
                <ButtonBase className={styles.iconWrapper} onClick={() => setIsPasswordModal(true)}>
                  <Image className={styles.editIcon} src="/images/post/editIcon.svg" width={20} />
                </ButtonBase>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className={styles.midContent}>
              <Controller
                name="reserveEmail"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <TextField
                    {...field}
                    className={styles.modal_input}
                    label="Reserve email"
                    value={value}
                    onChange={onChange}
                    error={!!errors.reserveEmail}
                    helperText={errors.reserveEmail?.message}
                  />
                )}
              />
              <Controller
                name="timezone"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TimezoneSelect
                    className={styles.timezone}
                    placeholder="Choose your timezone"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="tel"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Box sx={{ width: '100%' }}>
                    <ReactPhoneInput
                      onChange={onTelChange}
                      onFocus={() => setPhoneInputLabel('Phone number')}
                      onBlur={() => setPhoneInputLabel('')}
                      value={value}
                      id="phone-input"
                      specialLabel={value ? 'Phone number' : phoneInputLabel}
                      placeholder="Phone number"
                      buttonClass={styles.phone}
                      inputClass={clsx(
                        styles.phoneInput,
                        errors.tel?.message ? styles.errorInput : ''
                      )}
                    />
                    {errors.tel?.message && (
                      <div className={styles.errorMessage}>{errors.tel?.message}</div>
                    )}
                  </Box>
                )}
              />
            </div>
          </div>
        </div>
        <TextField
          className={styles.modal_input}
          style={{ width: '100%' }}
          label="Locations within this area, country/region, postal code"
          value={address}
          onClick={() => setIsLocationModal(true)}
          data-type="location"
          // error={!postal}
          helperText={null}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Image src="/images/templates/map-icon.svg" width={26} height={22} />
              </InputAdornment>
            ),
          }}
        />
        <div className={styles.midContent}>
          <Controller
            name="location.data.postcode"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <TextField
                {...field}
                className={styles.modal_input}
                label="Zip / Postal code"
                disableUnderline
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name="location.data.country"
            render={({ field: { onChange, value } }) => (
              <Select
                defaultValue=""
                displayEmpty
                onChange={(e) => {
                  onChange(e);
                  setValue(
                    'location.data.country_code',
                    countriesList.find((item) => item.name === e.target.value)?.iso_3166_2 || ''
                  );
                }}
                value={value}
                sx={{
                  padding: '0px',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                }}
                className={styles.modal_select}
              >
                <MenuItem value="" disabled>
                  <span style={{ color: 'rgba(117, 117, 117, 1)' }}>Country</span>
                </MenuItem>
                {countriesList && countriesList.length
                  ? countriesList.map((item) => (
                      <MenuItem key={item.id} value={item.name}>
                        <p>{item.name}</p>
                      </MenuItem>
                    ))
                  : null}
              </Select>
            )}
          />
          <Controller
            name="location.data.locality"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <TextField
                {...field}
                className={styles.modal_input}
                label="Town"
                disableUnderline
                onChange={onChange}
                value={value}
              />
            )}
          />
        </div>
        <TextField
          className={styles.modal_input}
          style={{ width: '100%' }}
          label="Address"
          disableUnderline
          disabled
          value={location}
          // onChange={(e) => setEmailReserveUser(e.target.value)}
        />
        <LocationModal
          title={LOCATION_MODAL_TITLE}
          isModelOpen={isLocationModal}
          handleClose={onClose}
          setIsOpen={setIsLocationModal}
          address={address}
          setAddress={setAddress}
          setLocationData={setLocationData}
          coordinates={coordinates}
          setCoordinates={setCoordinates}
          background="#FFFFFF"
          isProfileEditing
        />
        <PasswordModal
          isModelOpen={isPasswordModal}
          setIsOpen={setIsPasswordModal}
          handleClose={onClose}
        />
      </form>
    </FormProvider>
  );
};

export default UserInfoGeneralEditing;
