import { gql } from '@apollo/client';
import { TOKEN } from 'fragments';

const GET_PRELIMINARY_ASSESSMENT = gql`
  mutation ($id: ID!) @api(name: calculator) {
    preliminaryAssessment(id: $id) {
      id
      subIndustryId: sub_industry_id
      country: registration_country_id
      industrialGroupId: industrial_group_id
      testYear: test_year
      environmental
      social
      governance
      productTotal: co_product_total
      packageTotal: co_package_total
      companyTotal: co_company_total
      globalTotal: global
    }
  }
`;

const UPDATE_DRAFT = gql`
  mutation ($input: EsgLocalDraftUpdateInput!) @api(name: calculator) {
    updateEsgDraft(input: $input) {
      id
    }
  }
`;

const GET_DRAFT_PRICE = gql`
  query ($input: DraftCostInput!) @api(name: calculator) {
    getCalculatorPrice(input: $input) {
      draftId: draft_id
      draftName: draft_name
      calcType: calc_type
      baseCost: base_cost
      extraCost: extra_cost
      discount
      finalCost: final_cost
    }
  }
`;

const GET_PRICING_DATA = gql`
  query @api(name: user) {
    getPricing {
      id
      role
      code
      tariffs {
        id
        name
        code
        monthly
        annually
        monthDetails
        annualDetails
      }
      categories {
        id
        name
        code
        order
        activities {
          id
          name
          shortList
          description
          order
          free {
            base
            annually
          }
          standard {
            base
            annually
          }
          pro {
            base
            annually
          }
          enterprise {
            base
            annually
          }
        }
      }
    }
  }
`;

const GET_COMPANY_QUESTIONS_DATA = gql`
  query @api(name: calculator) {
    getCo2CompanyQuestions {
      scopeNum
      scope {
        name
        code
        activities {
          id
          scopeId: scope_id
          name
          types {
            id
            name
            units {
              id
              name
              additional {
                co2e_primary
                co2e_reused
                co2e_open_loop
                co2e_closed_loop
                co2e_combustion
                co2e_composting
                co2e_landfill
                co2e_anaerobic
                co2e_laden_average
                co2e_laden_100
                co2e_laden_50
                co2e_laden_0
                co2e_lpg
                co2e_cng
                co2e_without_rf
                co2e_with_rf
                co2e_battery
                co2e_hybrid
                co2e_plugin
                co2e_unknown
                co2e_petrol
                co2e_diesel
              }
              size {
                id
                name
                additional {
                  co2e_primary
                  co2e_reused
                  co2e_open_loop
                  co2e_closed_loop
                  co2e_combustion
                  co2e_composting
                  co2e_landfill
                  co2e_anaerobic
                  co2e_laden_average
                  co2e_laden_100
                  co2e_laden_50
                  co2e_laden_0
                  co2e_lpg
                  co2e_cng
                  co2e_without_rf
                  co2e_with_rf
                  co2e_battery
                  co2e_hybrid
                  co2e_plugin
                  co2e_unknown
                  co2e_petrol
                  co2e_diesel
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GET_GLOBAL_QUESTIONS_DATA = gql`
  query @api(name: calculator) {
    getGlobalQuestions {
      GlobalQuestions
    }
  }
`;

const GET_LOCAL_QUESTIONS_DATA = gql`
  query @api(name: calculator) {
    questions {
      id
      name
      esg
      esgPosition: position_in_esg
      questions {
        type
        code
        title: question_title
        body: question_body
      }
    }
  }
`;

const IS_ANY_DRATF_OR_RESULT_EXISTS = gql`
  query @api(name: calculator) {
    isDraftOrResultExists
  }
`;

const GET_ALL_RESULTS = gql`
  query @api(name: calculator) {
    resultAll {
      id
      companyName: company_name
      industrialGroupId: industrial_group_id
      subIndustryId: sub_industry_id
      countryId: registration_country_id
      employeesNumber: employees_number
      result
      type
    }
  }
`;

const GET_USER_RESULTS = gql`
  query @api(name: calculator) {
    getOwnResults {
      id
      companyName: company_name
      reportDate: test_year
      createdAt: created_at
      updatedAt: updated_at
      result
      product: co_product_total
      package: co_package_total
      company: co_company_total
      social
      governance
      environmental
      isBlockchain: is_blockchain
      type
      sharingId: sharing_id
      resourcesDraftId: resources_draft_id
      name
      files {
        id
        questions_code
        calc_tt_draft_id
        type
        original_name
        uuidName: file
        description
        state
      }
    }
  }
`;

const GET_USER_RESULTS_BY_PAGE_TYPE = gql`
  query ($type: SRType!) @api(name: calculator) {
    getOwnResultsByPageType(type: $type) {
      id
      companyName: company_name
      reportDate: test_year
      createdAt: created_at
      updatedAt: updated_at
      result
      product: co_product_total
      package: co_package_total
      company: co_company_total
      social
      governance
      environmental
      isBlockchain: is_blockchain
      type
      sharingId: sharing_id
      resourcesDraftId: resources_draft_id
      name
      files {
        id
        questions_code
        calc_tt_draft_id
        type
        original_name
        uuidName: file
        description
        state
      }
    }
  }
`;

const GET_USER_RESULT = gql`
  query ($id: String) @api(name: calculator) {
    resultById(id: $id) {
      id
      stateGraphics: state_graphics
      companyName: company_name
      companyId: company_id
      industrialGroupId: industrial_group_id
      subIndustryId: sub_industry_id
      registrationCountryId: registration_country_id
      employeesNumber: employees_number
      reportDate: test_year
      createdAt: created_at
      updatedAt: updated_at
      isBlockchain: is_blockchain
      localAnswers: answers
      companyAnswers: company_answers
      packageAnswers: package_answers
      productAnswers: product_answers
      globalAnswers: global_answers
      type
      totalResult: result
      productResult: co_product_total
      packageResult: co_package_total
      companyResult: co_company_total
      socialResult: social
      governanceResult: governance
      environmentalResult: environmental
      sharingId: sharing_id
      resourcesDraftId: resources_draft_id
      factorId: factor_id
      name
      files {
        id
        questions_code
        calc_tt_draft_id
        type
        original_name
        uuidName: file
        description
        state
      }
    }
  }
`;

const GET_DRAFT_BY_ID = gql`
  query ($id: ID!) @api(name: calculator) {
    draftById(id: $id) {
      id
      name
      countryId: registration_country_id
      companyId: company_id
      companyName: company_name
      establishedDate: company_date
      legalTypeId: legal_type_id
      address1
      address2
      zipCode: zip_code
      industrialGroupId: industrial_group_id
      subIndustryId: sub_industry_id
      turnover: annual_turnover
      profit: net_profit
      taxes: year_taxes
      uniqProduct: number_unique_products
      productivity: annual_productivity_each_position
      employees: employees_number
      reportDate: period_report_date
      reportDateEnd: period_report_date_end
      createdAt: created_at
      updatedAt: updated_at
      type
      answers
      companyAnswers: company_answers
      packageAnswers: package_answers
      productAnswers: product_answers
      globalAnswers: global_answers
      completedAt: completed_at
      stateGraphics: state_graphics
    }
  }
`;

const GET_USER_DRAFTS = gql`
  query @api(name: calculator) {
    getOwnDrafts {
      id
      name
      companyId: company_id
      companyName: company_name
      reportDate: period_report_date
      reportDateEnd: period_report_date_end
      createdAt: created_at
      updatedAt: updated_at
      type
      completedAt: completed_at
    }
  }
`;

const GET_USER_DRAFTS_BY_PAGE_TYPE = gql`
  query ($type: SRType!) @api(name: calculator) {
    getOwnDraftsByPageType(type: $type) {
      id
      name
      companyId: company_id
      companyName: company_name
      reportDate: period_report_date
      reportDateEnd: period_report_date_end
      createdAt: created_at
      updatedAt: updated_at
      type
      completedAt: completed_at
    }
  }
`;

const DELETE_DRAFT = gql`
  mutation ($id: ID!) @api(name: calculator) {
    deleteEsgDraft(id: $id)
  }
`;

const RENAME_DRAFT = gql`
  mutation ($input: RenameEsgDraftInput!) @api(name: calculator) {
    renameEsgDraft(input: $input) {
      id
    }
  }
`;

const CAN_PAY_DRAFT = gql`
  query ($input: CalculatorPayAccessInput!) @api(name: market) {
    getCalculatorsPayAccess(input: $input) {
      id
      canPay
      paymentData {
        id
        exact_id
        payment_status
        status
      }
    }
  }
`;
const GET_COUNTRIES = gql`
  query @api(name: market) {
    showCountries {
      id
      name
      code
      iso_3166_2
      iso_3166_3
    }
  }
`;

const GET_INDUSTRIAL_GROUPS = gql`
  query @api(name: market) {
    showIndustryGroups {
      id
      name
      code
    }
  }
`;

const GET_SUBINDUSTRIES = gql`
  query @api(name: market) {
    showSubIndustries {
      id
      name
      code
    }
  }
`;

const GET_USER = gql`
  query ($id: ID!) @api(name: user) {
    user(id: $id) {
      id
      status
      market_id
      calculator_id
      email
      reserve_email
      first_name
      last_name
      created_at
      avatar
      avatarMedium
      avatarSmall
      is_draft
      tel
      timezone
      invited_by_name
      invited_by_id
      ceo_of
      kyc
      role_id
      role_name
      roles {
        id
        name
      }
      roles_list
      about
      interests {
        id
        body
      }
      agreement {
        validator_agreement
      }
      languages {
        id
        language {
          id
          code
          iso639_1
          english_name
          native_name
        }
        level
      }
      location {
        address
        latLng {
          lat
          lng
        }
        country_code
        country
        postcode
        locality
      }
      educations {
        id
        establishment
        study
        start_date
        end_date
      }
      projects {
        id
        title
        company_name
        start_date
        end_date
      }
      experiences {
        id
        title
        company_name
        start_date
        end_date
      }
      collaborations {
        id
        ps_education
        study
        start_date
        end_date
      }
      kyc
      settings {
        name
        value
      }
      expertises {
        id
        name
      }
    }
  }
`;

const GET_USER_PUBLIC_DATA = gql`
  query ($id: ID!) @api(name: user) {
    user(id: $id) {
      id
      first_name
      last_name
      avatar
    }
  }
`;

const GET_CURRENT_USER = gql`
  query @api(name: user) {
    currentUser {
      id
      email
      first_name
      last_name
      avatar
      avatarMedium
      avatarSmall
      kyc
      role_id
      role_name
      roles {
        id
        name
      }
      roles_list
      market_id
      created_at
      invited_by_id
      invited_by_name
      identity_verification
      tel
      about
      timezone
      is_draft
      kyc
      reserve_email
      last_login_ip
      registration_type
      status
    }
  }
`;

const GET_VIEWED_USER = gql`
  query ($id: ID!) @api(name: user) {
    user(id: $id) {
      id
      first_name
      last_name
      status
      avatar
      timezone
      invited_by_name
      invited_by_id
      ceo_of
      kyc
      role_name
      about
      is_draft
      interests {
        id
        body
      }
      agreement {
        validator_agreement
      }
      languages {
        id
        language {
          id
          code
          iso639_1
          english_name
          native_name
        }
        level
      }
      location {
        address
        latLng {
          lat
          lng
        }
        country_code
        country
        postcode
        locality
      }
      educations {
        id
        establishment
        study
        start_date
        end_date
      }
      projects {
        id
        title
        company_name
        start_date
        end_date
      }
      experiences {
        id
        title
        company_name
        start_date
        end_date
      }
      collaborations {
        id
        ps_education
        study
        start_date
        end_date
      }
      kyc
      expertises {
        id
        name
      }
    }
  }
`;

const GET_PRO_LOGIN = gql`
  ${TOKEN}
  mutation GetProLogin($input: LoginInput) @api(name: user) {
    login(input: $input) {
      ...TokenFields
      user {
        id
        market_id
        calculator_id
        email
        is_draft
        registration_role
        firstName: first_name
        lastName: last_name
      }
    }
  }
`;

const SEND_INVITE = gql`
  mutation ($input: SendInvitationInput!) @api(name: user) {
    sendInvitation(input: $input) {
      link
      code
      invitation {
        id
        first_name
        last_name
        tel
        email
        level
        status
        created_at
        created_by
        expired_at
      }
    }
  }
`;

const ACCEPT_INVITE = gql`
  mutation AcceptInvitation($input: AcceptInvitationInput!) @api(name: user) {
    acceptInvitation(input: $input) {
      invitation {
        first_name
        last_name
      }
      url
    }
  }
`;

const CONFIRM_REGISTRATION_BY_INVITATION = gql`
  ${TOKEN}
  mutation confirmRegistrationByInvitation($input: ConfirmRegistrationByInvitationInput)
  @api(name: user) {
    confirmRegistrationByInvitation(input: $input) {
      tokens {
        ...TokenFields
        status
        user {
          id
          email
          first_name
          last_name
          market_id
          calculator_id
        }
      }
    }
  }
`;

const REGISTER_BY_INVITATION = gql`
  mutation registerByInvitation($input: RegisterByInvitationInput) @api(name: user) {
    registerByInvitation(input: $input) {
      status
      error
    }
  }
`;

const EMAIL_AND_TOKEN_VALIDATION = gql`
  mutation confirmEmail($input: ConfirmEmailInput) @api(name: user) {
    confirmEmail(input: $input) {
      access_token
      refresh_token
      expires_in
      token_type
      status
      user {
        id
        market_id
        calculator_id
        email
        is_draft
        registration_role
        firstName: first_name
        lastName: last_name
      }
    }
  }
`;

const CREATE_NEW_INDIVIDUAL = gql`
  ${TOKEN}
  mutation registerIndividual($input: RegisterIndividualInput) @api(name: user) {
    registerIndividual(input: $input) {
      tokens {
        ...TokenFields
        user {
          id
          email
          first_name
          last_name
          market_id
          calculator_id
        }
      }
      status
    }
  }
`;

const CREATE_NEW_DIGITAL_INDIVIDUAL = gql`
  ${TOKEN}
  mutation CreateNewDigitalIndividual($input: RegisterIndividualInput) @api(name: user) {
    registerIndividual(input: $input) {
      tokens {
        ...TokenFields
        user {
          id
          email
          first_name
          last_name
          market_id
          calculator_id
        }
      }
      status
    }
  }
`;

const GET_URL_FOR_PUBLIC_UPLOAD = gql`
  mutation getPublicUrl($input: sFile) @api(name: user) {
    getUrlForPublicUpload(input: $input) {
      url
      download_url
    }
  }
`;

const GET_URL_FOR_PRIVATE_UPLOAD = gql`
  mutation getPrivateUrl($input: sFile) @api(name: user) {
    getUrlForPrivateUpload(input: $input) {
      url
    }
  }
`;

const DELETE_FROM_PUBLIC = gql`
  mutation deletePublic($input: sFile) @api(name: user) {
    deleteFromPublic(input: $input)
  }
`;

const DELETE_FROM_PRIVATE = gql`
  mutation deletePrivate($input: sFile) @api(name: user) {
    deleteFromPrivate(input: $input)
  }
`;

const GET_IDV_INIT = gql`
  mutation GetIdvInit($input: idvInitInput) @api(name: user) {
    idvInit(input: $input) {
      url
    }
  }
`;

const GET_USER_COMPANIES_LIST = gql`
  query ($user_id: String!) @api(name: market) {
    showAllCompaniesByUserId(user_id: $user_id) {
      id
      user_id
      company_name
      kyb_completed
      tags {
        id
        tag
      }
      logos {
        original_name
        path
        type
        mediumPath
        smallPath
      }
    }
  }
`;

const GET_USER_COMPANIES_SHORT_LIST = gql`
  query ($user_id: String!) @api(name: market) {
    showAllCompaniesByUserId(user_id: $user_id) {
      id
      company_name
      logos {
        original_name
        path
        type
        mediumPath
        smallPath
      }
    }
  }
`;

export const GET_SHORT_COMPANY_BY_ID = gql`
  query ($id: String!) @api(name: market) {
    showCompanyById(id: $id) {
      id
      company_name
      logos {
        original_name
        path
        type
        mediumPath
        smallPath
      }
    }
  }
`;

export const GET_ACTIVE_USER_COMPANIES_LIST = gql`
  query @api(name: market) {
    showActiveUserCompanies {
      id
      user_id
      company_name
      kyb_completed
      logos {
        original_name
        path
        type
        mediumPath
        smallPath
      }
    }
  }
`;

const GET_USER_COMPANIES = gql`
  query ($user_id: String!) @api(name: market) {
    showAllCompaniesByUserId(user_id: $user_id) {
      id
      userId: user_id
      companyName: company_name
      email
      industrialGroupId: industry_group_id
      subIndustryId: sub_industry_id
      legalTypeId: legal_type_id
      establishedDate: established_date
      addressLocation {
        countryId: country_id
        zipCode: postcode
        address
      }
      address2
      logos {
        path
        file
        type
        mediumPath
        smallPath
      }
    }
  }
`;

const GET_IDV_INIT_SUCCESS = gql`
  mutation GetIdvInitSuccess($input: idvSuccessInput) @api(name: user) {
    idvInit(input: $input) {
      user {
        id
      }
      state
    }
  }
`;

const CHECK_EMAIL = gql`
  mutation checkEmail($email: String!) @api(name: user) {
    validateEmail(email: $email) {
      validate
    }
  }
`;

const GET_AGREEMENT = gql`
  query @api(name: user) {
    agreementPrivacyAndHonor {
      id
      field
      body
    }
  }
`;

const GET_VALIDATOR_AGREEMENT = gql`
  query @api(name: user) {
    agreementValidator {
      id
      field
      body
    }
  }
`;

const SIGN_VALIDATOR_AGREEMENT = gql`
  query @api(name: user) {
    signAgreementValidator {
      validator_agreement
    }
  }
`;

const CREATE_COMMENT = gql`
  mutation ($input: PostCommentInput) @api(name: user) {
    createPostComment(input: $input) {
      id
      user_id
      post_id
      body
    }
  }
`;

const UPDATE_USER_AVATAR = gql`
  mutation ($input: updateUserAvatarInput) @api(name: user) {
    updateUserAvatar(input: $input) {
      avatar
    }
  }
`;

const GET_ROLE = gql`
  query @api(name: user) {
    roles {
      id
      name
    }
  }
`;

const DELETE_POST = gql`
  mutation ($id: ID) @api(name: user) {
    deletePost(id: $ID) {
      id
      body
      user {
        first_name
        last_name
        avatar
        ceo_of
        role_id
        role_name
      }
      likes_count
      comments_count
      comments
      files
    }
  }
`;

const GET_POST_EMOJI_LIST = gql`
  query @api(name: user) {
    postEmojiList {
      id
      code
    }
  }
`;

export const GET_CHAT_CATEGORIES = gql`
  query @api(name: user) {
    chatCategories {
      id
      name
      color
    }
  }
`;

const CREATE_FULL_POST = gql`
  mutation ($input: AddPostInput) @api(name: user) {
    addPost(input: $input) {
      id
      user {
        first_name
        last_name
        avatar
        invited_by_name
        ceo_of
        role_id
        role_name
      }
      body
      filesImage {
        id
        post_id
        type
        file
        original_name
        path
      }
      filesVideo {
        id
        post_id
        type
        file
        original_name
        path
      }
      filesAudio {
        id
        post_id
        type
        file
        original_name
        path
      }
      filesDoc {
        id
        post_id
        type
        file
        original_name
        path
      }
    }
  }
`;

const LIKE_POST = gql`
  mutation ($input: LikeInput) @api(name: user) {
    likePost(input: $input) {
      emoji_id
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ($input: UpdatePassword!) @api(name: user) {
    updatePassword(input: $input) {
      status
      message
    }
  }
`;

const UPDATE_USER_PROFILE = gql`
  mutation ($input: UpdateUserProfileInput) @api(name: user) {
    updateUserProfile(input: $input) {
      id
      status
      market_id
      calculator_id
      email
      reserve_email
      first_name
      last_name
      created_at
      avatar
      is_draft
      tel
      timezone
      invited_by_name
      invited_by_id
      ceo_of
      kyc
      role_id
      role_name
      roles {
        id
        name
      }
      roles_list
      about
      interests {
        id
        body
      }
      agreement {
        validator_agreement
      }
      languages {
        id
        language {
          id
          code
          iso639_1
          english_name
          native_name
        }
        level
      }
      location {
        address
        latLng {
          lat
          lng
        }
        country_code
        country
        postcode
        locality
      }
      educations {
        id
        establishment
        study
        start_date
        end_date
      }
      projects {
        id
        title
        company_name
        start_date
        end_date
      }
      experiences {
        id
        title
        company_name
        start_date
        end_date
      }
      collaborations {
        id
        ps_education
        study
        start_date
        end_date
      }
      kyc
      settings {
        name
        value
      }
      expertises {
        id
        name
      }
    }
  }
`;

const GET_LANGUAGES_LIST = gql`
  query @api(name: user) {
    languages {
      id
      code
      iso639_1
      english_name
      native_name
    }
  }
`;

const DELETE_USER_EDUCATION = gql`
  mutation deleteUserEducation($id: ID!) @api(name: user) {
    deleteUserEducation(id: $id)
  }
`;

const DELETE_USER_COLLABORATION = gql`
  mutation deleteUserCollaboration($id: ID!) @api(name: user) {
    deleteUserCollaboration(id: $id)
  }
`;

const DELETE_USER_EXPERIENCE = gql`
  mutation deleteUserExperience($id: ID!) @api(name: user) {
    deleteUserExperience(id: $id)
  }
`;

const DELETE_USER_PROJECT = gql`
  mutation deleteUserProject($input: ID!) @api(name: user) {
    deleteUserProject(input: $input)
  }
`;

const SAVE_USER_EDUCATION = gql`
  mutation ($input: [UserEducationInput!]) @api(name: user) {
    saveUserEducation(educations: $input) {
      success
      educations {
        id
        establishment
        study
        start_date
        end_date
      }
    }
  }
`;

const SAVE_USER_EXPERIENCE = gql`
  mutation ($input: [UserExperienceInput!]) @api(name: user) {
    saveUserExperience(experiences: $input) {
      success
      experiences {
        id
        title
        company_name
        start_date
        end_date
      }
    }
  }
`;

const SAVE_USER_COLLABORATION = gql`
  mutation ($input: [UserCollaborationInput!]) @api(name: user) {
    saveUserCollaboration(collaborations: $input) {
      success
      collaborations {
        id
        ps_education
        study
        start_date
        end_date
        is_present
      }
    }
  }
`;

const SAVE_USER_PROJECTS = gql`
  mutation ($input: [UserProjectInput!]) @api(name: user) {
    saveUserProject(projects: $input) {
      success
      projects {
        id
        title
        company_name
        start_date
        end_date
      }
    }
  }
`;

const GET_COMMUNITY_USERS = gql`
  query ($input: CommunityUsersInput) @api(name: user) {
    communityUsers(input: $input) {
      count
      users {
        id
        first_name
        last_name
        created_at
        avatar
        avatarMedium
        avatarSmall
        status
        invited_by_name
        roles {
          id
          name
        }
        location {
          address
          latLng {
            lat
            lng
          }
          country
          postcode
          locality
        }
        experiences {
          id
          title
          company_name
          start_date
          end_date
        }
        collaborations {
          id
          ps_education
          study
          start_date
          end_date
        }
        registration_type
      }
    }
  }
`;

const GET_INVITATIONS_WITH_PAGINATION = gql`
  query ($input: InvitationsSearchInput, $first: Int!, $page: Int) @api(name: user) {
    invitationsWithPagination(input: $input, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        first_name
        last_name
        tel
        email
        level
        status
        created_at
        created_by
        expired_at
      }
    }
  }
`;
const WITHDRAW_INVITATION = gql`
  mutation ($id: ID!) @api(name: user) {
    withdrawInvitation(id: $id) {
      id
      first_name
      last_name
      tel
      email
      level
      status
      role {
        name
      }
      comment
      note
      created_at
      created_by
      expired_at
    }
  }
`;

const GET_INVITATION_BY_ID = gql`
  query ($id: ID!) @api(name: user) {
    getInvitationById(id: $id) {
      id
      first_name
      last_name
      tel
      email
      level
      status
      created_at
      comment
      note
      role {
        name
      }
      created_by
      expired_at
    }
  }
`;

const GET_COMPANIES_BY_ID = gql`
  query ($id: String!) @api(name: market) {
    showCompanyById(id: $id) {
      id
      user_id
      type
      kyb_completed
      is_draft
      step_number
      tax_vat
      company_name
      address_location_id
      address1
      address2
      email
      website
      established_date
      industry_group_id
      sub_industry_id
      legal_type_id
      for_profit
      is_governmental
      is_open_for_investment
      description
      tags {
        id
        tag
      }
      logos {
        original_name
        path
        type
        mediumPath
        smallPath
      }
      companyGoals {
        id
        name
        description
      }
      addressLocation {
        id
        address
        lat
        lng
        country
        locality
        postcode
      }
      industryGroup {
        id
        name
      }
      subIndustry {
        id
        name
      }
      legalType {
        id
        name
      }
      paymentAccountList {
        id
        user_id
        account_id
        completed_onboarding
        payment_system
        company_id
      }
    }
  }
`;

const GET_ALL_COMPANIES = gql`
  query ($input: CompaniesSearchInput, $first: Int!, $page: Int) @api(name: market) {
    getCompaniesByWithPagination(input: $input, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        user_id
        type
        kyb_completed
        is_draft
        step_number
        tax_vat
        company_name
        address_location_id
        address1
        address2
        email
        website
        established_date
        industry_group_id
        sub_industry_id
        legal_type_id
        for_profit
        is_governmental
        is_open_for_investment
        description
        addressLocation {
          id
          lat
          lng
        }
        industryGroup {
          id
        }
        subIndustry {
          id
        }
        legalType {
          id
          name
        }
        userShop {
          id
        }
        paymentAccountList {
          id
          user_id
          account_id
          completed_onboarding
          payment_system
          company_id
        }
        tags {
          id
          tag
        }
        logos {
          id
          original_name
          file
          extension
          size
          type
          mediumPath
          smallPath
          path
          created_at
          updated_at
        }
        companyGoals {
          id
          name
          description
        }
      }
    }
  }
`;

const GET_COMMUNITY_USERS_MAP = gql`
  query ($input: CommunityUsersMapInput) @api(name: user) {
    communityUsersMap(input: $input) {
      users {
        id
        avatar
        kyc
        roles {
          id
          name
        }
        first_name
        last_name
        created_at
        location {
          address
          latLng {
            lat
            lng
          }
          country
          postcode
          locality
        }
      }
    }
  }
`;
const GET_COMMUNITY_USERS_COUNTRIES = gql`
  query @api(name: user) {
    communityCountries {
      country
      country_code
    }
  }
`;

// Admin
const GET_ADMIN_TUTORIALS = gql`
  query ($type: SectionEnum!) @api(name: user) {
    showAllAdminTutorial(type: $type) {
      section {
        id
        name
      }
      tutorials {
        id
        name
        preview
        file
        description
        type
        section_id
        position
        created_at
        updated_at
      }
    }
  }
`;

// Admin
const GET_ADMIN_PRESENTATIONS = gql`
  query ($type: SectionEnum!) @api(name: user) {
    showAllAdminPresentations(type: $type) {
      section {
        id
        name
      }
      presentations {
        id
        name
        preview
        file
        type
        section_id
        position
        created_at
        updated_at
      }
    }
  }
`;

const CREATE_TUTORIAL = gql`
  mutation ($input: TutorialInput) @api(name: user) {
    createTutorial(input: $input) {
      id
      name
      preview
      file
      description
      type
      section_id
      position
      created_at
      updated_at
    }
  }
`;

const CREATE_PRESENTATION = gql`
  mutation ($input: PresentationInput) @api(name: user) {
    createPresentation(input: $input) {
      id
      name
      preview
      file
      type
      section_id
      position
      created_at
      updated_at
    }
  }
`;

const UPDATE_TUTORIAL = gql`
  mutation ($input: TutorialUpdateInput) @api(name: user) {
    updateTutorial(input: $input) {
      id
      name
      preview
      file
      description
      type
      section_id
      position
      created_at
      updated_at
    }
  }
`;

const DELETE_TUTORIAL = gql`
  mutation ($id: ID) @api(name: user) {
    deleteTutorial(id: $id) {
      success
    }
  }
`;

const UPDATE_PRESENTATION = gql`
  mutation ($input: PresentationUpdateInput) @api(name: user) {
    updatePresentation(input: $input) {
      id
      name
      preview
      file
      type
      section_id
      position
      created_at
      updated_at
    }
  }
`;

const DELETE_PRESENTATION = gql`
  mutation ($id: ID) @api(name: user) {
    deletePresentation(id: $id) {
      success
    }
  }
`;

const GET_ADMIN_ALL_COMPANIES = gql`
  query ($input: AdminCompaniesSearchInput, $first: Int!, $page: Int) @api(name: market) {
    getAdminCompaniesByWithPagination(input: $input, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        user_id
        type
        kyb_completed
        is_draft
        step_number
        tax_vat
        company_name
        address_location_id
        address1
        address2
        email
        website
        established_date
        industry_group_id
        sub_industry_id
        legal_type_id
        for_profit
        is_governmental
        is_open_for_investment
        description
        created_at
        kyb_status
        addressLocation {
          id
          lat
          lng
        }
        industryGroup {
          id
        }
        subIndustry {
          id
        }
        legalType {
          id
          name
        }
        userShop {
          id
        }
        paymentAccountList {
          id
          user_id
          account_id
          completed_onboarding
          payment_system
          company_id
        }
        tags {
          id
          tag
        }
        logos {
          id
          original_name
          file
          extension
          size
          type
          path
          mediumPath
          smallPath
          created_at
          updated_at
        }
        companyGoals {
          id
          name
          description
        }
      }
    }
  }
`;

const UPDATE_ADMIN_COMPANY_STATUS = gql`
  mutation ($input: CompanyStatusUpdateInput!) @api(name: market) {
    editCompanyStatus(input: $input) {
      id
      company_name
      is_draft
    }
  }
`;

const GET_ADMIN_USERS = gql`
  query ($input: UsersInput) @api(name: user) {
    adminUsers(input: $input) {
      count
      users {
        id
        first_name
        last_name
        email
        roles {
          id
          name
        }
        created_at
        invited_by_name
        kyc
        last_login_ip
        status
      }
    }
  }
`;

const GET_ADMIN_ROLES_COUNT = gql`
  query @api(name: user) {
    adminRolesCount {
      role
      count
    }
  }
`;
const UPDATE_USER_ROLE = gql`
  mutation ($input: UpdateUserRoleInput) @api(name: user) {
    updateUserRole(input: $input) {
      success
      user {
        id
        roles {
          name
        }
      }
    }
  }
`;

const UPDATE_USER_STATUS = gql`
  mutation ($input: UpdateUserStatusInput) @api(name: user) {
    updateUserStatus(input: $input) {
      success
      user {
        id
        status
      }
    }
  }
`;
const GET_NOTIFICATIONS = gql`
  query ($filter: String!) @api(name: user) {
    notifications(filter: $filter) {
      id
      read_at
      created_at
      user {
        id
        avatar
        first_name
        last_name
      }
      message
      content {
        id
        body
        title
      }
    }
  }
`;
const MARK_AS_READ_NOTIFICATION = gql`
  mutation ($id: ID!) @api(name: user) {
    markAsReadNotification(id: $id) {
      message
      status
    }
  }
`;
const DELETE_NOTIFICATION = gql`
  mutation ($id: ID!) @api(name: user) {
    deleteNotification(id: $id) {
      message
      status
    }
  }
`;

const FORGOT_USER_PASSWORD = gql`
  mutation ($input: ForgotPasswordInput!) @api(name: user) {
    forgotPassword(input: $input) {
      status
      message
    }
  }
`;

const CONFIRM_FORGOT_USER_PASSWORD = gql`
  mutation ($input: NewPasswordWithCodeInput!) @api(name: user) {
    updateForgottenPassword(input: $input) {
      status
      message
    }
  }
`;

const GET_TUTORIALS = gql`
  query ($section_id: String, $searchValue: String) @api(name: user) {
    showAllTutorial(section_id: $section_id, searchValue: $searchValue) {
      id
      name
      preview
      file
      description
      type
      link
      section_id
      position
      created_at
      updated_at
    }
  }
`;

const GET_PRESENTATIONS = gql`
  query ($section_id: String, $searchValue: String) @api(name: user) {
    showAllPresentations(section_id: $section_id, searchValue: $searchValue) {
      id
      name
      preview
      file
      type
      section_id
      position
      link
      created_at
      updated_at
    }
  }
`;

const GET_TUTORIAL_BY_ID = gql`
  query ($id: ID) @api(name: user) {
    showTutorialById(id: $id) {
      id
      name
      preview
      file
      file_name
      description
      type
      section_id
      position
      keywords
      link
      type
    }
  }
`;

const GET_PRESENTATION_BY_ID = gql`
  query ($id: ID) @api(name: user) {
    showPresentationById(id: $id) {
      id
      name
      preview
      file
      file_name
      type
      section_id
      position
      keywords
      link
      type
    }
  }
`;

const GET_SECTIONS = gql`
  query ($type: SectionEnum) @api(name: user) {
    showAllSections(type: $type) {
      id
      name
      type
    }
  }
`;

const CREATE_SECTION = gql`
  mutation ($input: SectionInput) @api(name: user) {
    createSection(input: $input) {
      id
      name
      type
    }
  }
`;

const UPDATE_SECTION = gql`
  mutation ($input: SectionUpdateInput) @api(name: user) {
    updateSection(input: $input) {
      id
      name
      type
    }
  }
`;

const DELETE_SECTION = gql`
  mutation ($id: ID) @api(name: user) {
    deleteSection(id: $id) {
      success
    }
  }
`;

const MAKE_TEST_PAYMENT = gql`
  mutation ($input: TestInternalCheckoutStoreInput!) @api(name: market) {
    testPaymentInternalService(input: $input) {
      url
    }
  }
`;

const MAKE_CALCULATOR_PAYMENT = gql`
  mutation ($input: CalculatorDraftCheckoutStoreInput!) @api(name: market) {
    payForCalculatorDraft(input: $input) {
      url
    }
  }
`;

const LOGOUT_USER = gql`
  mutation @api(name: user) {
    logout {
      status
      message
    }
  }
`;

const SHARING_TYPES = gql`
  query @api(name: calculator) {
    getSharingSecurityType {
      id
      name
      code
    }
  }
`;

const GET_SHARING_BY_ID = gql`
  query ($id: ID!) @api(name: calculator) {
    getSharingById(id: $id) {
      id
      targetId: target_id
      targetType: target_type
      securityType: security_type {
        id
        name
        code
      }
      isAutoApprove: is_auto_approve
      isNeedNDA: is_need_nda
      isNeedPay: is_need_pay
      productId: product_id
      price
      currency
    }
  }
`;

const CREATE_SHARING = gql`
  mutation ($input: CreateSharingInput!) @api(name: calculator) {
    createSharing(input: $input) {
      id
      userId: user_id
      targetId: target_id
      targetType: target_type
      securityType: security_type {
        id
        name
        code
      }
      isAutoApprove: is_auto_approve
      isNeedNDA: is_need_nda
      isNeedPay: is_need_pay
      productId: product_id
      price
      currency
    }
  }
`;

const UPDATE_SHARING = gql`
  mutation ($input: UpdateSharingInput!) @api(name: calculator) {
    updateSharing(input: $input) {
      id
      targetId: target_id
      targetType: target_type
      securityType: security_type {
        id
        name
        code
      }
      isAutoApprove: is_auto_approve
      isNeedNDA: is_need_nda
      isNeedPay: is_need_pay
      productId: product_id
      price
      currency
    }
  }
`;

const GET_USERS_FOR_SHARING = gql`
  query ($input: UsersInput) @api(name: user) {
    adminUsers(input: $input) {
      count
      users {
        id
        firstName: first_name
        lastName: last_name
        avatar
        roleId: role_id
        roleName: role_name
        kyc
        status
      }
    }
  }
`;

const GET_COMPANIES_FOR_SHARING = gql`
  query ($input: CompaniesSearchInput, $first: Int!, $page: Int) @api(name: market) {
    getCompaniesByWithPagination(input: $input, first: $first, page: $page) {
      paginatorInfo {
        total
      }
      data {
        id
        companyName: company_name
        kyb: kyb_completed
        logos {
          original_name
          path
          type
          mediumPath
          smallPath
        }
      }
    }
  }
`;
const CREATE_BATCH_SHARING_ACCESS = gql`
  mutation ($input: [CreateSharingAccessInput]!) @api(name: calculator) {
    createBatchSharingAccess(input: $input) {
      id
      sharing {
        id
      }
      user_id
      company_id
      has_personal_access
      is_nda_signed
      is_approved
      is_cancel_nda
      is_cancel_payment
      access_state
    }
  }
`;

const GET_ACCESSES_BY_SHARING = gql`
  query ($sharing_id: ID!) @api(name: calculator) {
    getSharingAccessesBySharingId(sharing_id: $sharing_id) {
      id
      accessState: access_state
      sharingId: sharing_id
      userId: user_id
      companyId: company_id
      hasPersonalAccess: has_personal_access
      isNdaSignrd: is_nda_signed
      isApproved: is_approved
      isCancelNda: is_cancel_nda
      isCancelPayment: is_cancel_payment
    }
  }
`;

const GET_USERS_BY_ID = gql`
  query ($input: [ID]!) @api(name: user) {
    getUserListByIDs(input: $input) {
      id
      firstName: first_name
      lastName: last_name
      avatar
      roleId: role_id
      roleName: role_name
      kyc
    }
  }
`;

const DELETE_SHARING_ACCESS = gql`
  mutation ($input: [ID!]!) @api(name: calculator) {
    deleteSharingAccesses(input: $input)
  }
`;

const SHOW_ACTIVE_USER_COMPANIES = gql`
  query @api(name: market) {
    showActiveUserCompanies {
      id
      logos {
        id
        path
        type
        mediumPath
        smallPath
      }
      companyName: company_name
      isDraft: is_draft
      addressLocation {
        address
        countryId: country_id
        postcode: postcode
      }
      establishedDate: established_date
      industryGroupId: industry_group_id
      subIndustryId: sub_industry_id
      legalTypeId: legal_type_id
    }
  }
`;

const SHOW_ACTIVE_COMPANIES_BY_IDS = gql`
  query ($ids: [String!]!) @api(name: market) {
    showActiveCompaniesByIds(ids: $ids) {
      id
      logos {
        id
        path
        type
        mediumPath
        smallPath
      }
      kyb: kyb_completed
      companyName: company_name
      isDraft: is_draft
      addressLocation {
        address
        countryId: country_id
        postcode
      }
      establishedDate: established_date
      industryGroupId: industry_group_id
      subIndustryId: sub_industry_id
    }
  }
`;

const GET_REPORT_SURVEY = gql`
  mutation ($input: SurveyInput) @api(name: calculator) {
    getOrCreateReportSurvey(input: $input) {
      id
      resultId: result_id
      count
      questions {
        header
        question
        type
        code
        example: answer
        description
      }
      answers
      status
    }
  }
`;

const UPDATE_REPORT_SURVEY = gql`
  mutation ($input: UpdateSurveyInput) @api(name: calculator) {
    updateReportSurvey(input: $input) {
      id
      resultId: result_id
      answers
      status
    }
  }
`;

const GET_REPORT_FILES = gql`
  query ($result_id: String) @api(name: calculator) {
    getReportFiles(result_id: $result_id) {
      resultId: result_id
      fileName: file_name
      path
      type
      status
    }
  }
`;

const GET_RESULT_FILES = gql`
  query ($result_id: ID!) @api(name: calculator) {
    getResultFiles(result_id: $result_id) {
      id
      draftId: calc_tt_draft_id
      questionCode: questions_code
      originalName: original_name
      type
      uuidName: file
      description
      state
    }
  }
`;

const GET_QUESTIONS_FILE = gql`
  query ($input: getFileInput) @api(name: calculator) {
    getQuestionsFile(input: $input) {
      id
      draftId: calc_tt_draft_id
      questionCode: questions_code
      originalName: original_name
      type
      uuidName: file
      description
      state
    }
  }
`;

const UPDATE_CALC_QUESTION = gql`
  mutation ($input: inputBaseQuestion) @api(name: calculator) {
    updateCalcQuestions(input: $input) {
      draftId: calc_tt_draft_id
      data
    }
  }
`;

const GET_REPORT_QUESTIONS = gql`
  query ($calc_tt_draft_id: ID!, $first: Int, $page: Int) @api(name: calculator) {
    getCalcQuestions(calc_tt_draft_id: $calc_tt_draft_id, first: $first, page: $page) {
      draftId: calc_tt_draft_id
      data
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`;

const GET_RESULT_QUESTIONS = gql`
  query ($calc_tt_draft_id: ID!, $first: Int, $page: Int) @api(name: calculator) {
    getCustomResultQuestions(calc_tt_draft_id: $calc_tt_draft_id, first: $first, page: $page) {
      result_id: calc_tt_draft_id
      data
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`;
const GET_DRAFT_FILES = gql`
  query ($draft_id: ID!) @api(name: calculator) {
    getDraftFiles(draft_id: $draft_id) {
      id
      draftId: calc_tt_draft_id
      questionCode: questions_code
      originalName: original_name
      type
      uuidName: file
      description
      state
    }
  }
`;

// ===================
// DA Carbon

export const CARBON_GET_LIST = gql`
  query GetAllUserCarbonCredit @api(name: market) {
    getAllUserCarbonCredit {
      id
      credit_id
      order_id
      serial_number
      project
      project_link
      smart_contract_address
      user_id
      quantity
      email
      wallet
      created_at
    }
  }
`;

export const CARBON_GET_DATA = gql`
  query GetUserCarbonCredit($id: String!) @api(name: market) {
    getUserCarbonCredit(id: $id) {
      id
      credit_id
      order_id
      serial_number
      project
      project_link
      smart_contract_address
      user_id
      quantity
      email
      wallet
      created_at
    }
  }
`;

export const CARBON_GET_OFFSET_DATA = gql`
  mutation GetAllOffsetData($input: OffsetCreditDataInput!) @api(name: market) {
    getAllOffsetData(input: $input) {
      id
      tx_hash
      user_id
      register_id
      email
      wallet
      parentCertificate {
        smart_contract_address
        project
        project_link
      }
      issued_by
      issued_for
      description
      date
      beneficiary
      calc_result_id
      quantity
    }
  }
`;

export const CARBON_OFFSET = gql`
  mutation Offset($input: OffsetCreditInput!) @api(name: market) {
    offset(input: $input) {
      id
      tx_hash
      user_id
      register_id
      email
      wallet
      parentCertificate {
        smart_contract_address
        project
        project_link
      }
      issued_by
      description
      date
      beneficiary
      calc_result_id
      quantity
    }
  }
`;

// register

export const CARBON_CREATE_REGISTER = gql`
  mutation CreateRegistry($input: RegistryInput!) @api(name: market) {
    createRegistry(input: $input) {
      id
      company_id
      user_id
      name
      description
      unit
      project_id
    }
  }
`;

export const CARBON_GET_REGISTER = gql`
  query GetRegistry($id: String!) @api(name: market) {
    getRegistry(id: $id) {
      id
      company_id
      user_id
      name
      description
      unit
      project_id
    }
  }
`;

export const CARBON_GET_REGISTERS = gql`
  query GetRegistries($company_id: String, $user_id: String) @api(name: market) {
    getRegistries(company_id: $company_id, user_id: $user_id) {
      id
      company_id
      user_id
      name
      description
      unit
      project_id
    }
  }
`;

export const CARBON_UPDATE_REGISTER = gql`
  mutation UpdateRegistry($input: RegistryInput!) @api(name: market) {
    updateRegistry(input: $input) {
      id
      company_id
      user_id
      name
      description
      unit
      project_id
    }
  }
`;

export const CARBON_DELETE_REGISTER = gql`
  mutation DeleteRegistry($id: String!) @api(name: market) {
    deleteRegistry(id: $id) {
      id
    }
  }
`;

export const CARBON_GET_REGISTER_ENTRY = gql`
  query GetRegistryItem($id: String!) @api(name: market) {
    getRegistryItem(id: $id) {
      id
      carbon_registry_id
      customers_id
      name
      email
      wallet_address
      amount
      status
    }
  }
`;

export const CARBON_GET_REGISTER_ENTRIES_BY_ID = gql`
  query GetRegistryItemsByRegistryId($carbon_registry_id: String!) @api(name: market) {
    getRegistryItemsByRegistryId(carbon_registry_id: $carbon_registry_id) {
      id
      carbon_registry_id
      customers_id
      name
      email
      wallet_address
      amount
      status
    }
  }
`;

export const CARBON_CREATE_REGISTER_ENTRY = gql`
  mutation CreateRegistryItem($input: RegistryItemInput!) @api(name: market) {
    createRegistryItem(input: $input) {
      id
      carbon_registry_id
      customers_id
      name
      email
      wallet_address
      amount
      status
    }
  }
`;

export const CARBON_UPDATE_REGISTER_ENTRY = gql`
  mutation UpdateRegistryItem($input: RegistryItemInput!) @api(name: market) {
    updateRegistryItem(input: $input) {
      id
      carbon_registry_id
      customers_id
      name
      email
      wallet_address
      amount
      status
    }
  }
`;

export const CARBON_DELETE_REGISTER_ENTRY = gql`
  mutation DeleteRegistryItem($id: String!) @api(name: market) {
    deleteRegistryItem(id: $id) {
      id
    }
  }
`;

// ===================

export {
  GET_USER,
  LOGOUT_USER,
  GET_CURRENT_USER,
  GET_VIEWED_USER,
  GET_PRO_LOGIN,
  SEND_INVITE,
  CONFIRM_REGISTRATION_BY_INVITATION,
  REGISTER_BY_INVITATION,
  EMAIL_AND_TOKEN_VALIDATION,
  CREATE_NEW_INDIVIDUAL,
  CREATE_NEW_DIGITAL_INDIVIDUAL,
  GET_IDV_INIT,
  GET_USER_COMPANIES,
  GET_USER_COMPANIES_LIST,
  GET_IDV_INIT_SUCCESS,
  CHECK_EMAIL,
  GET_AGREEMENT,
  CREATE_COMMENT,
  UPDATE_USER_AVATAR,
  GET_ROLE,
  DELETE_POST,
  GET_POST_EMOJI_LIST,
  CREATE_FULL_POST,
  LIKE_POST,
  GET_URL_FOR_PUBLIC_UPLOAD,
  GET_URL_FOR_PRIVATE_UPLOAD,
  DELETE_FROM_PUBLIC,
  DELETE_FROM_PRIVATE,
  CHANGE_PASSWORD,
  GET_ALL_RESULTS,
  GET_USER_RESULTS_BY_PAGE_TYPE,
  GET_USER_RESULTS,
  GET_USER_RESULT,
  GET_COMPANY_QUESTIONS_DATA,
  GET_GLOBAL_QUESTIONS_DATA,
  GET_LOCAL_QUESTIONS_DATA,
  IS_ANY_DRATF_OR_RESULT_EXISTS,
  UPDATE_DRAFT,
  GET_DRAFT_BY_ID,
  GET_PRELIMINARY_ASSESSMENT,
  GET_USER_DRAFTS,
  GET_USER_DRAFTS_BY_PAGE_TYPE,
  DELETE_DRAFT,
  RENAME_DRAFT,
  CAN_PAY_DRAFT,
  UPDATE_USER_PROFILE,
  DELETE_USER_EXPERIENCE,
  DELETE_USER_EDUCATION,
  DELETE_USER_COLLABORATION,
  DELETE_USER_PROJECT,
  SAVE_USER_EDUCATION,
  SAVE_USER_EXPERIENCE,
  SAVE_USER_COLLABORATION,
  SAVE_USER_PROJECTS,
  GET_LANGUAGES_LIST,
  GET_ADMIN_USERS,
  UPDATE_USER_STATUS,
  FORGOT_USER_PASSWORD,
  CONFIRM_FORGOT_USER_PASSWORD,
  GET_ADMIN_ROLES_COUNT,
  GET_COMMUNITY_USERS,
  GET_COMMUNITY_USERS_MAP,
  UPDATE_USER_ROLE,
  GET_COMMUNITY_USERS_COUNTRIES,
  GET_VALIDATOR_AGREEMENT,
  SIGN_VALIDATOR_AGREEMENT,
  GET_SUBINDUSTRIES,
  GET_INDUSTRIAL_GROUPS,
  GET_COUNTRIES,
  GET_ALL_COMPANIES,
  GET_NOTIFICATIONS,
  MARK_AS_READ_NOTIFICATION,
  DELETE_NOTIFICATION,
  GET_TUTORIALS,
  GET_TUTORIAL_BY_ID,
  GET_PRICING_DATA,
  GET_DRAFT_PRICE,
  ACCEPT_INVITE,
  MAKE_TEST_PAYMENT,
  MAKE_CALCULATOR_PAYMENT,
  SHARING_TYPES,
  GET_SHARING_BY_ID,
  CREATE_SHARING,
  UPDATE_SHARING,
  GET_USERS_FOR_SHARING,
  CREATE_BATCH_SHARING_ACCESS,
  GET_ACCESSES_BY_SHARING,
  DELETE_SHARING_ACCESS,
  GET_USERS_BY_ID,
  SHOW_ACTIVE_USER_COMPANIES,
  GET_COMPANIES_FOR_SHARING,
  SHOW_ACTIVE_COMPANIES_BY_IDS,
  GET_REPORT_SURVEY,
  UPDATE_REPORT_SURVEY,
  GET_REPORT_FILES,
  GET_COMPANIES_BY_ID,
  GET_USER_COMPANIES_SHORT_LIST,
  GET_RESULT_FILES,
  GET_QUESTIONS_FILE,
  UPDATE_CALC_QUESTION,
  GET_REPORT_QUESTIONS,
  GET_RESULT_QUESTIONS,
  GET_DRAFT_FILES,
  GET_USER_PUBLIC_DATA,
  GET_ADMIN_ALL_COMPANIES,
  GET_INVITATIONS_WITH_PAGINATION,
  GET_INVITATION_BY_ID,
  UPDATE_ADMIN_COMPANY_STATUS,
  WITHDRAW_INVITATION,
  GET_ADMIN_TUTORIALS,
  CREATE_TUTORIAL,
  DELETE_TUTORIAL,
  UPDATE_TUTORIAL,
  GET_PRESENTATION_BY_ID,
  GET_ADMIN_PRESENTATIONS,
  CREATE_PRESENTATION,
  DELETE_PRESENTATION,
  UPDATE_PRESENTATION,
  DELETE_SECTION,
  GET_SECTIONS,
  CREATE_SECTION,
  UPDATE_SECTION,
  GET_PRESENTATIONS,
};
