import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { TITLES } from 'constants/pagesTitles';
import PageContainerImgBg from 'components/layout/PageContainer/PageContainerImgBg';
import GoalPage from 'core/views/SDGPage/GoalPage';
import { SLICES } from 'constants/SDGPage';
import { FourOhFour } from 'views/FourOhFour';

const Goal = () => {
  const [currentGoal, setCurrentGoal] = useState(null);
  const [isFourOhFour, setIsFourOhFour] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location) {
      setIsFourOhFour(false);
      const goalNumber = location.pathname.split('_')[1];
      if (goalNumber) {
        const goalDAta = SLICES.find((s) => `${s.goal}` === goalNumber);
        if (goalDAta) {
          setCurrentGoal(goalDAta);
        } else {
          setIsFourOhFour(true);
        }
      } else {
        setIsFourOhFour(true);
      }
    }
  }, [location]);

  return (
    <>
      {currentGoal && (
        <PageContainerImgBg
          imageBg="/images/sdg-main-bg.png"
          imageBgMobile={currentGoal.goalBg}
          title={TITLES.goalPage}
          isSdgPage
          isGoalPage
        >
          <GoalPage currentGoal={currentGoal} setCurrentGoal={setCurrentGoal} />
        </PageContainerImgBg>
      )}
      {isFourOhFour && <FourOhFour />}
    </>
  );
};

export default Goal;
