/* eslint-disable no-return-await */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import format from 'date-fns/format';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { getValidationSchema } from 'utils/validationSignUpSchemaGenerator';
import { useNavigate } from 'react-router';
import { COMPLETE_REG_KYC, COMPLETE_REG_REQUEST_INVITE } from 'constants/modals';
import { getBrowser } from 'utils/defineBrowser';
import Cookies from 'js-cookie';
import PartialLoader from 'components/layout/PartialLoader';
import ErrorModal from 'components/Modals/ErrorModal';
import HeaderRolesMenu from 'components/layout/Header/HeaderRolesMenu';
import { ROLES_LIST } from 'constants/joinPage';
import { SIGN_UP_FORM_DATA } from '../../../../constants/signUpPage';
import SignUpInput from './SignUpInput';
import ButtonsField from './ButtonsField';
import PrivacyPolicyField from './PrivacyPolicyField';
import InviteInput from './InviteInput';
import styles from './SignUpPage.module.scss';
import {
  CREATE_NEW_DIGITAL_INDIVIDUAL,
  CREATE_NEW_INDIVIDUAL,
  CONFIRM_REGISTRATION_BY_INVITATION,
  EMAIL_AND_TOKEN_VALIDATION,
  GET_IDV_INIT,
  REGISTER_BY_INVITATION,
  GET_USER_PUBLIC_DATA,
} from '../../../../api';
import DragDropField from './DragDropField';
import CompleteRegModal from './Modals/CompleteRegModal';
import { KYCYotiModal } from '../../../../modules/tt-modals';
import ThxModal from './Modals/ThxModal';
import WarningModal from './Modals/WarningModal';
import VerifyEmailModal from './Modals/VerifyEmailModal';
import CompanyNameConsentModal from './Modals/CompanyNameConsentModal';
import PolicyModalWarpper from '../../../../components/Modals/PolicyModal/PolicyModal';
import { useSearchParams } from 'react-router-dom';
import { deleteNestedKeys } from '../../../../utils/validation';
import InviteCodeModal from './InviteCodeModal';
import { openNotification } from 'tt-ui-lib/core';
import { useSelectedContext } from 'context/contextSelector';

const UPLOADED_STATUS = 'uploaded';

const SignUpPage = ({ role, digital }) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [avatar, setAvatar] = useState([]);
  const [docs, setDocs] = useState([]);
  const [avatarName, setAvatarName] = useState([]);
  const [docsNames, setDocsNames] = useState([]);
  const [formData, setFormData] = useState(null);
  const [isCompleteRegOpen, setIsCompleteRegOpen] = useState(false);
  const [isKycModelOpen, setIsKycModalOpen] = useState(false);
  const [isThxKycModelOpen, setIsThxKycModalOpen] = useState(false);
  const [isThxModelOpen, setIsThxModalOpen] = useState(false);
  const [isWarningModelOpen, setIsWarningModalOpen] = useState(false);
  const [isInvitationWarningModalOpen, setIsInvitationWarningModalOpen] = useState(false);
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const [isCompanyAgreeOpen, setIsCompanyAgreeOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [yotiUrl, setYotiUrl] = useState(null);
  const [isCompanyAgree, setIsCompanyAgree] = useState(false);
  const [isPolicyAgree, setIsPolicyAgree] = useState(false);
  const [isPolicyLoading, setIsPolicyLoading] = useState(false);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);
  const [isHonorAgree, setIsHonorAgree] = useState(false);
  const [isHonorModalOpen, setIsHonorModalOpen] = useState(false);
  const [policyText, setPolicyText] = useState(null);
  const [honorText, setHonorText] = useState(null);
  const [isRequestInviteModalOpen, setIsRequestInviteModalOpen] = useState(false);
  const [isIndividual, setIsIndividual] = useState(false);
  const [browser, setBrowser] = useState(null);
  const [isSingUpErrorModelOpen, setIsSingUpErrorModalOpen] = useState(false);
  const [isSingUpEmailErrorModelOpen, setIsSingUpEmailErrorModelOpen] = useState(false);
  const [isSubmitStepClicked, setIsSubmitStepClicked] = useState(false);
  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);
  const [isInvitationAcceptWarningModalOpen, setIsInvitationAcceptWarningModalOpen] =
    useState(false);
  const [verifyUserId, setVerifyUserId] = useState(null);
  const [codeModalOpen, setCodeModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [photoError, setPhotoError] = useState(false);

  const validationSchema = getValidationSchema(role.url, digital);
  const formOptions = { resolver: yupResolver(validationSchema[0]) };
  const formMethods = useForm(formOptions, { mode: 'all' });
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    control,
    setError,
    clearErrors,
    getFieldState,
    getValues,
    setValue,
    watch,
  } = formMethods;

  const currentPassword = watch('password');

  const [confirmRegistrationByInvitation] = useMutation(CONFIRM_REGISTRATION_BY_INVITATION);
  const [emailAndTokenValidation] = useMutation(EMAIL_AND_TOKEN_VALIDATION);
  const [createNewIndividual] = useMutation(CREATE_NEW_INDIVIDUAL);
  const [createNewDigitalIndividual] = useMutation(CREATE_NEW_DIGITAL_INDIVIDUAL);
  const [registerByInvitation] = useMutation(REGISTER_BY_INVITATION);
  const [getUser] = useLazyQuery(GET_USER_PUBLIC_DATA);

  const [idvInit] = useMutation(GET_IDV_INIT, {
    variables: { input: formOptions },
  });

  const { setIsError } = useSelectedContext((context) => context.app);
  const navigate = useNavigate();

  const handleSkip = () => {
    setIsCompleteRegOpen(false);
    setIsThxModalOpen(true);
  };

  const handleModalOpen = (setIsOpen) => setIsOpen(true);
  const handleModalClose = (setIsOpen) => setIsOpen(false);

  const handleKYCModalClose = () => {
    setIsKycModalOpen(false);
    handleSkip();
  };

  const handleProceed = () => {
    setIsCompleteRegOpen(false);
    (async () => {
      setIsSignUpLoading(true);
      const res = await idvInit({
        variables: {
          input: { user_id: userId, feedback_url: process.env.REACT_APP_FEED_LOCATION },
        },
      });
      setIsSignUpLoading(false);
      if (res.errors) {
        console.log(errors);
      } else if (!res.errors && res.data.idvInit.url) {
        setYotiUrl(res.data.idvInit.url);
        setIsKycModalOpen(true);
      }
    })();
  };

  const removeEmptyFields = (data) => {
    if (data?.user_experiences?.length === 0) {
      delete data.user_experiences;
    } else {
      data?.user_experiences?.map((exp) => {
        if (Object.prototype.hasOwnProperty.call(exp, 'is_present')) {
          delete exp.is_present;
        }
      });
    }
    if (data?.user_collaborations?.length === 0) {
      delete data.user_collaborations;
    } else {
      data?.user_collaborations?.map((p) => {
        if (Object.prototype.hasOwnProperty.call(p, 'is_present')) {
          delete p.is_present;
        }
      });
    }
    if (!data?.about_myself) {
      delete data?.about_myself;
    }
    if (!data?.interests?.length) {
      delete data.interests;
    }
    if (!data?.tel) {
      data.tel = null;
    }
  };

  const setUser = (accessToken, user, setId) => {
    const domainValue = process.env.REACT_APP_DOMAIN;
    Cookies.set('access_token', accessToken, { domain: domainValue });
    Cookies.set('userId', user.id, { domain: domainValue });
    Cookies.set('calculatorId', user.calculator_id, { domain: domainValue });
    setId(user.id);
  };

  const addFilesWithReturn = (filesArray) => {
    let data = '';

    if (filesArray && filesArray.length) {
      const docsArr = filesArray.reduce(
        (arr, doc) =>
          doc.status === UPLOADED_STATUS && [
            ...arr,
            { newName: doc.newName, oldName: doc.oldName },
          ],
        []
      );

      data = JSON.stringify(docsArr);
    }

    return data;
  };

  const addFiles = (data) => {
    if (avatarName && avatarName.length && avatarName[0].status === UPLOADED_STATUS) {
      data.avatar = avatarName[0].newName;
    }
    if (docsNames && docsNames.length) {
      const docsArr = docsNames.reduce(
        (arr, doc) =>
          doc.status === UPLOADED_STATUS && [
            ...arr,
            { newName: doc.newName, oldName: doc.oldName },
          ],
        []
      );

      const jsonString = JSON.stringify(docsArr);

      data.documents = jsonString;
    }
  };

  const addRole = (data) => {
    if (role.url !== 'individual') {
      data.role = role.url;
    }

    if (digital === 'true') {
      data.role = 'digital';
    }
  };

  const onSubmit = async (data) => {
    removeEmptyFields(data);
    addFiles(data);
    addRole(data);

    if (digital !== 'true') {
      if (data.code && role.url === 'individual') {
        const newData = {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          password: data.password,
          password_confirmation: data.password_confirmation,
          avatar: data.avatar,
          role: role.url,
          code: data.code || searchParams.get('code'),
        };

        setIsSignUpLoading(true);
        registerByInvitation({ variables: { input: newData } })
          .then(async (res) => {
            if (!res) {
              setIsSingUpErrorModalOpen(true);
              setIsError(true);
            } else if (res.errors) {
              setIsSingUpErrorModalOpen(true);
            } else if (!res.errors && res.data.registerByInvitation.status) {
              setIsVerifyEmailModalOpen(true);
              setIsSignUpLoading(false);
            }
          })
          .catch((err) => {
            if (err.message === 'Validation failed for the field [confirmInvitation].') {
              setError('code', { type: 'custom', message: 'The code is invalid.' });
            } else if (err.message === 'The invitation cannot be accepted') {
              setIsInvitationAcceptWarningModalOpen(true);
            } else {
              openNotification({
                message: err.message,
                type: 'error',
              });
            }
            setIsError(true);
          })
          .finally(() => {
            setIsSignUpLoading(false);
          });
      }
      if (!data.code && role.url === 'individual') {
        try {
          setIsSignUpLoading(true);
          const newUser = await createNewDigitalIndividual({
            variables: { input: deleteNestedKeys(data, ['province', 'phoneCode', 'expertises']) },
          });

          if (newUser.errors) {
            setIsSingUpErrorModalOpen(true);
            setIsSignUpLoading(false);
            openNotification({
              message: 'Error while creating your account, try again later.',
              type: 'error',
            });
          }
          if (!newUser.errors && newUser.data.registerIndividual.tokens) {
            setIsVerifyEmailModalOpen(true);
            setIsSignUpLoading(false);
          }
          setIsSignUpLoading(false);
        } catch (e) {
          if (e.message === 'Validation failed for the field [confirmInvitation].') {
            setError('code', { type: 'custom', message: 'The code is invalid.' });
          } else if (e.message === 'Validation failed for the field [registerIndividual].') {
            setIsSingUpEmailErrorModelOpen(true);
          } else {
            setError('code', { type: 'custom', message: e.message });
          }
          setIsSignUpLoading(false);
        }
      }
      if (role.url !== 'individual') {
        setIsSignUpLoading(true);

        const verifyData = {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          password: data.password,
          password_confirmation: data.password_confirmation,
          avatar: data.avatar,
          company: data.company_name,
          files: data.files || undefined,
          role: role.url,
          code: data.code || searchParams.get('code'),
        };

        registerByInvitation({ variables: { input: verifyData } })
          .then(async (res) => {
            if (!res) {
              setIsSingUpErrorModalOpen(true);
              setIsError(true);
            } else if (res.errors) {
              setIsSingUpErrorModalOpen(true);
            } else if (!res.errors && res.data.registerByInvitation.status) {
              setIsVerifyEmailModalOpen(true);
              setIsSignUpLoading(false);
            }
          })
          .catch((err) => {
            if (err.message === 'Validation failed for the field [confirmInvitation].') {
              setError('code', { type: 'custom', message: 'The code is invalid.' });
            } else if (err.message === 'The invitation cannot be accepted') {
              setIsInvitationAcceptWarningModalOpen(true);
            } else {
              openNotification({
                message: err.message,
                type: 'error',
              });
            }
            setIsError(true);
          })
          .finally(() => {
            setIsSignUpLoading(false);
          });

        // const code = searchParams.get('code');
      }
    }

    if (digital === 'true') {
      if (!data.avatar || data.avatar === '') {
        setError('photo', {
          type: 'custom',
          message: 'Please fill out the rest fields. This is mandatory.',
        });
        return;
      }

      const newData = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
        avatar: data.avatar,
        company: null,
        role: 'digital',
        code: data.code || searchParams.get('code'),
      };

      setIsSignUpLoading(true);
      registerByInvitation({ variables: { input: newData } })
        .then(async (res) => {
          if (!res) {
            setIsSingUpErrorModalOpen(true);
            setIsError(true);
          } else if (res.errors) {
            setIsSingUpErrorModalOpen(true);
          } else if (!res.errors && res.data.registerByInvitation.status) {
            setIsVerifyEmailModalOpen(true);
            setIsSignUpLoading(false);
          }
        })
        .catch((err) => {
          if (err.message === 'Validation failed for the field [confirmInvitation].') {
            setError('code', { type: 'custom', message: 'The code is invalid.' });
          } else if (err.message === 'The invitation cannot be accepted') {
            setIsInvitationAcceptWarningModalOpen(true);
          } else {
            openNotification({
              message: err.message,
              type: 'error',
            });
          }
          setIsError(true);
        })
        .finally(() => {
          setIsSignUpLoading(false);
        });
    }
  };

  const validateBeforeSubmit = async (e) => {
    e.preventDefault();
    const isStepValid = await trigger();
    if (avatar.length !== 0) {
      if (!isStepValid) {
        setIsSubmitStepClicked(true);
      } else {
        setPhotoError(false);
        await handleSubmit(async (data) => await onSubmit(data))();
      }
    } else {
      setPhotoError('Please fill out the rest fields. This is mandatory.');
    }
  };

  const handleSignUpAsIndividual = async () => {
    const values = getValues();

    const requestDataIndividual = {
      tel: values.tel,
      user_id: verifyUserId,
    };

    setIsSignUpLoading(true);
    await createNewIndividual({ variables: { input: requestDataIndividual } })
      .then((newUser) => {
        if (newUser.errors) {
          setIsRequestInviteModalOpen(false);
          setIsSingUpErrorModalOpen(true);
          setIsSignUpLoading(false);
        }

        if (!newUser.errors && newUser.data.registerIndividual.tokens) {
          const accessToken = newUser.data.registerIndividual.tokens.access_token;
          setIsIndividual(true);
          setUser(accessToken, newUser.data.registerIndividual.tokens.user, setUserId);
          setIsRequestInviteModalOpen(false);
          setIsSignUpLoading(false);
          setIsThxModalOpen(true);
        }
      })
      .catch((err) => {
        setIsRequestInviteModalOpen(false);
        setIsSingUpErrorModalOpen(true);
        setIsSignUpLoading(false);
      });
  };

  const goToFeed = () => {
    navigate('/dashboard/feed');
  };

  const confirmRegistration = (confirmedUserId) => {
    const newUserData = {
      user_id: confirmedUserId,
      code: searchParams.get('code'),
    };

    confirmRegistrationByInvitation({
      variables: { input: newUserData },
    })
      .then(async (response) => {
        if (!response) {
          setIsSingUpErrorModalOpen(true);
          setIsError(true);
        } else if (response.errors) {
          setIsSingUpErrorModalOpen(true);
          setIsSignUpLoading(false);
        } else if (
          !response.errors &&
          response.data.confirmRegistrationByInvitation.tokens.user.id
        ) {
          const accessToken = response.data.confirmRegistrationByInvitation.tokens.access_token;

          setUser(
            accessToken,
            response.data.confirmRegistrationByInvitation.tokens.user,
            setUserId
          );
        }
        setIsSignUpLoading(false);
      })
      .catch((err) => {
        if (err.message === 'Validation failed for the field [confirmInvitation].') {
          setError('code', { type: 'custom', message: 'The code is invalid.' });
        } else if (err.message === 'The invitation cannot be accepted') {
          setIsInvitationAcceptWarningModalOpen(true);
        } else {
          openNotification({
            message: err.message,
            type: 'error',
          });
        }
        setIsError(true);
      })
      .finally(() => {
        setIsSignUpLoading(false);
      });
  };

  const emailTokenValidation = async (token, email) => {
    const validationData = await emailAndTokenValidation({
      variables: {
        input: { token, email },
      },
    });
    if (validationData.data.confirmEmail.status) {
      setVerifyUserId(validationData.data.confirmEmail.user.id);
    }
    searchParams.delete('token');
    searchParams.delete('email');
    setSearchParams(searchParams);
    if (role.url !== 'individual') {
      confirmRegistration(validationData.data.confirmEmail.user.id);
    } else {
      setUser(
        validationData.data.confirmEmail.access_token,
        validationData.data.confirmEmail.user,
        setUserId
      );
    }
    setIsSignUpLoading(false);
  };

  const draftUser = async (draftUserId) => {
    const userData = await getUser({ variables: { id: draftUserId } });
    if (userData && userData.data && userData.data.user) {
      setVerifyUserId(userData.data.user.id);
    }
    setIsSignUpLoading(false);
  };

  const setCodeInForm = (newCode) => {
    setValue('code', newCode);
  };

  useEffect(() => {
    setIsSignUpLoading(true);
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    const draftUserId = Cookies.get('draft_userId');
    if (token && email) {
      emailTokenValidation(token, email);
    } else if (draftUserId) {
      draftUser(draftUserId);
    } else {
      setIsSignUpLoading(false);
    }
    setBrowser(getBrowser());
  }, []);

  useEffect(() => {
    if (digital === 'true') {
      const data = SIGN_UP_FORM_DATA.find((d) => d.role === 'digital');
      return setFormData(data);
    }

    if (role) {
      const data = SIGN_UP_FORM_DATA.find((d) => d.role === role.url);
      setFormData(data);
    }
  }, [role]);

  useEffect(() => {
    if (userId && role.url !== 'individual' && !isIndividual) {
      handleModalOpen(setIsCompleteRegOpen);
    } else if (userId && role.url === 'individual' && digital === 'true') {
      handleModalOpen(setIsCompleteRegOpen);
    } else if (userId && role.url === 'individual') {
      goToFeed();
    }
  }, [userId, isIndividual]);

  useEffect(() => {
    if (!userId) {
      setCodeModalOpen(true);
    } else {
      setCodeModalOpen(false);
    }
  }, [role.url]);

  useEffect(() => {
    if (avatar.length > 0) {
      setPhotoError(false);
    }
  }, [avatar]);

  useEffect(() => {
    (async () => {
      const isStepValid = await trigger();
      setIsFormValid(isStepValid);
    })();
  }, []);

  return (
    <>
      <div className={styles.formWrapper}>
        {role && formData && (
          <>
            <HeaderRolesMenu roles={ROLES_LIST} selectedRole={role} isSignUpPage isScrolled />
            {/* <PrivacyPolicyField /> */}
            <FormProvider {...formMethods}>
              <form
                autoComplete="off"
                name="sign_up"
                onSubmit={async (e) => await validateBeforeSubmit(e)}
                className={styles.form}
                method="post"
              >
                {formData.formFields.map((inputData, index) => (
                  <div key={inputData.step} className={`${styles.stepWrapper} ${styles.active}`}>
                    {inputData.fields.map((field) => (
                      <div key={field.name} className={field.name === 'tel' && styles.addedZIndex}>
                        {field.name === 'photo' || field.name === 'doc' ? (
                          <DragDropField
                            key={field.name + 1}
                            title={field.title}
                            fieldName={field.name}
                            dragDropDesc={field.dragDropDesc}
                            description={field.description}
                            add={field.add}
                            maxFiles={1}
                            isMultiple={field.name === 'doc'}
                            isImage={field.name === 'photo'}
                            files={field.name === 'doc' ? docs : avatar}
                            setFiles={field.name === 'doc' ? setDocs : setAvatar}
                            path={field.name === 'photo' ? 'avatars' : 'documents'}
                            filesNames={field.name === 'photo' ? avatarName : docsNames}
                            setFilesNames={field.name === 'photo' ? setAvatarName : setDocsNames}
                            formErrors={errors}
                            clearErrors={clearErrors}
                            photoError={photoError}
                          />
                        ) : field.name === 'policy' ? (
                          <PrivacyPolicyField
                            setIsPolicyModalOpen={setIsPolicyModalOpen}
                            setIsHonorModalOpen={setIsHonorModalOpen}
                            setIsPolicyAgree={setIsPolicyAgree}
                            setIsHonorAgree={setIsHonorAgree}
                            isPolicyAgree={isPolicyAgree}
                            isHonorAgree={isHonorAgree}
                            setPolicyText={setPolicyText}
                            setHonorText={setHonorText}
                            description={field.description}
                            errorMessage={field.errorMessage}
                            isSubmitStepClicked={isSubmitStepClicked}
                            setIsPolicyLoading={setIsPolicyLoading}
                          />
                        ) : (
                          <SignUpInput
                            name={field.name}
                            key={field.name + 2}
                            type={field.type}
                            placeholder={field.placeholder}
                            description={field.description}
                            successMessage={field.successMessage}
                            trigger={trigger}
                            control={control}
                            errors={errors}
                            role={role}
                            setIsCompanyAgreeOpen={setIsCompanyAgreeOpen}
                            setIsCompanyAgree={setIsCompanyAgree}
                            isAgree={isCompanyAgree}
                            setIsFormValid={setIsFormValid}
                            browser={browser}
                            isSubmitStepClicked={isSubmitStepClicked}
                            passwordValue={currentPassword}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                ))}
                <ButtonsField role={role} code={getValues().code} />
              </form>
            </FormProvider>
            <CompleteRegModal
              isModelOpen={isCompleteRegOpen}
              setIsOpen={setIsCompleteRegOpen}
              handleClose={handleModalClose}
              title={COMPLETE_REG_KYC.title}
              text={COMPLETE_REG_KYC.text}
              firstBtnText={COMPLETE_REG_KYC.firstBtnText}
              secondBtnText={COMPLETE_REG_KYC.secondBtnText}
              handleSecondBtnClick={handleProceed}
              handleFirstBtnClick={handleSkip}
            />
            <KYCYotiModal
              isModelOpen={isKycModelOpen}
              setIsOpen={setIsKycModalOpen}
              handleClose={handleKYCModalClose}
              yotiUrl={yotiUrl}
            />
            <ThxModal
              isModelOpen={isThxKycModelOpen}
              setIsOpen={setIsThxKycModalOpen}
              handleClose={handleModalClose}
              text="You have been registered on TransparenTerra."
              subtext="Welcome onboard!"
            />
            <ThxModal
              isModelOpen={isThxModelOpen}
              setIsOpen={setIsThxModalOpen}
              handleClose={handleModalClose}
              text="You have been signed up on TransparenTerra."
            />
            <WarningModal
              isModelOpen={isWarningModelOpen}
              setIsOpen={setIsWarningModalOpen}
              handleClose={handleModalClose}
              text="Please verify your email and proceed the sign-up process by clicking on the button in the email."
            />
            <WarningModal
              isModelOpen={isInvitationWarningModalOpen}
              setIsOpen={setIsInvitationWarningModalOpen}
              handleClose={handleModalClose}
              text="Sorry, but it looks like you don't have an invite."
            />
            <WarningModal
              isModelOpen={isInvitationAcceptWarningModalOpen}
              setIsOpen={setIsInvitationAcceptWarningModalOpen}
              handleClose={handleModalClose}
              text="The invitation cannot be accepted."
            />
            <VerifyEmailModal
              isModelOpen={isVerifyEmailModalOpen}
              setIsOpen={setIsVerifyEmailModalOpen}
              handleClose={handleModalClose}
              title="Please verify your email address"
              text={`We have sent an email with a confirmation link to ${getValues().email}.`}
              subText="In order to proceed the sign-up process, please click the button."
            />
            <ErrorModal
              isModelOpen={isSingUpErrorModelOpen}
              setIsOpen={setIsSingUpErrorModalOpen}
              handleClose={handleModalClose}
              title="Sorry, something went wrong."
              subTitle="Please try again."
            />
            <ErrorModal
              isModelOpen={isSingUpEmailErrorModelOpen}
              setIsOpen={setIsSingUpEmailErrorModelOpen}
              handleClose={handleModalClose}
              title="The email has already been taken."
            />
            <CompanyNameConsentModal
              isModelOpen={isCompanyAgreeOpen}
              setIsOpen={setIsCompanyAgreeOpen}
              handleClose={handleModalClose}
              setIsAgree={setIsCompanyAgree}
              isAgree={isCompanyAgree}
              text="If you use someone else's trademark name, you are solely responsible for violation of copyright or any other rights of the copyright holders."
              checkboxText="I have read and agree"
            />
            <PolicyModalWarpper
              isModelOpen={isPolicyModalOpen}
              setIsOpen={setIsPolicyModalOpen}
              handleClose={handleModalClose}
              setIsAgree={setIsPolicyAgree}
              isAgree={isPolicyAgree}
              text={policyText}
              checkboxText="I agree to the Privacy Policy"
              loading={isPolicyLoading}
            />
            <PolicyModalWarpper
              isModelOpen={isHonorModalOpen}
              setIsOpen={setIsHonorModalOpen}
              handleClose={handleModalClose}
              setIsAgree={setIsHonorAgree}
              isAgree={isHonorAgree}
              text={honorText}
              checkboxText="I accept the Honor Code of TransparenTerra members"
              loading={isPolicyLoading}
            />
            <CompleteRegModal
              isModelOpen={isRequestInviteModalOpen}
              setIsOpen={setIsRequestInviteModalOpen}
              handleClose={handleModalClose}
              text={COMPLETE_REG_REQUEST_INVITE(role).text}
              firstBtnText={COMPLETE_REG_REQUEST_INVITE(role).firstBtnText}
              secondBtnText={COMPLETE_REG_REQUEST_INVITE(role).secondBtnText}
              handleFirstBtnClick={handleSignUpAsIndividual}
            />
            <InviteCodeModal
              open={codeModalOpen}
              setOpen={setCodeModalOpen}
              setCodeInForm={setCodeInForm}
              setGlobalLoading={setIsSignUpLoading}
              role={role}
            />
          </>
        )}
      </div>
      {/* STEP_ITEMS */}
      {/* <div className={styles.stepNavWrapper}>
        {formData &&
          formData.formFields.length > 1 &&
          formData.formFields.map((stepContent) => (
            <StepItem
              id={stepContent.step}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              roleIndex={role.index}
              key={stepContent.step + role.index}
            />
          ))}
      </div> */}
      <PartialLoader loading={isSignUpLoading} />
    </>
  );
};

export default SignUpPage;
