import { useNavigate } from 'react-router-dom';
import VideoBg from '../../../components/layout/VideoBg';
import Header from '../../../components/layout/Header';
import styles from './HomePage.module.scss';
import { useWindowSize } from '../../../utils/useWindowSize';

const MAX_MOBILE_WIDTH = 678;

const HomePage = () => {
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  return (
    <div className={styles.homePage}>
      <Header homePage />
      <VideoBg
        src="/video/start-bg.mp4"
        srcImg="/images/start-body-bg.jpg"
        isAutoPlay={windowSize.width > MAX_MOBILE_WIDTH}
      />
      <div className={styles.mainWrapper}>
        <div className={styles.startBl}>
          <h1>
            <div className={styles.startHeader}>TransparenTerra:</div>
            <div className={styles.startSubHeader}>
              Your trusted partner for comprehensive
              <br />
              sustainability solutions
            </div>
          </h1>
          <div className={styles.startTxt}>
            Accurately assess, report, validate, and verify your environmental and social impact. We
            provide Transparency for businesses of all sizes across industries. Join our global
            community to build trust, enhance compliance, strengthen your brand, go net zero, and
            make informed decisions.
            <br />
            Start your sustainability journey today!
          </div>
          <div className={styles.startBut}>
            <button
              type="button"
              className={styles.startBtn}
              onClick={() => navigate(`/dashboard/feed`)}
            >
              START
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
