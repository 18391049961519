import React from 'react';
import styles from '../PostView.module.scss';
import { ContentParser } from '../../ContentEditor/Parser';
import { RightIcon } from 'tt-ui-kit';
import { AppAvatar } from '../../Avatar';
import moment from 'moment/moment';

const ArticleBodyLayout = ({ postInstance, openPost, postBody, isPostPage, openUserProfile }) => {
  const cover = postInstance.cover ? postInstance.cover[0] || '' : '';
  const { title, preview } = postInstance;

  return isPostPage ? (
    <div className={styles.articlePageBody}>
      {cover?.url ? <img src={cover.url || ''} alt="" className={styles.articlePageCover} /> : null}

      <div className={styles.articlePageTitle}>{title}</div>

      <div className={styles.articlePageUserRow}>
        <div className={styles.postAvatar} onClick={openUserProfile}>
          <AppAvatar
            nav={1}
            size="xs"
            userName={
              postInstance?.user
                ? `${postInstance.user.first_name} ${postInstance.user.last_name}`
                : 'Unknown'
            }
            src={postInstance?.user?.avatar || ''}
          />
          <div className={styles.postAvatarTitle}>
            <p>
              {postInstance?.user
                ? `${postInstance.user.first_name} ${postInstance.user.last_name}`
                : 'Unknown'}
            </p>
            <span>{moment(postInstance.updated_at).format('DD/MM/YYYY')}</span>
          </div>
        </div>
      </div>

      <div className={styles.postBody}>
        <ContentParser content={postBody} />
      </div>
    </div>
  ) : (
    <div style={{ marginBottom: 10, height: '100%', display: 'flex', flexDirection: 'column' }}>
      {preview ? <div className={styles.articleFeedPreview}>{preview}</div> : null}

      {cover?.url ? <img src={cover.url || ''} alt="" className={styles.articleFeedCover} /> : null}

      <div className={styles.articleFeedLink} onClick={() => openPost()}>
        <div className={styles.articleFeedLinkContent}>
          <div className={styles.articleFeedTitle}>{title}</div>

          {/* <div className={styles.articleFeedDesc}> */}
          {/*  <div className={styles.articleBodyFeed}> */}
          {/*    <ContentParser content={postBody} /> */}
          {/*  </div> */}
          {/* </div> */}
        </div>

        <div>
          <RightIcon style={{ width: 20, height: 20 }} />
        </div>
      </div>
    </div>
  );
};

export default ArticleBodyLayout;
