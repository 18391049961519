/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GENERAL_MENU } from 'constants/menuPage';
import { TBSTooltip } from 'components/TBSTooltip/TBSTooltip';
import clsx from 'clsx';
import styles from './MenuPage.module.scss';
import MenuCarousel from './MenuCarousel';
import HelpModal from './HelpModal';
import MenuPageFooter from './MenuPageFooter';
import Cookies from 'js-cookie';

const MenuPage = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [hoveredSubItem, setHoveredSubItem] = useState(null);
  const [itemToHelp, setItemToHelp] = useState(null);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [itemToHelpModal, setItemToHelpModal] = useState(null);
  const [isOnItemClick, setIsOnItemClick] = useState(false);

  const idUser = Cookies.get('userId');

  const chooseItemToHelp = (selected, hoveredSub) => {
    if (hoveredSub && hoveredSub.desc) {
      return hoveredSub;
    }
    if (selected && selected.desc) {
      return selected;
    }
    return null;
  };

  const handleOpenModal = (item) => {
    setItemToHelpModal(item);
    setIsHelpModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsHelpModalOpen(false);
  };

  useEffect(() => {
    if (selectedItem || (selectedItem && hoveredSubItem)) {
      setItemToHelp(chooseItemToHelp(selectedItem, hoveredSubItem));
    }
  }, [selectedItem, hoveredSubItem]);

  return (
    <>
      <div
        className={clsx(
          styles.menuPageContainer,
          isOnItemClick ? styles.sumMenuActive : '',
          selectedItem && selectedItem.subList && selectedItem.subList.length
            ? styles.sumMenuContain
            : ''
        )}
      >
        <h1>Menu</h1>
        <div className={styles.menuContainer}>
          <div className={styles.menuHelpContainer}>
            {itemToHelp && (
              <div className={styles.menuHelpSecondContainer}>
                <div className={styles.menuHelpTitle}>{itemToHelp.label}</div>
                <div
                  className={styles.menuHelpText}
                  dangerouslySetInnerHTML={{ __html: `${itemToHelp.desc}` }}
                />
              </div>
            )}
          </div>
          <div className={styles.menuMainContainer}>
            <MenuCarousel
              data={GENERAL_MENU.map((item) => {
                if (idUser && item.url === '/sign_in') {
                  return {
                    label: 'Home',
                    url: '/dashboard/feed',
                    desc: '',
                    subList: null,
                  };
                }

                return item;
              })}
              setSelectedItem={setSelectedItem}
              setIsOnItemClick={setIsOnItemClick}
              isOnItemClick={isOnItemClick}
            />
          </div>
          <div
            className={`${styles.subMenuContainer} ${
              selectedItem && selectedItem.subList && selectedItem.subList.length && styles.active
            }`}
          >
            <div className={styles.backArrow} onClick={() => setIsOnItemClick(false)} />
            {selectedItem &&
              selectedItem.subList &&
              selectedItem.subList.length &&
              selectedItem.subList.map((subItem, index) => (
                <div className={styles.subMenuSecondContainer} key={subItem.label + index}>
                  <div
                    className={styles.subMenuItem}
                    onMouseEnter={() => setHoveredSubItem(subItem)}
                    onMouseLeave={() => setHoveredSubItem(null)}
                  >
                    {subItem.toBeSoon ? (
                      <TBSTooltip>
                        <Link to={subItem.url}>{subItem.label}</Link>
                      </TBSTooltip>
                    ) : (
                      <Link to={subItem.url}>{subItem.label}</Link>
                    )}

                    {itemToHelp && itemToHelp.desc && (
                      <div
                        className={styles.helpIconWrapper}
                        onClick={() => handleOpenModal(subItem)}
                        aria-hidden="true"
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <HelpModal
          isModelOpen={isHelpModalOpen}
          handleClose={handleCloseModal}
          setIsOpen={setIsHelpModalOpen}
          title={itemToHelpModal?.label ?? ''}
          text={itemToHelpModal?.desc ?? ''}
        />
      </div>
      <MenuPageFooter />
    </>
  );
};

export default MenuPage;
