export const ROLES_LIST = [
  {
    url: 'expert',
    label: 'Expert',
    index: 'exp',
  },
  {
    url: 'investor',
    label: 'Investor',
    index: 'inv',
  },
  {
    url: 'business',
    label: 'Business',
    index: 'bsn',
  },
  {
    url: 'individual',
    label: 'Individual',
    index: 'ind',
  },
];

export const EXTRA_ROLES_LIST = [
  {
    roleName: 'expert',
    pageTitle: 'TransparenTerra - Role - expert',
    index: 'exp',
    sectionsContent: [
      {
        step: 1,
        content: '',
      },
      {
        step: 2,
        content:
          '<p><b>Experts are independent members of our community</b> who provide advice, assessment, and consulting to other community members.</p>',
      },
      {
        step: 3,
        content:
          '<p><b>We invite you to join the first global professional community</b> of sustainability experts!</p>',
      },
      {
        step: 4,
        content:
          '<p><b>Experts play a principal role in the TransparenTerra ecosystem.</b> Consulting companies and professional and certified sustainability experts gather here to:</p><ul><li>Advise business owners on the sustainability of their organizations and products;</li><li>Help ESG investors do due diligence on the sustainability of companies that interest them;</li><li>Invite other experts and expand the professional network.</li></ul>',
      },
      {
        step: 5,
        content:
          '<p><b>You can become an expert</b> only by receiving a special invitation. You can get it either from TransparenTerra or from an expert who has already registered and has expert status on TransparenTerra.</p>',
      },
      {
        step: 6,
        content:
          '<p><b>To get an invitation from TransparenTerra</b>, you need to complete the registration form, attach documents confirming your competence and level of expertise, and submit a request. We can also direct your invitation requests to other TransparenTerra experts associated with your email address.</p>',
      },
    ],
    videoPath: '/video/exp-bg.mp4',
    bgImgPath: '/images/exp-body-bg.jpg',
  },
  {
    roleName: 'investor',
    pageTitle: 'TransparenTerra - Role - investor',
    index: 'inv',
    sectionsContent: [
      {
        step: 1,
        content: '',
      },
      {
        step: 2,
        content:
          '<p><b>ESG professional Investors on TransparenTerra</b> have access to verified and accurate data on projects and businesses that need support or are seeking investments. Visualization, transparency, and data trustworthiness help investors make decisions that will change the world for the better.</p>',
      },
      {
        step: 3,
        content:
          '<p><b>Investing in sustainable development impacts our future.</b> TransparenTerra welcomes ESG professional investors who are looking for companies and projects that are willing to share their verified data, processes, and plans.</p>',
      },
      {
        step: 4,
        content:
          '<h3>On TransparenTerra, you can:</h3><ul><li>get access to companies’ ESG scores;</li><li>request a sustainability due diligence test of a particular company;</li><li>sign an agreement directly on the platform using blockchain notarization;</li><li>invest in tokenized shares or assets around the world;</li><li>сooperate with the TransparenTerra expert community;</li><li>сreate your own professional communities;</li><li>blog and share your experience;</li><li>organize and host webinars and online conferences.</li></ul>',
      },
      {
        step: 5,
        content:
          '<p><b>You can become an investor</b> only by receiving a special invitation. You can get it either from TransparenTerra or from an expert or ESG professional investor who has already registered and has expert or professional investor status on TransparenTerra.</p>',
      },
      {
        step: 6,
        content:
          '<p><b>To get an invitation from TransparenTerra</b>, you need to complete the registration form, attach documents confirming your competence and level of expertise, and submit a request. We can also direct your invitation requests to other TransparenTerra experts associated with your email address.</p>',
      },
    ],
    videoPath: '/video/inv-bg.mp4',
    bgImgPath: '/images/inv-body-bg.jpg',
  },
  {
    roleName: 'business',
    pageTitle: 'TransparenTerra - Role - business',
    index: 'bsn',
    sectionsContent: [
      {
        step: 1,
        content: '',
      },
      {
        step: 2,
        content:
          '<p><b>Businesses on TransparenTerra</b> provide sustainable services, products, and share information on the raw materials they use and their manufacturing and shipping processes. Certifications, peer reviews, original eco-friendly products, and services are concentrated here.</p>',
      },
      {
        step: 3,
        content:
          '<p><b>The TransparenTerra community welcomes businesses</b> that strive to become truly sustainable and want to demonstrate their proactive position to a like-minded community.</p><h3>On TransparenTerra, you can:</h3><ul><li>connect to experts that can help you improve the sustainable performance of your business and production</li><li>tokenize your company and products</li><li>attract ESG professional investors around the world</li><li>leverage a global marketplace of sustainable products and services</li><li>buy off-set certificates to mitigate your company’s environmental impact</li><li>sell off-set certificates if your company has an approved status for an offset project.</li></ul>',
      },
      {
        step: 4,
        content:
          "<p><b>Harness TransparenTerra’s approved methodology for ESG score</b> calculation.<br>Calculate the environmental footprint of your company and products.<br>Learn how to improve it.<br>Calculate your company's ESG score and prove your sustainability to partners, investors and consumers.</p>",
      },
      {
        step: 5,
        content:
          '<p><b>To become a member of the TransparenTerra</b> Sustainable Business Community, a company must receive an invitation. You can request from TransparenTerra directly or join us through an invitation from an expert or store already registered on the platform.</p>',
      },
      {
        step: 6,
        content:
          '<p><b>To receive an invitation from TransparenTerra</b>, fill out our registration form, attach certificates and other documents confirming the status and activities of your company, and send us your request.</p>',
      },
    ],
    videoPath: '/video/bsn-bg.mp4',
    bgImgPath: '/images/bsn-body-bg.jpg',
  },
  {
    roleName: 'individual',
    pageTitle: 'TransparenTerra - Role - individual',
    index: 'ind',
    sectionsContent: [
      {
        step: 1,
        content: '',
      },
      {
        step: 2,
        content:
          '<p><b>TransparenTerra users are conscious consumers</b> who understand that every action they take has consequences for their health and the planet. The TransparenTerra community welcomes people who are keen to know everything about the products and services they buy and how they affect the environment.</p>',
      },
      {
        step: 3,
        content:
          '<h3>On TransparenTerra, you can:</h3><ul><li>purchase quality and sustainable products and services directly from companies;</li><li>receive verified information about production, the origin of raw materials, and the environmental footprint of the businesses, products, and services you choose;</li><li>compensate for your carbon footprint to mitigate your environmental impact;</li><li>blog and share your thoughts, opinions, and feedback with other users;</li><li>participate in webinars and online conferences;</li><li>join an expert community of like-minded people.</li></ul>',
      },
      {
        step: 4,
        content:
          '<p><b>TransparenTerra is a reputation-based platform.</b> Our users are welcome to rate, leave feedback, and share information about the products and services they buy.<br>Your ratings and feedback are crucial for the sustainable companies on TransparentTerra.</p>',
      },
      {
        step: 5,
        content:
          '<p><b>How do I become a TransparenTerra user?</b><br>Just sign up and join TransparenTerra.<br>Our community welcomes respectable and conscious members.<br>Some services are available for verified users only.</p>',
      },
      {
        step: 6,
        content:
          '<p><b>To be verified on TransparenTerra</b>, you have to fill out our extended form and send us a request. We may also direct your verification requests to other TransparenTerra verified users or experts associated with your email address.</p>',
      },
    ],
    videoPath: '/video/ind-bg.mp4',
    bgImgPath: '/images/ind-body-bg.jpg',
  },
];
