export const SIGN_UP_FORM_DATA = [
  {
    role: 'expert',
    formFields: [
      {
        step: 1,
        fields: [
          {
            name: 'first_name',
            type: 'text',
            placeholder: 'Enter your real name *',
            description: 'This is public information',
          },
          {
            name: 'last_name',
            type: 'text',
            placeholder: 'Enter your real last name *',
            description: 'This is public information',
          },
          {
            name: 'photo',
            iconSrc: '/images/templates/add-photo.svg',
            title: 'Add your photo',
            dragDropDesc: 'Select and drop your file here',
            add: 'You can add jpg. or png. file with the maximum size 2 MB.',
            description:
              'This is public information.<br/>Choose your real photo to represent yourself.',
          },
          {
            name: 'email',
            type: 'text',
            placeholder: 'Email * ',
            description: 'Enter your email address.',
          },
          {
            name: 'password',
            type: 'password',
            placeholder: 'Password * ',
            description: 'Enter a secure password',
          },
          {
            name: 'password_confirmation',
            type: 'password',
            placeholder: 'Confirm Password *',
            description: 'Please confirm your password',
            successMessage: 'Password confirmed',
          },
          {
            name: 'policy',
            description: 'Please accept Privacy Policy and Honor Code',
            errorMessage: 'To proceed, you must accept Privacy Policy and Honor Code',
          },
        ],
      },
    ],
  },
  {
    role: 'investor',
    formFields: [
      {
        step: 1,
        fields: [
          {
            name: 'first_name',
            type: 'text',
            placeholder: 'Enter your real name *',
            description: 'This is public information',
          },
          {
            name: 'last_name',
            type: 'text',
            placeholder: 'Enter your real last name *',
            description: 'This is public information',
          },
          {
            name: 'photo',
            iconSrc: '/images/templates/add-photo.svg',
            title: 'Add your photo',
            dragDropDesc: 'Select and drop your file here',
            add: 'You can add jpg. or png. file with the maximum size 2 MB.',
            description:
              'This is public information.<br/>Choose your real photo to represent yourself.',
          },
          {
            name: 'email',
            type: 'text',
            placeholder: 'Email * ',
            description: 'Enter your email address.',
          },
          {
            name: 'password',
            type: 'password',
            placeholder: 'Password * ',
            description: 'Enter a secure password',
          },
          {
            name: 'password_confirmation',
            type: 'password',
            placeholder: 'Confirm Password *',
            description: 'Please confirm your password',
            successMessage: 'Password confirmed',
          },
          {
            name: 'policy',
            description: 'Please accept Privacy Policy and Honor Code',
            errorMessage: 'To proceed, you must accept Privacy Policy and Honor Code',
          },
        ],
      },
    ],
  },
  {
    role: 'business',
    formFields: [
      {
        step: 1,
        fields: [
          {
            name: 'first_name',
            type: 'text',
            placeholder: 'Enter your real name *',
            description: 'This is public information',
          },
          {
            name: 'last_name',
            type: 'text',
            placeholder: 'Enter your real last name *',
            description: 'This is public information',
          },
          {
            name: 'photo',
            iconSrc: '/images/templates/add-photo.svg',
            title: 'Add your photo',
            dragDropDesc: 'Select and drop your file here',
            add: 'You can add jpg. or png. file with the maximum size 2 MB.',
            description:
              'This is public information.<br/>Choose your real photo to represent yourself.',
          },
          {
            name: 'email',
            type: 'text',
            placeholder: 'Email * ',
            description: 'Enter your email address.',
          },
          {
            name: 'password',
            type: 'password',
            placeholder: 'Password * ',
            description: 'Enter a secure password',
          },
          {
            name: 'password_confirmation',
            type: 'password',
            placeholder: 'Confirm Password *',
            description: 'Please confirm your password',
            successMessage: 'Password confirmed',
          },
          {
            name: 'policy',
            description: 'Please accept Privacy Policy and Honor Code',
            errorMessage: 'To proceed, you must accept Privacy Policy and Honor Code',
          },
        ],
      },
    ],
  },
  {
    role: 'individual',
    formFields: [
      {
        step: 1,
        fields: [
          {
            name: 'first_name',
            type: 'text',
            placeholder: 'Enter your real name *',
            description: 'This is public information',
          },
          {
            name: 'last_name',
            type: 'text',
            placeholder: 'Enter your real last name *',
            description: 'This is public information',
          },
          {
            name: 'photo',
            iconSrc: '/images/templates/add-photo.svg',
            title: 'Add your photo',
            dragDropDesc: 'Select and drop your file here',
            add: 'You can add jpg. or png. file with the maximum size 2 MB.',
            description:
              'This is public information.<br/>Choose your real photo to represent yourself.',
          },
          {
            name: 'email',
            type: 'text',
            placeholder: 'Email * ',
            description: 'Enter your email address.',
          },
          {
            name: 'password',
            type: 'password',
            placeholder: 'Password * ',
            description: 'Enter a secure password',
          },
          {
            name: 'password_confirmation',
            type: 'password',
            placeholder: 'Confirm Password *',
            description: 'Please confirm your password',
            successMessage: 'Password confirmed',
          },
          {
            name: 'policy',
            description: 'Please accept Privacy Policy and Honor Code',
            errorMessage: 'To proceed, you must accept Privacy Policy and Honor Code',
          },
        ],
      },
    ],
  },
  {
    role: 'digital',
    formFields: [
      {
        step: 1,
        fields: [
          {
            name: 'first_name',
            type: 'text',
            placeholder: 'Enter your real name *',
            description: 'This is public information',
          },
          {
            name: 'last_name',
            type: 'text',
            placeholder: 'Enter your real last name *',
            description: 'This is public information',
          },
          {
            name: 'photo',
            iconSrc: '/images/templates/add-photo.svg',
            title: 'Add your photo',
            dragDropDesc: 'Select and drop your file here',
            add: 'You can add jpg. or png. file with the maximum size 2 MB.',
            description:
              'This is public information.<br/>Choose your real photo to represent yourself.',
          },
          {
            name: 'email',
            type: 'text',
            placeholder: 'Email * ',
            description: 'Enter your email address.',
          },
          {
            name: 'password',
            type: 'password',
            placeholder: 'Password * ',
            description: 'Enter a secure password',
          },
          {
            name: 'password_confirmation',
            type: 'password',
            placeholder: 'Confirm Password *',
            description: 'Please confirm your password',
            successMessage: 'Password confirmed',
          },
          {
            name: 'policy',
            description: 'Please accept Privacy Policy and Honor Code',
            errorMessage: 'To proceed, you must accept Privacy Policy and Honor Code',
          },
        ],
      },
    ],
  },
];

export const MONTHS = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

export const PRIVACY_POLICY = `FCE GROUP AG and its affiliates (“we” or “us” or “our”) respect the privacy of our users (“user” or “you”) as per 
  the applicable data protection laws, in particular, the Swiss Data Protection Act (DPA) and – if applicable to your personal data – the European General Data Protection Regulation (GDPR). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our websites including www.transparenterra.com (the “Site“) and other websites, devices, products, services, and mobile applications that reference this Privacy Policy (collectively "TransparenTerra Services"). Please read this Privacy Policy carefully. By using TransparenTerra Services, you are consenting to the practices described in this Privacy Policy.
  
  We reserve the right to make changes to this Privacy Policy at any time and for any reason.  We will alert you about 
  any changes by updating the “Last Updated” date of this Privacy Policy.  Any changes or modifications will be effective immediately upon posting the updated Privacy Policy on the Site, and you waive the right to receive specific notice 
  of each such change or modification.
  
  You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy 
  by your continued use of the Site after the date such revised Privacy Policy is posted.  `;

export const HONOR_CODE = `Welcome to TarsparenTerraTM. 
  TransparenTerra is a Community of like-minded people and experts concerned with the future of the planet, sustainable development, trustworthiness, integrity, and transparency in communications and cooperation. 
  
  We set ourselves a noble goal—to Restore Trust—and we can achieve it together only by following rules based 
  on transparency, mutual respect, and support. 
  We distinguish between privacy and transparency. Our Rules are based on respect for your privacy. In turn, we expect mutual respect and compliance with the transparency requirement, which is the fundamental element of the principal value 
  of our platform: Trust. We invite you to read carefully the Rules of our Community below.
  
  Our Principles
  TransparenTerra is a new generation of digital platforms combining the elements of social media, expert networks, marketplaces, and investor hubs.
  The TransparenTerra Community aggregates responsible and honest people. We expect all Community members 
  to contribute to maintaining a respectful environment of intelligence, honesty, robust debate, openness, and appreciation 
  for other Community members.`;
