import React from 'react';

export const Emoji = ({ symbol, label, ...restProps }) => (
  <span
    className="emoji"
    role="img"
    aria-label={label || ''}
    aria-hidden={label ? 'false' : 'true'}
    {...restProps}
  >
    {symbol}
  </span>
);
