/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-cycle */
import { Avatar, styled } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useSelectedContext } from 'context/contextSelector';
import { useNavigate } from 'react-router';
import styles from './AppAvatar.module.scss';
import { AvaOptionModal } from './Modals/avatapOptionModal';
import { AvatarBadge } from './AvatarBadgeDashboard';
import Collapse from '@mui/material/Collapse';
import KycModalWrapper from '../Modals/KycModal/KycModal';
import { useLazyQuery } from '@apollo/client';
import { SIGN_VALIDATOR_AGREEMENT, GET_VALIDATOR_AGREEMENT } from '../../api';
import AgreementModal from './Modals/agreementModal';
import { ReactComponent as ArrowUp } from '../../assets/svg/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/svg/arrow-down.svg';
import Badge from '@mui/material/Badge';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

export const AppAvatar = ({
  children,
  size = 'md',
  userName,
  role,
  nav,
  logOut,
  openOptions,
  arrowButton,
  selectProfile,
  selectSettings,
  selectHelp,
  selectCompany,
  selectAddCompany,
  selectHashtegs,
  selectLogOut,
  isOnline = false,
  ...props
}) => {
  const router = useNavigate();
  const sizes = {
    xs: '40px',
    sm: '56px',
    md: '63px',
    mdl: '100px',
    lg: '128px',
  };
  const fontSizes = {
    xs: '12px',
    sm: '12px',
    md: '16px',
    mdl: '20px',
    lg: '20px',
  };

  // const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);
  const [isPolicyLoading, setIsPolicyLoading] = useState(false);
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
  const [isValidatorAgree, setIsValidatorAgree] = useState(false);
  const [validatorText, setValidatorText] = useState(null);

  const handleModalOpen = (setIsOpen) => setIsOpen(true);
  const handleModalClose = (setIsOpen) => setIsOpen(false);

  const [openCollapse, setOpenCollapse] = useState(false);
  const [visOption, setVisOption] = useState(false);
  const [isModal, setModal] = useState(false);
  const [showNeedKYCModal, setShowNeedKYCModal] = useState(false);
  const [isIndividual, setIsIndividual] = useState(true);

  const dropdownRef = useRef();

  const { user } = useSelectedContext((context) => context.user);

  const [validatorAgreement] = useLazyQuery(GET_VALIDATOR_AGREEMENT, {});
  const [signValidatorAgreement] = useLazyQuery(SIGN_VALIDATOR_AGREEMENT, {});

  const onChangeOpen = () => {
    setModal((value) => !value);
    setVisOption((value) => !value);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setVisOption(false);
    }
  };

  const selectPassKYC = () => {
    onChangeOpen();
    setShowNeedKYCModal(true);
  };

  const selectValidatorAgreement = async () => {
    onChangeOpen();
    validatorAgreement()
      .then((res) => {
        setValidatorText(res.data.agreementValidator.body);
        setIsAgreementModalOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const signAgreement = async () => {
    signValidatorAgreement()
      .then((res) => {
        // TODO update user
        console.log('tada');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (user.roles && user.roles.length) {
      const roleExpert = user.roles?.find((elem) => elem.name === 'expert');
      const roleBusiness = user.roles?.find((elem) => elem.name === 'business');
      const roleInvestor = user.roles?.find((elem) => elem.name === 'investor');
      if (roleExpert || roleBusiness || roleInvestor) {
        setIsIndividual(false);
      } else {
        const roleHolder = user.roles?.find((elem) => elem.name === 'holder');
        if (roleHolder) {
          setIsIndividual(false);
        } else {
          const roleIndividual = user.roles?.find((elem) => elem.name === 'individual');

          if (roleIndividual) {
            setIsIndividual(true);
          } else {
            setIsIndividual(false);
          }
        }
      }
    }
  }, [user]);

  const text = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#212121',
  };

  // let onSelecetBusiness = (e) => {
  //   e.stopPropagation();
  //   setOpenCollapse(!openCollapse);
  // };

  const initials = userName
    ? userName
        .split(' ')
        .map((i) => i[0])
        .join('')
    : '';

  const onSelectProfile = () => {
    router(`/dashboard/profile/${user?.id}`);
  };

  return (
    <div>
      <div
        className={styles.avatarOverlay}
        style={{
          width: size[size],
          height: size[size],
        }}
        ref={dropdownRef}
        onClick={() => onChangeOpen()}
      >
        {nav === 2 && (
          <div className={styles.avatarRow}>
            <Avatar {...props} src={user?.avatar} className={styles.avatar}>
              <div>
                {children}
                {initials}
              </div>
            </Avatar>
          </div>
        )}
        {nav === 1 && (
          <Avatar
            {...props}
            sx={{
              width: sizes[size],
              height: sizes[size],
              fontSize: fontSizes[size],
              bgcolor: 'primary.black-10',
              color: 'primary.black-80',
              fontWeight: '500',
            }}
            className={styles.avatar}
          >
            <div>
              {children}
              {initials}
            </div>
          </Avatar>
        )}
        {nav === 0 && (
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
            invisible={!isOnline}
          >
            <Avatar
              {...props}
              sx={{
                width: sizes[size],
                height: sizes[size],
                fontSize: fontSizes[size],
                bgcolor: 'primary.black-10',
                color: 'primary.black-80',
                fontWeight: '500',
              }}
              className={styles.avatarMobile}
            >
              <div>
                {children}
                {initials}
              </div>
            </Avatar>
          </StyledBadge>
        )}
        {arrowButton === 1 && (
          <div
            className={styles.downArrowDiv}
            style={{ backgroundColor: `${visOption ? '#143471' : 'white'}` }}
          >
            {!visOption ? (
              <ArrowDown
                className={styles.arrow}
                /*
                  width={12}
                  height={12}
                */
                // layout="fixed"
                // src="/images/templates/arrow-slice.png"
              />
            ) : (
              <ArrowUp
                className={styles.arrowActive}
                fill="white"
                /*
                  width={12}
                  height={12}
                */
                // layout="fixed"
                // src="/images/templates/arrow-sliceWhite.png"
              />
            )}
          </div>
        )}
        {role && (size === 'md' || size === 'sm' || size === 'mdl' || size === 'lg') && (
          <AvatarBadge size={size} role={role} />
        )}
        {openOptions === 1 && (
          <div
            hidden={!visOption}
            onClick={() => setVisOption((value) => !value)}
            className={styles.dropdownBox}
          >
            <ul
              className={styles.dropdown}
              hidden={!visOption}
              onClick={() => setVisOption(false)}
              // component="nav"
              aria-label="mailbox folders"
            >
              <li className={styles.dropdownItem} onClick={onSelectProfile}>
                Profile
              </li>
              <li className={styles.dropdownItem} onClick={selectSettings}>
                Settings and security
              </li>
              <li className={styles.dropdownItem} onClick={selectHelp}>
                Help
              </li>
              {/* {user.role_name !== 'individual' && (
                <div className={styles.dropdownCollapse} onClick={(e) => onSelecetBusiness(e)}>
                  <li className={styles.dropdownItemCollapse}>Business account</li>
                  {openCollapse ? (
                    <div className={styles.dropdownArrowCollapse}>
                      <ExpandLess />
                    </div>
                  ) : (
                    <div className={styles.dropdownArrowCollapse}>
                      <ExpandMore />
                    </div>
                  )}
                </div>
              )} */}
              <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                <div style={{ paddingLeft: '10px' }}>
                  <div className={styles.dropdownItemCompany}>
                    <img src="/images/templates/DropDownElipse.png" alt="" />
                    <li>Companyname</li>
                  </div>
                  <div className={styles.dropdownItemCompany}>
                    <img src="/images/templates/dropdownElipsetwo.png" alt="" />
                    <li>Company 2</li>
                  </div>
                </div>
              </Collapse>
              {!isIndividual && user?.kyc !== 'success' && user?.kyc !== 'pending' && (
                <li className={styles.dropdownItem} onClick={selectPassKYC}>
                  Pass KYC
                </li>
              )}
              {user?.roles_list &&
                JSON.parse(user.roles_list).includes('validator') &&
                user.agreement?.validator_agreement !== 1 && (
                  <li className={styles.dropdownItem} onClick={selectValidatorAgreement}>
                    Validator agreement
                  </li>
                )}
              <li className={styles.dropdownItem} onClick={logOut}>
                Log out
              </li>
            </ul>
          </div>
        )}
        {openOptions === 1 && (
          <div className={styles.settingModal}>
            <AvaOptionModal
              visible={isModal}
              onClose={onChangeOpen}
              isIndividual={isIndividual}
              selectPassKYC={selectPassKYC}
            />
          </div>
        )}
      </div>

      <KycModalWrapper
        isOpen={showNeedKYCModal}
        setIsOpen={setShowNeedKYCModal}
        userId={user?.id}
        userKyc={user?.kyc}
      />

      <AgreementModal
        isModelOpen={isAgreementModalOpen}
        setIsOpen={setIsAgreementModalOpen}
        handleClose={handleModalClose}
        setIsAgree={signAgreement}
        isAgree={isValidatorAgree}
        text={validatorText}
        checkboxText="I accept the Validator agreement"
        loading={isPolicyLoading}
      />
    </div>
  );
};
