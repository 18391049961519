import React from 'react';
import { Skeleton } from '@mui/material';
import styles from './PostsList.module.scss';

const FeedLoader = ({ skeletonsCount = 3 }) => (
  <div>
    {Array.from({ length: skeletonsCount }).map(() => (
      <div className={styles.loadingContainer}>
        <div className={styles.loadingHeader}>
          <Skeleton animation="pulse" variant="circular" width={40} height={40} />
          <div>
            <Skeleton animation="pulse" variant="text" width={90} />
            <Skeleton animation="pulse" variant="text" width={70} />
          </div>
        </div>

        <Skeleton animation="pulse" variant="rounded" height={100} />
        <Skeleton animation="pulse" variant="rounded" height={100} />

        <div className={styles.loadingFooter}>
          <Skeleton animation="pulse" variant="text" width={80} />
          <Skeleton animation="pulse" variant="text" width={80} />
          <Skeleton animation="pulse" variant="text" width={80} />
        </div>
      </div>
    ))}
  </div>
);

export default FeedLoader;
