/* eslint-disable camelcase */
/* eslint-disable no-useless-escape */
import * as Yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);

export const getValidationSchema = (key) => {
  if (key === 'educations') {
    return Yup.object({
      education: Yup.array()
        .nullable()
        .of(
          Yup.object().shape({
            study: Yup.string().required('Please fill out the rest fields. This is mandatory.'),
            establishment: Yup.string().required(
              'Please fill out the rest fields. This is mandatory.'
            ),
            start_date: Yup.date()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('Expected a value of type Date but got ${value}')
              .nullable()
              .required('Please fill out the rest fields. This is mandatory.')
              .max(today, 'Start date cannot be greater than the current date'),
            end_date: Yup.date()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('Expected a value of type Date but got ${value}')
              .nullable()
              .min(Yup.ref('start_date'), "End date can't be before Start date")
              .max(today, 'End date cannot be greater than the current date'),
          })
        ),
    });
  }
  if (key === 'experience') {
    return Yup.object({
      experience: Yup.array()
        .nullable()
        .of(
          Yup.object().shape({
            title: Yup.string().required('Please fill out the rest fields. This is mandatory.'),
            company_name: Yup.string().required(
              'Please fill out the rest fields. This is mandatory.'
            ),
            start_date: Yup.date()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('Expected a value of type Date but got ${value}')
              .nullable()
              .required('Please fill out the rest fields. This is mandatory.')
              .max(today, 'Start date cannot be greater than the current date'),
            end_date: Yup.date()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('Expected a value of type Date but got ${value}')
              .nullable()
              .min(Yup.ref('start_date'), "End date can't be before Start date")
              .max(today, 'End date cannot be greater than the current date'),
            is_present: Yup.boolean(),
          })
        ),
    });
  }
  if (key === 'collaborations') {
    return Yup.object({
      collaboration: Yup.array()
        .nullable()
        .of(
          Yup.object().shape({
            study: Yup.string().required('Please fill out the rest fields. This is mandatory.'),
            ps_education: Yup.string().required(
              'Please fill out the rest fields. This is mandatory.'
            ),
            start_date: Yup.date()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('Expected a value of type Date but got ${value}')
              .nullable()
              .required('Please fill out the rest fields. This is mandatory.')
              .max(today, 'Start date cannot be greater than the current date'),
            end_date: Yup.date()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('Expected a value of type Date but got ${value}')
              .nullable()
              .min(Yup.ref('start_date'), "End date can't be before Start date")
              .max(today, 'End date cannot be greater than the current date'),
            is_present: Yup.boolean(),
          })
        ),
    });
  }
};
