/* eslint-disable no-unused-vars */
import React from 'react';
import { Image } from 'tt-ui-kit';
import { COLORS } from 'constants/colorPicker';
import styles from '../MessengerPage.module.scss';

const ChatFoldersList = ({ activeFolderId, folders, changeFolder }) => (
  <>
    {folders.map((folder) => (
      <button
        key={folder.id}
        className={styles.folderButton}
        style={{ background: activeFolderId === folder.id ? '#ffffff' : folder.color }}
        type="button"
        onClick={() => changeFolder(folder.id)}
      >
        {activeFolderId !== folder.id &&
        COLORS.find((item) => item.color === folder.color)?.isDark ? (
          <Image src="/images/chat/folder-icon-white.svg" width={21} height={15} />
        ) : (
          <Image src="/images/chat/folder-icon.svg" width={21} height={15} />
        )}
        <span
          style={{
            marginTop: 4,
            color:
              activeFolderId !== folder.id &&
              COLORS.find((item) => item.color === folder.color)?.isDark
                ? '#ffffff'
                : '#555555',
          }}
        >
          {folder.name}
        </span>
      </button>
    ))}
  </>
);

export default ChatFoldersList;
