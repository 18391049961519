import HomePage from '../../core/views/HomePage';
import PageContainer from '../../components/layout/PageContainer';

const Home = () => (
  <PageContainer>
    <HomePage />
  </PageContainer>
);

export default Home;
