import React, { useRef } from 'react';
import styles from '../../NewPostModal.module.scss';
import { CloseCrossIcon, MUIAlert } from 'tt-ui-kit';

const LinkPreview = ({
  url,
  title,
  description,
  imageUrl,
  onRemovePreview = null,
  isEdit = false,
}) => {
  const imageRef = useRef();

  return title && url ? (
    <div className={styles.linkPreviewWrapper}>
      {onRemovePreview && (
        <button
          type="button"
          className={styles.uploadImgClose}
          onClick={(e) => {
            if (onRemovePreview) {
              e.stopPropagation();
              onRemovePreview();
            }
          }}
        >
          <CloseCrossIcon style={{ width: 16, height: 16 }} className="icon-white-fill" />
        </button>
      )}
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className={styles.linkPreview}
        onClick={(e) => e.stopPropagation()}
      >
        {imageUrl && (
          <img
            ref={imageRef}
            src={imageUrl.replace(/[?&].*$/, '') || ''}
            alt=""
            className={styles.linkPreviewImage}
          />
        )}
        <div className={styles.linkPreviewText}>
          <div style={{ wordBreak: 'break-all' }}>{title}</div>
          <p style={{ wordBreak: 'break-all' }}>{description || url}</p>
        </div>
      </a>
    </div>
  ) : isEdit ? (
    <div className={styles.linkPreviewError}>
      <MUIAlert severity="warning">
        Cannot display preview. You can post as is, or try another link
      </MUIAlert>
    </div>
  ) : null;
};

export default LinkPreview;
