import React, { useEffect, useState } from 'react';
import styles from './PostPage.module.scss';
import AuthPostView from '../../components/PostView/Views/AuthPostView';
import UnauthPostView from '../../components/PostView/Views/UnauthPostView';
import Comments from '../../components/PostView/Comments/Comments';
// import ShareModal from '../../components/PostView/Components/ShareModal';
import { useNavigate } from 'react-router';
import { useSelectedContext } from 'context/contextSelector';
import { useParams } from 'react-router-dom';
import { allowedReactions } from '../../constants/addPost';
import { getPost, getPostComments, updateLikePost } from '../../api/posts/endpoints/posts';
import { openNotification } from 'tt-ui-kit';
import FeedLoader from '../../core/PostsList/FeedLoader';
import { ShareModal } from '../../modules/tt-modals';
import AuthWrapper from '../../components/AuthWarpper';
import Cookies from 'js-cookie';

const PostPage = ({ isContent = false }) => {
  const navigate = useNavigate();
  const { user } = useSelectedContext((context) => context.user);
  const { id } = useParams();

  const [currentPost, setCurrentPost] = useState(null);
  const [selectedActionUser, setSelectedActionUser] = useState(user?.id || '');
  const [comments, setComments] = useState([]);
  const [postLoading, setPostLoading] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [commentsFetch, setCommentsFetch] = useState(false);
  const [postFetch, setPostFetch] = useState(true);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [likeLoading, setLikeLoading] = useState(false);
  const [selectedReaction, setSelectedReaction] = useState('');

  useEffect(() => {
    if (user) {
      setSelectedActionUser(user.id);
    }
  }, [user]);

  const fetchPostComments = (postId) => {
    setCommentsLoading(true);

    getPostComments(postId, user.id)
      .then((response) => {
        if (response.status === 200 && response.data) {
          setComments(response.data);
        } else {
          openNotification({
            message: 'Error while fetching comments. Try again later.',
            type: 'error',
          });
        }
      })
      .catch((e) => {
        openNotification({
          message: 'Error while fetching comments. Try again later.',
          type: 'error',
        });
      })
      .finally(() => {
        setCommentsLoading(false);
      });
  };

  const fetchPostData = (postId) => {
    setPostLoading(true);

    getPost(postId, user.id)
      .then((response) => {
        if (response.status === 200 && response.data) {
          const postData = { ...response.data, body: response.data.body };

          setCurrentPost(postData);
          fetchPostComments(postId);
        } else {
          openNotification({
            message: 'Error while fetching post. Try again later.',
            type: 'error',
          });
        }
      })
      .catch((e) => {
        if (e?.response?.status === 404) {
          navigate('/404');
          return;
        }

        openNotification({
          message: 'Error while fetching post. Try again later.',
          type: 'error',
        });
      })
      .finally(() => {
        setPostLoading(false);
      });
  };

  useEffect(() => {
    if (postFetch && (!Cookies.get('access_token') || user.id)) {
      fetchPostData(id);
      setPostFetch(false);
    }
  }, [postFetch, user]);

  useEffect(() => {
    if (commentsFetch && id) {
      fetchPostComments(id);
      setCommentsFetch(false);
    }
  }, [commentsFetch]);

  useEffect(() => {
    if (user?.id) {
      setSelectedActionUser(user.id);
    }
  }, [user]);

  useEffect(() => {
    if (currentPost?.user_reaction) {
      setSelectedReaction(currentPost?.user_reaction || '');
    }

    setLikeLoading(false);
  }, [currentPost]);

  const selectReaction = (val) => {
    setLikeLoading(true);
    setSelectedReaction(val);

    const data = {
      user_id: user?.id || '',
      emoji_id: val,
      post_id: currentPost.id,
    };

    updateLikePost(data)
      .then(() => {
        setPostFetch(true);
      })
      .catch(() => {
        setSelectedReaction('');
        openNotification({
          message: 'Error while set reaction, try again later',
          type: 'error',
        });
        setLikeLoading(false);
      });
  };

  const clearReaction = () => {
    const prevReaction = selectedReaction;
    setSelectedReaction('');

    const data = {
      user_id: user?.id || '',
      post_id: currentPost.id,
    };

    updateLikePost(data)
      .then(() => {
        setPostFetch(true);
      })
      .catch(() => {
        setSelectedReaction(prevReaction);
        openNotification({
          message: 'Error while set reaction, try again later',
          type: 'error',
        });
      });
  };

  const openUserProfile = () => {
    navigate(`/dashboard/profile/${currentPost.user.id}`);
  };

  const sharePost = () => {
    setOpenShareModal(true);
  };

  const onDeletePost = () => {
    navigate(`/dashboard/feed`);
  };

  return currentPost && !user.id && currentPost.is_only_tt === '1' ? (
    <AuthWrapper reloadAfterLogin />
  ) : currentPost ? (
    <>
      <div className={styles.postContainer}>
        {user.id ? (
          <AuthPostView
            user={user}
            postInstance={currentPost}
            postBody={currentPost.body}
            selectedReaction={selectedReaction}
            allowedReactions={allowedReactions}
            likeLoading={likeLoading}
            selectReaction={selectReaction}
            clearReaction={clearReaction}
            openComments={null}
            sharePost={sharePost}
            selectedActionUser={selectedActionUser}
            setSelectedActionUser={setSelectedActionUser}
            openUserProfile={openUserProfile}
            onDeletePost={onDeletePost}
            isArticle={currentPost?.type === 'article'}
            isPostPage
            isContent={isContent}
          />
        ) : (
          <UnauthPostView
            postInstance={currentPost}
            postBody={currentPost.body}
            sharePost={sharePost}
            openUserProfile={openUserProfile}
            isArticle={currentPost?.type === 'article'}
            allowedReactions={allowedReactions}
            isPostPage
            isContent={isContent}
          />
        )}

        {(user?.id && currentPost.is_available_comments === 1) || comments.length > 0 ? (
          <div className={styles.commentsWrapper}>
            <Comments
              postId={id}
              user={user}
              comments={comments}
              loading={commentsLoading}
              setCommentsFetch={setCommentsFetch}
            />
          </div>
        ) : null}

        {/* {!user.id && ( */}
        {/*  <div className={styles.commentUnauthTooltip}> */}
        {/*    <UnauthDashboardTooltip /> */}
        {/*  </div> */}
        {/* )} */}
      </div>

      {/* <ShareModal */}
      {/*  isOpen={openShareModal} */}
      {/*  setIsOpen={setOpenShareModal} */}
      {/*  selectedUser={selectedActionUser} */}
      {/* /> */}
      <ShareModal
        open={openShareModal}
        closeModal={() => setOpenShareModal(false)}
        link={`${process.env.REACT_APP_FEED_LOCATION}/post/${currentPost.id}`}
      />
    </>
  ) : (
    <div style={{ width: '100%' }}>
      <FeedLoader skeletonsCount={1} />
    </div>
  );
};

export default PostPage;
