import React, { useEffect, useRef, useState } from 'react';
import { ContactSupportModal } from './components/contactSupportModal/ContactSupportModal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { api } from './api';
import styles from './Concierge.module.scss';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Image } from 'tt-ui-kit';
import { TextField, ButtonBase } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelectedContext } from 'context/contextSelector';

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const messageSchema = yup.object().shape({
  message: yup.string().min(2).max(255).required(),
});

const isUrl = (message) => {
  const regexQuery =
    // eslint-disable-next-line no-useless-escape
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  return message.match(regexQuery);
};

const getCategories = async (apiSecret, apiUrl) => {
  const categoriesList = await api(apiSecret, apiUrl).get('docs/categories/list');
  return categoriesList;
};

const getFullTime = () => {
  const newDate = new Date();
  return `${newDate.getHours()}:${
    newDate.getMinutes() > 9 ? newDate.getMinutes() : `0${newDate.getMinutes()}`
  }`;
};

const { REACT_APP_CONCIERGE_URL, REACT_APP_CONCIERGE_SECRETS } = process.env;

const them = {
  headerGradient: 'linear-gradient(99.65deg, #143471 100%, #1550BD 100%)',
  sendButtonColor: '#143471',
  messageBackgroundColor: '#E3EDFF',
  supportButton: '#143471',
};

const apiUrl = REACT_APP_CONCIERGE_URL;
const apiSecret = REACT_APP_CONCIERGE_SECRETS;
const supportMail = 'support@transparenterra.com';

const ConciergePage = () => {
  const { user } = useSelectedContext((context) => context.user);

  const [typed, setTyped] = useState(false);
  const chatWindowBody = useRef();
  const [showContactSupportModal, setShowContactSupportModal] = useState(false);
  const [copiedAlert, setCopiedAlert] = useState(false);
  const [chatMessage, setChatMessage] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(messageSchema),
    defaultValues: {
      message: '',
    },
  });

  const messageStyle = (messageItem, type = '') => {
    switch (messageItem.type) {
      case 'User':
        return {
          backgroundColor: them.messageBackgroundColor,
        };
      case 'Automatic':
        if (messageItem.messageType === 'Standard') {
          if (type === 'button') {
            return {
              color: them.sendButtonColor,
            };
          }
          return {
            border: `1px solid ${them.sendButtonColor}`,
            color: them.sendButtonColor,
          };
        }
        if (messageItem.messageType === 'Press') {
          return {
            border: `1px solid ${them.sendButtonColor}`,
            backgroundColor: them.sendButtonColor,
            color: 'white',
          };
        }
        return {};
      case 'linksMessage':
        if (messageItem.type === 'Support') {
          return {
            flexDirection: 'column',
          };
        }
        return {};
      default:
        return {};
    }
  };

  useEffect(() => {
    setTyped(true);
    new Promise((resolve) => {
      setTimeout(() => {
        setChatMessage((current) => [
          ...current,
          {
            id: 1,
            type: 'Support',
            messageType: 'Standard',
            time: getFullTime(),
            message: 'Hello, I’m your TT.Concierge.',
          },
        ]);
      }, 1000);
      setTimeout(() => {
        setChatMessage((current) => [
          ...current,
          {
            id: 2,
            type: 'Support',
            messageType: 'Standard',
            time: getFullTime(),
            message:
              'As a chatbot powered by GPT technology, I am here to provide initial customer support and assist with onboarding on the FCE platform. I can answer questions related to the FCE Ecosystem and TransparenTerra and help navigate you through our solutions for data-driven sustainability consulting, carbon offsetting, impact assessment, and investment. However, please note that I am not a financial advisor.',
          },
        ]);
      }, 2000);
      setTimeout(() => {
        setChatMessage((current) => [
          ...current,
          {
            id: 3,
            type: 'Support',
            messageType: 'Standard',
            time: getFullTime(),
            message:
              'Feel free to ask me complex questions and I will do my best to provide helpful answers.',
          },
        ]);
      }, 3000);
      setTimeout(() => {
        setChatMessage((current) => [
          ...current,
          {
            id: 4,
            type: 'Support',
            messageType: 'Standard',
            time: getFullTime(),
            message:
              'FCE is here to address your concerns and you are not alone. We are at your disposal.',
          },
        ]);
        setTyped(false);
        resolve(true);
      }, 4000);
    }).then((res) => {
      getCategories(apiSecret, apiUrl).catch((err) => {
        console.log('err:::::', err);
      });
    });
  }, []);

  useEffect(() => {
    if (chatWindowBody.current?.scrollHeight) {
      chatWindowBody.current.scrollTop = chatWindowBody.current?.scrollHeight;
    }
    if (chatMessage.length > 3) {
      const understandQuestionAnswer = `I'm sorry, I'm not sure I understand your question. Could you please rephrase it or provide more context so that I can provide a more accurate response?`;
      const lastAnswerMessage = chatMessage[chatMessage.length - 1].message;
      const previousAnswerMessage = chatMessage[chatMessage.length - 3].message;
      if (
        lastAnswerMessage === understandQuestionAnswer &&
        previousAnswerMessage === understandQuestionAnswer
      ) {
        setChatMessage((current) => [
          ...current,
          {
            id: current[current.length - 1].id + 1,
            type: 'Automatic',
            messageType: 'Standard',
            message: 'Contact Support',
          },
        ]);
      }
    }
  }, [chatMessage]);

  const getAnswer = async (formDate) => {
    setTimeout(() => {
      setTyped(true);
    }, 500);
    await api(apiSecret, apiUrl)
      .post('qa/answer', formDate)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.text === 'Contact Support') {
            setShowContactSupportModal(true);
          } else {
            const youtubeRawLinks = res.data.data.linksYoutube;
            const otherRawLinks = res.data.data.linksUrl;
            const linksYt = youtubeRawLinks ? [...youtubeRawLinks] : [];
            const links = otherRawLinks ? [...otherRawLinks] : [];

            setChatMessage((current) => [
              ...current,
              {
                id: current[current.length - 1].id + 1,
                type: 'Support',
                messageType: 'Standard',
                time: getFullTime(),
                message: res.data.data.text,
                extraLinks: [...links, ...linksYt],
              },
            ]);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setTyped(false);
      });
  };

  const submit = async (data) => {
    setChatMessage((current) => [
      ...current,
      {
        id: current[current.length - 1].id + 1,
        type: 'User',
        messageType: 'Standard',
        time: getFullTime(),
        message: data.message,
      },
    ]);
    reset();
    // eslint-disable-next-line no-undef
    const formDate = new FormData();
    formDate.append('q', data.message);
    await getAnswer(formDate);
  };

  const tooltipOnMouseOver = (index) => {
    const child = document.querySelector(`#tooltipBlock_${index}`);
    const tooltip = document.querySelector(`#tooltip_${index}`);
    const cloneTooltip = tooltip.cloneNode(true);
    const body = document.querySelector(`body`);
    const x0 = child.getBoundingClientRect().left;
    const y0 = child.getBoundingClientRect().top;
    cloneTooltip.style.transform = `translate(${x0}px, ${y0 - 39}px)`;
    cloneTooltip.style.display = 'block';
    cloneTooltip.id = `tooltip_show_${index}`;
    body.appendChild(cloneTooltip);
  };

  const tooltipOnMouseOut = (index) => {
    const body = document.querySelector(`body`);
    const tooltip = document.querySelector(`#tooltip_show_${index}`);
    if (tooltip) {
      body.removeChild(tooltip);
    }
  };

  const copyMessage = (message) => {
    navigator.clipboard.writeText(message);
    setCopiedAlert(true);
  };

  const messagePressFunc = async (messageItem) => {
    if (messageItem.type === 'Automatic' && messageItem.messageType === 'Standard') {
      if (messageItem.message === 'Contact Support') {
        setShowContactSupportModal(true);
      } else {
        setChatMessage([
          ...chatMessage,
          {
            id: chatMessage.length + 1,
            type: 'Automatic',
            messageType: 'Press',
            message: messageItem.message,
          },
        ]);
        // eslint-disable-next-line no-undef
        const formDate = new FormData();
        formDate.append('q', messageItem.message);
        formDate.append('category_id', messageItem.id);
        await getAnswer(formDate);
      }
    }
  };
  return (
    <>
      <Box
        className={`${styles.chatWindow} ${styles.fullScreenWindow}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box className={styles.chatWindowHeader} style={{ background: them.headerGradient }}>
          <Image src="/images/support-chat-logo.svg" />
          <Box className={styles.chatWindowHeaderContent}>
            <Box className={styles.chatHeaderTitle}>
              <Typography variant="h2">TT Concierge</Typography>
              <Typography>Your chat GPT assistant</Typography>
            </Box>
          </Box>
        </Box>
        <Box className={styles.chatWindowBody}>
          <Box className={styles.chatWindowMessage} ref={chatWindowBody}>
            {chatMessage.map((messageItem) => (
              <Box className={styles[`message${messageItem.type}`]} key={messageItem.id}>
                <Box
                  onClick={() => messagePressFunc(messageItem)}
                  className={styles.message}
                  style={messageStyle(messageItem)}
                >
                  <Box className={styles.messageWrapper}>
                    <Box
                      className={styles.messageContent}
                      style={messageStyle(messageItem, 'button')}
                    >
                      {messageItem.message.split(' ').map((ms, i) =>
                        isUrl(ms) ? (
                          <a href={ms} key={ms} target="_blank" rel="noreferrer">
                            {`${ms} `}
                          </a>
                        ) : (
                          `${ms} `
                        )
                      )}
                    </Box>
                    {messageItem.extraLinks?.length > 0 ? (
                      <Box className={styles.extraLinks}>
                        {messageItem.extraLinks.map((link, index) => (
                          <Box key={link.url}>
                            <a
                              href={link.url}
                              target="_blank"
                              rel="noreferrer"
                              id={`tooltipBlock_${index}`}
                              onMouseOver={() => tooltipOnMouseOver(index)}
                              onFocus={() => {}}
                              onMouseOut={() => tooltipOnMouseOut(index)}
                              onBlur={() => {}}
                            >
                              {link.title}
                            </a>
                            <Box
                              component="span"
                              className={styles.tooltip}
                              id={`tooltip_${index}`}
                            >
                              {link.title}
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    ) : null}
                  </Box>
                  {messageItem.messageType === 'linksMessage' ? (
                    <Box className={styles.linksMessage}>
                      {messageItem.links.map((link) => (
                        <a href={link.url} key={link.id}>
                          <Box component="span">{link.id}.</Box>{' '}
                          <Box component="span">{link.name}</Box>
                        </a>
                      ))}
                    </Box>
                  ) : (
                    <Box className={styles.messageTime}>
                      <Typography>{messageItem.time}</Typography>
                    </Box>
                  )}
                </Box>
                <Box className={styles.messageButtonsGroup}>
                  {/* <Image src="/images/share.svg" width={19.5} /> */}
                  <Image
                    src="/images/copy.svg"
                    width={19.5}
                    onClick={() => copyMessage(messageItem.message)}
                  />
                </Box>
                {messageItem.messageType === 'buttonMessage' ? (
                  <ButtonBase type="button" style={{ color: them.sendButtonColor }}>
                    {messageItem.buttonText}
                  </ButtonBase>
                ) : messageItem.messageType === 'rateMessage' ? (
                  <Box className={styles.reateMessage}>
                    <Typography style={{ color: them.sendButtonColor }}>
                      {messageItem.buttonText}
                    </Typography>
                    <Box
                      style={{
                        border: `1px solid ${them.sendButtonColor}`,
                        color: them.sendButtonColor,
                      }}
                    >
                      <ButtonBase type="button">
                        <Image src="/images/message-like.svg" />
                      </ButtonBase>
                      <ButtonBase type="button">
                        <Image src="/images/message-dislike.svg" />
                      </ButtonBase>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            ))}
          </Box>
          {showContactSupportModal ? (
            <ContactSupportModal
              setShowContactSupportModal={setShowContactSupportModal}
              color={them.sendButtonColor}
              supportMail={supportMail}
              user={user}
            />
          ) : (
            <>
              <Box className={styles.messagePrinted}>
                {typed && (
                  <>
                    <Image src="/images/message-printed.svg" />
                    <Typography>TT Concierge is typing...</Typography>
                  </>
                )}
              </Box>
              <Box className={styles.chatWindowFooter}>
                <form onSubmit={handleSubmit(submit)}>
                  <TextField
                    autoComplete="off"
                    name="message"
                    className={styles.formInput}
                    type="text"
                    placeholder="Enter your message..."
                    {...register('message')}
                  />
                  <ButtonBase
                    type="submit"
                    className={styles.messageSendBtn}
                    style={{
                      backgroundColor: !isValid ? '#C4C4C4' : them.sendButtonColor,
                      cursor: isValid ? 'pointer' : 'no-drop',
                    }}
                    disabled={!isValid}
                  >
                    <Image src="/images/send-message.svg" width={20} />
                  </ButtonBase>
                </form>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Snackbar
        open={copiedAlert}
        autoHideDuration={2000}
        onClose={() => setCopiedAlert(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setCopiedAlert(false)} severity="success" sx={{ width: '100%' }}>
          Copied
        </Alert>
      </Snackbar>
    </>
  );
};

export default ConciergePage;
