import React, { useEffect, useRef } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Image, ToBeSoonIcon } from 'tt-ui-kit';
import clsx from 'clsx';

import { generateUniqueId } from 'utils/uniqueIdGenerator';
import styles from './TbsTooltip.module.scss';

export const TBSTooltip = ({ place, children }) => {
  const idRef = useRef(generateUniqueId().toString());
  const childRef = useRef();
  const timerIdRef = useRef(0);
  const isVisibleRef = useRef(false);

  useEffect(() => {
    const hideTooltip = () => {
      ReactTooltip.hide(childRef.current);
    };
    window.addEventListener('click', hideTooltip);
    return () => window.removeEventListener('click', hideTooltip);
  }, []);

  return (
    <>
      <ReactTooltip
        id={idRef.current}
        place={place || 'right'}
        effect="solid"
        afterShow={() => {
          isVisibleRef.current = true;
          timerIdRef.current = +setTimeout(() => ReactTooltip.hide(childRef.current), 3000);
        }}
        afterHide={() => {
          isVisibleRef.current = false;
          clearTimeout(timerIdRef.current);
        }}
        arrowColor="transparent"
        className={clsx(
          styles.tbsTooltip,
          !isVisibleRef || !isVisibleRef.current ? styles.hidden : ''
        )}
      >
        <ToBeSoonIcon className={styles.icon} />
        To Be Soon
      </ReactTooltip>

      {React.cloneElement(Array.isArray(children) ? children[0] : children, {
        'data-tip': true,
        'data-for': idRef.current,
        'data-event': 'none',
        ref: childRef,
        onClick: (e) => {
          e.stopPropagation();
          if (!childRef.current) return;

          if (isVisibleRef.current) {
            ReactTooltip.hide(childRef.current);
          } else {
            ReactTooltip.show(childRef.current);
          }
          ReactTooltip.hide();
        },
      })}
    </>
  );
};
