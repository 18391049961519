import { Modal } from '@mui/material';
import ModalContainer from 'components/ModalContainer';
import styles from './HelpModal.module.scss';
import { CloseIcon } from 'tt-ui-kit';

const HelpModal = ({ isModelOpen, setIsOpen, handleClose, text, title }) => (
  <Modal
    open={isModelOpen}
    onClose={() => handleClose(setIsOpen)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <ModalContainer
      background="rgba(255, 255, 255, 0.4)"
      handleClose={() => handleClose(setIsOpen)}
    >
      <div className={styles.modalHeader}>
        <div className={styles.title}>{title}</div>
        <CloseIcon
          onClick={() => handleClose(setIsOpen)}
          className={styles.closeIcon}
          style={{ width: 22, height: 22 }}
        />
      </div>
      <div className={styles.text}>{text}</div>
    </ModalContainer>
  </Modal>
);

export default HelpModal;
