import clsx from 'clsx';
import React, { useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import styles from './NavbarPageContent.module.scss';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

const NavbarPageContent = ({
  menuItems,
  setActiveMenuItem,
  activeMenuItem,
  setActiveSubMenuItem,
  // activeSubMenuItem,
}) => {
  const handleSelectChange = (event) => {
    if (event.target.value) {
      setActiveMenuItem(event.target.value);
    }
  };

  useEffect(() => {
    if (!activeMenuItem) return;
    const menuItem = menuItems.find((item) => activeMenuItem === item.name);
    if (menuItem && menuItem.subMenu && menuItem.subMenu.length > 0) {
      setActiveSubMenuItem(menuItem.subMenu[0]);
    }
  }, [activeMenuItem]);

  return (
    <>
      <div className={styles.tabsContainer}>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: '#143471' } }}
          centered
          value={activeMenuItem}
          onChange={(e, newValue) => setActiveMenuItem(newValue)}
          className={styles.tabs}
          scrollButtons
        >
          {menuItems.map((item) => (
            <Tab
              value={item.name}
              label={item.name}
              className={[styles.tabItem, activeMenuItem === item.name ? styles.activeItem : null]}
              sx={{ margin: '0px 30px', padding: 0 }}
            />
          ))}
        </Tabs>
      </div>
      <Select
        value={activeMenuItem}
        className={styles.navbarSelectWrapper}
        onChange={handleSelectChange}
        size="medium"
        sx={{
          '.MuiOutlinedInput-notchedOutline': { border: '0 !important' },
          '.MuiSelect-select': { padding: '12px 0 12px 18px !important' },
        }}
      >
        {menuItems &&
          menuItems.length &&
          menuItems.map((item) => (
            <MenuItem
              className={clsx(
                styles.navbarButton,
                activeMenuItem === item.name ? styles.active : ''
              )}
              key={item.name}
              value={item.name}
            >
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </>
  );
};

export default NavbarPageContent;
