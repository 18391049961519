import React from 'react';
import ReactPlayer from 'react-player';
import styles from './VideoPlayer.module.scss';

export const VideoPlayerComponent = (props) => {
  const { src, className } = props;

  return (
    <div
      className={`${className || ''} ${styles.videoBox}`}
      style={{
        width: '90vw !important',
        height: '90vh !important',
      }}
      onClick={(event) => event.stopPropagation()}
    >
      <ReactPlayer
        url={src}
        width="100%"
        height="100%"
        controls
        style={{ background: '#212121', borderRadius: 12 }}
      />
    </div>
  );
};
