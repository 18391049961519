import React from 'react';
import styles from './UserBlock.module.scss';
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';
import { ReactComponent as KycIcon } from '../../assets/svg/kyc-sm.svg';
import { ReactComponent as RoleBsnIcon } from '../../assets/svg/role-bsn.svg';
import { ReactComponent as RoleExpIcon } from '../../assets/svg/role-exp.svg';
import { ReactComponent as RoleIndIcon } from '../../assets/svg/role-ind.svg';
import { ReactComponent as RoleInvIcon } from '../../assets/svg/role-inv.svg';
import { Button } from 'tt-ui-kit';
import { Avatar } from '@mui/material';

const UserBlock = ({ user, onClose, onClick }) => {
  const getRole = () => {
    switch (user.roleName) {
      case 'business':
        return <RoleBsnIcon />;
      case 'expert':
        return <RoleExpIcon />;
      case 'investor':
        return <RoleInvIcon />;
      default:
        return <RoleIndIcon />;
    }
  };

  const onBlockClick = (value) => onClick && onClick(value);

  return (
    <div className={styles.avatarRow} onClick={() => onBlockClick(user.id)}>
      <div>
        <Avatar src={user?.avatar} className={styles.avatar} />
        <div className={styles.role}>{getRole()}</div>
      </div>
      <div className={styles.fio}>
        <p>
          {user.lastName} {user.firstName}
        </p>
        {user.kyc && <KycIcon />}
      </div>
      {onClose && (
        <Button type="icon" onClick={() => onClose(user.id)}>
          <CloseIcon />
        </Button>
      )}
    </div>
  );
};

export default UserBlock;
