import { Link } from 'react-router-dom';
import styles from './RoleItem.module.scss';

const RoleItem = ({ role }) => (
  <div className={styles.rolesItem}>
    {role && role.index && (
      <Link to={`/join/${role.url}`} title={role.title} passhref="true">
        <span className={`${styles.rolesBig} ${styles[role.index]}`}>
          {role.index}
          <span className={styles.rolesSmall}>{role.label}</span>
        </span>
      </Link>
    )}
  </div>
);

export default RoleItem;
