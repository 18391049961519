import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes } from 'react-router';
import {
  ROUTE_ANSWERS_DETAILS,
  ROUTE_REPORT_DRAFT_DETAILS,
  ROUTE_REPORT_RESULT_DETAILS,
  ROUTE_SCORE_DETAILS,
} from 'constants/calculatorsPage';
import AnswersDetail from '../Pages/AnswersDetail/AnswersDetail';
import CalculatorsDetail from '../Pages/CalculatorsDetail/CalculatorsDetail';
import ReportDraftDetails from '../components/ByType/CustomReport/DetailPage/ReportDraftDetails';
import ReportResultDetails from '../components/ByType/CustomReport/DetailPage/ReportResultDetails';
import { FourOhFour } from 'views/FourOhFour';
import { useTargetBlockSize } from 'utils/useTargetBlockSize';

/**
 * Represents the CalcsPage component.
 * This component displays the calculations page with tabs and subtabs.
 * It retrieves and displays assessments, drafts, and other data based on the selected tabs.
 */
const ScoreAndReportingDetailsRouter = () => {
  const targetRef = useRef(null);
  const { targetWidth } = useTargetBlockSize(targetRef);

  const SCORE_AND_REPORTING_ROUTES_INFO = [
    {
      path: `${ROUTE_SCORE_DETAILS}/:type/:id`,
      element: <CalculatorsDetail targetWidth={targetWidth} />,
    },
    {
      path: `${ROUTE_ANSWERS_DETAILS}/:type/:id`,
      element: <AnswersDetail targetWidth={targetWidth} />,
    },
    {
      path: `${ROUTE_REPORT_DRAFT_DETAILS}/:type/:id`,
      element: <ReportDraftDetails targetWidth={targetWidth} />,
    },
    {
      path: `${ROUTE_REPORT_RESULT_DETAILS}/:type/:id`,
      element: <ReportResultDetails targetWidth={targetWidth} />,
    },
    {
      path: '*',
      name: 'Not Found',
      element: <FourOhFour />,
    },
  ];

  return (
    <div style={{ width: '100%' }} ref={targetRef}>
      <Routes>
        {SCORE_AND_REPORTING_ROUTES_INFO.map((routeInfo) => (
          <Route exact path={routeInfo.path} element={routeInfo.element} key={routeInfo.path} />
        ))}
      </Routes>
    </div>
  );
};

export default ScoreAndReportingDetailsRouter;
