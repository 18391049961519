// const browserVersion = (userAgent, regex) =>
//   userAgent.match(regex) ? userAgent.match(regex)[2] : null;

export const getBrowser = () => {
  const { userAgent } = navigator;
  let browser = 'unkown';
  // Detect browser name
  browser = /ucbrowser/i.test(userAgent) ? 'UCBrowser' : browser;
  browser = /edg/i.test(userAgent) ? 'Edge' : browser;
  browser = /googlebot/i.test(userAgent) ? 'GoogleBot' : browser;
  browser = /chromium/i.test(userAgent) ? 'Chromium' : browser;
  browser = /firefox|fxios/i.test(userAgent) && !/seamonkey/i.test(userAgent) ? 'Firefox' : browser;
  browser = /; msie|trident/i.test(userAgent) && !/ucbrowser/i.test(userAgent) ? 'IE' : browser;
  browser =
    /chrome|crios/i.test(userAgent) &&
    !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent)
      ? 'Chrome'
      : browser;
  browser =
    /safari/i.test(userAgent) &&
    !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(userAgent)
      ? 'Safari'
      : browser;
  browser = /opr|opera/i.test(userAgent) ? 'Opera' : browser;

  // detect browser version
  switch (browser) {
    case 'UCBrowser':
      return { isUCBrowser: true };
    case 'Edge':
      return { isEdge: true };
    case 'GoogleBot':
      return { isGoogleBot: true };
    case 'Chromium':
      return { isChromium: true };
    case 'Firefox':
      return { isFirefox: true };
    case 'Chrome':
      return { isChrome: true };
    case 'Safari':
      return { isSafari: true };
    case 'Opera':
      return { isOpera: true };
    case 'IE':
      // const version = browserVersion(userAgent, /(trident)\/([\d\.]+)/i);
      // IE version is mapped using trident version
      // IE/8.0 = Trident/4.0, IE/9.0 = Trident/5.0
      return { isIE: true };
    default:
      return null;
  }
};
