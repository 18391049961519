export const FORM_TITLE_LINES = ['Update', 'profile'];

export const WARNINGS = [
  {
    id: 1,
    text: 'Please add your e-mail address and a new password to sign in on the TransparenTerra.',
  },
  {
    id: 2,
    text: "Once you tap the “Save” button, we'll send to your e-mail a confirmation link.",
  },
  {
    id: 3,
    text: 'Please click on this link to confirm your registration and redirect you to your profile on TransparenTerra.',
  },
];
