import React from 'react';
import Box from '@mui/material/Box';
import { Image } from 'tt-ui-kit/dist';
import styles from './UserPostCard.module.scss';
import Typography from '@mui/material/Typography';

const UserPostCard = ({ type, avatar, name }) => {
  const renderTitle = () => {
    switch (type) {
      case 'expert':
        return 'Welcome to the Global Community of Sustainability Experts';
      case 'investor':
        // eslint-disable-next-line no-multi-str
        return 'Welcome  to the Global Community of Impact Investors';
      case 'business':
        // eslint-disable-next-line no-multi-str
        return 'Welcome  to the Global Community of Sustainable Businesses';
      case 'individual':
        // eslint-disable-next-line no-multi-str
        return 'Welcome  to the Global Community of like-minded people';
      default:
        return '';
    }
  };

  return (
    <Box className={styles.container}>
      {type === 'expert' || type === 'investor' ? (
        <Image
          className={styles.backgroundImage}
          src="/images/user-post-expert.png"
          alt="post-background"
        />
      ) : type === 'business' ? (
        <Image
          className={styles.backgroundImage}
          src="/images/user-post-business.png"
          alt="post-background"
        />
      ) : type === 'individual' ? (
        <Image
          className={styles.backgroundImage}
          src="/images/user-post-individual.png"
          alt="post-background"
        />
      ) : null}
      {avatar ? (
        <Image className={styles.avatar} src={avatar} alt="avatar" />
      ) : (
        <Image className={styles.avatar} src="/images/expert-post-avatar.png" alt="avatar" />
      )}
      <Box className={styles.titleBlock}>
        <Typography className={styles.title}>{renderTitle()}</Typography>
      </Box>
      <Typography className={styles.name}>{name}</Typography>
    </Box>
  );
};

export default UserPostCard;
