import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import styles from './BuyCreditsPage.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_DRAFT_PRICE, MAKE_CALCULATOR_PAYMENT } from '../../api';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ReactComponent as Stripe } from '../../assets/svg/stripe.svg';
import { Alert, Snackbar } from '@mui/material';
import { Button } from 'tt-ui-kit';
import DisclaimerAgreement from './DisclaimerAgreement';
import { CALC_NAMES } from 'utils/calculatorUtils';
import { useSelectedContext } from 'context/contextSelector';
import { URL_CALCS_DRAFTS, URL_CALCS_SCORE } from 'constants/calculatorsPage';

const BuyCreditsPage = () => {
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [isDisabledPaymentButton, setIsDisabledPaymentButton] = useState(false);
  const location = window.location.href;
  const currentHost = `${window.location.protocol}//${window.location.host}`;

  const [checkoutLinkMutation, { data: calculatorServiceData, loading, error }] =
    useMutation(MAKE_CALCULATOR_PAYMENT);

  const { user } = useSelectedContext((context) => context.user);

  const { draftId } = useParams();
  const navigate = useNavigate();
  const [cost, setCost] = useState(null);
  const [calcType, setCalcType] = useState();
  const [selected, setSelected] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const [getDraftPrice, { data: priceByDraftId, error: errorPriceByDraftId }] = useLazyQuery(
    GET_DRAFT_PRICE,
    { variables: { input: { draft_id: draftId } } }
  );

  useEffect(() => {
    setIsConfirm(false);
    setIsModalOpen(false);
    getDraftPrice();
  }, []);

  useEffect(() => {
    if (priceByDraftId && priceByDraftId.getCalculatorPrice) {
      setCost(priceByDraftId.getCalculatorPrice);
    }
  }, [priceByDraftId]);

  useEffect(() => {
    if (errorPriceByDraftId) {
      setOpenErrorAlert(true);
      setIsDisabledPaymentButton(false);
      console.error(errorPriceByDraftId);
    }
  }, [errorPriceByDraftId]);

  useEffect(() => {
    if (error) {
      setOpenErrorAlert(true);
      setIsDisabledPaymentButton(false);
      console.error(error);
    }
  }, [error]);

  const openModalChange = (value) => {
    setIsModalOpen(value);
  };

  const onPayClick = () => {
    setIsDisabledPaymentButton(true);
    setOpenErrorAlert(false);
    checkoutLinkMutation({
      variables: {
        input: {
          exact_id: draftId,
          payment_system: `stripe`,
          success_url: `${currentHost}${URL_CALCS_SCORE}`,
          cancel_url: location,
        },
      },
    });
  };

  // Redirect to Payment System url
  useEffect(() => {
    if (calculatorServiceData?.payForCalculatorDraft?.url) {
      window.location.href = calculatorServiceData?.payForCalculatorDraft?.url;
    }
  }, [calculatorServiceData]);

  const onBackClick = () => {
    navigate(`${URL_CALCS_DRAFTS}`);
  };

  const confirmChange = () => {
    setIsModalOpen(false);
    setIsConfirm(true);
  };

  return (
    <>
      {cost && (
        <>
          <div className={styles.contentBox}>
            <h1>Payment</h1>
            <div>
              <div className={styles.info}>
                <div>
                  Service:
                  <span>{CALC_NAMES[cost.calcType]}</span>
                </div>
                <div>
                  Amount:
                  <span>${cost.finalCost}</span>
                </div>
                <div>Payment methods:</div>
                <div>
                  <div
                    id="stripe"
                    className={clsx(styles.payMethod, selected && styles.selected)}
                    onClick={() => setSelected((value) => (value ? null : 'stripe'))}
                  >
                    <Stripe />
                    Stripe
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttonCont}>
            <Button type="default" onClick={onBackClick}>
              Back
            </Button>
            <Button
              type="primary"
              onClick={isConfirm ? () => onPayClick() : () => openModalChange(true)}
              disabled={!selected || isDisabledPaymentButton}
            >
              Pay
            </Button>
          </div>
        </>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openErrorAlert}
        onClose={() => setOpenErrorAlert(false)}
      >
        <Alert
          onClose={() => setOpenErrorAlert(false)}
          severity="error"
          sx={{ width: '100%', backgroundColor: '#D32F2F', color: '#ffffff' }}
        >
          Something went wrong, try again
        </Alert>
      </Snackbar>
      {cost && (
        <DisclaimerAgreement
          cost={cost}
          isModalOpen={isModalOpen}
          setIsModalOpen={openModalChange}
          confirmChange={confirmChange}
        />
      )}
    </>
  );
};

export default BuyCreditsPage;
