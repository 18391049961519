import clsx from 'clsx';
import styles from './CalcInfoBlock.module.scss';
import { ReactComponent as SoonIco } from '../../assets/svg/tobesoon.svg';
import { Button } from 'tt-ui-kit';
import { Typography } from '@mui/material';

const CalcInfoBlock = ({
  icon,
  headerText,
  description,
  onButtonClick,
  isDisabled,
  targetWidth,
}) => {
  const isMobile = targetWidth <= 600;

  return (
    <>
      <div className={styles.container}>
        <div className={clsx(styles.headerContainer, isMobile && styles.center)}>
          <div className={styles.header}>
            <div className={styles.icon}>{icon}</div>
            <div className={styles.headerText}>{headerText}</div>
          </div>
          {!isMobile && (
            <div>
              <Button type="primary" onClick={onButtonClick} disabled={isDisabled}>
                {isDisabled && <SoonIco />}
                <Typography className={styles.signUpText}>
                  {isDisabled ? 'TO BE SOON' : 'START'}
                </Typography>
              </Button>
            </div>
          )}
        </div>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: `${description}` }}
        />
        {isMobile && (
          <Button
            type="primary"
            onClick={onButtonClick}
            disabled={isDisabled}
            className={styles.wide}
          >
            {isDisabled && <SoonIco />}
            <Typography className={styles.signUpText}>
              {isDisabled ? 'TO BE SOON' : 'START'}
            </Typography>
          </Button>
        )}
      </div>
    </>
  );
};

export default CalcInfoBlock;
