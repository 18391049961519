import PageContainerImgBg from 'components/layout/PageContainer/PageContainerImgBg';
import { TITLES } from 'constants/pagesTitles';
import SignInPage from '.';

export const SignIn = () => (
  <PageContainerImgBg
    imageBg="/images/section-bckg.jpg"
    title={TITLES.signInPage}
    isLogoHidden
    isSignInPage
  >
    <SignInPage />
  </PageContainerImgBg>
);

/* export const SignIn = () => (
    <SignInPage />
); */
