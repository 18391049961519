import {
  DESCRIPTION_TITLE,
  DESCRIPTION_SUBTITLE,
  PARAGRAPH_FIRST,
  PARAGRAPH_SECOND,
  PARAGRAPH_THIRD,
  BTN_MAIN_TEXT,
  SLICES,
} from 'constants/SDGPage';
import { useNavigate } from 'react-router';
import styles from './SDGPage.module.scss';
import PieChartD3 from './PieChartD3';

const SDGPage = () => {
  const navigate = useNavigate();

  const goToGoals = () => {
    navigate('/sdgs/goal_1');
  };

  return (
    <div className={styles.pageContentWrapper}>
      <PieChartD3 chartData={SLICES} hasDesktopFormat />
      <div className={styles.sectionWrapper}>
        <div className={styles.secondWrapper}>
          <div className={styles.descriptionWrapper}>
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: `${DESCRIPTION_TITLE}` }}
            />
            <div
              className={styles.subTitle}
              dangerouslySetInnerHTML={{ __html: `${DESCRIPTION_SUBTITLE}` }}
            />
            <div
              className={styles.paragraph}
              dangerouslySetInnerHTML={{ __html: `${PARAGRAPH_FIRST}` }}
            />
            <div
              className={styles.paragraph}
              dangerouslySetInnerHTML={{ __html: `${PARAGRAPH_SECOND}` }}
            />
            <div
              className={styles.paragraph}
              dangerouslySetInnerHTML={{ __html: `${PARAGRAPH_THIRD}` }}
            />
          </div>
          <button className={styles.btn} type="button" onClick={goToGoals}>
            {BTN_MAIN_TEXT}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SDGPage;
