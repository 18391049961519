import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Hidden,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Typography,
  CircularProgress,
  Tabs,
} from '@mui/material';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { useLazyQuery } from '@apollo/client';
import styles from './PresentationsPage.module.scss';
import { GET_SECTIONS, GET_PRESENTATIONS } from '../../../api';
import TitledBackgroundHeader from '../../../components/layout/TitledBackgroundHeader';

const PresentationsPage = () => {
  const [value, setValue] = React.useState(null);
  const [getAllPresentations] = useLazyQuery(GET_PRESENTATIONS);
  const [getSections] = useLazyQuery(GET_SECTIONS);
  const [inputValue, setInputValue] = React.useState('');
  const [allPresentations, setAllPresentations] = React.useState([]);
  const [timeoutID, setTimeoutID] = React.useState(null);
  const [sections, setSections] = React.useState([]);
  const [loading, setLoading] = useState(false);

  const getPresentationsList = async (sectionId, searchValue = '') => {
    setLoading(true);
    const response = await getAllPresentations({
      variables: { section_id: sectionId === '0' ? null : sectionId, searchValue },
      fetchPolicy: 'network-only',
    });
    if (response.data && response.data.showAllPresentations) {
      setAllPresentations(response.data.showAllPresentations);
    }
    setLoading(false);
  };

  const getSectionsList = async () => {
    const sectionsRes = await getSections({
      variables: {
        type: 'presentation',
      },
      fetchPolicy: 'network-only',
    });
    if (sectionsRes && sectionsRes.data && sectionsRes.data.showAllSections) {
      setSections(sectionsRes.data.showAllSections);
    }
  };

  useEffect(() => {
    (async () => {
      await getPresentationsList(value);
    })();
  }, [value]);

  useEffect(() => {
    (async () => {
      await getSectionsList(value);
    })();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const search = (inputtedValue) => {
    setInputValue(inputtedValue);
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    const timeout = setTimeout(() => {
      getPresentationsList(value, inputtedValue);
    }, 1000);
    setTimeoutID(timeout);
  };

  return (
    <div className={styles.pageContainer}>
      <TitledBackgroundHeader isHeaderNav title="Presentations" />
      <div className={styles.content}>
        <Box className={styles.filterBar}>
          <Hidden mdDown>
            <Tabs
              variant="scrollable"
              className={styles.filterTabs}
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  display: 'none',
                },
              }}
            >
              <Tab
                sx={{
                  '&.Mui-selected': {
                    color: '#1F4A9D !important',
                    fontWeight: '600 !important',
                  },
                }}
                className={styles.tabItem}
                label="All"
                value={null}
              />
              {sections.map((section) => (
                <Tab
                  key={section.id}
                  sx={{
                    '&.Mui-selected': {
                      color: '#1F4A9D !important',
                      fontWeight: '600 !important',
                    },
                  }}
                  value={section.id}
                  className={styles.tabItem}
                  label={section.name}
                />
              ))}
            </Tabs>
          </Hidden>
          <Hidden mdUp>
            <Select
              value={`${value}`}
              onChange={(event) => setValue(event.target.value)}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiSelect-select': {
                  color: '#1F4A9D',
                  fontWeight: 700,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
              size="medium"
              MenuProps={{
                PaperProps: {
                  style: {
                    borderRadius: '12px',
                    padding: '12px 20px',
                    border: '1px solid #EFEFEF',
                    borderTop: '0',
                  },
                },
                sx: {
                  '&& .Mui-selected': {
                    color: '#143471 !important',
                    backgroundColor: 'transparent !important',
                  },
                },
              }}
              className={styles.filterSelect}
              renderValue={(selected) => {
                const selectedItem = sections.find((item) => item.id === selected);
                return selectedItem ? selectedItem.name : 'All';
              }}
            >
              <MenuItem value="0" className={styles.menuItem}>
                All
              </MenuItem>
              {sections.map((section) => (
                <MenuItem value={section.id} className={styles.menuItem} key={section.id}>
                  {section.name}
                </MenuItem>
              ))}
            </Select>
          </Hidden>
          <div className={styles.searchFilter}>
            <SearchIcon sx={{ color: '#555555' }} className={styles.searchFilterIcon} />
            <OutlinedInput
              value={inputValue}
              className={styles.searchFilterInput}
              placeholder="Search"
              onChange={(e) => search(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            />
          </div>
        </Box>
        {allPresentations.length > 0 && !loading ? (
          <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12, lg: 15 }}>
            {allPresentations.map((presentations) => (
              <Grid item xs={12} sm={4} md={3} lg={3} key={presentations.id}>
                <Card className={styles.card}>
                  <a
                    href={
                      presentations.file !== 'no file' ? presentations.file : presentations.link
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CardMedia
                      className={styles.cardImage}
                      component="img"
                      image={presentations.preview}
                    />
                    <CardContent className={styles.cardContent}>
                      <div className={styles.cardTitle}>{presentations.name}</div>
                    </CardContent>
                  </a>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box className={styles.noData}>
            {loading ? (
              <CircularProgress disableShrink />
            ) : (
              <Typography className={styles.noDataText}>
                More presentation will be coming here soon
              </Typography>
            )}
          </Box>
        )}
      </div>
    </div>
  );
};

export default PresentationsPage;
