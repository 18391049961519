import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material';
import { ReactComponent as AnsveredSvg } from '../../../../../../assets/svg/checkMark.svg';
import styles from './GlobalAnswers.module.scss';

import { GET_GLOBAL_QUESTIONS_DATA } from '../../../../../../api';
import { useLazyQuery, useQuery } from '@apollo/client';
import { filterGlobalCategory, snakeToCamel } from 'utils/calculatorUtils';
import ResultFilesModal from '../../../CalcResultFilesModal/ResultFilesModal';

const CustomizedAccordion = styled(Accordion)(() => ({
  '&.MuiAccordion-root': {
    borderRadius: '16px',
    width: '100%',
    padding: '16px',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
    marginTop: '8px',
  },
  '&:before': {
    backgroundColor: 'white',
  },
  boxShadow: 'none',
}));

const GlobalAnswers = ({ params }) => {
  const { resultFiles, answersData, type, title } = params;

  const { data: globalData } = useQuery(GET_GLOBAL_QUESTIONS_DATA);

  const [questionsData, setQuestionsData] = useState([]);
  const [resultsFilesList, setResultsFilesList] = useState([]);
  const [resultsFilesCodes, setResultsFilesCodes] = useState([]);
  const [resultsList, setResultsList] = useState([]);

  const createResultObject = (dimention, files) => {
    // Flatten the scope array and map it to an object for easier lookup
    const dimMap = dimention.reduce((acc, category, index) => {
      category.questions.forEach((question) => {
        acc[question.id] = {
          question: category.name,
          subquestion: question.title,
          code: question.id,
          title: title,
          files: [],
        };
      });
      return acc;
    }, {});

    // Reduce the files array to the desired format
    files.forEach((file) => {
      if (dimMap[file.questionCode]) {
        dimMap[file.questionCode].files.push(file);
      }
    });

    // Convert the scopeMap object to an array and filter out objects without files
    const resultObject = Object.values(dimMap).filter((item) => item.files.length > 0);
    return resultObject;
  };

  useEffect(() => {
    if (!resultsList.length) return;
    const filesList = createResultObject(resultsList, resultFiles);
    setResultsFilesList([...filesList]);
    setResultsFilesCodes([...filesList.map((q) => q.code)]);
  }, [resultsList, resultFiles]);

  useEffect(() => {
    if (!globalData?.getGlobalQuestions) return;
    const newData = snakeToCamel(JSON.parse(globalData.getGlobalQuestions.GlobalQuestions));
    setQuestionsData(
      globalData.getGlobalQuestions.GlobalQuestions ? filterGlobalCategory(newData, type) : []
    );
  }, [globalData]);

  useEffect(() => {
    if (!questionsData.length || !answersData.globalAnswers) return;
    const newQuestions = questionsData.map((category) => ({
      ...category,
      questions: category.questions.map((question) => ({
        ...question,
        subQuestions: question.subQuestions.map((subQuestion) => ({
          ...subQuestion,
          answer: answersData.globalAnswers[subQuestion.code],
        })),
      })),
    }));
    setResultsList(newQuestions);
  }, [questionsData, answersData]);

  return (
    <div className={styles.globalMainFrame}>
      {resultsList.map((category, indx) => (
        <div key={category.id}>
          <CustomizedAccordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              // sx={{ padding: '0px', margin: '0px' }}
            >
              <div className={styles.summary}>
                <AnsveredSvg
                  className={clsx(
                    styles.qbuttonsvg,
                    {
                      [styles.required]: !category.questions.some((question) =>
                        question.subQuestions.some((sq) => !!sq.answer)
                      ),
                    },
                    {
                      [styles.answered]: category.questions.some((question) =>
                        question.subQuestions.some((sq) => !!sq.answer)
                      ),
                    }
                  )}
                />
                <h3 className={styles.title}>{category.name}</h3>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {category.questions.map((question) => (
                <div key={question.id}>
                  <CustomizedAccordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      // sx={{ padding: '0px', margin: '0px' }}
                    >
                      <div className={styles.globalTitle}>
                        <AnsveredSvg
                          className={clsx(
                            styles.qbuttonsvg,
                            { [styles.required]: !question.subQuestions.some((sq) => !!sq.answer) },
                            { [styles.answered]: question.subQuestions.some((sq) => !!sq.answer) }
                          )}
                        />
                        <h4>{question.title}</h4>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {question.subQuestions.map((subquestion) => (
                        <div key={subquestion.id}>
                          <div className={styles.subquestionBlock}>
                            <div className={styles.subquestionText}>{subquestion.text}</div>
                            {subquestion.answer && (
                              <div>
                                <span className={styles.spanText}>Answer: </span>
                                {subquestion.answer}
                              </div>
                            )}
                            {!subquestion.answer && <div>No answer available</div>}
                          </div>
                        </div>
                      ))}
                    </AccordionDetails>
                    {resultsFilesCodes.includes(question.id) && (
                      <div className={styles.buttonBlock}>
                        <ResultFilesModal
                          params={{
                            resultsFilesList,
                            resultsFilesCodes,
                            code: question.id,
                          }}
                        />
                      </div>
                    )}
                  </CustomizedAccordion>
                </div>
              ))}
            </AccordionDetails>
          </CustomizedAccordion>
        </div>
      ))}
    </div>
  );
};

export default GlobalAnswers;
