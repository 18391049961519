import React from 'react';
import { ThemeProvider } from 'tt-ui-kit';
import AdminNavbar from './AdminNavbar';

const AdminNavbarWrapper = ({ showNavbar, theme, baseUrl = null, baseFceUrl = null, mode }) => (
  <ThemeProvider theme={theme}>
    <AdminNavbar showNavbar={showNavbar} baseUrl={baseUrl} baseFceUrl={baseFceUrl} mode={mode} />
  </ThemeProvider>
);

export default AdminNavbarWrapper;
