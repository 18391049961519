import { useState, useEffect } from 'react';
import styles from './AppAvatar.module.scss';

export const AvatarBadge = ({ role, size }) => {
  const [currentColor, setCurrentColor] = useState('');
  const [transformedRole, setTransformedRole] = useState('');

  const short = () => {
    if (role === 'expert') {
      setCurrentColor('#0ab2ab');
      return 'exp';
    }
    if (role === 'investor') {
      setCurrentColor('#3C7097');
      return 'inv';
    }
    if (role === 'business') {
      setCurrentColor('#2D8342');
      return 'bsn';
    }
    if (role === 'individual') {
      setCurrentColor('#D8913C');
      return 'ind';
    }
    return '';
  };

  useEffect(() => {
    const convertedRole = short();
    setTransformedRole(convertedRole);
  }, [role]);

  return (
    <div
      style={{
        color: `${currentColor}`,
      }}
      className={styles.avatarBadge}
    >
      <span>{transformedRole}</span>
    </div>
  );
};
