import React, { useEffect, useRef, useState } from 'react';
import styles from './EmojiPostPicker.module.scss';
import { Emoji } from '../../EmojiWrapper';
import { Loader } from 'tt-ui-kit';

const EmojiPostPicker = ({
  selectedEmoji,
  allowedEmoji,
  likeLoading,
  placeholder,
  selectReaction,
  clearReaction,
  mode = 'main',
}) => {
  const pickerRef = useRef();

  const [isPickerShow, setIsPickerShow] = useState(false);
  const [selectedReactions, setSelectedReactions] = useState(
    selectedEmoji?.emoji_id || selectedEmoji || null
  );

  const chooseReaction = (value) => {
    setSelectedReactions(value);
    selectReaction(value, () => {
      setIsPickerShow(false);
    });
    // setIsPickerShow(false);
  };

  const cancelReaction = () => {
    clearReaction(() => {
      setSelectedReactions(null);
    });
  };

  const showPicker = () => {
    if (!selectedReactions) {
      setIsPickerShow(true);
    }
  };

  const closePicker = () => {
    if (!selectedReactions) {
      setIsPickerShow(false);
    }
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      closePicker();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedEmoji) {
      setSelectedReactions(selectedEmoji?.emoji_id || selectedEmoji || null);
    } else {
      setSelectedReactions(null);
    }
  }, [selectedEmoji]);

  return (
    <div
      className={styles.pickerContainer}
      onTouchStart={showPicker}
      onMouseEnter={showPicker}
      onMouseLeave={closePicker}
    >
      <div className={styles.placeholderWrapper}>{placeholder}</div>

      <div
        style={{
          display: likeLoading && (selectedEmoji || selectedReactions) ? 'flex' : 'none',
          zIndex: 1,
          width: 156,
        }}
        className={mode === 'main' ? styles.pickerShow : styles.pickerShowComment}
      >
        <Loader />
      </div>
      <div
        style={{ display: selectedReactions ? 'flex' : 'none' }}
        className={mode === 'main' ? styles.pickerShow : styles.pickerShowComment}
      >
        <Emoji
          symbol={allowedEmoji.find((item) => item.id === selectedReactions)?.icon}
          onClick={() => cancelReaction()}
        />
      </div>
      <div
        style={{ display: isPickerShow ? 'flex' : 'none' }}
        className={mode === 'main' ? styles.pickerWrapper : styles.pickerWrapperComment}
        ref={pickerRef}
      >
        {mode === 'main' ? null : <span style={{ color: '#f9f9f9', cursor: 'default' }}>Like</span>}
        {allowedEmoji.map((item, index) => (
          <Emoji
            symbol={item.icon}
            key={`em-${index + 1}`}
            onClick={() => chooseReaction(item.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default EmojiPostPicker;
