import { VideoPlayerComponent } from 'core/VideoPlayer';
import React, { useEffect, useState } from 'react';
import styles from './VideoEdit.module.scss';
import { Button } from 'tt-ui-kit';
import { TextField, Snackbar, Alert } from '@mui/material';
import { imageFileTypes } from 'constants/addPost';
import { useMutation } from '@apollo/client';
import { DELETE_FROM_PUBLIC, GET_URL_FOR_PUBLIC_UPLOAD } from 'api';
import { v1 as uuidv1 } from 'uuid';
import axios from 'axios';
import { Loader } from 'components/Loader';
import Cookies from 'js-cookie';
import { getPostFileUploadLink } from '../../../../../api/posts/endpoints/posts';

export const VideoEdit = ({ file, updateFileMethod, openModal, setOpenModal, isEdit }) => {
  const userID = typeof window !== 'undefined' ? Cookies.get('userId') : null;
  const [preview, setPreview] = useState(null);
  const [videoName, setVideoName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [openError, setOpenError] = useState(false);

  const [getPublicUrl] = useMutation(GET_URL_FOR_PUBLIC_UPLOAD);
  const [deletePublic] = useMutation(DELETE_FROM_PUBLIC);

  useEffect(() => {
    setVideoName(file.videoName);
  }, [file]);

  const closeModal = () => {
    if (preview) {
      const fileToDel = {
        path: isEdit ? preview.path : preview.url,
        name: isEdit ? preview.id : preview.uuid,
      };
      deletePublic({ variables: { input: fileToDel } });
    }
    setPreview(null);
    setOpenModal(false);
  };

  const setErrorMessage = (text) => {
    if (text) {
      setError(text);
      setOpenError(true);
    }
  };

  const uploadPreview = (previewFile) => {
    setIsLoading(true);
    const fileName = `${uuidv1()}.png`;
    const path = `${userID}/post/image_file`;

    const toUpload = { path: path, name: fileName };

    getPostFileUploadLink(toUpload)
      .then((response) => {
        const urlToUpload = response.data?.url;

        if (urlToUpload) {
          axios
            .put(urlToUpload, previewFile, {
              headers: {
                'Content-type': previewFile.type,
                'Access-Control-Allow-Origin': '*',
              },
            })
            .then((uploadResponse) => {
              if (uploadResponse.statusText === 'OK') {
                const updatedFile = {
                  file: previewFile[0],
                  uuid: fileName,
                  url: `https://${response.data?.download_url}`,
                };

                setPreview(updatedFile);
                setIsLoading(false);
              }
            })
            .catch(() => {
              setErrorMessage('Error while uploading file');
              setPreview(null);
              setIsLoading(false);
            });
        } else {
          setErrorMessage('Error while uploading file');
          setPreview(null);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setErrorMessage('Error while uploading file');
        setPreview(null);
        setIsLoading(false);
      });
  };

  const addCurrentPreview = (previewFile) => {
    uploadPreview(previewFile);
  };

  const saveChanges = () => {
    const newFile = {
      ...file,
      thumbnail: preview,
      videoName: videoName,
    };

    updateFileMethod(newFile, newFile.id);
    setOpenModal(false);
  };

  if (!openModal) return null;

  return (
    <div className={styles.modal}>
      <div className={styles.modal_dialog} onClick={(event) => event.stopPropagation()}>
        <div className={styles.modal_header}>
          <h3 className={styles.modal_title}>Edit video</h3>
          <span className={styles.modal_close} onClick={() => closeModal()}>
            <img src="/images/close.svg" alt="" />
          </span>
        </div>

        <div className={styles.modal_body} id="modalBody">
          <div className={styles.videoPlayerContainer}>
            <VideoPlayerComponent src={isEdit ? file.path || '' : file.url || ''} />
          </div>

          <div className={styles.inputWrapper}>
            <TextField
              className={styles.formInput}
              label="Video name"
              disableUnderline
              value={videoName}
              onChange={(e) => {
                setVideoName(e.target.value);
              }}
            />
          </div>

          <div className={styles.prevUploadContainer}>
            <div>
              <b>Upload a Thumbnail (JPG/GIF/PNG only)</b>
              <div>{isLoading ? <Loader /> : preview?.file?.name}</div>
            </div>
            {!preview ? (
              <Button type="default" className={styles.buttonDefault} disabled={isLoading}>
                <>
                  <input
                    id="post-img-upload"
                    type="file"
                    hidden
                    onChange={(e) => addCurrentPreview(e.target.files)}
                    accept={imageFileTypes}
                  />
                  <label htmlFor="post-img-upload">Select Thumbnail</label>
                </>
              </Button>
            ) : (
              <Button type="default" className={styles.buttonDefault} disabled={isLoading}>
                <>
                  <input
                    id="post-img-upload"
                    type="file"
                    hidden
                    multiple
                    onChange={(e) => addCurrentPreview(e.target.files)}
                    accept={imageFileTypes}
                  />
                  <label htmlFor="post-img-upload">Change Thumbnail</label>
                </>
              </Button>
            )}
          </div>

          <div className={styles.modalButtons}>
            <Button
              type="default"
              className={styles.buttonDefault}
              disabled={isLoading}
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className={styles.submitButton}
              disabled={isLoading}
              onClick={() => {
                saveChanges();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openError}
        autoHideDuration={6000}
        onClose={() => {
          setOpenError(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenError(false);
          }}
          severity="info"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};
