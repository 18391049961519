import { Box, ButtonBase, Hidden, IconButton, InputBase } from '@mui/material';
import styles from './Filters.module.scss';
import {
  SearchIcon,
  SelectDownIcon,
  SelectUpIcon,
  SortAscIcon,
  SortDescIcon,
  Button,
} from 'tt-ui-kit';
import AddIcon from '@mui/icons-material/Add';
import React, { useState } from 'react';

export const Filter = () => {
  const [filterType, setFilterType] = useState(true);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    role: 'activeRole',
    limit: 15,
    order_dir: 'desc',
    page: 1,
    q: '',
  });
  const changeOrder = () => {
    if (filterType) {
      setFilters((current) => ({ ...current, order_by: 'created_at', page: 1 }));
    } else {
      setFilters((current) => ({ ...current, order_by: null, page: 1 }));
    }
    setPage(1);
    setFilterType(!filterType);
  };
  return (
    <Box className={styles.filters}>
      <Box className={styles.inputGroup}>
        <InputBase
          className={styles.searchInput}
          placeholder="Search"
          // value={searchValue}
          // onChange={(e) => changeSearchValue(e.target.value)}
        />
        <Box className={styles.searchIcon}>
          <SearchIcon style={{ width: 20, height: 20 }} />
        </Box>
      </Box>
      <Box className={styles.filtersRight}>
        <Box onClick={() => changeOrder()} className={styles.sortSelect}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton style={{ padding: 0, width: 30, height: 30, marginLeft: 10 }}>
              {filterType ? (
                <SortAscIcon className={styles.filterIcons} />
              ) : (
                <SortDescIcon className={styles.filterIcons} />
              )}
            </IconButton>
            <ButtonBase className={styles.filterNewFirst}>New first</ButtonBase>
          </div>
          {filterType ? (
            <SelectDownIcon className={styles.selectArrow} />
          ) : (
            <SelectUpIcon className={styles.selectArrow} />
          )}
        </Box>
        <Hidden mdDown>
          <Button type="primary" className={styles.createEventBtn}>
            create an event
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Button type="primary">
            <AddIcon />
          </Button>
        </Hidden>
      </Box>
    </Box>
  );
};
