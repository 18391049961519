/* eslint-disable camelcase */
/* eslint-disable no-useless-escape */
import * as Yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const whitespacesReg = /^\s*\S.*$/;
const specialCharReg = /^[^<>'\"/;`%]*$/;

const today = new Date();
today.setHours(0, 0, 0, 0);
today.setDate(1);

export const getValidationSchema = () => [
  // validation
  Yup.object({
    first_name: Yup.string()
      .required('Please fill out the rest fields. This is mandatory.')
      .max(35, 'Too many characters.')
      .matches(whitespacesReg, 'The field cannot contain only spaces.')
      .matches(specialCharReg, 'Special characters are prohibited.'),
    last_name: Yup.string()
      .required('Please fill out the rest fields. This is mandatory.')
      .max(35, 'Too many characters.')
      .matches(whitespacesReg, 'The field cannot contain only spaces.')
      .matches(specialCharReg, 'Special characters are prohibited.'),
    email: Yup.string()
      .required('Please fill out the rest fields. This is mandatory.')
      .email('Email is invalid'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/(?=.*\d)/, 'Include at least one number')
      .matches(/(?=.*[!@#$%^&.*])/, 'Include at least 1 special character (!@#$%^&.*)')
      .matches(/(?=.*[a-z])/, 'Include at least 1 lowercase letter')
      .matches(/(?=.*[A-Z])/, 'Include at least 1 uppercase letter')
      .required('Please fill out the rest fields. This is mandatory.'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Confirmation doesn`t match your password')
      .required('Please fill out the rest fields. This is mandatory.'),
  }),
];
