import React, { useState, useEffect } from 'react';
import { InvisibleIcon, VisibleIcon } from 'tt-ui-kit';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import SignUpField from '../SignUpField';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import styles from './SignUpInput.module.scss';
import { FormErrorsView } from '../../../../../components/FormErrorsView/FormErrorsView';

const NOT_SET = 'not set';

const SignUpInput = ({
  name,
  type,
  placeholder,
  description,
  control,
  trigger,
  errors,
  setIsFormValid,
  browser,
  isSubmitStepClicked,
  successMessage,
  passwordValue,
}) => {
  const [inputValue, setInputValue] = useState(null);
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [isValid, setIsValid] = useState(NOT_SET);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentSuccessMessage, setCurrentSuccessMessage] = useState(null);

  const changePasswordVisibility = (isHidden, set) => {
    set(!isHidden);
  };

  const onNameChange = async (value, inputName, formErrors) => {
    const valid = await trigger(inputName);
    const isFormValidNow = await trigger();
    setIsFormValid(isFormValidNow);
    setInputValue(value);
    if (!valid) {
      setIsValid(valid);
      setErrorMessage(formErrors[inputName].message || '');
      setCurrentSuccessMessage(null);
    } else {
      setIsValid(true);
      setErrorMessage(null);
      setCurrentSuccessMessage(successMessage);
    }
  };

  useEffect(() => {
    (async () => {
      if (isSubmitStepClicked) {
        const valid = await trigger(name);
        if (!valid) {
          setIsValid(valid);
          setErrorMessage(errors[name].message || '');
        } else {
          setIsValid(valid);
          setErrorMessage(null);
        }
      }
    })();
  }, [isSubmitStepClicked, errors]);

  return (
    <SignUpField>
      <div
        className={`${styles.inputWrapper} ${
          isValid !== NOT_SET && !isValid && isSubmitStepClicked && styles.errorInputWrapper
        }`}
      >
        <div className={styles.secondInputWrapper}>
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <input
                {...field}
                className={`${styles.input} ${
                  !isValid && isSubmitStepClicked && styles.placeholderRed
                }`}
                onChange={async ({ target }) => {
                  onChange(target.value);
                  await onNameChange(target.value, name, errors);
                }}
                type={isPasswordHidden ? type : 'text'}
                placeholder={placeholder}
                autoComplete="new-password"
                defaultValue=""
              />
            )}
          />
          {(name === 'password' || name === 'password_confirmation') && (
            <button
              className={clsx(
                styles.formPassShowBtn,
                browser?.isSafari ? styles.safariEyeIcon : ''
              )}
              type="button"
              onClick={() => changePasswordVisibility(isPasswordHidden, setIsPasswordHidden)}
              tabIndex="-1"
            >
              <div className={styles.iconWrapper}>
                {isPasswordHidden ? <VisibleIcon /> : <InvisibleIcon />}
              </div>
            </button>
          )}
          <div className={styles.descriptionWrapper}>
            {description && name !== 'password' && !errorMessage && !currentSuccessMessage && (
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: `${description}` }}
              />
            )}
            {successMessage &&
              name === 'password_confirmation' &&
              isValid &&
              isValid !== NOT_SET && (
                <div
                  className={styles.successMessage}
                  dangerouslySetInnerHTML={{ __html: `${successMessage}` }}
                />
              )}
            {name === 'password' && (
              <PasswordStrengthMeter password={inputValue} description={description} />
            )}
            {!isValid && errorMessage && name !== 'password' && (
              <div
                className={styles.error}
                dangerouslySetInnerHTML={{ __html: `${errorMessage}` }}
              />
            )}
          </div>
        </div>
        {name === 'password' && (
          <div>
            {passwordValue?.length > 0 && (
              <FormErrorsView
                errors={[
                  {
                    match: passwordValue.length >= 8,
                    errMessage: 'Password must be at least 8 characters',
                  },
                  {
                    match: passwordValue.match(/(?=.*\d)/),
                    errMessage: 'Include at least one number',
                  },
                  {
                    match: passwordValue.match(/(?=.*[!@#$%^&.*])/),
                    errMessage: 'Include at least 1 special character (!@#$%^&.*)',
                  },
                  {
                    match: passwordValue.match(/(?=.*[a-z])/),
                    errMessage: 'Include at least 1 lowercase letter',
                  },
                  {
                    match: passwordValue.match(/(?=.*[A-Z])/),
                    errMessage: 'Include at least 1 uppercase letter',
                  },
                ]}
                wrapperClassName={styles.passwordValidationWrapper}
                successClassName={styles.formSuccess}
                errClassName={styles.formError}
              />
            )}
          </div>
        )}
      </div>
    </SignUpField>
  );
};

export default SignUpInput;
