/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable prefer-destructuring */
import { Button, MUIBox, MUIModal, CloseIcon, LeftIcon, RightIcon } from 'tt-ui-kit';
import { useState } from 'react';
import styles from './ImageSlider.module.scss';

// TODO: make refactoring
export const ImageSlider = ({ array }) => {
  const [current, setCurrent] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => {
    setOpenModal(false);
    setCurrent(0);
  };
  const length = array.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(array) || array.length <= 0) {
    return null;
  }

  if (length === 1) {
    return (
      <div className={styles.slider}>
        {array.map((slide, index) => (
          <div key={slide.id} className={styles.singleImageContainer}>
            {index === current && (
              <>
                <img
                  src={slide.path}
                  alt=""
                  className={styles.singleImage}
                  onClick={() => handleOpen()}
                />
                <img src={slide.path} alt="" className={styles.bgImage} />
              </>
            )}
            <MUIModal
              slotProps={{
                backdrop: {
                  style: {
                    backgroundColor: 'white',
                    backdropFilter: 'blur(14px)',
                    background: 'rgba(250, 250, 250, 0.8)',
                  },
                },
              }}
              open={openModal}
              onClose={handleClose}
              className={styles.modalBox}
            >
              <MUIBox className={styles.modal}>
                <Button type="icon" className={styles.modalClose} onClick={handleClose}>
                  <CloseIcon />
                </Button>
                <img src={slide.path} alt="" className={styles.modalImage} onClick={handleClose} />
              </MUIBox>
            </MUIModal>
          </div>
        ))}
      </div>
    );
  }
  if (length === 2) {
    return (
      <div className={styles.slider}>
        {array.map((slide, index) => (
          <div key={slide.id}>
            <img src={slide.path} alt="" className={styles.image} onClick={() => handleOpen()} />
          </div>
        ))}
        <MUIModal
          slotProps={{
            backdrop: {
              style: {
                backgroundColor: 'white',
                backdropFilter: 'blur(14px)',
                background: 'rgba(250, 250, 250, 0.8)',
              },
            },
          }}
          open={openModal}
          onClose={handleClose}
          className={styles.modalBox}
        >
          <MUIBox className={styles.modal}>
            <Button type="icon" className={styles.modalClose} onClick={handleClose}>
              <CloseIcon />
            </Button>
            <Button type="icon" className={styles.left_arrow} onClick={prevSlide}>
              <LeftIcon style={{ width: 20, height: 20, flexShrink: 0 }} />
            </Button>
            <Button type="icon" className={styles.right_arrow} onClick={nextSlide}>
              <RightIcon style={{ width: 20, height: 20, flexShrink: 0 }} />
            </Button>
            {array.map((slide, index) => (
              <>
                {index === current && (
                  <img
                    src={slide.path}
                    alt=""
                    className={styles.modalImage}
                    onClick={() => handleClose()}
                  />
                )}
              </>
            ))}
          </MUIBox>
        </MUIModal>
      </div>
    );
  }
  if (length === 3) {
    return (
      <div className={styles.slider}>
        <div className={styles.rightImg}>
          <img src={array[0].path} alt="" className={styles.image} onClick={() => handleOpen()} />
        </div>
        <div className={styles.leftImg}>
          <img src={array[1].path} alt="" className={styles.image} onClick={() => handleOpen()} />
          <img src={array[2].path} alt="" className={styles.image} onClick={() => handleOpen()} />
        </div>
        <MUIModal
          slotProps={{
            backdrop: {
              style: {
                backgroundColor: 'white',
                backdropFilter: 'blur(14px)',
                background: 'rgba(250, 250, 250, 0.8)',
              },
            },
          }}
          open={openModal}
          onClose={handleClose}
          className={styles.modalBox}
        >
          <MUIBox className={styles.modal}>
            <Button type="icon" className={styles.modalClose} onClick={handleClose}>
              <CloseIcon />
            </Button>
            <Button type="icon" className={styles.left_arrow} onClick={prevSlide}>
              <LeftIcon style={{ width: 20, height: 20, flexShrink: 0 }} />
            </Button>
            <Button type="icon" className={styles.right_arrow} onClick={nextSlide}>
              <RightIcon style={{ width: 20, height: 20, flexShrink: 0 }} />
            </Button>
            {array.map((slide, index) => (
              <>
                {index === current && (
                  <img
                    src={slide.path}
                    alt=""
                    className={styles.modalImage}
                    onClick={() => handleClose()}
                  />
                )}
              </>
            ))}
          </MUIBox>
        </MUIModal>
      </div>
    );
  }

  if (length >= 4) {
    return (
      <div className={styles.slider}>
        <div className={styles.rightImg}>
          <img src={array[0].path} alt="" className={styles.image} onClick={() => handleOpen()} />
        </div>
        <div className={styles.leftImg}>
          <img src={array[1].path} alt="" className={styles.image} onClick={() => handleOpen()} />
          <img src={array[2].path} alt="" className={styles.image} onClick={() => handleOpen()} />
          <div className={styles.showMoreImg} onClick={() => handleOpen()}>
            <p>+ {length - 1}</p> <span> photo</span>
          </div>
        </div>
        <MUIModal
          slotProps={{
            backdrop: {
              style: {
                backgroundColor: 'white',
                backdropFilter: 'blur(14px)',
                background: 'rgba(250, 250, 250, 0.8)',
              },
            },
          }}
          open={openModal}
          onClose={handleClose}
          className={styles.modalBox}
        >
          <MUIBox className={styles.modal}>
            <Button type="icon" className={styles.modalClose} onClick={handleClose}>
              <CloseIcon />
            </Button>
            <Button type="icon" className={styles.left_arrow} onClick={prevSlide}>
              <LeftIcon style={{ width: 20, height: 20, flexShrink: 0 }} />
            </Button>
            <Button type="icon" className={styles.right_arrow} onClick={nextSlide}>
              <RightIcon style={{ width: 20, height: 20, flexShrink: 0 }} />
            </Button>
            {array.map((slide, index) => (
              <>
                {index === current && (
                  <img
                    src={slide.path}
                    alt=""
                    className={styles.modalImage}
                    onClick={() => handleClose()}
                  />
                )}
              </>
            ))}
          </MUIBox>
        </MUIModal>
      </div>
    );
  }
};
