import React, { useState } from 'react';
import styles from './UpcomingEventsTab.module.scss';
import { Box, Menu, styled, MenuItem } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Image, Button } from 'tt-ui-kit';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Filter } from '../Filter';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Pagination from '@mui/material/Pagination/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { ReactComponent as DeleteSvg } from '../../../../assets/svg/delete.svg';

export const UpcomingEventsTab = ({ events }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const StyledMenu = styled((props) => (
    <Menu
      id="event-info-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'menu-button',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08) !important',
      borderRadius: '12px',
      '& .MuiMenu-list': {},
    },
  }));
  return (
    <>
      <Box className={styles.filtersAndList}>
        <Filter />
        <Box className={styles.container}>
          {events.map((item) => (
            <Box key={item.id} className={styles.eventItem}>
              <Box className={styles.leftBlock}>
                <Image
                  src="/images/event-test.png"
                  height={96}
                  width={96}
                  style={{ borderRadius: '12px' }}
                />
              </Box>
              <Box className={styles.rightBlock}>
                <Box className={styles.eventInfo}>
                  <Box className={styles.eventTitle}>
                    Virtual Writing Hour with the National Portrait Gallery
                  </Box>
                  <ButtonBase type="button" className={styles.optionsButton} onClick={handleClick}>
                    <MoreVertIcon />
                  </ButtonBase>
                </Box>
                <Box className={styles.subInfo}>
                  <Box className={styles.mainSubInfo}>
                    <Box className={styles.eventDate}>Thu, Sep 1, 2022 8:30 PM EEST</Box>
                    <Box className={styles.status}>Online</Box>
                  </Box>
                  <Button type="default" className={styles.calculate}>
                    Calculate{' '}
                    <Box component="span" className={styles.buttonTextMobile}>
                      carbon footprint
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
          <StyledMenu>
            <MenuItem>
              <ListItemIcon>
                <EditIcon fontSize="small" style={{ fill: '#212121' }} />
              </ListItemIcon>
              <Box>Edit</Box>
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <Image src="/images/templates/share.svg" />
              </ListItemIcon>
              <Box>Share on social networks</Box>
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <Image src="/images/post/share.svg" />
              </ListItemIcon>
              <Box>Share for TT members</Box>
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <ContentCopyIcon fontSize="small" style={{ fill: '#212121' }} />
              </ListItemIcon>
              <Box>Copy link</Box>
            </MenuItem>
            <MenuItem>
              <ListItemIcon className={styles.deleteIcon}>
                <DeleteSvg />
              </ListItemIcon>
              <Box>Delete</Box>
            </MenuItem>
          </StyledMenu>
          <div className={styles.paginationBlock}>
            <Pagination
              color="primary"
              variant="outlined"
              shape="rounded"
              page={1}
              count={5}
              className={styles.pagination}
              renderItem={(props) => (
                <PaginationItem
                  className={`${styles.paginationItem} ${
                    props.page === 1 ? styles.activePaginationItem : ''
                  }`}
                  {...props}
                  disableRipple
                />
              )}
              // onChange={(event, value) => setPage(value)}
            />
          </div>
        </Box>
      </Box>
    </>
  );
};
