import { useEffect, useState } from 'react';
import { SLICES } from 'constants/SDGPage';
import styles from './GoalPage.module.scss';
import PieChartD3 from '../PieChartD3';
import GoalDescription from './GoalDescription';

const GoalPage = ({ currentGoal, setCurrentGoal }) => {
  const [isMobile, setIsMobile] = useState(false);

  return (
    <>
      {currentGoal && (
        <div className={`${styles.pageContentWrapper} ${isMobile && styles.mobileWrapper}`}>
          <PieChartD3
            chartData={SLICES}
            hasDesktopFormat
            goal={currentGoal}
            isMobile={isMobile}
            setIsMobile={setIsMobile}
            isGoalPage
          />
          <GoalDescription goal={currentGoal} setGoal={setCurrentGoal} isMobile={isMobile} />
        </div>
      )}
    </>
  );
};

export default GoalPage;
