export const NAVBAR = [
  {
    label: 'Admins',
    // url: '/',
  },
  {
    label: 'All items',
    url: '/admin/all-items',
  },
  {
    label: 'Orders',
    // url: '/',
  },
  {
    label: 'Users',
    url: '/admin/users',
  },
  {
    label: 'Communications',
    // url: '/',
  },
  {
    label: 'Tools',
    url: '/admin/tools',
  },
  {
    label: 'Statistics',
    // url: '/',
  },
  {
    label: 'Chat-Bot',
    url: '/admin/chat-bot',
  },
  {
    label: 'Digital assets',
    // url: '/',
  },
  {
    label: 'Companies',
    url: '/admin/companies',
  },
  {
    label: 'Chat',
    // url: '/',
  },
  {
    label: 'Content',
    url: '/admin/content',
  },
  {
    label: 'Suggestions',
    // url: '/',
  },
  {
    label: 'Сomplaints',
    // url: '/',
  },
];

export const FCE_GROUP_NAVBAR = [
  {
    label: 'FCE Users',
    url: '/admin/fce-users',
  },
  {
    label: 'FCE admin',
    url: '/admin/fce-admin',
  },
  {
    label: 'Contracts',
    url: '/admin/fce-contracts',
  },
];
