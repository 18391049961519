import React, { useEffect, useState } from 'react';
import { Button } from 'tt-ui-kit';
import styles from './ToolsPage.module.scss';
import { useMutation } from '@apollo/client';
import { MAKE_TEST_PAYMENT } from '../../../../api';
import { Alert, Snackbar } from '@mui/material';

const ToolsPage = () => {
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const location = window.location.href;

  const [checkoutLinkMutation, { data, loading, error }] = useMutation(MAKE_TEST_PAYMENT);

  useEffect(() => {
    if (error) {
      setOpenErrorAlert(true);
      setIsDisabled(false);
      console.error(error);
    }
  }, [error]);

  const testBuy = () => {
    setIsDisabled(true);
    setOpenErrorAlert(false);
    checkoutLinkMutation({
      variables: {
        input: {
          payment_system: `stripe`,
          success_url: location,
          cancel_url: location,
        },
      },
    });
  };
  // Redirect to Payment System url
  useEffect(() => {
    if (data?.testPaymentInternalService?.url) {
      window.location.href = data?.testPaymentInternalService?.url;
    }
  }, [data]);

  return (
    <>
      <div className={styles.pageContainer}>
        <Button
          type="primary"
          className={styles.primaryButton}
          onClick={() => testBuy()}
          disabled={isDisabled}
        >
          <div className={styles.buttonWithImage}>
            <img src="/images/admin/plus.svg" alt="" /> <span>Test Stripe</span>
          </div>
        </Button>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={openErrorAlert}
          onClose={() => setOpenErrorAlert(false)}
        >
          <Alert
            onClose={() => setOpenErrorAlert(false)}
            severity="error"
            sx={{ width: '100%', backgroundColor: '#D32F2F', color: '#ffffff' }}
          >
            Something went wrong, try again
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default ToolsPage;
