import clsx from 'clsx';
import React from 'react';
import { LogoIcon } from 'tt-ui-kit';
import styles from './PartialLoader.module.scss';

const PartialLoader = ({ loading, ...restProps }) => (
  <div
    className={clsx(
      styles.container,
      restProps?.isLightContainer ? styles.lightContainer : '',
      !loading ? styles.closed : ''
    )}
  >
    <LogoIcon className={styles.logo} />
    <div className={styles.loading}>Loading...</div>
  </div>
);

export default PartialLoader;
