import styles from './FormItemRight.module.scss';

const FormItemRight = ({ children, formName, formAction, onChange, onSubmit }) => (
  <div className={styles.formItemRight}>
    <form
      name={formName}
      method="POST"
      action={formAction}
      className={styles.form}
      onChange={onChange}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  </div>
);

export default FormItemRight;
