import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material';
import { ReactComponent as AnsveredSvg } from '../../../../../../assets/svg/checkMark.svg';
import styles from './CompanyAnswers.module.scss';
import ResultFilesModal from '../../../CalcResultFilesModal/ResultFilesModal';
import { getQuestions } from 'api/calc/endpoints/calc';

const CustomizedAccordion = styled(Accordion)(() => ({
  '.MuiAccordionDetails-root': {
    padding: 0,
  },
  '&.MuiAccordion-root': {
    borderRadius: '16px',
    width: '100%',
    padding: '16px',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
    marginBottom: '10px',
  },
  '&:before': {
    backgroundColor: 'white',
  },
  boxShadow: 'none',
}));

const CompanyAnswers = ({ params }) => {
  const { resultFiles, answersData, title, factorId } = params;

  const [questionsData, setQuestionsData] = useState([]);
  const [resultsFilesList, setResultsFilesList] = useState([]);
  const [resultsFilesCodes, setResultsFilesCodes] = useState([]);
  const [resultsList, setResultsList] = useState([]);

  useEffect(() => {
    getQuestions({ collectionId: factorId }).then((res) => {
      // TODO replace
      // getQuestions({ collectionId: 'e7fb1c68-00db-4ef0-ac7d-4d891eb6ffe7' }).then((res) => {
      setQuestionsData(res?.data);
    });
  }, []);

  const createResultObject = (scope, files) => {
    // Flatten the scope array and map it to an object for easier lookup
    const scopeMap = scope.reduce((acc, curr, index) => {
      curr.forEach((item) => {
        acc[item.question.id] = {
          question: item.question.name,
          subquestion: null,
          code: item.question.id,
          title: `Scope ${index + 1}`,
          files: [],
        };
      });
      return acc;
    }, {});

    // Reduce the files array to the desired format
    files.forEach((file) => {
      if (scopeMap[file.questionCode]) {
        scopeMap[file.questionCode].files.push(file);
      }
    });

    // Convert the scopeMap object to an array and filter out objects without files
    const resultObject = Object.values(scopeMap).filter((item) => item.files.length > 0);

    return resultObject;
  };

  useEffect(() => {
    if (!resultsList.length) return;
    const filesList = createResultObject(resultsList, resultFiles);
    setResultsFilesList([...filesList]);
    setResultsFilesCodes([...filesList.map((q) => q.code)]);
  }, [resultsList, resultFiles]);

  useEffect(() => {
    if (!questionsData || !answersData.companyAnswers) return;
    const newQuestions = questionsData.reduce((res, category) => {
      const arr = category.questions.reduce(
        (res1, el) => [
          ...res1,
          {
            question: el,
            answer: answersData.companyAnswers[el.id],
          },
        ],
        []
      );
      return [...res, arr];
    }, []);
    setResultsList(newQuestions);
  }, [questionsData, answersData]);

  return (
    <div className={styles.companyMainFrame}>
      {resultsList.map((scope, indx) => (
        <CustomizedAccordion key={`${indx.toString()}`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: '0px', margin: '0px' }}
          >
            <div className={styles.summary}>
              <h3 className={styles.title}>Scope {indx + 1}</h3>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {scope.map((el) => (
              <div className={styles.container}>
                <div key={el.question.code} className={styles.sourceBlock}>
                  <div className={styles.questionTitle}>
                    <span>
                      <AnsveredSvg
                        className={clsx(
                          styles.qbuttonsvg,
                          { [styles.required]: !el.answer },
                          { [styles.answered]: el.answer }
                        )}
                      />
                    </span>
                    <h4>{el.question.name}</h4>
                  </div>
                  {!el.answer && <div>No answer available</div>}
                  {!!el.info && el.info}
                  {!el.info && el.answer && (
                    <div className={styles.componentsContainer}>
                      {el.answer.map((com, idx) => (
                        <div className={styles.componentBlock}>
                          <div className={styles.componentNum}>Activity {idx + 1}:</div>
                          <div className={styles.componentDetails}>
                            {!!com.activities && (
                              <div>
                                <span>Activity:</span> {com.activities}
                              </div>
                            )}
                            {!!com.type && (
                              <div>
                                <span>Type:</span> {com.type}
                              </div>
                            )}
                            {!!com.unit && (
                              <div>
                                <span>Unit:</span> {com.unit}
                              </div>
                            )}
                            {!!com.size && (
                              <div>
                                <span>Size:</span> {com.size}
                              </div>
                            )}
                            {!!com.additional && com.additional.length > 0 && (
                              <div>
                                <span>Additional:</span> {com.additional}
                              </div>
                            )}
                            {!!com.data && (
                              <div>
                                <span>Data:</span> {com.data}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {resultsFilesCodes.includes(el.question.code) && (
                  <div className={styles.buttonBlock}>
                    <ResultFilesModal
                      params={{
                        resultsFilesList,
                        resultsFilesCodes,
                        code: el.question.code,
                      }}
                    />
                  </div>
                )}
              </div>
            ))}
          </AccordionDetails>
        </CustomizedAccordion>
      ))}
    </div>
  );
};

export default CompanyAnswers;
