/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import styles from './SharingUserSearchSelect.module.scss';
import infoStyles from './InfoModal.module.scss';
import SearchIcon from '@mui/icons-material/Search';
import {
  GET_USERS_FOR_SHARING,
  GET_COMPANIES_FOR_SHARING,
  CREATE_BATCH_SHARING_ACCESS,
} from '../../../api';
import { TextInput, CheckBox, Button, Modal } from 'tt-ui-kit';
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
} from '@mui/material';
import UserBlock from '../../UserBlock/UserBlock';
import { useSelectedContext } from 'context/contextSelector';
import CompanyBlock from 'components/CompanyBlock/CompanyBlock';

const SharingUserSearchSelect = ({ sharing, goBack }) => {
  const { user } = useSelectedContext((context) => context.user);
  const pageSize = 20;
  const [searchType, setSearchType] = useState('user');
  const [searchString, setSearchString] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [isCancelNDA, setIsCancelNDA] = useState(false);
  const [message, setMessage] = useState('');
  const [isCancelPayment, setIsCancelPayment] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [timeoutID, setTimeoutID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOkModalOpened, setIsOkModalOpened] = useState(false);

  const initialUserQueryFilter = {
    limit: pageSize,
    page: 1,
    order_dir: 'desc',
    f: [],
  };

  const initialCompanyQueryFilter = {
    first: pageSize,
    input: {
      type: 'company',
      is_draft: false,
    },
  };

  const [getUsers] = useLazyQuery(GET_USERS_FOR_SHARING);
  const [getCompanies] = useLazyQuery(GET_COMPANIES_FOR_SHARING);
  const [createAccess] = useMutation(CREATE_BATCH_SHARING_ACCESS);

  useEffect(() => {
    if (!open || !searchString) {
      setUsersList([]);
      setCompanyList([]);
    }
    setLoading(open && searchString?.length >= 2);
  }, [open, searchString]);

  useEffect(() => {
    if (!user?.id) return;

    let active = true;
    if (!loading || !searchString || searchString?.length < 2) {
      return undefined;
    }

    if (searchType === 'user') {
      (async () => {
        const users = await getUsers({
          variables: { input: { ...initialUserQueryFilter, q: searchString } },
        });
        setLoading(false);
        if (users.data && users.data.adminUsers && active) {
          setUsersList(users.data.adminUsers.users);
          setCompanyList([]);
        }
      })();
    }
    if (searchType === 'company') {
      (async () => {
        const input = { ...initialCompanyQueryFilter.input };
        if (searchString.includes('@')) {
          input.partial_email = searchString;
        } else {
          input.partial_name = searchString;
        }
        const companiesData = await getCompanies({
          variables: {
            ...initialCompanyQueryFilter,
            input: { ...input },
          },
        });
        setLoading(false);
        if (companiesData?.data?.getCompaniesByWithPagination?.data && active) {
          setCompanyList(companiesData?.data?.getCompaniesByWithPagination?.data);
          setUsersList([]);
        }
      })();
    }

    return () => {
      active = false;
    };
  }, [loading]);

  const startSearch = (value) => {
    setInputValue(value);
    if (!value) {
      setSearchString('');
    } else {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
      const newTimeoutId = setTimeout(() => {
        setSearchString(value);
      }, 1000);
      setTimeoutID(newTimeoutId);
    }
  };

  const saveAccess = async () => {
    const userArr = selectedUsers.reduce(
      (res, usr) => [
        ...res,
        {
          sharing_id: sharing.id,
          user_id: usr.id,
          company_id: '', // TODO Добавить компании
          is_cancel_nda: isCancelNDA,
          is_cancel_payment: isCancelPayment,
          is_approved: sharing.isAutoApprove,
          has_personal_access: true,
        },
      ],
      []
    );
    const companiesArr = selectedCompanies.reduce(
      (res, com) => [
        ...res,
        {
          sharing_id: sharing.id,
          user_id: '',
          company_id: com.id, // TODO Добавить компании
          is_cancel_nda: isCancelNDA,
          is_cancel_payment: isCancelPayment,
          is_approved: sharing.isAutoApprove,
          has_personal_access: true,
        },
      ],
      []
    );
    const accesses = await createAccess({
      variables: {
        input: [...userArr, ...companiesArr],
      },
    });
    setIsOkModalOpened(true);
    goBack();
  };

  const onReturn = () => {
    setIsOkModalOpened(false);
    goBack();
  };

  const setNewItem = (selectedId, selectedList, sourceList) => {
    const selectedIds = selectedList.map((el) => el.id);
    let arr = [];
    if (selectedIds.includes(selectedId)) {
      arr = selectedList.filter((el) => el.id !== selectedId);
    } else {
      const newItem = sourceList.find((el) => el.id === selectedId);
      arr = [...selectedList, newItem];
    }
    return arr;
  };

  const onUserSelected = (selectedId) => {
    const newList = setNewItem(selectedId, selectedUsers, usersList);
    setSelectedUsers(newList);
  };

  const onCompanySelected = (selectedId) => {
    const newList = setNewItem(selectedId, selectedCompanies, companyList);
    setSelectedCompanies(newList);
  };

  return (
    <>
      <Modal open={isOkModalOpened} onClose={() => onReturn()} className={infoStyles.infoModal}>
        <p style={{ marginBottom: 20 }}>The accesses were created successfully!</p>
        <Button onClick={() => onReturn()}>OK</Button>
      </Modal>
      <div>
        <RadioGroup row value={searchType} onChange={(e) => setSearchType(e.target.value)}>
          <FormControlLabel value="user" control={<Radio />} label="Search by person" />
          <FormControlLabel value="company" control={<Radio />} label="Search by company" />
        </RadioGroup>
      </div>
      <div className={styles.container}>
        <div>
          <Autocomplete
            // sx={{ width: 300 }}
            options={searchType === 'user' ? usersList : companyList}
            disableCloseOnSelect
            noOptionsText={!loading ? 'Type filter' : 'Loading...'}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            clearOnEscape
            multiple
            clearOnBlur
            filterOptions={(options) => options} // update list when userList changed
            getOptionLabel={(option) => option.id}
            renderOption={(props, option) => (
              <Box
                key={option.id}
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {searchType === 'user' && (
                  <UserBlock user={option} onClick={() => onUserSelected(option.id)} />
                )}
                {searchType === 'company' && (
                  <CompanyBlock company={option} onClick={() => onCompanySelected(option.id)} />
                )}
              </Box>
            )}
            renderInput={(params) => (
              <TextInput
                {...params}
                label="Choose a user"
                required={false}
                onChange={(e) => startSearch(e.target.value)}
                inputProps={{
                  ...params.inputProps,
                  value: inputValue,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: '#555555' }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </div>
        <div className={styles.usersListContainer}>
          <div className={styles.usersListBlock}>
            {!!selectedUsers.length &&
              selectedUsers.map((usr) => <UserBlock user={usr} onClose={onUserSelected} />)}
            {!!selectedCompanies.length &&
              selectedCompanies.map((com) => (
                <CompanyBlock company={com} onClose={onCompanySelected} />
              ))}
            {selectedUsers.length === 0 &&
              selectedCompanies.length === 0 &&
              'Please select users or companies'}
          </div>
        </div>
        {(!!sharing.isNeedNDA || !!sharing.isNeedPay) && (
          <div>
            {!!sharing.isNeedNDA && (
              <CheckBox
                name="isCancelNDA"
                label="Request NDA before access is granted"
                value={isCancelNDA}
                onChange={() => setIsCancelNDA((value) => !value)}
              />
            )}
            {!!sharing.isNeedPay && (
              <CheckBox
                name="isCancelPayment"
                label="Payment required"
                value={isCancelPayment}
                onChange={() => setIsCancelPayment((value) => !value)}
              />
            )}
          </div>
        )}
        <div>
          <TextInput
            minRows={4}
            maxRows={4}
            multiline
            fullWidth
            inputProps={{ maxLength: 200 }}
            className={styles.textArea}
            placeholder="Message to users"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            required={false}
          />
        </div>
        <div className={styles.bottomButton}>
          <Button
            type="primary"
            onClick={() => saveAccess()}
            disabled={selectedUsers.length === 0 && selectedCompanies.length === 0}
          >
            Share
          </Button>
        </div>
      </div>
    </>
  );
};

export default SharingUserSearchSelect;
