import React, { useState, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, MenuItem, Select, Stack } from '@mui/material';
import styles from '../InviteGroupModal.module.scss';
import { USERS } from '../fakeUsers';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Button, SendIcon, SearchIcon } from 'tt-ui-kit';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { AppAvatar } from 'components/Avatar';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxOutlinedIcon fontSize="small" />;

const selectSx = {
  padding: '0px',
  color: 'rgba(33, 33, 33, 1)',
  '.MuiOutlinedInput-notchedOutline': {
    borderRadius: '12px',
    border: '1px solid #EFEFEF',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderRadius: '12px',
    border: '1px solid #EFEFEF',
  },
};
const ROLL_IN_GROUP = [
  {
    name: 'Admin',
    value: 'admin',
  },
  {
    name: 'Member',
    value: 'member',
  },
];

export const CommunityInviteForm = ({ onClose }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const formOptions = {
    defaultValues: {
      users: '',
    },
  };
  const formMethods = useForm(formOptions, { mode: 'all' });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    getValues,
    register,
  } = formMethods;

  const onSubmitCommunityInvite = (data) => {
    console.log('community invite data::::', data);
  };

  const removeSelectedUser = (id) => {
    const updatedUsersLIst = selectedUsers.filter((item) => item !== id);
    setSelectedUsers(updatedUsersLIst);
  };
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmitCommunityInvite)}>
        <Box className={styles.formFields}>
          <Controller
            name="group_roles"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Select
                displayEmpty
                value={value}
                defaultValue=""
                onChange={onChange}
                sx={selectSx}
                className={styles.select}
              >
                <MenuItem disabled value="">
                  <Box className={styles.menuItem}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Suggest a role in the group*</Typography>
                    </Stack>
                  </Box>
                </MenuItem>
                {ROLL_IN_GROUP.map((item) => (
                  <MenuItem value={item.value}>
                    <Box className={styles.menuItem}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>{item.name}</Typography>
                      </Stack>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Controller
            name="users[]"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                multiple
                options={USERS}
                className={styles.select}
                sx={{
                  '.MuiOutlinedInput-root': {
                    padding: 0,
                  },
                }}
                disableCloseOnSelect
                getOptionLabel={(option) => option.firstName}
                renderOption={(props, option, { selected }) => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <li
                    {...props}
                    className={styles.listItem}
                    onClick={() => {
                      if (!selectedUsers.includes(option.id)) {
                        setSelectedUsers((current) => [...current, option.id]);
                      } else {
                        setSelectedUsers((current) => current.filter((item) => item !== option.id));
                      }
                    }}
                  >
                    {selectedUsers.includes(option.id) ? (
                      <Checkbox
                        icon={icon}
                        sx={{
                          '&.Mui-checked': {
                            color: '#212121',
                          },
                        }}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedUsers((current) => [...current, option.id]);
                          } else {
                            setSelectedUsers((current) =>
                              current.filter((item) => item !== option.id)
                            );
                          }
                        }}
                        checked={selectedUsers.includes(option.id)}
                      />
                    ) : (
                      <AppAvatar
                        userName={`${option.firstName} ${option.lastName}`}
                        src={option.avatar}
                        nav={0}
                        size="sm"
                        role={option.role}
                      />
                    )}
                    {`${option.firstName} ${option.lastName}`}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <Box className={styles.inputGroup}>
                    <TextField className={styles.textField} {...params} label="Search" />
                    <Box className={styles.searchIcon}>
                      <SearchIcon style={{ width: 20, height: 20 }} />
                    </Box>
                  </Box>
                )}
              />
            )}
          />
        </Box>
        {selectedUsers.length > 0 && (
          <Box className={styles.usersList}>
            {selectedUsers.map((item) => {
              const user = USERS.find((el) => el.id === item);
              return (
                <Box className={styles.userListItem}>
                  <Box className={styles.leftSide}>
                    <AppAvatar
                      userName={`${user.firstName} ${user.lastName}`}
                      src={user.avatar}
                      nav={0}
                      size="sm"
                      role={user.role}
                    />
                    <Box>{`${user.firstName} ${user.lastName}`}</Box>
                  </Box>
                  <Button
                    type="icon"
                    className={styles.deleteIcon}
                    onClick={() => removeSelectedUser(user.id)}
                  >
                    <CloseIcon />
                  </Button>
                </Box>
              );
            })}
          </Box>
        )}
        <Box className={styles.modalButtons}>
          <Button type="default" onClick={onClose}>
            cancel
          </Button>
          <Button type="primary" isSubmit className={styles.sendBtn}>
            <SendIcon />
            Send
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
