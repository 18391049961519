/* eslint-disable no-unused-expressions */
/* eslint-disable no-throw-literal */

import {
  chainSettings,
  closeAppLoader,
  hideStandby,
  openAppLoader,
  showError,
  showStandby,
} from './common';
import { isABI, isAddress, toBN, toJSON } from './web3_helper';
import Cookies from 'js-cookie';
import { useMetaMask } from 'metamask-react';

const {
  REACT_APP_CHAIN_RPC_API_URL,
  REACT_APP_CHAIN_ID,
  REACT_APP_SSO_API,
  REACT_APP_SSO_TT_API_KEY,
} = process.env;

const API_URL = `${REACT_APP_CHAIN_RPC_API_URL}/api`;
const DIRECT_TT_API_URL = `${REACT_APP_CHAIN_RPC_API_URL}/tt_api`;
const TT_API_URL = `${REACT_APP_SSO_API}`;

//* ************************************************

/**
 * Отправка POST запроса
 * @param {String} url
 * @param {Object?} [headers]
 * @param {*} [data]
 * @param {Boolean} [bHide]
 * @param {Boolean} [credentials]
 * @param {Boolean} [originalData]
 * @return {Promise<*>}
 * @async
 */
export const post = async (url, headers, data, bHide, credentials, originalData) =>
  new Promise((resolve, reject) => {
    try {
      !bHide && openAppLoader();

      // eslint-disable-next-line no-underscore-dangle
      const _headers = headers || {};
      if (!(_headers.authorization || _headers.Authorization) && !credentials) {
        _headers.authorization = 'Basic YWRtaW46Z2ZoamttMDE=';
      }
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        cors: '*',
        credentials: credentials ? 'include' : 'omit',
        headers: _headers,
        body: originalData ? data : typeof data === 'object' && data ? toJSON(data) : data,
      })
        .then(async (req) => {
          if (req.ok) {
            req
              .json()
              .then((res) => {
                if (res?.error) {
                  reject(new Error(res?.code, res?.message, res?.stack));
                } else {
                  resolve(res);
                }
                !bHide && closeAppLoader();
              })
              .catch((err) => {
                !bHide && closeAppLoader();
                reject(err);
              });
          } else {
            !bHide && closeAppLoader();
            // eslint-disable-next-line prefer-promise-reject-errors
            let descr = await req.text();
            try {
              descr = JSON.parse(descr);
            } catch (err) {
              /* empty */
            }
            if (descr?.error) {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(descr.error);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ code: req.status, message: req.statusText, descr: descr });
            }
          }
        })
        .catch((err) => {
          !bHide && closeAppLoader();
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });

let apiRequestId = 0;

/**
 * Запрос к API Proxy
 * @param {String} method
 * @param {Object} [data]
 * @param {Boolean} [bHide]
 * @return {Promise<Object|Array>}
 * @async
 */
export const APIRequest = async (method, data, bHide) => {
  const body = {
    id: ++apiRequestId,
    method: method,
  };
  // eslint-disable-next-line no-return-assign
  data && Object.keys(data).forEach((k) => (body[k] = data[k]));
  try {
    const res = await post(API_URL, { 'content-type': 'application/json' }, body, bHide);
    if (res.error) {
      throw new Error({ code: res?.code, message: res?.message, stack: res?.stack });
    }
    return res?.result;
  } catch (err) {
    console.error(err);
    if (bHide) {
      throw err;
    } else {
      showError(err);
      return null;
    }
  }
};

/**
 * Запрос к TT API
 * @param {String}   method
 * @param {Object}  [data=null]
 * @param {Boolean} [bHide=false]
 * @param {Boolean} [pureBody=false]
 * @return {Promise<Object|Array>}
 * @async
 */
export const TTAPIRequest = async (method, data, bHide, pureBody = false) => {
  const body = {
    id: ++apiRequestId,
  };

  if (pureBody) {
    // eslint-disable-next-line no-return-assign
    data && Object.keys(data).forEach((k) => (body[k] = data[k]));
  } else {
    body.params = {};
    // eslint-disable-next-line no-return-assign
    data && Object.keys(data).forEach((k) => (body.params[k] = data[k]));
  }

  const domainValue = process.env.REACT_APP_DOMAIN;
  const token = Cookies.get('access_token', { domain: domainValue });
  // it is a crutch to auth user on market
  const userId = Cookies.get('userId', { domain: domainValue });

  if (token && userId) {
    try {
      const res = await post(
        `${TT_API_URL}${method}`,
        {
          'content-type': 'application/json',
          userId: userId,
          Authorization: token ? `Bearer ${token}` : '',
        },
        body,
        bHide,
        false
      );
      return res?.result;
    } catch (err) {
      showError(err);
      return null;
    }
  } else {
    throw new Error('Token is missing');
  }
};

/**
 * Запрос к TT API (напрямую, для тестирования)
 * @param {String} method
 * @param {Object} [data]
 * @param {Boolean} [bHide]
 * @return {Promise<Object|Array>}
 * @async
 */
export const DirectTTAPIRequest = async (method, data, bHide) => {
  const body = {
    id: ++apiRequestId,
    method: method,
  };
  // eslint-disable-next-line no-return-assign
  data && Object.keys(data).forEach((k) => (body[k] = data[k]));
  try {
    const res = await post(
      DIRECT_TT_API_URL,
      {
        'content-type': 'application/json',
        'tt-qevpoq': REACT_APP_SSO_TT_API_KEY,
      },
      body,
      bHide
    );
    if (res.error) {
      throw new Error({ code: res?.code, message: res?.message, stack: res?.stack });
    }
    return res?.result;
  } catch (err) {
    console.error(err);
    if (bHide) {
      throw err;
    } else {
      showError(err);
      return null;
    }
  }
};

//* ************************************************

const contractDetails = {};

/**
 * Получаем данные контракта
 * @param {String}         name
 * @param {Number}        [version]
 * @param {Number|String} [netID]
 * @param {Boolean}       [bytecode]
 * @param {Boolean}       [last]
 * @return {Promise<?{address: ?String, abi: ?Object, [bytecode]: ?String}>}
 * @async
 */
export const getContractsDetail = async (name, version, netID, bytecode, last) => {
  if (!contractDetails[name]) {
    const chainId = netID
      ? parseInt(netID, netID.substring(0, 2) === '0x' ? 16 : 10)
      : parseInt(chainSettings.chainId, chainSettings.chainId.substring(0, 2) === '0x' ? 16 : 10);

    contractDetails[name] = await APIRequest('getContractDetail', {
      name: name,
      net: chainId,
      bytecode: !!bytecode,
      last: !!last,
    });
  }

  return contractDetails[name];
};

/**
 * Получаем данные контракта по адресу
 * @param {String}         address
 * @param {Number|String} [netID]
 * @param {Boolean}       [bytecode]
 * @return {Promise<?{address: ?String, name: String, version: Number, abi: ?Object, [bytecode]: ?String}>}
 * @async
 */
export const getContractByAddress = async (address, netID, bytecode) => {
  if (!isAddress[address]) {
    const chainId = netID
      ? parseInt(netID, netID.substring(0, 2) === '0x' ? 16 : 10)
      : parseInt(chainSettings.chainId, chainSettings.chainId.substring(0, 2) === '0x' ? 16 : 10);

    return APIRequest('getContractByAddress', {
      address: address,
      net: chainId,
      bytecode: !!bytecode,
    });
  }
};

/**
 * Получаем данные контракта по адресу
 * @param {String}         name
 * @param {Number|String} [netID]
 * @param {Number}        [version]
 * @return {Promise<?String>}
 * @async
 */
export const getContractAddress = async (name, netID, version) => {
  if (name) {
    const chainId = netID
      ? parseInt(netID, netID.substring(0, 2) === '0x' ? 16 : 10)
      : parseInt(chainSettings.chainId, chainSettings.chainId.substring(0, 2) === '0x' ? 16 : 10);

    return APIRequest('getContractAddress', {
      name: name,
      net: chainId,
      version: parseInt(version, 10),
    });
  }
};

//* ************************************************

/**
 * Отправка запроса без транзакции с указанием ABI и Адреса
 * @param {Object|web3} web3
 * @param {String}      status
 * @param {String}      account
 * @param {String}      address
 * @param {Object}      abi
 * @param {String}      methodName
 * @param {Array?}     [params]
 * @param {Boolean}    [bHide]
 * @return {Promise<*>}
 * @async
 */
export const callContractMethodWithAddressABI = async (
  web3,
  status,
  account,
  address,
  abi,
  methodName,
  params,
  bHide
) => {
  if (web3 && web3?.eth) {
    if (status === 'connected') {
      if (isAddress(account)) {
        if (isAddress(address)) {
          if (isABI(abi)) {
            if (methodName) {
              try {
                !bHide && showStandby();

                const contract = new web3.eth.Contract(abi, address);

                const method = contract?.methods[methodName];
                if (method) {
                  const fnc = method.apply(this, params || []);

                  const data = fnc.encodeABI();

                  return await fnc.call({ from: account, data: data });
                }
              } catch (err) {
                console.error(err);
                throw err?.data?.message || err;
              } finally {
                !bHide && hideStandby();
              }
            } else {
              throw 'Need the Method name of the called contract.';
            }
          } else {
            throw 'Need the ABI of the called contract.';
          }
        } else {
          throw 'Need the address of the called contract.';
        }
      } else {
        throw 'Need connect wallet in MetaMask plugin first.';
      }
    } else {
      throw 'Need connect MetaMask plugin first.';
    }
  } else {
    throw 'Need install MetaMask plugin first.';
  }
};

/**
 * Отправка запроса без транзакции с указанием адреса и наименование контракта
 * @param {Object|web3} web3
 * @param {String}      status
 * @param {String}      account
 * @param {String}      address
 * @param {String}      contractName
 * @param {String}      methodName
 * @param {Array?}     [params]
 * @param {Boolean}    [bHide]
 * @return {Promise<*>}
 * @async
 */
export const callContractMethodWithAddress = async (
  web3,
  status,
  account,
  address,
  contractName,
  methodName,
  params,
  bHide
) => {
  if (web3 && web3?.eth) {
    if (status === 'connected') {
      if (isAddress(account)) {
        if (isAddress(address)) {
          if (contractName) {
            if (methodName) {
              try {
                !bHide && showStandby();

                let contractData = await getContractsDetail(contractName);
                if (!contractData?.abi) {
                  // Попытка поправить багу с отсутствием ABI
                  contractData = await getContractsDetail(contractName, null, null, false, true);
                }
                if (contractData?.abi) {
                  return callContractMethodWithAddressABI(
                    web3,
                    status,
                    account,
                    address,
                    contractData.abi,
                    methodName,
                    params,
                    bHide
                  );
                }

                throw `Contract ${contractName} not compile or not found`;
              } finally {
                !bHide && hideStandby();
              }
            } else {
              throw 'Need the Method name of the called contract.';
            }
          } else {
            throw 'Need the Contract name of the called contract.';
          }
        } else {
          throw 'Need the address of the called contract.';
        }
      } else {
        throw 'Need connect wallet in MetaMask plugin first.';
      }
    } else {
      throw 'Need connect MetaMask plugin first.';
    }
  } else {
    throw 'Need install MetaMask plugin first.';
  }
};

/**
 * Отправка запроса без транзакции
 * @param {Object|web3} web3
 * @param {String}      status
 * @param {String}      account
 * @param {String}      contractName
 * @param {String}      methodName
 * @param {Array?}     [params]
 * @param {Boolean}    [bHide]
 * @return {Promise<*>}
 * @async
 */
export const callContractMethod = async (
  web3,
  status,
  account,
  contractName,
  methodName,
  params,
  bHide
) => {
  if (web3 && web3?.eth) {
    if (status === 'connected') {
      if (isAddress(account)) {
        if (contractName) {
          if (methodName) {
            try {
              !bHide && showStandby();

              let contractData = await getContractsDetail(contractName);
              if (!contractData?.abi) {
                // Попытка поправить багу с отсутствием ABI
                contractData = await getContractsDetail(contractName, null, null, false, true);
              }

              if (isAddress(contractData?.address) && contractData?.abi) {
                return await callContractMethodWithAddressABI(
                  web3,
                  status,
                  account,
                  contractData.address,
                  contractData.abi,
                  methodName,
                  params,
                  bHide
                );
              }

              throw `Contract ${contractName} not found`;
            } finally {
              !bHide && hideStandby();
            }
          } else {
            throw 'Need the Method name of the called contract.';
          }
        } else {
          throw 'Need the Contract name of the called contract.';
        }
      } else {
        throw 'Need connect wallet in MetaMask plugin first.';
      }
    } else {
      throw 'Need connect MetaMask plugin first.';
    }
  } else {
    throw 'Need install MetaMask plugin first.';
  }
};

//* ************************************************

/**
 * Отправка запроса с транзакцией с указанием адреса и ABI
 * @param {Object|web3}           web3
 * @param {String}                status
 * @param {String}                account
 * @param {String}                address
 * @param {Object}                abi
 * @param {String}                methodName
 * @param {Array?}               [params]
 * @param {Boolean}              [bHide]
 * @param {String|Number|BigInt} [amount]
 * @param {BigInt}               [gasLimit]
 * @return {Promise<unknown>}
 */
export const sendContractMethodWithAddressABI = async (
  web3,
  status,
  account,
  address,
  abi,
  methodName,
  params,
  bHide,
  amount,
  gasLimit
) => {
  if (web3 && web3?.eth) {
    if (status === 'connected') {
      if (isAddress(account)) {
        if (isAddress(address)) {
          if (isABI(abi)) {
            if (methodName) {
              let gas;
              let gasPrice;
              let data;
              try {
                !bHide && showStandby();

                const contract = new web3.eth.Contract(abi, address);

                const method = contract?.methods[methodName];
                if (method) {
                  const fnc = method.apply(this, params || []);

                  data = fnc.encodeABI();

                  let res;
                  if (chainSettings.chainId === REACT_APP_CHAIN_ID) {
                    gasPrice = await web3.eth.getGasPrice();
                    gas = await fnc.estimateGas({
                      from: account,
                      data: data,
                      value: amount,
                    });

                    res = await fnc.send({
                      from: account,
                      gas: gasLimit > 0 ? toBN(gasLimit) : toBN(gas) * 2n, // Для своей сети увеличиваем газ в 2 раза
                      gasPrice: gasPrice,
                      data: data,
                      value: toBN(amount),
                    });
                  } else {
                    res = await fnc.send({
                      from: account,
                      data: data,
                      value: toBN(amount),
                    });
                  }

                  return res;
                }
                throw `Method ${methodName} not found`;
              } catch (err) {
                console.error(`sendContractMethodWithAddressABI:`);
                console.error(err);
                console.error({
                  gas: toBN(gas),
                  gasPrice: toBN(gasPrice),
                  data: data,
                  address: address,
                  abi: abi,
                  amount: toBN(amount),
                  params: params,
                });
                throw err?.data?.message || err;
              } finally {
                !bHide && hideStandby();
              }
            } else {
              throw 'Need the Method name of the called contract.';
            }
          } else {
            throw 'Need the ABI of the called contract.';
          }
        } else {
          throw 'Need the address of the called contract.';
        }
      } else {
        throw 'Need connect wallet in MetaMask plugin first.';
      }
    } else {
      throw 'Need connect MetaMask plugin first.';
    }
  } else {
    throw 'Need install MetaMask plugin first.';
  }
};

/**
 * Отправка запроса с транзакцией по конкретному адресу
 * @param {Object|web3}           web3
 * @param {String}                status
 * @param {String}                account
 * @param {String}                address
 * @param {String}                contractName
 * @param {String}                methodName
 * @param {Array?}               [params]
 * @param {Boolean}              [bHide]
 * @param {String|Number|BigInt} [amount]
 * @param {BigInt}               [gasLimit]
 * @return {Promise<unknown>}
 */
export const sendContractMethodWithAddress = async (
  web3,
  status,
  account,
  address,
  contractName,
  methodName,
  params,
  bHide,
  amount,
  gasLimit
) => {
  if (web3 && web3?.eth) {
    if (status === 'connected') {
      if (isAddress(account)) {
        if (isAddress(address)) {
          if (contractName) {
            if (methodName) {
              try {
                !bHide && showStandby();

                let contractData = await getContractsDetail(contractName);
                if (!contractData?.abi) {
                  // Попытка поправить багу с отсутствием ABI
                  contractData = await getContractsDetail(contractName, null, null, false, true);
                }
                if (contractData?.abi) {
                  return sendContractMethodWithAddressABI(
                    web3,
                    status,
                    account,
                    address,
                    contractData.abi,
                    methodName,
                    params,
                    bHide,
                    amount,
                    gasLimit
                  );
                }

                throw `'contract ${contractName} not found`;
              } finally {
                !bHide && hideStandby();
              }
            } else {
              throw 'Need the Method name of the called contract.';
            }
          } else {
            throw 'Need the Contract name of the called contract.';
          }
        } else {
          throw 'Need the address of the called contract.';
        }
      } else {
        throw 'Need connect wallet in MetaMask plugin first.';
      }
    } else {
      throw 'Need connect MetaMask plugin first.';
    }
  } else {
    throw 'Need install MetaMask plugin first.';
  }
};

/**
 * Отправка запроса с транзакцией по конкретному адресу без контракта
 * @param {Object|web3}           web3
 * @param {String}                status
 * @param {String}                account
 * @param {String}                address
 * @param {String|Number|BigInt}  amount
 * @param {Boolean}              [bHide]
 * @param {BigInt}               [gasLimit]
 * @return {Promise<unknown>}
 */
export const sendWithAddress = async (web3, status, account, address, amount, bHide, gasLimit) => {
  if (web3 && web3?.eth) {
    if (status === 'connected') {
      if (isAddress(account)) {
        if (isAddress(address)) {
          try {
            !bHide && showStandby();

            const gasPrice = await web3.eth.getGasPrice();
            const gas = await web3.eth.estimateGas({ from: account, value: amount });

            await web3.eth.sendTransaction({
              from: account,
              to: address,
              gasPrice: gasPrice,
              gas: gasLimit > 0 ? toBN(gasLimit) : toBN(gas) * 2n, // Для своей сети увеличиваем газ в 2 раза
              value: toBN(amount),
            });
          } finally {
            !bHide && hideStandby();
          }
        } else {
          throw 'Need the address of the called contract.';
        }
      } else {
        throw 'Need connect wallet in MetaMask plugin first.';
      }
    } else {
      throw 'Need connect MetaMask plugin first.';
    }
  } else {
    throw 'Need install MetaMask plugin first.';
  }
};

/**
 * Отправка запроса с транзакцией
 * @param {Object|web3}           web3
 * @param {String}                status
 * @param {String}                account
 * @param {String}                contractName
 * @param {String}                methodName
 * @param {Array?}               [params]
 * @param {Boolean}              [bHide]
 * @param {String|Number|BigInt} [amount]
 * @param {BigInt}               [gasLimit]
 * @return {Promise<unknown>}
 */
export const sendContractMethod = async (
  web3,
  status,
  account,
  contractName,
  methodName,
  params,
  bHide,
  amount,
  gasLimit
) => {
  if (web3 && web3?.eth) {
    if (status === 'connected') {
      if (isAddress(account)) {
        if (contractName) {
          if (methodName) {
            try {
              !bHide && showStandby();

              let contractData = await getContractsDetail(contractName);
              if (!contractData?.abi) {
                // Попытка поправить багу с отсутствием ABI
                contractData = await getContractsDetail(contractName, null, null, false, true);
              }
              if (isAddress(contractData?.address) && contractData?.abi) {
                return await sendContractMethodWithAddressABI(
                  web3,
                  status,
                  account,
                  contractData?.address,
                  contractData.abi,
                  methodName,
                  params,
                  bHide,
                  amount,
                  gasLimit
                );
              }

              throw `'contract ${contractName} not found`;
            } finally {
              !bHide && hideStandby();
            }
          } else {
            throw 'Need the Method name of the called contract.';
          }
        } else {
          throw 'Need the Contract name of the called contract.';
        }
      } else {
        throw 'Need connect wallet in MetaMask plugin first.';
      }
    } else {
      throw 'Need connect MetaMask plugin first.';
    }
  } else {
    throw 'Need install MetaMask plugin first.';
  }
};

/**
 * Отправка запроса с транзакцией
 * @param {Object|web3}           web3
 * @param {String}                status
 * @param {String}                account
 * @param {Object}                abi
 * @param {String}                bytecode
 * @param {Array?}               [params]
 * @param {Boolean}              [bHide]
 * @param {String|Number|BigInt} [amount]
 * @param {BigInt}               [gasLimit]
 * @return {Promise<unknown>}
 */
export const deploy = async (
  web3,
  status,
  account,
  abi,
  bytecode,
  params,
  bHide,
  amount,
  gasLimit
) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    if (web3 && web3?.eth) {
      if (status === 'connected') {
        if (isAddress(account)) {
          if (isABI(abi)) {
            if (bytecode) {
              try {
                !bHide && showStandby();

                try {
                  const contract = new web3.eth.Contract(abi);
                  const contractDeploy = contract.deploy({
                    data: bytecode,
                    arguments: params || [],
                  });

                  const gasPrice = await web3.eth.getGasPrice();
                  const gas = await contractDeploy.estimateGas({
                    from: account,
                  });

                  await contractDeploy
                    .send({
                      from: account,
                      gasPrice: gasPrice,
                      gas: gasLimit > 0 ? toBN(gasLimit) : toBN(gas) * 2n, // Для своей сети увеличиваем газ в 2 раза
                      value: toBN(amount),
                    })
                    .on('error', (err) => reject(err))
                    .on('receipt', (receipt) => resolve(receipt));
                } catch (err) {
                  console.error(err);
                  reject(err?.data?.message || err);
                }
              } finally {
                !bHide && hideStandby();
              }
            } else {
              reject(new Error('Need the BYTECODE of the called contract.'));
            }
          } else {
            reject(new Error('Need the ABI of the called contract.'));
          }
        } else {
          reject(new Error('Need connect wallet in MetaMask plugin first.'));
        }
      } else {
        reject(new Error('Need connect MetaMask plugin first.'));
      }
    } else {
      reject(new Error('Need install MetaMask plugin first.'));
    }
  });

/**
 * Отправка запроса с транзакцией
 * @param {Object|web3}           web3
 * @param {String}                account
 * @param {String}                status
 * @param {String}                contractName
 * @param {Array?}               [params]
 * @param {Boolean}              [bHide]
 * @param {String|Number|BigInt} [amount]
 * @return {Promise<unknown>}
 */
export const deployContract = async (
  web3,
  status,
  account,
  contractName,
  params,
  bHide,
  amount
) => {
  if (web3 && web3?.eth) {
    if (status === 'connected') {
      if (isAddress(account)) {
        if (contractName) {
          try {
            !bHide && showStandby();

            const contractData = await getContractsDetail(
              contractName,
              null,
              REACT_APP_CHAIN_ID,
              true,
              true
            );
            if (contractData?.abi && contractData?.bytecode) {
              return deploy(
                web3,
                status,
                account,
                contractData.abi,
                contractData.bytecode,
                params,
                bHide,
                amount
              );
            }

            throw 'wrong contractData';
          } finally {
            !bHide && hideStandby();
          }
        } else {
          throw 'Need the Contract name of the called contract.';
        }
      } else {
        throw 'Need connect wallet in MetaMask plugin first.';
      }
    } else {
      throw 'Need connect MetaMask plugin first.';
    }
  } else {
    throw 'Need install MetaMask plugin first.';
  }
};

//* ************************************************

/**
 * Асинхронное ожидание
 * @param {Number?} [t]
 * @return {Promise<void>}
 */
// eslint-disable-next-line no-promise-executor-return
export const sleep = async (t) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(), t || 0);
  });
