import React, { useState } from 'react';
import { Select, PostIcon, ArticleIcon, NewsIcon, ClipIcon, Button } from 'tt-ui-kit';
import styles from './NewPost.module.scss';
import { NewPostModal } from '../../components/Modals/NewPostModal';
// import { IconButton } from '@mui/material';

export const NewPost = ({ user, onClose }) => {
  const [createType, setCreateType] = useState('post');
  const [isModal, setModal] = useState(false);

  const onCloseModal = () => {
    setModal(false);
    if (onClose) {
      onClose();
    }
  };

  const createPostItems = [
    {
      value: 'post',
      name: (
        <div className={styles.selectItem}>
          <PostIcon style={{ width: 24, height: 24 }} />
          <span>Post</span>
        </div>
      ),
    },
    {
      value: 'article',
      name: (
        <div className={styles.selectItem}>
          <ArticleIcon style={{ width: 24, height: 24 }} />
          <span>Article</span>
        </div>
      ),
    },
    // {
    //   value: 'news',
    //   name: (
    //     <div className={styles.selectItem}>
    //       <NewsIcon style={{ width: 24, height: 24 }} />
    //       <span>News</span>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div className={styles.container} style={{ flexDirection: 'column' }}>
      <div className={styles.newPostBtn} onClick={() => setModal(true)}>{`Create ${
        createType === 'article' ? 'an article' : `a ${createType}`
      }`}</div>
      <div className={styles.inputOptions}>
        <Select
          type="clear"
          className={styles.select}
          value={createType}
          onChange={(e) => {
            setCreateType(e.target.value);
          }}
          renderValue={(val) => createPostItems?.find((item) => item.value === val)?.name || val}
          items={createPostItems}
        />

        {/* <IconButton className={styles.addFileIcon} onClick={() => setModal(true)}> */}
        {/*  <ClipIcon style={{ width: 24, height: 24 }} /> */}
        {/* </IconButton> */}
      </div>
      <NewPostModal visible={isModal} onClose={onCloseModal} createType={createType} user={user} />
    </div>
  );
};
