import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DataTable from 'components/DataTable';
import Typography from '@mui/material/Typography';
import { Hidden, IconButton, MenuItem, Select } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import EditStatusModal from './EditStatusModal/EditStatusModal';
import Filter from './Filter';
import styles from './CompaniesPage.module.scss';
import { GET_ADMIN_ALL_COMPANIES } from '../../../../api';
import { useLazyQuery } from '@apollo/client';

const TABS = [
  {
    label: 'Company representative',
    count: 0,
  },
  {
    label: 'Subscription',
    count: null,
  },
  {
    label: 'Discount',
    count: null,
  },
];

const CompaniesPage = () => {
  const pageSize = 9;
  const [selectedRole, setSelectedRole] = React.useState('Company representative');
  const [pageCount, setPageCount] = React.useState(5);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tabs, setTabs] = React.useState(TABS);
  const [loading, setLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [dateInputValue, setDateInputValue] = React.useState('');
  const [timeoutID, setTimeoutID] = React.useState(null);
  const [editStatusModal, setEditStatusModal] = React.useState(false);
  const [companyInfo, setCompanyInfo] = useState();
  const [companiesList, setCompaniesList] = React.useState([]);
  const [getAdminAllCompanies] = useLazyQuery(GET_ADMIN_ALL_COMPANIES, {
    fetchPolicy: 'network-only',
  });
  const [queryFilter, setQueryFilter] = React.useState({
    page: 1,
    first: 15,
    input: {
      ordersBy: [
        {
          column: 'created_at',
          direction: 'DESC',
        },
      ],
      filtersBy: [],
    },
  });

  function getCompanyName(params) {
    return `${params.row.company_name || ''}`;
  }

  function getId(params) {
    return `${params.row.id || ''}`;
  }

  const changeOrder = (value) => {
    const newQueryFilter = { ...queryFilter };
    if (value === 'all') {
      newQueryFilter.input.ordersBy = [];
      setQueryFilter(newQueryFilter);
    } else {
      if (newQueryFilter.input.ordersBy.length > 0) {
        newQueryFilter.input.ordersBy = queryFilter.input.ordersBy.map((item) => {
          if (
            item.column === 'updated_at' ||
            item.column === 'created_at' ||
            item.column === 'all'
          ) {
            return { ...item, column: value };
          }
          return item;
        });
      } else {
        newQueryFilter.input.ordersBy.push({
          column: value,
          direction: 'DESC',
        });
      }
      setQueryFilter(newQueryFilter);
    }
  };

  const changeStatus = (value) => {
    const newQueryFilter = { ...queryFilter };
    if (value === 'all') {
      newQueryFilter.input.filtersBy = [];
      setQueryFilter(newQueryFilter);
    } else {
      const isExistFilterParam = newQueryFilter.input.filtersBy.find(
        (item) => item.column === 'is_draft'
      );
      if (newQueryFilter.input.filtersBy.length > 0 && isExistFilterParam) {
        newQueryFilter.input.filtersBy = queryFilter.input.filtersBy.map((item) => {
          if (item.column === 'is_draft') {
            return value;
          }
          return item;
        });
      } else {
        newQueryFilter.input.filtersBy.push(value);
      }
      setQueryFilter(newQueryFilter);
    }
  };

  const changeInvestment = (value) => {
    const newQueryFilter = { ...queryFilter };
    if (value === 'all') {
      newQueryFilter.input.filtersBy = [];
      setQueryFilter(newQueryFilter);
    } else {
      const isExistFilterParam = newQueryFilter.input.filtersBy.find(
        (item) => item.column === 'is_open_for_investment'
      );
      if (newQueryFilter.input.filtersBy.length > 0 && isExistFilterParam) {
        newQueryFilter.input.filtersBy = queryFilter.input.filtersBy.map((item) => {
          if (item.column === 'is_open_for_investment') {
            return value;
          }
          return item;
        });
      } else {
        newQueryFilter.input.filtersBy.push(value);
      }
      setQueryFilter(newQueryFilter);
    }
  };

  const searchCompany = (value) => {
    setInputValue(value);
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    const timeout = setTimeout(() => {
      setQueryFilter((current) => ({
        ...current,
        input: { ...current.input, partial_name: value },
      }));
    }, 2000);
    setTimeoutID(timeout);
  };
  const searchByRegistrationDate = (value) => {
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    const timeout = setTimeout(() => {
      setQueryFilter((current) => ({
        ...current,
        input: { ...current.input, dateFilter: value !== 'Invalid date' ? value : null },
      }));
    }, 1000);
    setTimeoutID(timeout);
  };

  const changePage = (event, value) => {
    setCurrentPage(value);
    setQueryFilter((current) => ({ ...current, page: value }));
  };

  function renderOfi(props) {
    const { value } = props;
    return <div className={styles.ofiColumn}>{value ? 'Yes' : 'No'}</div>;
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const renderCreatedBy = (props) => {
    const { value } = props;
    return <div className={styles.createdByColumn}>{value ?? ''}</div>;
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const renderStatus = (props) => {
    const { value, row } = props;
    return (
      <div className={styles.nameColumnIcon}>
        {value ? 'Draft' : 'Active'}
        <IconButton
          onClick={() => {
            setEditStatusModal(true);
            setCompanyInfo(row);
          }}
          disableRipple
        >
          <EditIcon htmlColor="black" fontSize="small" />
        </IconButton>
      </div>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const renderLevel = (props) => (
    <div className={styles.nameColumnIcon}>
      -
      <IconButton disableRipple>
        <EditIcon htmlColor="black" fontSize="small" />
      </IconButton>
    </div>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const renderKyb = (props) => {
    const { value, id, row } = props;
    return (
      <div className={styles.nameColumn}>
        {value ? 'Verified' : value !== true ? 'Failed' : '-'}
      </div>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const renderId = (props) => {
    const { id } = props;
    return (
      <div className={styles.nameColumn}>
        <Tooltip
          slotProps={{
            arrow: {
              sx: {
                color: '#999999',
              },
            },
            tooltip: {
              sx: {
                fontFamily: 'Montserrat',
                fontSize: '12px',
                padding: '12px 20px',
                border: 'none',
                borderRadius: '12px',
                color: '#FFFFFF',
                backgroundColor: '#999999',
              },
            },
          }}
          title={id}
          placement="top"
          arrow
        >
          <Typography className={styles.idColumn}>{id}</Typography>
        </Tooltip>
      </div>
    );
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 88,
      valueGetter: getId,
      renderCell: renderId,
    },
    {
      field: 'company_name',
      headerName: 'Company name',
      width: 159,
      valueGetter: getCompanyName,
    },
    { field: 'email', headerName: 'Owner', width: 210 },
    {
      field: 'is_open_for_investment',
      headerName: 'OFI',
      width: 100,
      renderCell: renderOfi,
    },
    {
      field: 'created_at',
      headerName: 'Registration Date',
      width: 211,
    },
    {
      field: 'subscription',
      headerName: 'Subscription',
      width: 138,
    },
    {
      field: 'createdBy',
      headerName: 'Created by',
      width: 131,
      renderCell: renderCreatedBy,
    },
    {
      field: 'is_draft',
      headerName: 'Status',
      width: 158,
      renderCell: renderStatus,
    },
    {
      field: 'kyb_level',
      headerName: 'KYB level',
      width: 183,
      renderCell: renderLevel,
    },
    {
      field: 'kyb_completed',
      headerName: 'KYB',
      width: 111,
      renderCell: renderKyb,
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      const companies = await getAdminAllCompanies({
        variables: queryFilter,
      });
      if (
        companies.data?.getAdminCompaniesByWithPagination.data &&
        companies.data?.getAdminCompaniesByWithPagination.data.length
      ) {
        setCompaniesList(companies.data?.getAdminCompaniesByWithPagination?.data);
        setPageCount(companies.data?.getAdminCompaniesByWithPagination?.paginatorInfo?.lastPage);
        const updatedTabs = tabs.map((item) => {
          if (item.label === 'Company representative') {
            return {
              ...item,
              count: companies.data?.getAdminCompaniesByWithPagination?.paginatorInfo?.total,
            };
          }
          return item;
        });
        setTabs(updatedTabs);
      } else if (
        companies.data?.getAdminCompaniesByWithPagination.data &&
        companies.data?.getAdminCompaniesByWithPagination.data.length === 0
      ) {
        setCompaniesList([]);
        setPageCount(companies.data?.getAdminCompaniesByWithPagination?.paginatorInfo?.lastPage);
        const updatedTabs = tabs.map((item) => {
          if (item.label === 'Company representative') {
            return {
              ...item,
              count: companies.data?.getAdminCompaniesByWithPagination?.paginatorInfo?.total,
            };
          }
          return item;
        });
        setTabs(updatedTabs);
      }
      setLoading(false);
    })();
  }, [queryFilter]);

  return (
    <>
      <Hidden mdDown>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: '#143471' } }}
          centered
          value={selectedRole}
          className={styles.tabs}
        >
          {tabs.map((item) => (
            <Tab
              value={item.label}
              label={`${item.label} ${item.count ? `(${item.count})` : ''}`}
              className={[styles.tabItem, selectedRole === item.label ? styles.activeItem : null]}
              sx={{ margin: '0px 30px', padding: 0 }}
            />
          ))}
        </Tabs>
      </Hidden>
      <Hidden mdUp>
        <Select
          value={selectedRole}
          size="medium"
          className={styles.topSelect}
          sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
          renderValue={(selected) => {
            if (tabs.find((item) => item.label === selected)) {
              const { count } = tabs.find((item) => item.label === selected);
              return <Typography>{`${selected} (${count})`}</Typography>;
            }
            return <Typography>{selected}</Typography>;
          }}
        >
          {tabs.map((item) => (
            <MenuItem value={item.label} disabled={item.label !== 'Company representative'}>
              <Box
                className={styles.menuItem}
              >{`${item.label} ${item.count ? `(${item.count})` : ''}`}</Box>
            </MenuItem>
          ))}
        </Select>
      </Hidden>
      <Box className={styles.tableContent}>
        <Filter
          inputValue={inputValue}
          setDateInputValue={setDateInputValue}
          dateInputValue={dateInputValue}
          changeStatus={changeStatus}
          changeInvestment={changeInvestment}
          changeOrder={changeOrder}
          searchCompany={searchCompany}
          searchByRegistrationDate={searchByRegistrationDate}
        />
        <Box sx={{ marginTop: '20px' }}>
          <DataTable
            columns={columns}
            rows={companiesList}
            loading={loading}
            pageSize={pageSize}
            currentPage={currentPage}
            pageCount={pageCount}
            changePage={changePage}
          />
        </Box>
      </Box>
      {editStatusModal && (
        <EditStatusModal
          companyInfo={companyInfo}
          open={editStatusModal}
          setCompaniesList={setCompaniesList}
          handleClose={() => setEditStatusModal(false)}
        />
      )}
    </>
  );
};

export default CompaniesPage;
