import 'core-js/modules/es.array.includes';
import 'core-js/modules/es.array.iterator';
import 'core-js/modules/es.object.to-string';
import 'core-js/modules/es.set';
import 'core-js/modules/es.array.unscopables.flat';
import 'core-js/modules/es.array.unscopables.flat-map';
import 'core-js/modules/es.object.from-entries';
import 'core-js/modules/web.immediate';
import React from 'react';
import { MainContextProvider } from 'context';
import { createRoot } from 'react-dom';
import './index.scss';
import 'styles/globals.css';
import 'tt-ui-kit/dist/index.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from 'utils/api-client';
import { SignIn } from 'views/SignInPage/SignInPage';
import { HelmetProvider } from 'react-helmet-async';
import {
  Home,
  Join,
  Role,
  SignUp,
  Menu,
  SDG,
  Goal,
  Term,
  Reset,
  Confirm,
  FourOhFour,
  Presentations,
  Tutorial,
  Tutorials,
} from 'views';
import AdminRoutes from 'views/admin';
import DigitalSignUp from 'views/DigitalSignUp';
import ScrollToTop from 'hoc/ScrollToTop';
import reportWebVitals from './reportWebVitals';
import { AboutPage } from 'views/Pages/aboutPage/AboutPage';
import { WorkPage } from 'views/Pages/workPage/WorkPage';
import { DashboardRoutes } from './views/DashboardRoutes';
import DisconnectHandlerWrapper from './utils/disconnectHandler';
import GoogleMetrics from './utils/googleMetrics';
import RootLoaders from './utils/rootLoaders';
import { MetaMaskProvider } from 'metamask-react';
import PdfViewPage from './views/PDFs/PdfViewPage';
import { ConfigProvider } from 'tt-ui-lib/core';
import 'tt-ui-lib/core/style.css';

const endpointDA = process.env.REACT_APP_DIGITAL_ASSETS;

const ROUTES_INFO = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: 'join',
    element: <Join />,
  },
  {
    path: 'join/:role',
    element: <Role />,
  },
  {
    path: 'join/:role/sign_up',
    element: <SignUp />,
  },
  {
    path: 'join/digital/sign_up',
    element: <DigitalSignUp />,
  },
  {
    path: 'sign_in',
    element: <SignIn />,
  },
  {
    path: 'reset',
    element: <Reset />,
  },
  {
    path: 'menu',
    element: <Menu />,
  },
  {
    path: 'confirm',
    element: <Confirm />,
  },
  {
    path: 'sdgs',
    element: <SDG />,
  },
  {
    path: 'sdgs/:goal',
    element: <Goal />,
  },
  {
    path: '/:term',
    element: <Term />,
  },
  {
    path: '*',
    element: <FourOhFour />,
  },
  {
    path: 'about',
    element: <AboutPage />,
  },
  {
    path: 'work',
    element: <WorkPage />,
  },
  {
    path: '/presentations',
    element: <Presentations />,
  },
  {
    path: '/tutorials',
    element: <Tutorials />,
  },
  {
    path: '/tutorial/:id',
    element: <Tutorial />,
  },

  {
    path: '/dashboard/*',
    element: <DashboardRoutes />,
  },
  {
    path: '/certificate/:uid',
    element: <Navigate to={`${endpointDA}/certificate`} />,
  },

  {
    path: '/FCEchain',
    element: (
      <PdfViewPage fileUrl="https://prod-sso-public-tt.s3.eu-central-1.amazonaws.com/doc/FCEchain.pdf" />
    ),
  },
  {
    path: '/FCE2022',
    element: (
      <PdfViewPage fileUrl="https://prod-sso-public-tt.s3.eu-central-1.amazonaws.com/doc/FCE2022.pdf" />
    ),
  },
  // {
  //   path: '/draft/:id',
  //   element: <DraftPage />,
  // },

  // Admin
  {
    path: '/admin/*',
    element: <AdminRoutes />,
  },
];

const helmetContext = {};
const rootElement = document.getElementById('root');

createRoot(rootElement).render(
  // <React.StrictMode>
  <BrowserRouter>
    <HelmetProvider context={helmetContext}>
      <ApolloProvider client={client}>
        <MetaMaskProvider>
          <MainContextProvider>
            <ConfigProvider>
              <GoogleMetrics />
              <DisconnectHandlerWrapper />
              <RootLoaders />
              <ScrollToTop />
              <Routes>
                {ROUTES_INFO.map((routeInfo) => (
                  <Route path={routeInfo.path} element={routeInfo.element} key={routeInfo.path} />
                ))}
              </Routes>
            </ConfigProvider>
          </MainContextProvider>
        </MetaMaskProvider>
      </ApolloProvider>
    </HelmetProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
  rootElement
);

reportWebVitals();
