/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useReducer } from 'react';

import {
  SET_APP_COVER_LOADING,
  SET_APP_COVER_SECOND_LOADING,
  SET_APP_ERROR,
  SET_APP_LOADING,
} from '../types';

import AppContext from './appContext';
import appReducer from './appReducer';

const AppState = ({ children }) => {
  const initialState = {
    loading: null,
    coverLoading: false,
    coverSecondLoading: false,
    isError: false,
  };

  const [state, dispatch] = useReducer(appReducer, initialState);

  const changeAppLoading = (loading = null) => {
    dispatch({
      type: SET_APP_LOADING,
      payload: loading > 0.98 ? 1 : loading,
    });
  };

  const setIsError = (isError) => {
    dispatch({
      type: SET_APP_ERROR,
      payload: isError,
    });
  };

  const setIsCoverLoading = (isLoading) => {
    dispatch({
      type: SET_APP_COVER_LOADING,
      payload: isLoading,
    });
  };

  const setIsCoverSecondLoading = (isLoading) => {
    dispatch({
      type: SET_APP_COVER_SECOND_LOADING,
      payload: isLoading,
    });
  };

  return (
    <AppContext.Provider
      value={{
        loading: state.loading,
        changeAppLoading,
        coverLoading: state.coverLoading,
        setIsCoverLoading,
        coverSecondLoading: state.coverSecondLoading,
        setIsCoverSecondLoading,
        isError: state.isError,
        setIsError,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppState;
