import { useState } from 'react';
import { IconButton, InputBase, Paper } from '@mui/material';
import clsx from 'clsx';
import { Image } from 'tt-ui-kit';
import styles from './AddMembersMenu.module.scss';
import ListMember from './ListMember';

const TEST_DATA = [
  {
    id: 1,
    name: 'Devon Lane',
    avatar: '/images/test/user1.png',
    status: 'online',
  },
  {
    id: 2,
    name: 'Dianne Russell',
    avatar: '/images/test/user2.png',
    status: 'busy',
  },
  {
    id: 3,
    name: 'Darlene Robertson',
    avatar: '/images/test/user3.png',
    status: 'offline',
  },
];

const AddMembersMenu = ({ open, onClose }) => {
  const [members, setMembers] = useState(TEST_DATA);
  const [searchValue, setSearchValue] = useState('');

  return (
    <div className={clsx(styles.menuWrapper, open ? styles.visible : '')}>
      <div className={styles.menuHeader}>
        <div className={styles.title}>Add members</div>
        <IconButton onClick={() => onClose()}>
          <Image
            className={styles.close}
            src="/images/templates/close-icon-black.png"
            width={14}
            height={14}
          />
        </IconButton>
      </div>
      <Paper component="form" className={styles.searchInputContent}>
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <Image src="/images/templates/chatSearch.png" width={17} height={17} />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          value={searchValue}
          placeholder="Enter name..."
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </Paper>
      <div className={styles.membersList}>
        {members.map((member) => (
          <ListMember memberData={member} key={member.id} />
        ))}
      </div>
    </div>
  );
};

export default AddMembersMenu;
