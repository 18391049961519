const parseErrors = (response) => {
  const list = {};
  if (response?.errors?.graphQLErrors[0]?.extensions?.validation) {
    Object.keys(response.errors.graphQLErrors[0].extensions.validation).forEach((item, index) => {
      list[item.replace('input.', '')] = {
        message: response.errors.graphQLErrors[0].extensions.validation[item],
      };
    });
  }
  return list;
};

const deleteNestedKeys = (inputObject, keysToDelete) => {
  const obj = inputObject;

  if (inputObject && keysToDelete) {
    keysToDelete.forEach((keyToDelete) => {
      Object.keys(obj).forEach((prop) => {
        if (prop === keyToDelete) {
          delete obj[prop];
        } else if (typeof obj[prop] === 'object') {
          deleteNestedKeys(obj[prop], [keyToDelete]);
        }
      });
    });
  }

  return obj;
};

export { parseErrors, deleteNestedKeys };
