import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'utils/useWindowSize';
import styles from './PageContainerImgBg.module.scss';
import PageContainer from '..';
import Header from '../../Header';

const PageContainerImgBg = ({
  children,
  isLogoHidden,
  imageBg,
  imageBgMobile,
  isHeaderNav,
  isSignUpPage,
  isSignInPage,
  isSdgPage,
  isMenuPage,
  title,
  isGoalPage,
}) => {
  const [loadedImageSrc, setLoadedImageSrc] = useState();
  const windowSize = useWindowSize();

  useEffect(() => {
    function load(src) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', resolve);
        image.addEventListener('error', reject);
        image.src = src;
      });
    }

    if (imageBg) {
      load(imageBg).then(() => setLoadedImageSrc(imageBg));
    }
  }, []);

  return (
    <PageContainer title={title} isGoalPage={isGoalPage} isImgBg>
      <div
        id="imageBg"
        className={clsx(
          styles.imageBg,
          windowSize.width <= 480 && isGoalPage ? styles.goalPageBg : ''
        )}
        style={{
          background: isSignUpPage
            ? `linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${loadedImageSrc}) center/cover`
            : windowSize.width <= 480 && isGoalPage
              ? `linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${imageBgMobile}) center/cover`
              : `url(${imageBg}) center/cover`,
        }}
      >
        <Header
          isHeaderNav={isHeaderNav}
          isLogoHidden={isLogoHidden}
          isSignUpPage={isSignUpPage}
          isSignInPage={isSignInPage}
          isSdgPage={isSdgPage}
        />
        <section
          className={clsx(
            styles.formWrapper,
            isSignUpPage ? styles.signUpFormWrapper : '',
            isMenuPage ? styles.menuPageWrapper : '',
            isSdgPage ? styles.sdgWrapper : '',
            isGoalPage ? styles.goalWrapper : ''
          )}
        >
          {children}
        </section>
      </div>
    </PageContainer>
  );
};

export default PageContainerImgBg;
