import * as Yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);

export const IsDraftModalSchema = Yup.object().shape({
  location: Yup.object().nullable().required('Please fill out the rest fields. This is mandatory.'),
  user_experiences: Yup.object().shape({
    title: Yup.string().required('Please fill out the rest fields. This is mandatory.'),
    company_name: Yup.string().required('Please fill out the rest fields. This is mandatory.'),
    start_date: Yup.date()
      .nullable()
      .required('Please fill out the rest fields. This is mandatory.')
      .max(today, 'Start date cannot be greater than the current date'),
    // end_date: Yup.date().when('is_present', {
    //   is: true,
    //   then: Yup.date().nullable().default(null),
    //   otherwise: Yup.date()
    //     .nullable()
    //     .required('Please fill out the rest fields. This is mandatory.')
    //     .min(Yup.ref('start_date'), "End date can't be before Start date")
    //     .max(today, 'End date cannot be greater than the current date'),
    // }),
    is_present: Yup.boolean(),
  }),
});
