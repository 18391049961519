import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Filters from './Filters/Filters';
import styles from './CalculatorsDetail.module.scss';

import {
  GET_ALL_RESULTS,
  GET_INDUSTRIAL_GROUPS,
  GET_SUBINDUSTRIES,
  GET_COUNTRIES,
} from '../../../../api';
import { CALC_TYPES } from 'utils/calculatorUtils';

const headCells = [
  {
    id: 'companyName',
    numeric: false,
    label: 'Entity Name',
    align: 'left',
  },
  {
    id: 'industrialId',
    numeric: false,
    label: 'Industry',
    align: 'left',
  },
  {
    id: 'registrationCountryId',
    numeric: false,
    label: 'Region',
    align: 'left',
  },
  {
    id: 'employeesNumber',
    numeric: true,
    label: 'Company size',
    align: 'center',
  },
  {
    id: 'result',
    numeric: true,
    label: 'ESG Score',
    align: 'center',
  },
];

const tableCell = {
  'td,th': {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#212121',
    padding: '16px',
    borderBottom: '1px solid  #EFEFEF',
    marginBottom: '8px',
  },

  boxShadow: '0px',
};

const tableRow = {
  transition: 'all .3s ease',
  th: {
    padding: '16px 7px',
  },

  '&:hover': {
    backgroundColor: 'rgba(140, 157, 189, 0.12)',
    'td, th': {
      color: '#18397A',
    },
  },
};

const tableRowCurrent = {
  transition: 'all .3s ease',
  backgroundColor: 'rgba(0, 240, 15, 0.20)',

  th: {
    padding: '16px 7px',
  },

  '&:hover': {
    backgroundColor: 'rgba(0, 240, 15, 0.30)',
    'td, th': {
      color: '#18397A',
    },
  },
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const TableHeader = ({ order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sortDirection={orderBy === headCell.id ? order : false}
            key={headCell.id}
            align={headCell.align}
            sx={{ fontWeight: 500 }}
          >
            {headCell.numeric ? (
              <TableSortLabel
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                IconComponent=""
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              <TableSortLabel
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                IconComponent=""
              >
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const CalculatorDetailTable = ({ params }) => {
  const { result } = params;
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('result');

  const [countries, setCountries] = useState(null);
  const [industryGroups, setIndustryGroups] = useState(null);
  const [subIndustry, setSubIndustry] = useState(null);
  const [resultsList, setResultsList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const [filter, setFilter] = useState({});

  const { data: countriesData } = useQuery(GET_COUNTRIES);
  const { data: indGroupsData } = useQuery(GET_INDUSTRIAL_GROUPS);
  const { data: subIndData } = useQuery(GET_SUBINDUSTRIES);

  // TODO filter the results by type
  const [getAllResult, { data: resultAll }] = useLazyQuery(GET_ALL_RESULTS);

  const isEqualsType = (a, b) => !(!a && !b) && a.type === b.type;

  const isFiltered = (fltr, res) =>
    res.id === result.id ||
    ((!fltr.country || fltr.country === res.countryId) &&
      (!fltr.max || fltr.max >= res.employeesNumber) &&
      (!fltr.min || fltr.min <= res.employeesNumber) &&
      (!fltr.subIndustry ||
        (result.type === CALC_TYPES.GLOBAL && fltr.subIndustry === res.subIndustryId)) &&
      (!fltr.industry ||
        (result.type !== CALC_TYPES.GLOBAL && fltr.industry === res.industrialGroupId)));

  useEffect(() => {
    if (resultsList.length === 0) return;
    const newResult = resultsList.filter((res) => isFiltered(filter, res));
    setFilteredList(newResult);
  }, [resultsList, result, filter]);

  const formatList = (list) =>
    list.map((item) => ({
      name: item.name,
      value: item.id,
    }));

  useEffect(() => {
    if (countriesData) {
      setCountries(formatList(countriesData.showCountries));
    }
  }, [countriesData]);

  useEffect(() => {
    if (indGroupsData) {
      setIndustryGroups(formatList(indGroupsData.showIndustryGroups));
    }
  }, [indGroupsData]);

  useEffect(() => {
    if (subIndData) {
      setSubIndustry(formatList(subIndData.showSubIndustries));
    }
  }, [subIndData]);

  useEffect(() => {
    getAllResult();
  }, []);

  useEffect(() => {
    if (!result || !resultAll) return;
    if (resultAll && resultAll.resultAll) {
      const newResultList = resultAll.resultAll
        .filter((res) => isEqualsType(result, res))
        .map((res) => ({ ...res, current: res.id === result.id }));
      setResultsList(newResultList);
    }
  }, [resultAll, result]);

  const clearButtonClick = () => {
    setFilter({});
  };

  const applyButtonClick = (filterPart) => {
    const newFilter = { ...filter, ...filterPart };
    setFilter(newFilter);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <Box sx={{ width: '100%' }} elevation={0}>
        <div className={styles.filtersRow}>
          <Filters
            params={{
              countries,
              industryGroups,
              subIndustry,
              filter,
              calcType: result?.type,
              clearButtonClick,
              applyButtonClick,
            }}
          />
        </div>
        <Paper sx={{ width: '100%' }} elevation={0}>
          <TableContainer>
            <Table sx={tableCell} aria-labelledby="tableTitle">
              <TableHeader order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
              <TableBody>
                {filteredList.length > 0 &&
                  result &&
                  stableSort(filteredList, getComparator(order, orderBy)).map((row, index) => (
                    <TableRow
                      sx={row.current ? tableRowCurrent : tableRow}
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell width={285} component="th" scope="row" align="left">
                        {row.companyName}
                      </TableCell>
                      {result.type !== CALC_TYPES.GLOBAL && (
                        <TableCell width={251} component="th" align="left">
                          {industryGroups.find((g) => g.value === row.industrialGroupId)?.name ??
                            ''}
                        </TableCell>
                      )}
                      {result.type === CALC_TYPES.GLOBAL && (
                        <TableCell width={251} component="th" align="left">
                          {subIndustry.find((g) => g.value === row.subIndustryId)?.name ?? ''}
                        </TableCell>
                      )}
                      <TableCell component="th" align="left">
                        {countries.find((c) => c.value === row.countryId)?.name ?? ''}
                      </TableCell>
                      <TableCell component="th" align="center">
                        {row.employeesNumber}
                      </TableCell>
                      <TableCell component="th" align="center">
                        {row.result}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

export default CalculatorDetailTable;
