/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { DialogActions, List } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ListItemFolder from './ListItemFolder';
import { CreateChatCategory } from './CreateChatCategory';
import styles from './ChatCategories.module.scss';
import { getChats } from '../../../api/chat/endpoints/chat';
import { Button, Modal } from 'tt-ui-kit';
import { useSelectedContext } from 'context/contextSelector';

export const ChatCategoriesModal = ({
  activeFolderId,
  userId,
  open = false,
  handleClose,
  chats = [],
}) => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [allChats, setAllChats] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [getMore, setGetMore] = useState(false);
  const [lastList, setLastList] = useState(false);
  const [chatCategoryFormData, setChatCategoryFormData] = useState({
    name: '',
    color: '',
    chats: [],
  });
  const { handleCreateFolder, chatFolders, handleDeleteFolder } = useSelectedContext(
    (context) => context.messenger
  );

  const createChatFolder = () => {
    handleCreateFolder(chatCategoryFormData);
  };

  const getChatsList = () => {
    getChats({ first: 10, page }).then((res) => {
      if (res.data.chats.length === 0) {
        setGetMore(false);
        setLastList(true);
      } else if (getMore) {
        setGetMore(false);
        setAllChats((current) => [...current, ...res.data.chats]);
      } else setAllChats(res.data.chats);
      setOpenCreateModal(true);
      setPageCount(Math.ceil(res.data.count_pages / 10));
    });
  };

  useEffect(() => {
    if (getMore) getChatsList();
  }, [getMore]);

  const fetchMoreUsers = async () => {
    if (page < pageCount && !getMore) {
      const pageNumber = page + 1;
      setPage(pageNumber);
      setGetMore(true);
    }
  };

  const onScrollUsers = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const maxScroll = scrollHeight - scrollTop - 1;
    if (maxScroll <= clientHeight && !lastList) {
      fetchMoreUsers();
    }
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} title="Folders" onClick={(e) => e.stopPropagation()}>
        <>
          <div className={styles.foldersList}>
            <List sx={{ width: '100%' }}>
              {chatFolders &&
                chatFolders.map((folder) => (
                  <ListItemFolder
                    folder={folder}
                    key={folder.id}
                    handleDelete={() => handleDeleteFolder(folder.id)}
                  />
                ))}
            </List>
          </div>
          <DialogActions className={styles.dialogActions}>
            <Button
              type="default"
              disabled={chatFolders.length > 9}
              className={styles.dialogActionButton}
              onClick={() => getChatsList()}
            >
              <AddIcon className={styles.dialogActionButtonIcon} /> New folder
            </Button>
          </DialogActions>
        </>
      </Modal>

      <CreateChatCategory
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        searchValue={searchValue}
        changeSearchValue={(value) => setSearchValue(value)}
        chats={allChats}
        create={createChatFolder}
        formData={chatCategoryFormData}
        onScrollUsers={onScrollUsers}
        getMore={getMore}
        changeFormData={(name, value) =>
          setChatCategoryFormData((current) => ({ ...current, [name]: value }))
        }
        userId={userId}
        activeFolderId={activeFolderId}
      />
    </div>
  );
};
