import React, { useEffect, useState, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router';
import { CALCS_PER_PAGE, CALC_NAMES, CALC_PAGE_TYPES } from 'utils/calculatorUtils';
import CalcAccordion from '../../components/CalcAccordion/CalcAccordion';
import CalcIcon from '../../components/CalcIcon/CalcIcon';
import CalcFilter from '../../components/CalcFilter/CalcFilter';
import CalcAssessment from '../../components/CalcAssessment/CalcAssessment';
import CalcEmptyListBlock from '../../components/CalcEmptyListBlock/CalcEmptyListBlock';
import ReportResult from '../../components/ByType/CustomReport/ResultListElement/ReportResult';
import { GET_USER_RESULTS_BY_PAGE_TYPE } from 'api';
import { getResult } from 'api/calc/endpoints/calc';

const Results = ({ targetWidth, pageType }) => {
  const types = CALCS_PER_PAGE[pageType];
  const { pathname } = useLocation();
  const [searchedAssessments, setSearchedAssessments] = useState([]);
  const [assessments, setAssessments] = useState([]);

  const [getAssessments] = useLazyQuery(GET_USER_RESULTS_BY_PAGE_TYPE);

  const RenewReportResultList = () => {
    getResult({ pageType, f: true })
      .then((resp) => {
        const { data, success } = resp?.data ?? {};
        if (success) setAssessments(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const RenewCalcResultList = () => {
    getAssessments({ variables: { type: pageType } })
      .then((res) => {
        const { data } = res;
        const newAssessments = (data?.getOwnResultsByPageType ?? []).map((assessment) => {
          const resObj = {
            ...assessment,
            createdAt: new Date(assessment.createdAt),
            updatedAt: new Date(assessment.updatedAt),
            // reportDate: assessment.reportDate ? new Date(assessment.reportDate) : null,
            // reportDateEnd: assessment.reportDateEnd ? new Date(assessment.reportDateEnd) : null,
          };
          return resObj;
        });
        setAssessments(newAssessments);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (pageType !== CALC_PAGE_TYPES.BENCHMARK) RenewReportResultList();
    else RenewCalcResultList();
  }, [pathname]);

  return (
    <>
      <>
        {assessments.length > 0 ? (
          <>
            <CalcFilter setSearchedItems={setSearchedAssessments} items={assessments} />
            {types.map((type) => (
              <>
                {searchedAssessments.some((asses) => asses.type === type) && (
                  <div key={type}>
                    <CalcAccordion logo={<CalcIcon type={type} />} title={CALC_NAMES[type]}>
                      {searchedAssessments
                        .filter((assessment) => assessment.type === type)
                        .map((assessment) => (
                          <div key={assessment.id}>
                            {pageType === CALC_PAGE_TYPES.CUSTOM ? (
                              <ReportResult targetWidth={targetWidth} assessment={assessment} />
                            ) : (
                              <CalcAssessment targetWidth={targetWidth} assessment={assessment} />
                            )}
                          </div>
                        ))}
                    </CalcAccordion>
                  </div>
                )}
              </>
            ))}
          </>
        ) : (
          <CalcEmptyListBlock collectionName="results" />
        )}
      </>
    </>
  );
};

export default Results;
