import styles from './FormItemLeft.module.scss';

const FormItemLeft = ({ lines, className }) => (
  <div className={`${styles.formItemLeft} ${className && className}`}>
    {lines.map((line) => (
      <h2 className={styles.formTitle} key={line}>
        {line}
      </h2>
    ))}
  </div>
);

export default FormItemLeft;
