/* eslint-disable no-unused-vars */
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

const cleanHtml = (html) =>
  DOMPurify.sanitize(
    `${html}`
      .replaceAll('<style', '&lt;style')
      .replaceAll('<style>', '&lt;style&gt;')
      .replaceAll('</style>', '&lt;/style&gt;')
      .replaceAll('<form', '&lt;form')
      .replaceAll('<input', '&lt;input')
      .replaceAll('<button', '&lt;button')
      .replaceAll('<select', '&lt;select')
      .replaceAll('<script', '&lt;script')
      .replaceAll('<iframe', '&lt;iframe')
      .replaceAll('<object', '&lt;object')
      .replaceAll('<meta', '&lt;meta')
      .replaceAll('<param', '&lt;param')
      .replaceAll('<html', '&lt;html')
      .replaceAll('<body', '&lt;body')
      .replaceAll('<search', '&lt;search')
      .replaceAll('<noscript', '&lt;noscript')
      .replaceAll('<head', '&lt;head')
      .replaceAll('<base', '&lt;base')
      .replaceAll('<dialog', '&lt;dialog')
      .replaceAll('<footer', '&lt;footer')
      .replaceAll('<header', '&lt;header')
      .replaceAll('<track', '&lt;track')
      .replaceAll('<details', '&lt;details')
      .replaceAll('<source', '&lt;source')
  );

const Linkify = ({ children }) => {
  const isUrl = (word) => {
    const urlPattern =
      // eslint-disable-next-line
      /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?$/gm;
    return word.match(urlPattern);
  };
  const findLink = (string, token) => string.replace(new RegExp(token, 'g'), ` ${token}`);
  const addMarkup = (word) =>
    isUrl(word) ? `<a style="color: blue" target="_blank" href="${word}">${word}</a>` : word;
  const link = findLink(children, `http`);
  if (link.includes('http') && (link.indexOf('"') >= 0 || link.indexOf("'") >= 0)) {
    const newLink = `${link.substring(0, link.length - 2)} ${link.substring(
      link.length - 2,
      link.length - 1
    )}`;
    const words = newLink.split(' ');
    const formatedWords = words.map((w) => addMarkup(w));
    const html = formatedWords.join(' ');
    return <span dangerouslySetInnerHTML={{ __html: cleanHtml(html) }} />;
  }
  const words = link.split(' ');
  const formatedWords = words.map((w) => addMarkup(w));
  const html = formatedWords.join(' ');
  return <span dangerouslySetInnerHTML={{ __html: cleanHtml(html) }} />;
};

export const Message = (props) => {
  const {
    idUser,
    message,
    styles,
    setTextSelected,
    setDateMessages,
    onCopyMessage,
    selectedCompanyId,
  } = props;
  const [linkMessages, setLinkMessages] = useState([]);
  const wordList = message.message.split(' ');

  useEffect(() => {
    const urlRegex =
      '(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})';

    wordList.forEach((word) => {
      const regex = new RegExp(urlRegex);
      if (
        regex.test(word) &&
        (word.slice(0, 7) === 'http://' ||
          word.slice(0, 8) === 'https://' ||
          word.slice(0, 3) === 'www')
      ) {
        setLinkMessages((links) => [...links, word]);
      }
    });
  }, [message]);

  const getSelText = () => {
    setTextSelected(window.getSelection().toString());
    setDateMessages((oldMessages) => [...oldMessages, message.created_at]);
  };

  return (
    <div
      onClick={getSelText}
      onMouseDown={getSelText}
      onMouseUp={getSelText}
      onCopy={onCopyMessage}
      key={message.id}
    >
      <div
        className={
          message.sender_id === idUser || message.sender_id === selectedCompanyId
            ? styles.messageBubbleBlue
            : styles.messageBubble
        }
        id="text"
      >
        <span className={styles.messageText}>
          {wordList.map((word) => (
            <Linkify>{`${word} `}</Linkify>
          ))}
        </span>
        <div className={styles.time}>{format(parseISO(message.created_at), 'H:mm')}</div>
      </div>
    </div>
  );
};
