// import { EXTRA_ROLES_LIST } from 'constants/joinPage';
import PageContainer from 'components/layout/PageContainer';
import RolePage from 'core/views/JoinPage/RolePage';

const Role = ({ role }) => (
  <PageContainer>
    <RolePage role={role} />
  </PageContainer>
);

export default Role;
