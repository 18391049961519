import React, { useState } from 'react';
import PageContainer from 'components/layout/PageContainer';
import PresentationsPage from 'core/views/PresentationsPage';
import { TITLES } from 'constants/pagesTitles';

const Presentations = () => (
  <PageContainer title={TITLES.presentations} isImgBg>
    <PresentationsPage />
  </PageContainer>
);

export default Presentations;
