import React, { useState } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CONFIRM_FORGOT_USER_PASSWORD } from 'api';
import { getValidationSchema } from 'utils/validationConfirmResetPasswordSchemaGenerator';
import { parseErrors } from 'utils/validation';
import { isEmptyObj } from 'utils/isEmptyObj';
import { FORM_TITLE_LINES } from 'constants/confirmPage';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Button } from 'tt-ui-kit';
import FormBody from 'core/FormBody';
import FormItemLeft from 'core/FormItemLeft';
import FormItemRight from 'core/FormItemRight';
import styles from './ConfirmPage.module.scss';

const PASSWORD_CONFIRM_RESET_FORM_ID = 'passwordConfirmReset';

const ConfirmPage = () => {
  const router = useNavigate();
  const validationSchema = getValidationSchema();
  const formOptions = { resolver: yupResolver(validationSchema) };
  const formMethods = useForm(formOptions, { mode: 'all' });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = formMethods;

  const [updatePassword] = useMutation(CONFIRM_FORGOT_USER_PASSWORD, {
    onError: (graphQLErrors) => {
      // console.log(graphQLErrors);
    },
  });
  const [searchParams] = useSearchParams();
  const [customErrors, setCustomError] = useState(false);

  const onSubmit = async (data) => {
    setCustomError(false);
    const request = { ...data, ...{ token: searchParams.get('token') } };
    const response = await updatePassword({ variables: { input: request } });
    if (response?.errors) {
      const responseErrors = parseErrors(response);
      if (!isEmptyObj(responseErrors)) {
        return setCustomError(responseErrors);
      }
      setCustomError({
        email: {
          // message: response?.errors?.message,
          message: 'Incorect email or token',
        },
      });
    } else {
      router('/sign_in');
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  return (
    <>
      <FormBody>
        <FormItemLeft lines={FORM_TITLE_LINES} className={styles.formLeftContent} />
        <FormProvider {...formMethods}>
          <FormItemRight formName={PASSWORD_CONFIRM_RESET_FORM_ID}>
            <div className={styles.inputWrapper}>
              <Controller
                name="email"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <TextField
                    className={styles.textInput}
                    placeholder="Email"
                    type="email"
                    onChange={(e) => {
                      onChange(e);
                      setCustomError(false);
                    }}
                    error={!!errors.email || !!customErrors.email}
                    helperText={
                      errors?.email ? errors.email?.message : customErrors?.email?.message
                    }
                    variant="standard"
                    sx={{
                      '& .MuiInput-underline:before': {
                        borderBottomColor: 'hsla(0,0%,100%,.6)',
                      },
                      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                        borderBottomColor: 'hsla(0,0%,100%,.6)',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: 'hsla(0,0%,100%,.6)',
                      },
                      '& .MuiInput-underline.Mui-error input': {
                        color: 'red',
                      },
                    }}
                  />
                )}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Controller
                name="password"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <TextField
                    className={styles.textInput}
                    placeholder="New password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => {
                      onChange(e);
                      setCustomError(false);
                    }}
                    error={!!errors.password || !!customErrors.password}
                    helperText={
                      errors?.password ? errors.password?.message : customErrors?.password?.message
                    }
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      '& .MuiInput-underline:before': {
                        borderBottomColor: 'hsla(0,0%,100%,.6)',
                      },
                      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                        borderBottomColor: 'hsla(0,0%,100%,.6)',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: 'hsla(0,0%,100%,.6)',
                      },
                      '& .MuiInput-underline.Mui-error input': {
                        color: 'red',
                      },
                    }}
                  />
                )}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Controller
                name="password_confirmation"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <TextField
                    className={styles.textInput}
                    placeholder="Confirm new password"
                    type={showPasswordConfirm ? 'text' : 'password'}
                    onChange={onChange}
                    error={!!errors.password_confirmation}
                    helperText={errors.password_confirmation?.message}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                            onMouseDown={() => setShowPasswordConfirm(!showPasswordConfirm)}
                          >
                            {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      '& .MuiInput-underline:before': {
                        borderBottomColor: 'hsla(0,0%,100%,.6)',
                      },
                      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                        borderBottomColor: 'hsla(0,0%,100%,.6)',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: 'hsla(0,0%,100%,.6)',
                      },
                      '& .MuiInput-underline.Mui-error input': {
                        color: 'red',
                      },
                    }}
                  />
                )}
              />
            </div>
            <Button
              type="primary"
              form={PASSWORD_CONFIRM_RESET_FORM_ID}
              onClick={handleSubmit(onSubmit)}
              style={{ marginTop: '20px' }}
            >
              Save
            </Button>
          </FormItemRight>
        </FormProvider>
      </FormBody>
    </>
  );
};

export default ConfirmPage;
