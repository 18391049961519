const VOID_ELEMENTS = [
  'area',
  'base',
  'br',
  'col',
  'command',
  'embed',
  'hr',
  'img',
  'input',
  'keygen',
  'link',
  'meta',
  'param',
  'source',
  'track',
  'wbr',
];

// Block elements trigger newlines where they're inserted,
// and are always safe places for truncation.
const BLOCK_ELEMENTS = [
  'address',
  'article',
  'aside',
  'blockquote',
  'canvas',
  'dd',
  'div',
  'dl',
  'dt',
  'fieldset',
  'figcaption',
  'figure',
  'footer',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'header',
  'hgroup',
  'hr',
  'li',
  'main',
  'nav',
  'noscript',
  'ol',
  'output',
  'p',
  'pre',
  'section',
  'table',
  'tbody',
  'tfoot',
  'thead',
  'tr',
  'ul',
  'video',
];

// Elements that are unbreakable: they are either included verbatim, or omitted entirely.
const UNBREAKABLE_ELEMENTS = ['audio', 'math', 'svg', 'video'];

const NEWLINE_CHAR_CODE = 10; // '\n'
const EXCLAMATION_CHAR_CODE = 33; // '!'
const DOUBLE_QUOTE_CHAR_CODE = 34; // '"'
const AMPERSAND_CHAR_CODE = 38; // '&'
const SINGLE_QUOTE_CHAR_CODE = 39; // '\''
const FORWARD_SLASH_CHAR_CODE = 47; // '/'
const SEMICOLON_CHAR_CODE = 59; // ';'
const TAG_OPEN_CHAR_CODE = 60; // '<'
const EQUAL_SIGN_CHAR_CODE = 61; // '='
const TAG_CLOSE_CHAR_CODE = 62; // '>'

const CHAR_OF_INTEREST_REGEX = /[<&\n\ud800-\udbff]/;
const CHAR_OF_INTEREST_NO_NEWLINE_REGEX = /[<&\ud800-\udbff]/;

const SIMPLIFY_WHITESPACE_REGEX = /\s+/g;

export const isWhiteSpace = (charCode) =>
  charCode === 9 || charCode === 10 || charCode === 12 || charCode === 13 || charCode === 32;

export const shouldSimplifyWhiteSpace = (tagStack) => {
  for (let i = tagStack.length - 1; i >= 0; i--) {
    const tagName = tagStack[i];
    if (tagName === 'li' || tagName === 'td') {
      return false;
    }
    if (tagName === 'ol' || tagName === 'table' || tagName === 'ul') {
      return true;
    }
  }
  return false;
};

export const simplifyWhiteSpace = (string) => string.trim().replace(SIMPLIFY_WHITESPACE_REGEX, ' ');

export const takeCharAt = (string, index) => {
  const charCode = string.charCodeAt(index);
  // eslint-disable-next-line no-bitwise
  if ((charCode & 0xfc00) === 0xd800) {
    const nextCharCode = string.charCodeAt(index + 1);
    // eslint-disable-next-line no-bitwise
    if ((nextCharCode & 0xfc00) === 0xdc00) {
      return String.fromCharCode(charCode, nextCharCode);
    }
  }
  return String.fromCharCode(charCode);
};

export const isCharacterReferenceCharacter = (charCode) =>
  (charCode >= 48 && charCode <= 57) ||
  (charCode >= 65 && charCode <= 90) ||
  (charCode >= 97 && charCode <= 122);

export const takeHtmlCharAt = (string, index) => {
  let i = index;
  let char = takeCharAt(string, i);
  if (char === '&') {
    while (true) {
      i += 1;
      const nextCharCode = string.charCodeAt(i);
      if (isCharacterReferenceCharacter(nextCharCode)) {
        char += String.fromCharCode(nextCharCode);
      } else if (nextCharCode === SEMICOLON_CHAR_CODE) {
        char += String.fromCharCode(nextCharCode);
        break;
      } else {
        break;
      }
    }
  }
  return char;
};

export const indexOfWhiteSpace = (string, fromIndex) => {
  const { length } = string;
  for (let i = fromIndex; i < length; i++) {
    if (isWhiteSpace(string.charCodeAt(i))) {
      return i;
    }
  }
  return length;
};

export const isLineBreak = (string, index) => {
  const firstCharCode = string.charCodeAt(index);
  if (firstCharCode === NEWLINE_CHAR_CODE) {
    return true;
  }
  if (firstCharCode === TAG_OPEN_CHAR_CODE) {
    const newlineElements = `(${BLOCK_ELEMENTS.join('|')}|br)`;
    const newlineRegExp = new RegExp(`^<${newlineElements}[\t\n\f\r ]*/?>`, 'i');
    return newlineRegExp.test(string.slice(index));
  }
  return false;
};

export const clipHtml = (str, maxLength, options) => {
  const {
    imageWeight = 2,
    indicator = '\u2026',
    maxLines = Infinity,
    stripTags = false,
    onMaxCount = undefined,
  } = options;

  let numChars = indicator.length;
  let numLines = 1;
  let string = str;

  const shouldStrip =
    typeof stripTags === 'boolean' ? () => stripTags : (tagName) => stripTags.includes(tagName);

  const tagStack = [];
  const popTagStack = (result) => {
    let res = result;
    let tagName;
    while (tagName !== undefined) {
      if (!shouldStrip(tagName)) {
        res += `</${tagName}>`;
      }
      tagName = tagStack.pop();
    }
    return res;
  };

  let i = 0;
  let unbreakableElementIndex = -1;
  const { length } = string;
  for (; i < length; i++) {
    const rest = i ? string.slice(i) : string;
    const willSimplifyWhiteSpace = shouldSimplifyWhiteSpace(tagStack);
    const regex =
      unbreakableElementIndex > -1 || willSimplifyWhiteSpace
        ? CHAR_OF_INTEREST_NO_NEWLINE_REGEX
        : CHAR_OF_INTEREST_REGEX;
    const nextIndex = rest.search(regex);
    let nextBlockSize = nextIndex > -1 ? nextIndex : rest.length;

    if (unbreakableElementIndex === -1) {
      if (willSimplifyWhiteSpace) {
        let simplifiedBlock = simplifyWhiteSpace(
          nextBlockSize === rest.length ? rest : rest.slice(0, nextIndex)
        );

        if (shouldStrip(tagStack[tagStack.length - 1] !== null)) {
          const insertSpaceBefore = i > 0 && !isWhiteSpace(string.charCodeAt(i - 1));
          const insertSpaceAfter = !isWhiteSpace(string.charCodeAt(i + nextBlockSize));
          if (simplifiedBlock.length > 0) {
            simplifiedBlock =
              (insertSpaceBefore ? ' ' : '') + simplifiedBlock + (insertSpaceAfter ? ' ' : '');
          } else if (insertSpaceBefore && insertSpaceAfter) {
            simplifiedBlock = ' ';
          }

          string = string.slice(0, i) + simplifiedBlock + string.slice(i + nextBlockSize);
          nextBlockSize = simplifiedBlock.length;
        }

        numChars += simplifiedBlock.length;
        if (numChars > maxLength) {
          break;
        }
      } else {
        numChars += nextBlockSize;
        if (numChars > maxLength) {
          i = Math.max(i + nextBlockSize - numChars + maxLength, 0);
          break;
        }
      }
    }

    i += nextBlockSize;

    if (nextIndex === -1) {
      break;
    }

    const charCode = string.charCodeAt(i);
    if (charCode === TAG_OPEN_CHAR_CODE) {
      const nextCharCode = string.charCodeAt(i + 1);
      const isSpecialTag = nextCharCode === EXCLAMATION_CHAR_CODE;
      if (isSpecialTag && string.substr(i + 2, 2) === '--') {
        const commentEndIndex = string.indexOf('-->', i + 4) + 3;
        i = commentEndIndex - 1;
      } else if (isSpecialTag && string.substr(i + 2, 7) === '[CDATA[') {
        const cdataEndIndex = string.indexOf(']]>', i + 9) + 3;
        i = cdataEndIndex - 1;
      } else {
        const isEndTag = nextCharCode === FORWARD_SLASH_CHAR_CODE;
        if (numChars === maxLength && !isEndTag) {
          numChars++;
          break;
        }

        let attributeQuoteCharCode = 0;
        let endIndex = i;
        let isAttributeValue = false;
        while (true) {
          endIndex++;
          if (endIndex >= length) {
            throw new Error(`Invalid HTML: ${string}`);
          }

          const chrCode = string.charCodeAt(endIndex);
          if (isAttributeValue) {
            if (attributeQuoteCharCode) {
              if (chrCode === attributeQuoteCharCode) {
                isAttributeValue = false;
              }
            } else if (isWhiteSpace(chrCode) || chrCode === TAG_CLOSE_CHAR_CODE) {
              isAttributeValue = false;
            }
          } else if (chrCode === EQUAL_SIGN_CHAR_CODE) {
            isAttributeValue = true;
          } else if (chrCode === DOUBLE_QUOTE_CHAR_CODE || chrCode === SINGLE_QUOTE_CHAR_CODE) {
            attributeQuoteCharCode = chrCode;
          } else if (chrCode === TAG_CLOSE_CHAR_CODE) {
            break;
          }
        }

        if (isEndTag) {
          const tagName = string
            .slice(i + 2, endIndex)
            .trim()
            .toLowerCase();
          if (tagName === tagStack[tagStack.length - 1]) {
            if (tagName === UNBREAKABLE_ELEMENTS[unbreakableElementIndex]) {
              unbreakableElementIndex = -1;
            }
            tagStack.pop();
          }

          const elementOuterHtmlLength = endIndex + 1 - i;
          if (!shouldStrip(tagName)) {
            numChars += elementOuterHtmlLength;
            if (numChars > maxLength) {
              break;
            }
          }
        } else {
          const match = string.slice(i + 1, endIndex).match(/^[^\t\n\f\r />]*/);
          const tagName = match[0].toLowerCase();
          const tagAttributes = string.slice(i + 1 + tagName.length, endIndex).toLowerCase();
          const isVoidElement = VOID_ELEMENTS.includes(tagName);
          const isBlockElement = BLOCK_ELEMENTS.includes(tagName);

          const elementOuterHtmlLength = endIndex + 1 - i;
          if (numLines < maxLines && isBlockElement) {
            numLines++;
            numChars += elementOuterHtmlLength;
          }

          if (numChars > maxLength) {
            break;
          }

          if (!isVoidElement) {
            tagStack.push(tagName);
            if (UNBREAKABLE_ELEMENTS.includes(tagName)) {
              unbreakableElementIndex = tagStack.length - 1;
            }
          }

          const weight = tagName === 'img' ? imageWeight : 0;
          if (weight) {
            numChars += weight;
            if (numChars > maxLength) {
              break;
            }
          }

          if (!shouldStrip(tagName)) {
            numChars += elementOuterHtmlLength;
            if (numChars > maxLength) {
              break;
            }
          }
        }

        i = endIndex;
      }
    } else if (charCode === AMPERSAND_CHAR_CODE) {
      const entity = takeHtmlCharAt(string, i);
      const entityLength = entity.length;
      numChars += entityLength;
      if (numChars > maxLength) {
        break;
      }

      i += entityLength - 1;
    } else if (isWhiteSpace(charCode)) {
      if (willSimplifyWhiteSpace) {
        const wsEndIndex = indexOfWhiteSpace(string, i + 1);
        const block = string.slice(i, wsEndIndex);
        const simplifiedBlock = simplifyWhiteSpace(block);
        string = string.slice(0, i) + simplifiedBlock + string.slice(wsEndIndex);
        numChars += simplifiedBlock.length;
        if (numChars > maxLength) {
          i = Math.max(i - numChars + maxLength, 0);
          break;
        }

        i += simplifiedBlock.length - 1;
      } else if (isLineBreak(string, i)) {
        numLines++;
        numChars++;
        if (numLines === maxLines || numChars > maxLength) {
          break;
        }
      } else {
        numChars++;
        if (numChars > maxLength) {
          break;
        }
      }
    }
  }

  let result = string.slice(0, i);
  if (numChars >= maxLength && indicator.length > 0) {
    result += indicator;
  }

  if (numChars > maxLength) {
    onMaxCount();
  }

  return popTagStack(result);
};

export const clipPlainText = (string, maxLength, options) => {
  const { indicator = '\u2026', maxLines = Infinity } = options;

  let numChars = indicator.length;
  let numLines = 1;

  let i = 0;
  const { length } = string;
  for (; i < length; i++) {
    numChars++;
    if (numChars > maxLength) {
      break;
    }

    const charCode = string.charCodeAt(i);
    if (charCode === NEWLINE_CHAR_CODE) {
      numLines++;
      if (numLines > maxLines) {
        break;
      }
      // eslint-disable-next-line no-bitwise
    } else if ((charCode & 0xfc00) === 0xd800) {
      // high Unicode surrogate should never be separated from its matching low surrogate
      const nextCharCode = string.charCodeAt(i + 1);
      // eslint-disable-next-line no-bitwise
      if ((nextCharCode & 0xfc00) === 0xdc00) {
        i++;
      }
    }
  }

  if (numChars > maxLength) {
    let nextChar = takeCharAt(string, i);
    if (indicator) {
      const peekIndex = i + nextChar.length;
      if (peekIndex === string.length) {
        return string;
      }
      if (string.charCodeAt(peekIndex) === NEWLINE_CHAR_CODE) {
        return string.slice(0, i + nextChar.length);
      }
    }

    if (!options.breakWords) {
      // try to clip at word boundaries, if desired
      for (let j = i - indicator.length; j >= 0; j--) {
        const charCode = string.charCodeAt(j);
        if (charCode === NEWLINE_CHAR_CODE) {
          i = j;
          nextChar = '\n';
          break;
        } else if (isWhiteSpace(charCode)) {
          i = j + (indicator ? 1 : 0);
          break;
        }
      }
    }

    return string.slice(0, i) + (nextChar === '\n' ? '' : indicator);
  }
  if (numLines > maxLines) {
    return string.slice(0, i);
  }

  return string;
};

/**
 * Clips a string to a maximum length. If the string exceeds the length, it is truncated and an
 * indicator (an ellipsis, by default) is appended.
 *
 * In detail, the clipping rules are as follows:
 * - The resulting clipped string may never contain more than maxLength characters. Examples:
 *   - clip("foo", 3) => "foo"
 *   - clip("foo", 2) => "f…"
 * - The indicator is inserted if and only if the string is clipped at any place other than a
 *   newline. Examples:
 *   - clip("foo bar", 5) => "foo …"
 *   - clip("foo\nbar", 5) => "foo"
 * - If the html option is true and valid HTML is inserted, the clipped output *must* also be valid
 *   HTML. If the input is not valid HTML, the result is undefined (not to be confused with JS'
 *   "undefined" type; some errors might be detected and result in an exception, but this is not
 *   guaranteed).
 *
 * @param string The string to clip.
 * @param maxLength The maximum length of the clipped string in number of characters.
 * @param options Optional options object.
 *
 * @return The clipped string.
 */
export const clip = (string, maxLength, options = {}) => {
  try {
    if (!string) {
      return '';
    }

    if (!maxLength) {
      return string;
    }

    const s = string.toString();

    return options.html ? clipHtml(s, maxLength, options) : clipPlainText(s, maxLength, options);
  } catch (e) {
    console.error(e);

    return string;
  }
};
