import React, { useEffect, useState } from 'react';
import styles from '../MyCommunityPage.module.scss';
import { Button } from 'tt-ui-kit';
import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress } from '@mui/material';
import Filter from './Filter';
import { GridMode } from './GridMode';
import { useWindowSize } from 'utils/useWindowSize';
import { ListMode } from './ListMode';
import { useNavigate } from 'react-router';
import Pagination from '@mui/material/Pagination/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { InviteModal } from '../../../../modules/tt-modals';
import { GET_ROLE, GET_INVITATIONS_WITH_PAGINATION, SEND_INVITE } from '../../../../api';
import { useLazyQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';

const Invitations = ({ activeListPage, changePage }) => {
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [inputValue, setInputValue] = React.useState('');
  const [orderByValue, setOrderByValue] = React.useState('DESC');
  const [datePickerValue, setDatePickerValue] = React.useState('');
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [paginatorInfo, setPaginatorInfo] = useState({});
  const [invitations, setInvitations] = useState([]);
  const [timeoutID, setTimeoutID] = React.useState(null);
  const [activeMode, setActiveMode] = useState(
    localStorage.getItem('mode') ? localStorage.getItem('mode') : 'list'
  );
  const [getInvitations] = useLazyQuery(GET_INVITATIONS_WITH_PAGINATION);
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  const [queryFilter, setQueryFilter] = React.useState({
    page: 1,
    first: 6,
    input: {
      ordersBy: {
        column: 'created_at',
        direction: 'DESC',
      },
    },
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getInvitations({
        variables: queryFilter,
        fetchPolicy: 'network-only',
      });
      if (
        res &&
        res.data &&
        res.data.invitationsWithPagination &&
        res.data.invitationsWithPagination.data
      ) {
        setInvitations(res.data.invitationsWithPagination.data);
        setPaginatorInfo(res.data.invitationsWithPagination.paginatorInfo);
      }

      if (localStorage.getItem('mode')) {
        setActiveMode(localStorage.getItem('mode'));
      } else {
        setActiveMode('list');
      }
      setLoading(false);
    })();
  }, [queryFilter]);

  const openInvitationPage = (id) => {
    navigate(`/dashboard/invitation/${id}`);
  };
  const searchByRegistrationDate = (value) => {
    setDatePickerValue(value);
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    const timeout = setTimeout(() => {
      setQueryFilter((current) => ({
        ...current,
        input: { ...current.input, dateFilter: value !== 'Invalid date' ? value : null },
      }));
    }, 1000);
    setTimeoutID(timeout);
  };

  useEffect(() => {
    if (activeListPage) {
      setActivePage(activeListPage);
      setQueryFilter((current) => ({ ...current, page: activeListPage }));
    }
  }, [activeListPage]);

  const changeOrderBy = (value) => {
    setOrderByValue(value);
    setQueryFilter((current) => ({
      ...current,
      input: {
        ...current.input,
        ordersBy: {
          ...current.input.ordersBy,
          direction: value,
        },
      },
    }));
  };
  const changeStatusFilter = (value) => {
    setQueryFilter((current) => ({
      ...current,
      input: {
        ...current.input,
        status: value,
      },
    }));
  };

  const searchByEmail = (value) => {
    setInputValue(value);
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    const timeout = setTimeout(() => {
      setQueryFilter((current) => ({
        ...current,
        input: { ...current.input, partialName: value },
      }));
    }, 2000);
    setTimeoutID(timeout);
  };

  return (
    <Box className={styles.invitationsBlock}>
      <Box className={styles.headerBlock}>
        <Box className={styles.title}>New Connections</Box>
        <Button
          type="link"
          className={styles.createInviteBtn}
          onClick={() => setOpenInviteModal(true)}
        >
          <AddIcon />
          {windowSize.width > 767 && <>Create An Invitation</>}
        </Button>
      </Box>
      <Filter
        statusFilterValue={queryFilter.input.status}
        changeStatusFilter={changeStatusFilter}
        orderByValue={orderByValue}
        datePickerValue={datePickerValue}
        setDatePickerValue={setDatePickerValue}
        changeActiveMode={(mode) => setActiveMode(mode)}
        activeMode={activeMode}
        inputValue={inputValue}
        searchByEmail={searchByEmail}
        changeOrderBy={changeOrderBy}
        searchByRegistrationDate={searchByRegistrationDate}
      />
      {loading && (
        <Box className={styles.loadingBlock}>
          <CircularProgress
            sx={{
              width: '40px !important',
              height: '40px !important',
            }}
          />
        </Box>
      )}
      {!invitations.length && !loading && (
        <Box className={styles.loadingBlock}>
          <Typography className={styles.title}>No results found</Typography>
        </Box>
      )}
      {activeMode === 'grid' && !loading && invitations.length > 0 && (
        <GridMode openInvitationPage={openInvitationPage} invitations={invitations} />
      )}
      {activeMode === 'list' && !loading && invitations.length > 0 && (
        <ListMode openInvitationPage={openInvitationPage} invitations={invitations} />
      )}
      {paginatorInfo.lastPage > 1 && !loading && (
        <div className={styles.paginationBlock}>
          <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={paginatorInfo.currentPage}
            count={paginatorInfo.lastPage}
            siblingCount={
              windowSize.width > 1024
                ? 2
                : windowSize.width < 1024 && windowSize.width > 620
                  ? 1
                  : 0
            }
            boundaryCount={0}
            className={styles.pagination}
            renderItem={(props) => (
              <PaginationItem
                className={[
                  styles.paginationItem,
                  activePage === props.page ? styles.activePaginationItem : '',
                ]}
                {...props}
                disableRipple
              />
            )}
            onChange={(event, value) => {
              changePage(event, value);
            }}
          />
        </div>
      )}
      <InviteModal
        visible={openInviteModal}
        title="Create an invitation"
        // onClose={() => setOpenInviteModal(false)}
        onClose={() => setOpenInviteModal(false)}
        roleQuery={GET_ROLE}
        sendInviteQuery={SEND_INVITE}
        setInvitations={setInvitations}
      />
    </Box>
  );
};

export default Invitations;
