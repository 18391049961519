import React, { useState } from 'react';
import Box from '@mui/material/Box';
import DataTable from 'components/DataTable';
import { IconButton } from '@mui/material';
import Filter from './Filter';
import { Button, ConfirmModal, openNotification } from 'tt-ui-kit';
import styles from '../AllItemsPage.module.scss';
// import { changeVisibleQuestion, deleteQuestion } from '../../../../../api/admin/endpoints/chatBot';

export const Table = ({
  loading,
  tableData,
  pages,
  queryFilter,
  setQueryFilter,
  filter,
  setCurrentPage,
  selectedBlock,
  allBlocks,
  pageSize,
  currentPage,
  reloadTableMethod,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [timeoutID, setTimeoutID] = useState(null);
  const [openDeleteBlock, setOpenDeleteBlock] = useState(false);
  const [idDeleteBlock, setIdDeleteBlock] = useState('');
  const [loadingDeleteBlock, setLoadingDeleteBlock] = useState(false);

  const changeFilter = (value) => {
    setQueryFilter((current) => ({
      ...current,
      is_active: value.type === 'active' ? 1 : value.type === 'hidden' ? 0 : '',
    }));
  };

  const changeOrder = (value) => {
    if (value === 'all') {
      setQueryFilter((current) => ({ ...current, order_by: undefined }));
    } else {
      setQueryFilter((current) => ({ ...current, order_by: value }));
    }
  };

  const search = (value) => {
    setInputValue(value);
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    const timeout = setTimeout(() => {
      setQueryFilter((current) => ({ ...current, f: value }));
    }, 2000);
    setTimeoutID(timeout);
  };

  const changePage = (event, value) => {
    setCurrentPage(value);
    setQueryFilter((current) => ({ ...current, page: value }));
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 180,
    },
    {
      field: 'name',
      headerName: 'Item name',
      width: 180,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 180,
    },
    {
      field: 'store',
      headerName: 'Store',
      width: 180,
    },
    {
      field: 'price',
      headerName: 'Price',
      width: 390,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      // TODO
      renderCell: (value) => (
        // <Tooltip placement="left-start" title={value.value}>
        <span>{value.value}</span>
        // </Tooltip>
      ),
    },
    {
      field: 'options',
      headerName: 'Options',
      width: 290,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      // TODO
      renderCell: (value) => (
        // <Tooltip placement="left-start" title={value.value}>
        <span>{value.value}</span>
        // </Tooltip>
      ),
    },
    {
      field: 'date',
      headerName: 'Creation date',
      width: 180,
    },
    {
      field: 'moder',
      headerName: 'Moderation',
      width: 180,
    },
    {
      field: 'updated',
      headerName: 'Updated',
      width: 180,
    },
    // {
    //   field: 'sections',
    //   headerName: 'Answers',
    //   width: 740,
    //   filterable: false,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   renderCell: (value) => (
    //     <div className={styles.answerItems}>
    //       {value?.value?.map((item) => (
    //         <div key={item.id}>
    //           <p>{item.text}</p>

    //           <div className={styles.answerFiles}>
    //             {item.files && item.files?.length > 0
    //               ? item.files.map((file) => <a href={file.file}>{file.original_name}</a>)
    //               : '-'}
    //           </div>

    //           <div className={styles.answerLinks}>
    //             {item.links && item.links?.length > 0
    //               ? item.links.map((link) => <a>{link.url}</a>)
    //               : '-'}
    //           </div>
    //         </div>
    //       ))}
    //     </div>
    //   ),
    // },
    // {
    //   field: 'actions',
    //   headerName: ' ',
    //   width: 88,
    //   renderCell: (value) => (
    //     <div className={styles.answersColumn}>
    //       <IconButton
    //         onClick={() => {
    //           setIdDeleteBlock(value.id);
    //           setOpenDeleteBlock(true);
    //         }}
    //       >
    //         <img src="/images/admin/tableDeleteIcon.svg" alt="" />
    //       </IconButton>
    //     </div>
    //   ),
    // },
  ];

  return (
    <Box className={styles.tableContent}>
      {selectedBlock && selectedBlock !== 'all' ? (
        <Box className={styles.tableTitle}>
          <p>{allBlocks.find((item) => item.id === selectedBlock)?.name}</p>
          <div>
            {/* <Button
              type="default"
              className={styles.defaultButton}
              onClick={() => {
                setOpenEditBlock(true);
                setIdEditBlock(selectedBlock);
                setNameEditBlock(allBlocks.find((item) => item.id === selectedBlock)?.name);
              }}
            >
              <div className={styles.buttonWithImage}>
                <img src="/images/admin/edit.svg" alt="" /> <span>Edit Name</span>
              </div>
            </Button> */}
          </div>
        </Box>
      ) : null}

      {selectedBlock ? (
        <Filter
          filter={filter}
          inputValue={inputValue}
          changeFilter={changeFilter}
          orderBy={queryFilter.order_by}
          changeOrder={changeOrder}
          search={search}
          // setIsAddBlock={setIsAddBlock}
        />
      ) : null}

      <Box sx={{ marginTop: '20px' }}>
        <DataTable
          columns={columns}
          rows={tableData}
          loading={loading}
          pageSize={pageSize}
          currentPage={currentPage}
          pageCount={pages || 1}
          changePage={changePage}
          isCentered={false}
        />
      </Box>

      <ConfirmModal
        open={openDeleteBlock}
        setOpen={setOpenDeleteBlock}
        loading={loadingDeleteBlock}
        title="Delete question"
        subTitle="Do you really want to delete this question?"
        onSubmit={() => {
          // deleteQuestionHandler();
        }}
      />
    </Box>
  );
};
