import { FacebookIcon, InstagramIcon, LinkedinIcon, TwitterIcon } from 'tt-ui-kit';

export const GENERAL_MENU = [
  {
    label: 'Legal',
    url: '#',
    desc: '',
    subList: [
      {
        label: 'Privacy Policy',
        url: '/privacy-policy',
        desc: '',
      },
      {
        label: 'Honor Code',
        url: '/honor',
        desc: '',
      },
      {
        label: 'Terms of Service',
        url: '/terms',
        desc: '',
      },
    ],
  },
  // {
  //   label: 'API',
  //   url: '/qa/API',
  //   desc: '',
  //   subList: null,
  // },
  {
    label: 'Sign in',
    url: '/sign_in',
    desc: '',
    subList: null,
  },
  {
    label: 'About',
    url: '/about',
    desc: 'TransparenTerra is a digital platform designed to restore trust. It brings together qualified experts, business owners, professional ESG investors, and consumers to build a resilient economy and sustainable future.',
    subList: null,
    // toBeSoon: true,
  },
  {
    label: 'How does it work?',
    url: '/work',
    desc: 'The idea of using innovative technologies to make the commercial world fairer and more trustworthy has driven developers to invent and create an advanced digital platform for consulting, investing, communication, training, promotion, environmentally-friendly trading, and shopping.',
    subList: null,
  },
  {
    label: 'ESG Score',
    url: '#',
    desc: 'ESG stands for Environmental, Social, and Governance, which are three central factors in measuring sustainability. An ESG score is a way to understand and assess how companies are implementing sustainability principles from an environmental, economic, and social point of view.',
    subList: [
      {
        label: 'CO2 calculator',
        url: '#',
        desc: "Almost all actions of a person, organization, or enterprise (transport and delivery, electricity consumption, garbage, etc.) generate carbon dioxide and exacerbate the global climate crisis. On TransparenTerra, you can calculate the amount of your or your company's greenhouse gas emissions using a CO2 calculator and get recommendations on ways to reduce them.",
        toBeSoon: true,
      },
      // {
      //   label: 'CO2 calculator (individual)',
      //   url: '#',
      //   desc: 'Every action generates a footprint. Every footprint has a cost. Our activity costs the planet. This calculator helps you calculate your carbon footprint. The South Pole converts the results of all household activities into the equivalent of CO2 emissions. Once you assess the calculations, you will also have the option to offset it instantly.',
      //   toBeSoon: true,
      // },
      {
        label: 'ESG Excellence',
        url: '#',
        desc: "ESG Corporate Governance from the Board of Directors' view, Governance Lens watching over Corporate Behavior of the CEO, C-Suite, and employees at large includes measuring the Business ethics, anti-competitive practices, corruption, tax and providing accounting transparency for stakeholders.",
        toBeSoon: true,
      },
      {
        label: 'ESG Benchmark',
        url: '#',
        desc: "ESG Benchmark is a digital tool to automatically self-assess your company. Once your score is calculated, you can analyze your company's competitive sides and potential growth points in detail. You can share the assessments publicly and submit them to the experts for validation and deeper analysis.",
        toBeSoon: true,
      },
    ],
  },
  {
    label: 'SDGs',
    url: '/sdgs',
    desc: '',
    subList: null,
  },
  // {
  //   label: 'Calculators',
  //   url: '/qa/Calculators',
  //   desc: 'Calculators on TransparenTerra are tools that companies and users can use to calculate their environmental footprint. After the calculations, TransparenTerra experts can provide advice on how a company or household can improve its sustainability or reduce its carbon footprint by purchasing offset certificates.',
  //   subList: [
  //     {
  //       label: 'Water',
  //       url: '/qa/Calculators,What%20is%20a%20Water%20calculator',
  //       desc: 'Freshwater is required for various purposes: cleaning, cooking, cooling, and some industrial processes. Accountability of freshwater consumption is crucial for production and personal management. The TransparenTerra Water Calculator will help you measure the amount of water you use and advise you on how to reduce it.',
  //     },
  //     {
  //       label: 'Plastic',
  //       url: '/qa/Calculators,What%20is%20a%20Plastic%20calculator',
  //       desc: 'One major global environmental concern is plastic pollution. Disposable cups, coffee capsules, plastic staples, food labels, and grocery bags lead to global plastic pollution and damage the environment. TransparenTerra’s plastic calculator will help you calculate how much plastic you use and offer advice on how to reduce it.',
  //     },
  //     {
  //       label: 'Electric',
  //       url: '/qa/Calculators,What%20is%20an%20Electricity%20calculator',
  //       desc: 'The Electric Calculator helps you understand precisely how much electricity your company or business is consuming and enables you to know how to reduce your energy consumption and make your company more sustainable.',
  //     },
  //     {
  //       label: 'Product CO2 footprint',
  //       url: '/qa/Calculators,What%20is%20a%20CO2%20footprint%20calculator',
  //       desc: "A life cycle product's carbon footprint measures the total greenhouse gas emissions generated by a product, from extraction raw materials, manufacturing, shipping, and delivery, to end-of-life.",
  //     },
  //   ],
  // },
  // {
  //   label: 'Tokenization',
  //   url: '#',
  //   desc: 'Tokenization is the binding of assets (such as cash, stocks, products, etc.) to a particular type of token that can be securely managed through the blockchain.',
  //   subList: null,
  //   toBeSoon: true,
  // },
  // {
  //   label: 'Experts',
  //   url: '/join/expert',
  //   desc: 'Experts are independent members of our community who provide advice, ESG assessment, and consulting to other community members.',
  //   subList: null,
  // },
  // {
  //   label: 'Investors',
  //   url: '/join/investor',
  //   desc: 'ESG investors are professional and socially responsible investors. ESG Investors on TransparenTerra have access to verified and accurate data about projects and businesses that need support or are seeking investments.',
  //   subList: null,
  // },
  // {
  //   label: 'NFA/NFT',
  //   url: '/qa/NFA-NFT',
  //   desc: 'NFA (Non-Fungible Assets) are essential for real ownership of digital works. An NFA is a unique digital proof of ownership.<br>An NFT (Non-Fungible Token) links real or digital assets to unique digital tokens, each of which cannot be exchanged or replaced with another similar token. A non-fungible token certifies the uniqueness of a digital object.',
  //   subList: null,
  // },
  {
    label: 'Marketplace',
    url: '#',
    desc: 'The TransparenTerra Marketplace is a platform where sustainable projects can provide their services and sell goods.',
    subList: null,
    toBeSoon: true,
  },
  // {
  //   label: 'Offset Certificates',
  //   url: '#',
  //   desc: 'An Offset Certificate enables a company to offset its environmental impacts or be rewarded for zero-carbon activities.',
  //   subList: null,
  // },
  // {
  //   label: 'Solutions',
  //   url: '/qa/Solutions',
  //   desc: 'On TransparenTerra you can find solutions for different areas including: food, logistics, agriculture, energy, environment, MedTech, manufacture, cosmetics, pharma, luxury, etc.',
  //   subList: [
  //     {
  //       label: 'TT Chat',
  //       url: '/qa/Solutions,What%20is%20TT%20Chat',
  //       desc: 'TT Chat is a convenient functional messenger designed primarily for business communications, consultations, and educational platforms. In TT Chat, you can communicate and conduct online classes, webinars, and conferences, share files, a screen or whiteboard, discuss assignments, leave comments, take notes, and use a built-in online task manager and calendar.',
  //     },
  //     {
  //       label: 'Digital signature',
  //       url: '/qa/Solutions,What%20is%20a%20Digital%20signature',
  //       desc: 'A digital signature confirms the authorship of an electronic document. The e-signature links the author and the document itself using cryptographic methods and cannot be tampered with through simple copying.',
  //     },
  //     {
  //       label: 'Traceability',
  //       url: '/qa/Solutions,What%20is%20trustworthy%20traceability',
  //       desc: 'Blockchain-based trustworthy traceability provides trusted and permanently updated information, available to different parts of the supply chain and end consumers. Such a step increases the traceability and accountability of a supply chain, strengthens consumer loyalty, improves brand protection, and drastically reduces the need for working capital and environmental impact.',
  //     },
  //     {
  //       label: 'Circular Economy',
  //       url: '#',
  //       desc: 'Traceability and the trusted data recorded in the blockchain contribute to sustainable resource management, waste reduction, and recycling. By providing a single source of trust, blockchain reduces transaction costs, improves productivity and communication in the supply chain, and reduces carbon footprints. Blockchain architecture adds value to the circular economy by providing evidence of the sources of goods and services.',
  //     },
  //     {
  //       label: 'Notarization',
  //       url: '#',
  //       desc: 'Notarization guarantees the parties to a transaction the originality and trustworthiness of the document and makes any fraud impossible. The blockchain architecture does not allow unauthorized editing or deletion of the records.',
  //     },
  //   ],
  // },
  {
    label: 'Tutorials',
    url: '/tutorials',
    isTarget: false,
    desc: '',
    subList: null,
  },
  {
    label: 'Presentations',
    url: '/presentations',
    isTarget: false,
    desc: '',
    subList: null,
  },
  // {
  //   label: 'Press',
  //   url: '#',
  //   desc: '',
  //   subList: null,
  // },
  // {
  //   label: 'Media',
  //   url: '#',
  //   desc: '',
  //   subList: null,
  // },
  // {
  //   label: 'Map',
  //   url: '#',
  //   desc: '',
  //   subList: null,
  // },
  {
    label: 'Q&A',
    url: process.env.REACT_APP_QA,
    toBeSoon: true,
    desc: '',
    subList: null,
  },
];

export const FOOTER_NAV_ITEMS = [
  {
    label: 'Q&A',
    url: '#',
    isTarget: true,
  },
  {
    label: 'Contact Us',
    url: '#',
  },
  {
    label: 'Honor Guideline',
    url: '#',
  },
  {
    label: 'Privacy Policy',
    url: '#',
  },
  {
    label: 'Terms of Service',
    url: '#',
  },
];

export const FOOTER_BUTTONS = [
  {
    label: 'Calculators',
    url: '#',
  },
  {
    label: 'Solutions',
    url: '#',
  },
  {
    label: ' Оffset projects',
    url: '#',
  },
  {
    label: 'API',
    url: '#',
  },
];

export const SOCIAL_NETWORKS = [
  {
    href: 'https://instagram.com/transparenterra?igshid=12rx0wq2blntj',
    title: 'Instagram',
    iconSrc: '/images/templates/menu-instagram.svg',
    Icon: (props) => <InstagramIcon {...props} />,
    alt: 'instagram logo',
  },
  {
    href: 'https://www.facebook.com/TransparenTerra',
    title: 'Facebook',
    iconSrc: '/images/templates/menu-facebook.svg',
    Icon: (props) => <FacebookIcon {...props} />,
    alt: 'facebook logo',
  },
  {
    href: 'https://twitter.com/transparenterra',
    title: 'Twitter',
    iconSrc: '/images/templates/menu-twitter.svg',
    Icon: (props) => <TwitterIcon {...props} />,
    alt: 'twitter logo',
  },
  {
    href: 'https://www.linkedin.com/company/76845412/',
    title: 'LinkedIn',
    iconSrc: '/images/templates/menu-linkedin.svg',
    Icon: (props) => <LinkedinIcon {...props} />,
    alt: 'linkedin logo',
  },
];
