import { axiosClient, axiosSSOConfigPrivate } from '../../api';
import {
  API_CALC_GET_QUESTIONS,
  API_CUSTOM_REPORT_DATA_GET_URL,
  API_CUSTOM_REPORT_DATA_SET_URL,
  API_CUSTOM_RESULT_DATA_GET_URL,
  API_DRAFTS_DELETE_URL,
  API_DRAFTS_GET_URL,
  API_DRAFTS_UPDATE_URL,
  API_RESULT_GET_URL,
  API_DRAFT_BY_ID_GET_URL,
} from '../urls';

export const getQuestions = async (params) =>
  axiosClient.post(API_CALC_GET_QUESTIONS, axiosSSOConfigPrivate({}, { params: params }));

// export const createDraft = async (params) => axiosClient.post(API_DRAFTS_NEW_URL, params);
const transformDraft = (draft) =>
  Object.keys(draft).reduce(
    (res, key) => ({
      ...res,
      [key]:
        (key === 'periodReportDate' && draft[key]) ||
        key === 'periodReportDateEnd' ||
        key === 'createdAt' ||
        key === 'updatedAt'
          ? new Date(draft[key])
          : draft[key],
    }),
    {}
  );

const transformSingle = (resp) => {
  const { data, success } = resp;
  if (!success) {
    return resp;
  }
  return { data: transformDraft(data), success };
};

const transformArray = (resp) => {
  const { data, success } = resp;
  if (!success) {
    return resp;
  }
  return {
    data: [...data.map((draft) => ({ ...transformDraft(draft) }))],
    success,
  };
};

export const deleteDraft = async (params) =>
  axiosClient.post(API_DRAFTS_DELETE_URL, params, axiosSSOConfigPrivate());

export const updateDraft = async (params) =>
  axiosClient.post(
    API_DRAFTS_UPDATE_URL,
    params,
    axiosSSOConfigPrivate(
      {},
      { shouldTransformResponse: transformSingle, shouldSnakeToCamel: true }
    )
  );

export const getReportDraftById = async (id) =>
  axiosClient.get(
    `${API_DRAFT_BY_ID_GET_URL}/${id}`,
    axiosSSOConfigPrivate(
      {},
      { shouldTransformResponse: transformSingle, shouldSnakeToCamel: true }
    )
  );

export const getReportDraftWithFilter = async (params) =>
  axiosClient.get(
    API_DRAFTS_GET_URL,
    axiosSSOConfigPrivate(
      {},
      { params: params, shouldTransformResponse: transformArray, shouldSnakeToCamel: true }
    )
  );

export const getResult = async (params) =>
  axiosClient.get(
    API_RESULT_GET_URL,
    axiosSSOConfigPrivate(
      {},
      { params: params, shouldTransformResponse: transformArray, shouldSnakeToCamel: true }
    )
  );

// export const deleteResult = async (params) =>
//   axiosClient.get(API_RESULT_DELETE_URL, axiosSSOConfigPrivate({}, { params: params }));

export const getCustomDraftData = async (params) =>
  axiosClient.get(
    API_CUSTOM_REPORT_DATA_GET_URL,
    axiosSSOConfigPrivate({}, { params: params, shouldSnakeToCamel: true })
  );

export const setCustomDraftData = async (params) =>
  axiosClient.post(
    API_CUSTOM_REPORT_DATA_SET_URL,
    params,
    axiosSSOConfigPrivate({}, { shouldSnakeToCamel: true })
  );

export const getCustomResultData = async (params) =>
  axiosClient.get(
    API_CUSTOM_RESULT_DATA_GET_URL,
    axiosSSOConfigPrivate({}, { params: params, shouldSnakeToCamel: true })
  );
