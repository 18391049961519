import React from 'react';
import UserState from 'context/User/UserState';
import MessengerState from 'context/Messenger/MessengerState';
import { AppState } from 'context';

const MainContextProvider = ({ children }) => (
  <AppState>
    <UserState>
      <MessengerState>{children}</MessengerState>
    </UserState>
  </AppState>
);

export default MainContextProvider;
