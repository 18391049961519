import clsx from 'clsx';
import { useEffect, useState } from 'react';
import Input from 'components/Input';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import FilterDropdown from './FilterDropdown';
import styles from './Filters.module.scss';
import { Button, TextInput, Select } from 'tt-ui-kit';
import { CALC_TYPES } from 'utils/calculatorUtils';

const CustomizedAccordion = styled(Accordion)(() => ({
  '&.MuiAccordion-root': {
    // borderRadius: '16px',
    width: '100%',
    // boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
    // padding: '10px 24px',
  },
  boxShadow: 'none',
}));

const Filters = ({ params }) => {
  const {
    countries,
    industryGroups,
    subIndustry,
    filter,
    clearButtonClick,
    applyButtonClick,
    calcType,
  } = params;
  const [newFilter, setNewFilter] = useState({ ...filter });

  useEffect(() => {
    setNewFilter({ ...filter });
  }, [filter]);

  const onValueChange = (evnt, type) => {
    const val = evnt.target.value;
    setNewFilter({ ...newFilter, [type]: val });
  };

  const onApplyClick = () => {
    applyButtonClick(newFilter);
  };

  const renderSelect = (value, list) => {
    const item = list.find((el) => el.value === value);
    return item ? item.name : '';
  };

  return (
    <div style={{ width: '100%' }}>
      <CustomizedAccordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ padding: '0px', margin: '0px' }}
        >
          <div className={styles.summary}>
            <img src="/images/templates/Filters.png" alt="Filters" />
            <h1 className={styles.title}>Filters</h1>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px', margin: '0px' }}>
          <div className={styles.accordionDetails}>
            <div className={styles.filterBlock}>
              <div className={styles.filterInput}>
                {/* <FilterDropdown
                  placeholder="Industrial group"
                  data={industryGroups}
                  type="industry"
                  value={newFilter.industry ?? ''}
                  setValue={onValueChange}
                /> */}
                {calcType === CALC_TYPES.GLOBAL ? (
                  <Select
                    label="Sub Industry"
                    name="subIndustry"
                    renderValue={(value) => renderSelect(value, subIndustry)}
                    onChange={(event) => onValueChange(event, 'subIndustry')}
                    value={newFilter.subIndustry ?? ''}
                    items={subIndustry}
                  />
                ) : (
                  <Select
                    label="Industrial group"
                    name="industry"
                    renderValue={(value) => renderSelect(value, industryGroups)}
                    onChange={(event) => onValueChange(event, 'industry')}
                    value={newFilter.industry ?? ''}
                    items={industryGroups}
                  />
                )}
              </div>
              <div className={styles.filterInput}>
                <Select
                  label="Сountries"
                  name="country"
                  renderValue={(value) => renderSelect(value, countries)}
                  onChange={(event) => onValueChange(event, 'country')}
                  value={newFilter.country ?? ''}
                  items={countries}
                />
                {/* <FilterDropdown
                  placeholder="Сountries"
                  data={countries}
                  type="company"
                  value={newFilter.company ?? ''}
                  setValue={onValueChange}
                /> */}
              </div>
              <div className={styles.filterInput}>
                <Box className={styles.BlockNumber}>
                  Employees
                  <div>
                    <TextInput
                      placeholder="from"
                      required={false}
                      onChange={(event) => onValueChange(event, 'min')}
                      value={newFilter.min ?? ''}
                      inputProps={{ maxLength: 10, type: 'number' }}
                    />
                    <TextInput
                      placeholder="to"
                      required={false}
                      onChange={(event) => onValueChange(event, 'max')}
                      value={newFilter.min ?? ''}
                      inputProps={{ maxLength: 10, type: 'number' }}
                    />
                  </div>
                </Box>
              </div>
              <div className={clsx(styles.filterInput, styles.btnApplyRow)}>
                <Button onClick={clearButtonClick}>Clear Filter</Button>
                <Button onClick={onApplyClick}>Apply Filter</Button>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </CustomizedAccordion>
    </div>
  );
};

export default Filters;
