import React from 'react';
import { Emoji } from '../../EmojiWrapper';
import styles from './EmojiPostPicker.module.scss';

const EmojiDisplay = ({ emojiList, allowedReactions }) => (
  <div className={styles.emojiDisplay}>
    {emojiList
      .filter((v, i, self) => i === self.indexOf(v))
      .map((item, index) => {
        const emoji = allowedReactions.find((emojiItem) => emojiItem.id === item)?.icon;

        return (
          !!emoji && (
            <Emoji
              symbol={emoji}
              key={`em-${index + 1}`}
              style={{ zIndex: 2 - index >= 0 ? 2 - index : 0 }}
            />
          )
        );
      })}
  </div>
);
export default EmojiDisplay;
