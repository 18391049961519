/* eslint-disable jsx-a11y/label-has-associated-control */
import { GET_AGREEMENT } from 'api';
import { useLazyQuery } from '@apollo/client';
import SignUpField from '../SignUpField';
import styles from './PrivacyPolicyField.module.scss';

const PrivacyPolicyField = ({
  setIsPolicyModalOpen,
  setIsHonorModalOpen,
  setIsPolicyAgree,
  setIsHonorAgree,
  isPolicyAgree,
  isHonorAgree,
  setPolicyText,
  setHonorText,
  description,
  errorMessage,
  isSubmitStepClicked,
  setIsPolicyLoading,
}) => {
  const [getAgreement, { agreementPrivacyAndHonor }] = useLazyQuery(GET_AGREEMENT, {});

  const onChange = async (isAgree, setIsAgree, setModalOpen) => {
    if (isAgree) {
      setIsAgree(false);
    } else {
      setIsPolicyLoading(true);
      const res = await getAgreement();
      if (
        res &&
        res.data &&
        res.data.agreementPrivacyAndHonor &&
        res.data.agreementPrivacyAndHonor.length
      ) {
        const policy = res.data.agreementPrivacyAndHonor.find(
          (agreement) => agreement.field === 'Privacy Policy'
        );
        setPolicyText(policy.body);

        const honor = res.data.agreementPrivacyAndHonor.find(
          (agreement) => agreement.field === 'Honor Code'
        );
        setHonorText(honor.body);
      }
      setIsPolicyLoading(false);
      setModalOpen(true);
    }
  };

  return (
    <SignUpField>
      <div
        className={`${styles.fieldWrapper} ${
          isSubmitStepClicked && (!isPolicyAgree || !isHonorAgree) && styles.errorFieldWrapper
        }`}
      >
        <div className={styles.secondWrapper}>
          <div className={styles.checkboxesWrapper}>
            <div className={styles.checkboxWrapper}>
              <input
                className={styles.checkbox}
                id="policy-checkbox"
                type="checkbox"
                checked={isPolicyAgree}
                onChange={async () => {
                  await onChange(isPolicyAgree, setIsPolicyAgree, setIsPolicyModalOpen);
                }}
              />
              <label htmlFor="policy-checkbox">Privacy Policy</label>
            </div>

            <div className={styles.checkboxWrapper}>
              <input
                className={styles.checkbox}
                id="honor-checkbox"
                type="checkbox"
                checked={isHonorAgree}
                onChange={() => onChange(isHonorAgree, setIsHonorAgree, setIsHonorModalOpen)}
              />
              <label htmlFor="honor-checkbox">Honor Code</label>
            </div>
          </div>
          <div className={styles.descriptionWrapper}>
            {description &&
              (!isSubmitStepClicked || (isSubmitStepClicked && isPolicyAgree && isHonorAgree)) && (
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: `${description}` }}
                />
              )}
            {isSubmitStepClicked && (!isPolicyAgree || !isHonorAgree) && (
              <div className={styles.error}>{errorMessage}</div>
            )}
          </div>
        </div>
      </div>
    </SignUpField>
  );
};

export default PrivacyPolicyField;
