/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelectedContext } from 'context/contextSelector';
import { Link } from 'react-router-dom';
import { AppAvatar } from '../../AppAvatarDashboard';
import styles from './AvaOptionModal.module.scss';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LOGOUT_USER } from '../../../../api';
import { logoutUserHandler } from '../../../../utils/appUtils';
import { SR_URL } from 'constants/calculatorsPage';

export const AvaOptionModal = (props) => {
  const { visible, onClose, isIndividual, selectPassKYC } = props;
  const { user } = useSelectedContext((context) => context.user);
  const { disconnectSocket } = useSelectedContext((context) => context.messenger);
  const endpointMarket = process.env.REACT_APP_MARKET || '';
  const endpointDigitalAssets = process.env.REACT_APP_DIGITAL_ASSETS || '';

  const [logoutUser] = useMutation(LOGOUT_USER);

  const redirectMarket = (url) => `${endpointMarket}${url}`;
  const redirectDigitalAssets = (url) => `${endpointDigitalAssets}${url}`;

  const otherProps = [
    { href: SR_URL, name: 'Score & Reporting' },
    { href: redirectMarket('/dashboard/marketplace'), name: 'Marketplace', parent: true },
    {
      href: redirectMarket('/dashboard/companiesList'),
      name: 'Company Profile',
      isNotIndividual: true,
    },
    { href: redirectDigitalAssets('/dashboard/digital_assets'), name: 'Digital Assets' },
    { href: '/dashboard/events', name: 'Events' },
    { href: '/dashboard/financials', name: 'Financials' },
    { href: '/dashboard/content', name: 'Content' },
    { href: '/dashboard/pricing', name: 'Pricing' },
  ];

  const nestedMenu = [
    { href: '/dashboard/feed', name: 'Products & Services', nested: true },
    { href: '/dashboard/feed', name: 'Hire experts', nested: true },
    { href: '/dashboard/feed', name: 'Carbon offset projects', nested: true },
    { href: '/dashboard/feed', name: 'Exchange', nested: true },
  ];

  const ProfileProps = [
    { href: `/dashboard/profile/${user?.id}`, name: 'Profile' },
    { href: '/dashboard/feed', name: 'Settings & secure' },
    { href: '/dashboard/feed', name: 'Help' },
    // { href: '/dashboard/feed', name: 'Business account' },
  ];

  const logOut = async () => {
    logoutUserHandler(logoutUser, disconnectSocket);
  };

  const Close = () => {
    onClose();
  };

  const handleClick = (event, name) => {
    Close();
  };

  if (visible === false) return null;

  if (visible === true)
    return (
      <div className={styles.modal}>
        <div className={styles.modal_dialog} id={1}>
          <div className={styles.modal_dialog_block} onClick={(event) => event.stopPropagation()}>
            <div className={styles.user}>
              <div className={styles.userInfo}>
                <div className={styles.name}>
                  {user.first_name ? `${user.first_name} ${user.last_name}` : ''}
                </div>
                <div className={styles.invite}>
                  {user.invited_by_name ? `invited by ${user.invited_by_name}` : ''}
                </div>
                <div className={styles.ceoRow}>
                  <img src="/images/navbar/seoMark.svg" alt="Ceo" />
                  <div className={styles.ceo}>{user.ceo_of ? user.ceo_of : ''}</div>
                </div>
              </div>
              <div className={styles.avatarRow}>
                <AppAvatar
                  nav={0}
                  userName={`${user.first_name} ${user.last_name}`}
                  role={user.role_name}
                  src={user.avatar ? user.avatar : '/images/navbar/Avatar.png'}
                />
              </div>
            </div>
            <div className={styles.linksRow}>
              <div className={styles.ProfileLinks}>
                {ProfileProps.map((link) => (
                  <div>
                    {link.name !== 'Business account' ? (
                      <div className={styles.linksRow} key={link.name}>
                        <Link
                          color="inherit"
                          underline="none"
                          to={link.href}
                          className={styles.links}
                          onClick={handleClick}
                        >
                          {link.name}
                        </Link>
                      </div>
                    ) : (
                      <div className={styles.LinkRowBusiness}>
                        <Link
                          color="inherit"
                          underline="none"
                          to={link.href}
                          className={styles.links}
                          onClick={handleClick}
                        >
                          {link.name}
                        </Link>
                        <img
                          className={styles.businessImg}
                          src="/images/navbar/Business.png"
                          alt="business"
                        />
                      </div>
                    )}
                  </div>
                ))}
                {!isIndividual && user?.kyc !== 'success' && user?.kyc !== 'pending' && (
                  <div className={styles.links} onClick={selectPassKYC}>
                    Pass KYC
                  </div>
                )}
                <div className={styles.links} style={{ color: '#D32F2F' }} onClick={() => logOut()}>
                  Log out
                </div>
              </div>
              {otherProps.map((link) => (
                <div key={link.name}>
                  {link?.isNotIndividual && isIndividual ? null : link?.parent ? (
                    <div className={styles.parent}>
                      <Link
                        color="inherit"
                        underline="none"
                        onClick={handleClick}
                        to={link.href}
                        className={styles.links}
                      >
                        {link.name}
                      </Link>
                      {nestedMenu.map((item) => (
                        <Link
                          key={item.name}
                          color="inherit"
                          underline="none"
                          onClick={handleClick}
                          to={link.href}
                          className={styles.nestedLink}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.linksRow}>
                      <Link
                        color="inherit"
                        underline="none"
                        to={link.href}
                        className={styles.links}
                        onClick={handleClick}
                      >
                        {link.name}
                      </Link>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
};
