import React from 'react';
import { Button, GoogleIcon, Modal } from 'tt-ui-kit';
import styles from './GoogleAuthModal.module.scss';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const GoogleAuthModal = ({ open, onClose, onSubmit }) => (
  <Modal
    open={open}
    onClose={() => onClose()}
    onClick={(e) => e.stopPropagation()}
    title="Welcome!"
    className={styles.modalContainer}
  >
    <Box className={styles.modalContent}>
      <Typography className={styles.description}>
        You don&apos;t have an account on TransparentTerra yet. Sign up now as an Individual or
        <Link to="/dashboard/feed" className={styles.learnMore}>
          {' learn more '}
        </Link>
        about TransparenTerra&apos;s roles.
      </Typography>
      <Box className={styles.buttonGroup}>
        <Button type="default" onClick={() => onClose()}>
          cancel
        </Button>
        <Button type="button" className={styles.googleBtn} onClick={() => onSubmit()}>
          <GoogleIcon />
          <Typography className={styles.signUpText}>Sign up</Typography>
        </Button>
      </Box>
    </Box>
  </Modal>
);

export default GoogleAuthModal;
