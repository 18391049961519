export const mapUserInfoWindowMaker = (id, firstName, lastName, avatar, address, role, kyc) =>
  `<div style="display: flex; gap: 4px">
    <style>
        .gm-style-iw button[title="Close"] {
            display: none !important;
        }
        
        a:focus-visible {
            outline: none;
        }
    </style>
    <div style="position: relative; height: 100%">
      ${
        avatar
          ? `<img style="width: 64px; height: 64px; border-radius: 100%" src="${avatar}" alt=""/>`
          : `<div style="
                width: 64px;
                height: 64px;
                border-radius: 100%;
                background-color: #bdbdbd;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                color: #fff;
                font-size: 20px;"
              >
              ${firstName.substring(0, 1)} ${lastName.substring(0, 1)}
             </div>`
      }
      ${
        role && role !== 'companies'
          ? `<span style="
        color: #D8913C;
        background-color: #FFF;
        border-radius: 6px;
        position: absolute;
        bottom: -6px;
        right: 18px;
        padding: 3px;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;">${role.substring(0, 3)}</span>`
          : ``
      }
    </div>
    <div style="max-width: 195px;">
      <p style="font-weight: 600; font-size: 16px">
        <a target="_blank" href=${
          role === 'companies'
            ? `https://dev-market.transparenterra.com/dashboard/company/${id}`
            : `/dashboard/profile/${id}`
        }>
        ${role === 'companies' ? `${firstName}` : `${firstName} ${lastName}`}
          ${
            kyc
              ? '<span style="vertical-align: super; font-size: 6px; background-color: #4CAF50; border-radius: 6px; padding: 3px; color: #FFF;">KYC</span>'
              : ''
          }
        </a>
      </p>
      <p style="font-size: 12px;margin-top: 3px;">${address !== null ? address : ''}</p>
    </div>
  </div>`;
