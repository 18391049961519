import { API_BASE_API_SSO_URL } from '../urls';

export const API_POSTS_MAIN = `${API_BASE_API_SSO_URL}/posts`;
export const API_POSTS = `${API_POSTS_MAIN}/p`;
export const API_REACTIONS_POST = `${API_POSTS_MAIN}/e/p`;
export const API_REACTIONS_COMMENTS = `${API_POSTS_MAIN}/e/с`;
export const API_POSTS_LIKES = `${API_BASE_API_SSO_URL}/posts/e/p`;
export const API_COMMENTS_LIKES = `${API_BASE_API_SSO_URL}/posts/e/c`;
export const API_COMMENTS = `${API_BASE_API_SSO_URL}/posts/c`;
export const API_POSTS_UPLOAD = `${API_BASE_API_SSO_URL}/posts/f/public`;
export const API_POSTS_GET_PREVIEW_LINK = `${API_POSTS_MAIN}/metaTags`;
