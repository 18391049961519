import React, { useEffect, useState } from 'react';
import { NAVBAR, FCE_GROUP_NAVBAR } from './data';
import { useLocation } from 'react-router-dom';
import {
  IconButton,
  Typography,
  Drawer,
  Hidden,
  MUIBox,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  MenuIcon,
  CloseCrossIcon,
} from 'tt-ui-kit';
import styles from './AdminNavbar.module.scss';
import NavbarLink from './AdminNavbarLink';

const AdminNavbar = ({ showNavbar, baseUrl, baseFceUrl, mode = 'tt' }) => {
  const [section, setSection] = useState(mode === 'tt' ? 'transparenterra' : 'fce-group');
  const [openAdminHeader, setOpenAdminHeader] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const toggleSlider = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const isFceGroup = FCE_GROUP_NAVBAR.find((item) => item.url === location.pathname);
    if (isFceGroup) {
      setSection('fce-group');
    } else {
      setSection('transparenterra');
    }
    if (location.pathname.split('/')[1] === 'admin') {
      setOpenAdminHeader(true);
    }
  }, [location]);

  return (
    <>
      {showNavbar ? (
        <header className={styles.navbar}>
          <div className={styles.navbarContent}>
            <Hidden mdDown>
              {/* <div className={styles.exitLink}>
                <Link to="/dashboard/feed">Exit</Link>
              </div> */}
              <div className={styles.desctopLinks}>
                <div
                  className={styles.headerMenu}
                  style={!openAdminHeader ? { paddingBottom: 16 } : {}}
                >
                  {location.pathname.includes('admin') && (
                    <div
                      className={styles.headerMenuItem}
                      onClick={() => {
                        if (mode === 'tt') {
                          window.location.href = `${baseUrl}/`;
                        } else {
                          window.location.href = `${baseFceUrl}/`;
                        }
                      }}
                    >
                      Go to home screen
                    </div>
                  )}
                  <div
                    style={section === 'transparenterra' ? { color: '#143471' } : {}}
                    className={styles.headerMenuItem}
                    onClick={() => setSection('transparenterra')}
                  >
                    Transparenterra
                  </div>
                  <div
                    style={section === 'fce-group' ? { color: '#143471' } : {}}
                    className={styles.headerMenuDarkItem}
                    onClick={() => {
                      if (mode === 'tt') {
                        window.location.href = `${baseFceUrl}/admin/`;
                      } else {
                        setSection('fce-group');
                      }
                    }}
                  >
                    FCE Group
                  </div>
                  <IconButton onClick={() => setOpenAdminHeader(!openAdminHeader)}>
                    {openAdminHeader ? (
                      <KeyboardArrowUpIcon sx={{ color: '#212121' }} />
                    ) : (
                      <KeyboardArrowDownIcon sx={{ color: '#212121' }} />
                    )}
                  </IconButton>
                </div>
                {openAdminHeader && (
                  <nav className={styles.navbarLinks}>
                    {section === 'transparenterra'
                      ? NAVBAR.map((item, index) => (
                          <NavbarLink
                            key={`${index + 1}`}
                            href={baseUrl ? `${baseUrl}${item.url || '/'}` : item.url || '/'}
                            text={item.label}
                            className={styles.navBarLink}
                          />
                        ))
                      : FCE_GROUP_NAVBAR.map((item, index) => (
                          <NavbarLink
                            key={`${index + 1}`}
                            href={baseFceUrl ? `${baseFceUrl}${item.url || '/'}` : item.url || '/'}
                            text={item.label}
                            className={styles.navBarLink}
                          />
                        ))}
                  </nav>
                )}
              </div>
            </Hidden>
            <Hidden mdUp>
              <MUIBox
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: !open ? 'space-between' : 'flex-end',
                  alignItems: 'center',
                  paddingTop: '22px !important',
                  paddingBottom: '20px !important',
                }}
              >
                {!open ? <Typography className={styles.menuTitle}>Admin Menu</Typography> : null}
                <IconButton onClick={toggleSlider}>
                  <MenuIcon style={{ width: 24, height: 24, flexShrink: 0 }} />
                </IconButton>
              </MUIBox>
              <Drawer
                open={open}
                anchor="right"
                onClose={toggleSlider}
                sx={{ width: 300 }}
                PaperProps={{
                  sx: {
                    width: 300,
                  },
                }}
              >
                <MUIBox className={styles.menuHeader}>
                  <Typography className={styles.menuTitle}>Admin Menu</Typography>
                  <IconButton onClick={toggleSlider}>
                    <CloseCrossIcon style={{ width: 24, height: 24, flexShrink: 0 }} />
                  </IconButton>
                </MUIBox>
                <div
                  className={styles.headerMenu}
                  style={!openAdminHeader ? { paddingBottom: 16 } : {}}
                >
                  {location.pathname.includes('admin') && (
                    <div
                      className={styles.headerMenuItem}
                      onClick={() => {
                        if (mode === 'tt') {
                          window.location.href = `${baseUrl}/`;
                        } else {
                          window.location.href = `${baseFceUrl}/`;
                        }
                      }}
                    >
                      Go to home screen
                    </div>
                  )}
                  <div
                    style={section === 'transparenterra' ? { color: '#143471' } : {}}
                    className={styles.headerMenuItem}
                    onClick={() => setSection('transparenterra')}
                  >
                    Transparenterra
                  </div>
                  <div
                    style={section === 'fce-group' ? { color: '#143471' } : {}}
                    className={styles.headerMenuDarkItem}
                    onClick={() => {
                      if (mode === 'tt') {
                        window.location.href = `${baseFceUrl}/admin/`;
                      } else {
                        setSection('fce-group');
                      }
                    }}
                  >
                    FCE Group
                  </div>
                </div>
                {section === 'transparenterra'
                  ? NAVBAR.map((item) => (
                      // eslint-disable-next-line react/jsx-key
                      <NavbarLink
                        href={baseUrl ? `${baseUrl}${item.url || '/'}` : item.url || '/'}
                        text={item.label}
                        className={styles.navbarLink}
                      />
                    ))
                  : FCE_GROUP_NAVBAR.map((item) => (
                      // eslint-disable-next-line react/jsx-key
                      <NavbarLink
                        href={baseFceUrl ? `${baseFceUrl}${item.url || '/'}` : item.url || '/'}
                        text={item.label}
                        className={styles.navbarLink}
                      />
                    ))}
                {/* <div className={styles.exitLinkForMenu}>
                  <Link to="/dashboard/feed">Exit</Link>
                </div> */}
              </Drawer>
            </Hidden>
          </div>
        </header>
      ) : null}
    </>
  );
};

export default AdminNavbar;
