import React from 'react';
import { useNavigate } from 'react-router';
import styles from './ButtonsField.module.scss';
import { GoogleIcon } from 'tt-ui-kit';

const ButtonsField = ({ role, code }) => {
  const navigate = useNavigate();

  const handleCancel = (e) => {
    e.preventDefault();
    navigate(`/join/${role.url}`);
  };

  const signUpWithGoogle = () => {
    let url = `${process.env.REACT_APP_SSO}/login/google?registrationType=${role.url}`;
    if (code) {
      url += `&code=${code}`;
    }
    window.location.replace(url);
  };

  return (
    <div className={styles.buttonsWrapper}>
      <>
        <button className={styles.firstBtn} type="button" onClick={handleCancel}>
          Cancel
        </button>
        <div className={styles.rightButtons}>
          <button type="button" className={styles.googleBtn} onClick={() => signUpWithGoogle()}>
            <GoogleIcon />
            <p>Sign in with google</p>
          </button>
          <button className={styles.secondBtn} type="submit">
            Sign up
          </button>
        </div>
      </>
    </div>
  );
};

export default ButtonsField;
