import React, { useState } from 'react';
import styles from './AllItemsPage.module.scss';
import { MenuItem as MUIMenuItem } from '@mui/material';
import {
  Button,
  ConfirmModal,
  openNotification,
  MUITabs,
  MUITab,
  MUISelect,
  LoaderOn,
  Loader,
} from 'tt-ui-kit';
import { Table } from './Components/Table';

const AllItemsPage = () => {
  const pageSize = 9;
  const [tableData, setTableData] = useState([]);
  const [isFetchTable, setIsFetchTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedBlock, setSelectedBlock] = useState('');
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [loadingSections, setLoadingSections] = useState(false);
  const [queryFilter, setQueryFilter] = useState({
    limit: pageSize,
    page: currentPage,
    order_dir: 'desc',
    f: '',
    is_active: '',
  });

  const handleChangeSection = (event, newValue) => {
    // if (isAddQuestion) {
    //   setOpenAddCloseConfirm(true);
    //   setNewAddConfirmSectionValue(newValue);
    //   return;
    // }

    setSelectedSection(newValue);
    setSelectedBlock('all');
    setCurrentPage(1);
    setFilter({ f: '' });
  };

  const handleChangeBlock = (event, newValue) => {
    // if (isAddQuestion || isEditQuestion) {
    //   setOpenAddCloseConfirm(true);
    //   setNewAddConfirmValue(newValue);
    //   return;
    // }

    setSelectedBlock(newValue);
    setCurrentPage(1);
    setFilter({ f: '' });
  };

  const handleChangeSectionSelect = (event) => {
    handleChangeSection(event, event.target.value);
  };

  const handleChangeBlockSelect = (event) => {
    handleChangeBlock(event, event.target.value);
  };

  return (
    <>
      <div className={styles.pageContainer}>
        {sections.length > 0 ? (
          <div className={styles.tabsContainer}>
            <MUITabs
              TabIndicatorProps={{ style: { backgroundColor: '#143471' } }}
              centered
              value={selectedSection}
              onChange={handleChangeSection}
              className={styles.tabs}
              scrollButtons
            >
              {sections.map((item) => (
                <MUITab
                  value={item.id}
                  label={item.name}
                  className={[
                    styles.tabItem,
                    selectedSection === item.id ? styles.activeItem : null,
                  ]}
                  sx={{ margin: '0px 30px', padding: 0, color: '#ff001c' }}
                />
              ))}
            </MUITabs>

            <MUITabs
              TabIndicatorProps={{ style: { backgroundColor: '#143471' } }}
              centered
              value={selectedBlock}
              onChange={handleChangeBlock}
              className={[styles.tabs, styles.secondTabs]}
              scrollButtons
            >
              {loadingSections && <LoaderOn />}
              {blocks.map((item) => (
                <MUITab
                  value={item.id}
                  label={item.name}
                  className={[styles.tabItem, selectedBlock === item.id ? styles.activeItem : null]}
                  sx={{ margin: '0px 30px', padding: 0, color: '#ff001c' }}
                />
              ))}
            </MUITabs>
          </div>
        ) : null}

        {sections.length > 0 ? (
          <div>
            <MUISelect
              onChange={handleChangeSectionSelect}
              value={selectedSection}
              size="medium"
              className={styles.topSelect}
              sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
            >
              {sections.map((item) => (
                <MUIMenuItem value={item.id}>
                  <span className={styles.topMenuItem}>{item.name}</span>
                </MUIMenuItem>
              ))}
            </MUISelect>

            <MUISelect
              onChange={handleChangeBlockSelect}
              value={selectedBlock}
              size="medium"
              className={styles.topSelect}
              sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
            >
              {blocks.map((item) => (
                <MUIMenuItem value={item.id}>
                  <span className={styles.topMenuItem}>{item.name}</span>
                </MUIMenuItem>
              ))}
            </MUISelect>
          </div>
        ) : null}

        {sections.length > 0 ? (
          <Table
            tableData={tableData}
            pages={pages}
            loading={loading}
            queryFilter={queryFilter}
            setQueryFilter={setQueryFilter}
            // setOpenEditBlock={setOpenEditBlock}
            // setNameEditBlock={setNameEditBlock}
            // setIdEditBlock={setIdEditBlock}
            filter={filter}
            setFilter={setFilter}
            setCurrentPage={setCurrentPage}
            selectedBlock={selectedBlock}
            // setIsAddBlock={setIsAddQuestion}
            pageSize={pageSize}
            currentPage={currentPage}
            allBlocks={blocks}
            // setIsEditQuestion={setIsEditQuestion}
            // setIdEditQuestion={setIdEditQuestion}
            reloadTableMethod={() => {
              setIsFetchTable(true);
            }}
          />
        ) : loadingSections ? (
          <div className={styles.emptyContainer}>
            <Loader />
          </div>
        ) : (
          <div className={styles.emptyContainer}>No data yet...</div>
        )}
      </div>
    </>
  );
};

export default AllItemsPage;
