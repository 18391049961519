/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
// import { Button } from '@mui/material';
import { Button } from 'tt-ui-kit';
import Chart from 'components/Charts/RadialChart/Chart';
import Modal from 'components/Modals/modal/Modal';
import SharingResourceModal from 'components/Modals/SharingModal/SharingResourceModal';
import { ReactComponent as AnsveredSvg } from '../../../../assets/svg/checkMark.svg';
import { GET_USER_RESULT } from '../../../../api';
import { getCalculatordata, CALC_TYPES, CALC_NAMES } from '../../../../utils/calculatorUtils';
import styles from './CalculatorsDetail.module.scss';
import CalculatorDetailTable from './CalculatorsDetailTable';
import GenerateReportModal from 'components/Modals/GenerateReportModal/GenerateReportModal';
import { URL_ANSWERS_DETAILS, URL_CALCS_SCORE } from 'constants/calculatorsPage';
import { useTargetBlockSize } from 'utils/useTargetBlockSize';
import useResizeObserver from 'use-resize-observer';

const getChart = (res) => {
  if (!res.stateGraphics) return [];
  if (res.type === CALC_TYPES.GLOBAL)
    return JSON.parse(res.stateGraphics).reduce((arr, dim) => [...arr, ...dim.children], []);
  return JSON.parse(res.stateGraphics);
};

const CalculatorDetail = ({ targetWidth }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [chartData, setChartData] = useState([]);
  const [result, setResult] = useState(null);
  const [isReportingModalOpened, setReportingModalOpened] = useState(false);
  const [isSharingModalOpened, setSharingModalOpened] = useState(false);
  const [isOffsetModalOpened, setOffsetModalOpened] = useState(false);
  const [isCheckHashModalOpened, setCheckHashModalOpened] = useState(false);
  const [getResult, { data: resultById }] = useLazyQuery(GET_USER_RESULT, {
    variables: { id: id },
  });
  const [chartWidth, setChartWidth] = useState(null);
  const [isVertical, setIsVertical] = useState(false);
  const { ref, width, height } = useResizeObserver();

  const reportUrl = `/startReport?type=${CALC_TYPES.SUST_GRI}&reportId=${id}`;
  const marketUrl = `/dashboard/marketplace/list/product?activeTab=carbon`;
  const endpointReport = process.env.REACT_APP_REPORT || '';
  const endpointMarket = process.env.REACT_APP_MARKET;

  const redirectReport = () => {
    window.location.href = `${endpointReport}${reportUrl}`;
  };

  const redirectMarket = () => {
    window.location.href = `${endpointMarket}${marketUrl}`;
  };

  const sharingModalClosed = () => {
    setSharingModalOpened(false);
    getResult();
  };

  useEffect(() => {
    getResult();
  }, []);

  useEffect(() => {
    if (!targetWidth || !width) return;
    const checkVertical = targetWidth < 840;
    setIsVertical(checkVertical);
    setChartWidth(
      checkVertical ? width : targetWidth - width - (32 /* padding */ * 2 + 20) /* gap */
    );
  }, [targetWidth, width]);

  useEffect(() => {
    if (resultById && resultById.resultById) {
      setResult(resultById.resultById);
      const newChartData = getChart(resultById.resultById);
      setChartData(newChartData);
    }
  }, [resultById]);

  const onMarketRedirect = () => {
    setOffsetModalOpened(false);
    redirectMarket();
  };

  const onReportRedirect = () => {
    setReportingModalOpened(false);
    redirectReport();
  };

  return (
    <>
      {result && result.type && (
        <GenerateReportModal
          opened={isReportingModalOpened}
          closeModalFunc={onReportRedirect}
          resultId={id}
          type={result.type}
        />
      )}
      <Modal
        opened={isOffsetModalOpened}
        closeModalFunc={() => setOffsetModalOpened(false)}
        headerContent="Offset your footprint!"
        okBtnText="Go to Marketplace"
        backBtnText="Cancel"
        okFunc={onMarketRedirect}
        containedStyle
      >
        You are about to visit TT Carbon offset marketplace
      </Modal>
      <Modal
        opened={isCheckHashModalOpened}
        closeModalFunc={() => setCheckHashModalOpened(false)}
        headerContent="Data valid"
        okBtnText="Go to my assessment"
        backBtnText="Cancel"
        okFunc={() => setCheckHashModalOpened(false)}
        containedStyle
      >
        <p>
          <AnsveredSvg className={styles.qbuttonsvg} />
          &nbsp;0xb8cd0xb8c0xb8cd0xb8cd
        </p>
        <br />
        <p>
          While the architecture of blockchain technology guarantees immutability, hashing is a
          one-way encryption that ensures data confidentiality.
        </p>
        <p>
          To verify the authenticity of the data, the algorithm compares the hash of the data you
          see now with the hash of the data that was originally uploaded by its owner and stored in
          the blockchain. If the hashes match, the system confirms that they are unchanged.
        </p>
      </Modal>
      {result && (
        <SharingResourceModal
          type="result"
          sharingId={result.sharingId}
          targetId={result.id}
          open={isSharingModalOpened}
          onClose={sharingModalClosed}
        />
      )}
      <div className={styles.blockHeader}>
        <Button
          type="link"
          className={styles.shareButton}
          onClick={() => navigate(URL_CALCS_SCORE)}
        >
          <p>&lt; Back To List</p>
        </Button>
        <Button
          type="icon"
          className={styles.shareButton}
          onClick={() => setSharingModalOpened((value) => !value)}
        >
          <img src="/images/shareIcon.png" alt="share" />
          <p>Share</p>
        </Button>
      </div>

      {result && (
        <div className={styles.calculatorDetail}>
          <div className={clsx(styles.blockBody, { [styles.vertical]: isVertical })}>
            {!!chartWidth && (
              <div className={styles.chartArea} style={{ height: chartWidth, width: chartWidth }}>
                <Chart chartData={chartData} score={result.totalResult.toString()} />
              </div>
            )}
            <div className={styles.textArea} ref={ref}>
              <div className={styles.statistic}>
                <div className={styles.headerBlock}>
                  <div>{CALC_NAMES[result.type]}</div>
                  <div>{result.reportDate}</div>
                </div>
                <div className={styles.scoreBlock}>
                  <div>Score:</div>
                  <div>{result.totalResult}</div>
                </div>
                {result.type === CALC_TYPES.GLOBAL && <p>TT.Global calculator</p>}
                {(result.type === CALC_TYPES.LOCAL || result.type === CALC_TYPES.CARBON) && (
                  <p>Carbon footprint calculator</p>
                )}
                <div className={styles.list}>
                  {result &&
                    getCalculatordata(result).map((calc) => (
                      <div key={calc.code} className={styles.lineofList}>
                        <div className={`${styles.option} ${calc.nested && styles.nested}`}>
                          <Link to={`${URL_ANSWERS_DETAILS}/${calc.code}/${id}`}>
                            <p>{calc.title}</p>
                          </Link>
                        </div>
                        {calc.score !== undefined && calc.score !== null && (
                          <span>score: {calc.score}</span>
                        )}
                      </div>
                    ))}
                </div>
              </div>
              <div className={styles.buttonBlock}>
                <Button variant="outlined" onClick={() => setReportingModalOpened(true)}>
                  Create&nbsp;a&nbsp;Report
                </Button>
                <Button variant="outlined" onClick={() => setOffsetModalOpened(true)}>
                  To&nbsp;offset
                </Button>
                <Button variant="outlined" onClick={() => setCheckHashModalOpened(true)}>
                  Verified
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.calculatorDetail}>
        <h1>ESG rating</h1>
        <CalculatorDetailTable params={{ result }} />
      </div>
    </>
  );
};

export default CalculatorDetail;
