/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Dialog, Avatar, DialogContent, Paper } from '@mui/material';
import * as TwilioVideo from 'twilio-video';
import Participant from './Participant/Participant';
import Header from './Header';
import styles from './VideoCallModal.module.scss';
import Footer from './Footer';

const PaperComponent = (props) => (
  <Draggable cancel=".MuiButtonBase-root">
    <Paper style={{ overflow: 'inherit' }} {...props} />
  </Draggable>
);

const VideoCallModal = (props) => {
  const {
    open,
    handleClose,
    directCallData,
    twilioToken,
    twilioRoomId,
    callId,
    rejectCall,
    answerCall,
    endCall,
    cancelCall,
    callStatus,
  } = props;

  const [localeParticipant, setLocaleParticipant] = useState(null);
  const [localeVideoParticipant, setLocaleVideoParticipant] = useState([]);
  const [modalMaxWidth, setModalMaxWidth] = useState('xs');
  const [participants, setParticipants] = useState([]);
  const [audioMuted, setAudioMuted] = useState(false);
  const [videoOff, setVideoOff] = useState(true);
  const [deviceExist, setDeviceExist] = useState([]);
  const [participantVideoOff, setParticipantVideoOff] = useState(true);
  const [participantAudioMuted, setParticipantAudioMuted] = useState(false);
  const [timer, setTimer] = useState(0);

  const muteAudio = () => {
    if (callStatus === 'answered' || callStatus === 'ended') {
      localeParticipant?.localParticipant?.audioTracks?.forEach((publication) => {
        setAudioMuted(true);
        publication.track.disable();
        publication.track.stop();
      });
    }
  };

  const unmuteAudio = () => {
    if (callStatus === 'answered')
      localeParticipant?.localParticipant?.audioTracks?.forEach((publication) => {
        setAudioMuted(false);
        publication.track.restart();
        publication.track.enable();
      });
  };

  const offVideo = () => {
    if (callStatus === 'answered' || callStatus === 'ended') {
      localeParticipant?.localParticipant?.videoTracks?.forEach((publication) => {
        setVideoOff(true);
        publication.track.disable();
        publication.track.stop();
      });
      localeVideoParticipant?.forEach((publication) => {
        setVideoOff(true);
        publication[1].track.disable();
        publication[1].track.stop();
      });
    }
  };

  const endCallAndDisableDevices = (id) => {
    offVideo();
    muteAudio();
    endCall(id);
  };

  const onVideo = async () => {
    if (callStatus === 'answered') {
      if (localeParticipant?.localParticipant?.videoTracks?.size === 0) {
        let localVideoTrack = null;
        await TwilioVideo.createLocalVideoTrack()
          .then((res) => {
            localVideoTrack = res;
            setVideoOff(false);
          })
          .catch(() => {
            setDeviceExist((prevDeviceExist) => [...prevDeviceExist, 'video']);
            setVideoOff(true);
          });
        if (localVideoTrack) {
          await localeParticipant?.localParticipant?.publishTrack(localVideoTrack);
          setLocaleParticipant(localeParticipant);
          setLocaleVideoParticipant([...localeParticipant.localParticipant.videoTracks]);
        }
      } else {
        localeParticipant?.localParticipant?.videoTracks?.forEach((publication) => {
          setVideoOff(false);
          publication.track.restart();
          publication.track.enable();
        });
        setLocaleVideoParticipant([...localeParticipant.localParticipant.videoTracks]);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (twilioToken !== '' && callId !== '') {
        const participantConnected = (participant) => {
          if (participant.status !== 'disconnected') {
            setParticipants((prevParticipants) => [...prevParticipants, participant]);
          }
        };

        const participantDisconnected = (participant) => {
          setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));
        };

        const localAudioTrack = await navigator.mediaDevices.getUserMedia({ audio: true }).then(
          (stream) => {
            setAudioMuted(false);
            stream.getTracks().forEach((track) => {
              track.stop();
            });
            return true;
          },
          (e) => {
            setDeviceExist((prevDeviceExist) => [...prevDeviceExist, 'audio']);
            setAudioMuted(true);
            return false;
          }
        );

        TwilioVideo.connect(twilioToken, {
          audio: localAudioTrack,
          video: false,
          name: twilioRoomId,
        })
          .then((room) => {
            setLocaleParticipant(room);
            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);
            room.on('trackSubscribed', (track) => {
              track.on('disabled', (event) => {
                if (event.kind === 'video') setParticipantVideoOff(true);
                if (event.kind === 'audio') setParticipantAudioMuted(true);
              });
              track.on('enabled', (event) => {
                if (event.kind === 'video') setParticipantVideoOff(false);
                if (event.kind === 'audio') setParticipantAudioMuted(false);
              });
            });
            room.on('trackPublished', (track) => {
              if (track.kind === 'video') setParticipantVideoOff(false);
              if (track.kind === 'audio') setParticipantAudioMuted(false);
            });
            room.participants.forEach(participantConnected);
          })
          .catch((e) => {
            setDeviceExist((prevDeviceExist) => [...prevDeviceExist, 'audio']);
            setParticipantVideoOff(true);
            setAudioMuted(true);
            setParticipants((prevParticipants) => [...prevParticipants, directCallData.user]);
          });
      }
    })();

    return () => {
      setAudioMuted(false);
      setVideoOff(true);
      setParticipantVideoOff(true);
      setParticipantAudioMuted(false);
      setParticipants([]);
      setLocaleParticipant((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant?.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach((trackPublication) => {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        }
        return currentRoom;
      });
    };
  }, [callId]);

  const handleCloseDialog = (event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose(false);
    }
  };

  useEffect(() => {
    if (callStatus === 'answered') {
      setTimer(0);
      const interval = setInterval(() => setTimer((oldTimer) => oldTimer + 1), 1000);
      return () => {
        clearInterval(interval);
      };
    }
    if (callStatus === 'ended') {
      offVideo();
      muteAudio();
    }
  }, [callStatus]);

  const secondsToTime = (e) => {
    const h = Math.floor(e / 3600)
      .toString()
      .padStart(2, '0');
    const m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, '0');
    const s = Math.floor(e % 60)
      .toString()
      .padStart(2, '0');
    if (h !== '00') return `${h} : ${m} : ${s}`;
    return `${m} : ${s}`;
  };

  return (
    <Dialog
      style={{ padding: '0px 0px 0px 0px', overflow: 'auto', height: '100vh' }}
      open={open}
      fullWidth
      hideBackdrop={true}
      disableEnforceFocus
      disableEscapeKeyDown
      maxWidth={modalMaxWidth}
      onClose={handleCloseDialog}
      PaperComponent={PaperComponent}
    >
      <Header
        status={callStatus}
        size={modalMaxWidth}
        timer={secondsToTime(timer)}
        changeSize={(size) => setModalMaxWidth(size)}
      />
      <DialogContent className={styles.dialogContent}>
        {(callStatus === 'outgoing' || callStatus === 'incoming') && (
          <div className={styles.dialogContentPendingCall}>
            {directCallData.user && (
              <div className={styles.userAvatar}>
                <Avatar
                  sx={{ width: 100, height: 100 }}
                  src={directCallData.user.avatar ? directCallData.user.avatar : null}
                >
                  {`${directCallData.user.first_name.slice(0, 2)}
                ${directCallData.user.last_name.slice(0, 2)}`}
                </Avatar>
              </div>
            )}
            <p className={styles.webOnline}>Meet “WebOnline”</p>
          </div>
        )}
        {callStatus === 'answered' && (
          <div>
            <div
              style={{
                height: modalMaxWidth === 'xs' ? 'auto' : '70vh',
              }}
            >
              {participants.map((participant) => (
                <Participant
                  deviceExist={deviceExist}
                  user={directCallData.user}
                  participantAudioMuted={participantAudioMuted}
                  participantVideoOff={participantVideoOff}
                  key={participant.sid}
                  participant={participant}
                />
              ))}
            </div>
            {localeParticipant !== null && (
              <div className={styles.localParticipant}>
                <Participant
                  deviceExist={deviceExist}
                  user={directCallData.user}
                  key={localeParticipant?.localParticipant?.sid}
                  localeVideoOff={videoOff}
                  localeAudioMuted={audioMuted}
                  isLocale={true}
                  participant={localeParticipant?.localParticipant}
                />
              </div>
            )}
          </div>
        )}
      </DialogContent>
      <Footer
        deviceExist={deviceExist}
        rejectCall={() => rejectCall(directCallData.id)}
        answerCall={() => answerCall(directCallData.id)}
        endCall={() => endCallAndDisableDevices(directCallData.id)}
        cancelCall={() => cancelCall(directCallData.id)}
        muteAudio={() => muteAudio()}
        unmuteAudio={() => unmuteAudio()}
        offVideo={() => offVideo()}
        onVideo={() => onVideo()}
        timer={secondsToTime(timer)}
        videoOff={videoOff}
        audioMuted={audioMuted}
        status={callStatus}
        size={modalMaxWidth}
        changeSize={(size) => setModalMaxWidth(size)}
      />
    </Dialog>
  );
};

export default VideoCallModal;
