import React, { useEffect, useState } from 'react';
import styles from './PostView.module.scss';
import AuthPostView from './Views/AuthPostView';
import UnauthPostView from './Views/UnauthPostView';
import { useNavigate } from 'react-router';
// import ShareModal from './Components/ShareModal';
import { openNotification } from 'tt-ui-lib/core';
import { updateLikePost } from '../../api/posts/endpoints/posts';
import { ShareModal } from '../../modules/tt-modals';

const PostFeedView = ({
  postInstance,
  postBody,
  allowedReactions,
  user,
  onDeletePost,
  isContent = false,
}) => {
  const navigate = useNavigate();

  const [selectedActionUser, setSelectedActionUser] = useState(user?.id || '');
  const [openShareModal, setOpenShareModal] = useState(false);
  const [selectedReaction, setSelectedReaction] = useState(null);
  const [likeLoading, setLikeLoading] = useState(false);
  const [reactions, setReactions] = useState([]);
  const [emojiCount, setEmojiCount] = useState();

  useEffect(() => {
    setReactions(postInstance?.reactions);
    setEmojiCount(postInstance?.emoji_count);
  }, [postInstance]);

  useEffect(() => {
    if (postInstance?.user_reaction) {
      setSelectedReaction(postInstance?.user_reaction || null);
    } else {
      setSelectedReaction(null);
    }

    setLikeLoading(false);
  }, [postInstance]);

  useEffect(() => {
    if (user) {
      setSelectedActionUser(user.id);
    }
  }, [user]);

  const selectReaction = (val, onSuccess) => {
    setLikeLoading(true);

    const data = {
      user_id: user?.id || '',
      emoji_id: val,
      post_id: postInstance.id,
    };

    updateLikePost(data)
      .then(() => {
        setSelectedReaction(val);
        setLikeLoading(false);
        setReactions([...reactions, data]);
        setEmojiCount(emojiCount + 1);
        if (onSuccess) onSuccess();
      })
      .catch(() => {
        setSelectedReaction('');
        openNotification({
          message: 'Error while set reaction, try again later',
          type: 'error',
        });
        setLikeLoading(false);
      });
  };

  const clearReaction = (onSuccess) => {
    const prevReaction = selectedReaction;

    const data = {
      user_id: user?.id || '',
      post_id: postInstance.id,
    };
    const filtred = reactions.filter((e) => e.user_id !== data.user_id);
    updateLikePost(data)
      .then(() => {
        setSelectedReaction('');
        setReactions(filtred);
        setEmojiCount(emojiCount - 1);
        if (onSuccess) onSuccess();
      })
      .catch(() => {
        setSelectedReaction(prevReaction);
        openNotification({
          message: 'Error while set reaction, try again later',
          type: 'error',
        });
      });
  };

  const openUserProfile = () => {
    if (postInstance?.user?.id) navigate(`/dashboard/profile/${postInstance.user.id}`);
  };

  const openComments = () => {
    navigate(
      isContent
        ? `/dashboard/content/post/${postInstance.id}`
        : `/dashboard/feed/post/${postInstance.id}`
    );
  };

  const sharePost = () => {
    setOpenShareModal(true);
  };

  return (
    <div className={styles.postFeedContainer}>
      {user.id ? (
        <AuthPostView
          user={user}
          postInstance={postInstance}
          postBody={postBody}
          selectedReaction={selectedReaction}
          likeLoading={likeLoading}
          allowedReactions={allowedReactions}
          selectReaction={selectReaction}
          clearReaction={clearReaction}
          openComments={openComments}
          sharePost={sharePost}
          openPost={openComments}
          selectedActionUser={selectedActionUser}
          setSelectedActionUser={setSelectedActionUser}
          openUserProfile={openUserProfile}
          onDeletePost={onDeletePost}
          isArticle={postInstance?.type === 'article'}
          reactions={reactions}
          emojiCount={emojiCount}
        />
      ) : (
        <UnauthPostView
          postInstance={postInstance}
          postBody={postBody}
          openPost={openComments}
          sharePost={sharePost}
          openUserProfile={openUserProfile}
          isArticle={postInstance?.type === 'article'}
          allowedReactions={allowedReactions}
        />
      )}

      <ShareModal
        open={openShareModal}
        closeModal={() => setOpenShareModal(false)}
        link={`${process.env.REACT_APP_FEED_LOCATION}/post/${postInstance.id}`}
      />
    </div>
  );
};

export default PostFeedView;
