import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import styles from './ContentEditor.module.scss';
import { maxArticleBodyLength } from '../../../constants/addPost';
import { v1 as uuidv1 } from 'uuid';
import { getPostFileUploadLink } from '../../../api/posts/endpoints/posts';
import axios from 'axios';
import { setErrorMessage } from '../../../utils/appUtils';
import { useSelectedContext } from '../../../context/contextSelector';

const EDITOR_HOLDER_ID = 'content-editor';

export const ContentEditor = ({
  placeholder,
  value,
  saveDataMethod,
  className,
  maxLength = maxArticleBodyLength,
  disableControls = false,
  error = false,
  getPreviewFromLink = undefined,
  imageUploadPath = '',
}) => {
  const [bodyLength, setBodyLength] = useState();
  const { setIsCoverSecondLoading } = useSelectedContext((context) => context.app);

  const saveData = (newValue, editor) => {
    const { wordcount } = editor.plugins;
    const length = wordcount.body.getCharacterCount();
    setBodyLength(length);
    saveDataMethod(newValue, bodyLength);
  };

  const uploadImage = async (file) => {
    if (file && imageUploadPath) {
      const fileExt = file.name.match(/\.([^.]+)$/)[1];
      const fileName = `${uuidv1()}.${fileExt}`;

      const toUpload = { path: imageUploadPath, name: fileName };

      const upload = await getPostFileUploadLink(toUpload)
        .then(async (response) => {
          const urlToUpload = response.data?.url;

          if (urlToUpload) {
            return axios
              .put(urlToUpload, file, {
                headers: {
                  'Content-type': file.type,
                  'Access-Control-Allow-Origin': '*',
                },
              })
              .then((uploadResponse) => {
                if (uploadResponse.statusText === 'OK') {
                  return {
                    uuid: fileName,
                    url: `${'https://'}${response.data?.download_url}`,
                  };
                }
              })
              .catch(() => {
                setErrorMessage('Error while uploading file');
                return null;
              });
          }
          setErrorMessage('Error while uploading file');
          return null;
        })
        .catch(() => {
          setErrorMessage('Error while uploading file');
          return null;
        });

      if (upload) {
        return upload;
      }

      return null;
    }
  };

  return (
    <div>
      <div
        id={EDITOR_HOLDER_ID}
        className={`${className} ${styles.contentEditorWrapper} ${error ? styles.error : ''}`}
      >
        <Editor
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          value={value}
          onEditorChange={(newValue, e) => saveData(newValue, e)}
          init={{
            placeholder: placeholder,
            resize: false,
            menubar: false,
            promotion: false,
            statusbar: false,
            convert_urls: false,
            toolbar_mode: 'sliding',
            link_default_target: '_blank',
            contextmenu: 'link media',
            xss_sanitization: true,
            sandbox_iframes: true,
            invalid_elements: 'input,option,radio,button,select,script',
            paste_as_text: true,
            paste_data_images: false,
            link_context_toolbar: true,
            automatic_uploads: false,
            file_picker_types: 'image',
            file_picker_callback: (cb) => {
              const input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');

              input.addEventListener('change', (e) => {
                setIsCoverSecondLoading(true);
                const file = e.target.files[0];

                uploadImage(file)
                  .then((upload) => {
                    cb(upload.url, { title: file.name });
                  })
                  .catch(() => {
                    cb('');
                  })
                  .finally(() => {
                    setIsCoverSecondLoading(false);
                  });
              });

              input.click();
            },
            custom_link_preview_loader: getPreviewFromLink,
            toolbar: disableControls
              ? false
              : 'undo redo | blocks | ' +
                'bold italic underline forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'image link media | removeformat',
            content_style:
              'body { font-family: Montserrat, sans-serif; font-size: 16px } img {     width: 100%; height: 100%; }',
            plugins: [
              'advlist',
              'autolink',
              'image',
              'lists',
              'link',
              'charmap',
              'anchor',
              'searchreplace',
              'code',
              'media',
              'wordcount',
            ],
          }}
        />
      </div>
      {bodyLength >= maxLength && (
        <p className={styles.errorMessage}>
          You have reached the 10000-character limit. Please shorten your message.
        </p>
      )}
    </div>
  );
};
