import React, { useState } from 'react';
import { MenuItem } from '@mui/material';
import { ConfirmModal, EditIcon, LitterBinIcon, WarningIcon } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import { deletePostComment } from '../../../api/posts/endpoints/posts';

const CommentsActions = ({
  user,
  commentInstance,
  closeMenu,
  onDeleteComment,
  isReply = false,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);

  const removeComment = () => {
    setLoadingAction(true);

    deletePostComment(commentInstance.id, user.id)
      .then((response) => {
        if (response.status === 200) {
          openNotification({
            message: 'Your comment has been successfully deleted.',
            type: 'success',
          });
          setShowDeleteModal(false);
          closeMenu();
          onDeleteComment();
        }
      })
      .catch((e) => {
        openNotification({
          message: 'Error while removing comment. Try again later.',
          type: 'error',
        });
      })
      .finally(() => {
        setLoadingAction(false);
      });
  };

  const userCommentActions = (
    <>
      {/* <MenuItem */}
      {/*  onClick={() => { */}
      {/*    // TODO */}
      {/*  }} */}
      {/* > */}
      {/*  <EditIcon style={{ width: 18, height: 18, marginRight: 10 }} /> */}
      {/*  Edit */}
      {/* </MenuItem> */}
      <MenuItem
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        <LitterBinIcon
          className="icon-red-fill"
          style={{ width: 18, height: 18, marginRight: 10 }}
        />
        Delete
      </MenuItem>
    </>
  );

  const otherCommentActions = (
    <>
      <MenuItem
        onClick={() => {
          // TODO
        }}
      >
        <WarningIcon className="icon-red-fill" style={{ width: 18, height: 18, marginRight: 10 }} />
        Report
      </MenuItem>
    </>
  );

  return (
    <>
      {user.id === commentInstance.user.id ? userCommentActions : otherCommentActions}

      <ConfirmModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        title={`Do you want to delete a ${isReply ? 'reply' : 'comment'}?`}
        subTitle={`Your ${isReply ? 'reply' : 'comment'} will be deleted permanently`}
        okText="Delete"
        loading={loadingAction}
        onSubmit={() => removeComment()}
        onClose={() => closeMenu()}
      />
    </>
  );
};

export default CommentsActions;
