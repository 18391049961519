import React, { useState, useEffect } from 'react';
import messengerContext from 'context/Messenger/messengerContext';
import { useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import { GET_PRO_LOGIN } from 'api';
import FormBody from 'core/FormBody';
import FormItemLeft from 'core/FormItemLeft';
import FormItemRight from 'core/FormItemRight';
import { FORM_TITLE_LINES } from 'constants/signInPage';
import Input from 'components/Input';
import { Button } from 'tt-ui-kit';
import { BlockedModal } from '../../modules/tt-modals';
import { useNavigate } from 'react-router';
import { Link } from '@mui/material';
import styles from './SignInPage.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PartialLoader from 'components/layout/PartialLoader';
import { useSearchParams } from 'react-router-dom';
import { EMAIL_AND_TOKEN_VALIDATION } from '../../api';
import SupportChat from '../../modules/tt-concierge';
import { useSelectedContext } from 'context/contextSelector';

const laSalleGreenTheme = {
  headerGradient: 'linear-gradient(99.65deg, #143471 100%, #1550BD 100%)',
  sendButtonColor: '#143471',
  messageBackgroundColor: '#E3EDFF',
  supportButton: '#143471',
};

const schema = yup.object({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
});

const { REACT_APP_CONCIERGE_URL, REACT_APP_CONCIERGE_SECRETS } = process.env;

const SignInPage = () => {
  const router = useNavigate();

  const [error, setError] = useState();
  const [isSignInLoading, setIsSignInLoading] = useState(false);
  const [isBlockedModalOpen, setIsBlockedModalOpen] = useState(false);
  const [showSupportChat, setShowSupportChat] = useState(false);

  const { connectSocket } = useSelectedContext((context) => context.messenger);
  const { changeUserData } = useSelectedContext((context) => context.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const [emailAndTokenValidation] = useMutation(EMAIL_AND_TOKEN_VALIDATION);
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const [getLogin] = useMutation(GET_PRO_LOGIN, {
    variables: { input: {} },
    onError: (graphQLErrors) => {
      const loginError = `\n${graphQLErrors}`.split('Error:')[1];
      if (loginError.includes('Your account is currently blocked')) {
        setIsBlockedModalOpen(true);
      } else {
        setError(loginError);
      }
    },
  });

  const emailTokenValidation = async (token, email) => {
    const response = await emailAndTokenValidation({
      variables: {
        input: { token, email },
      },
    }).finally(() => {
      searchParams.delete('token');
      searchParams.delete('email');
      setSearchParams(searchParams);
    });

    if (
      !response.errors &&
      response.data.confirmEmail?.user?.is_draft &&
      response.data.confirmEmail.access_token
    ) {
      const domainValue = process.env.REACT_APP_DOMAIN;
      await Cookies.set('draft_userId', response.data.confirmEmail.user.id, {
        domain: domainValue,
      });
      await Cookies.set('access_token', response.data.confirmEmail.access_token, {
        domain: domainValue,
      });
      router(`/join/${response.data.confirmEmail.user.registration_role}/sign_up`);
    } else if (!response.errors && response.data.confirmEmail?.access_token) {
      const domainValue = process.env.REACT_APP_DOMAIN;
      Cookies.set('access_token', response.data.confirmEmail.access_token, { domain: domainValue });
      Cookies.set('userId', response.data.confirmEmail.user.id, { domain: domainValue });
      Cookies.set('calculatorId', response.data.confirmEmail.user.calculator_id, {
        domain: domainValue,
      });
      connectSocket(response.data.confirmEmail.user.id);
      router('/dashboard/feed');
    }
    setIsSignInLoading(false);
  };

  useEffect(() => {
    setIsSignInLoading(true);
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    if (token && email) {
      emailTokenValidation(token, email);
    } else {
      setIsSignInLoading(false);
    }
  }, []);

  const approved = async (data) => {
    setError('');
    const ee = await getLogin({
      variables: {
        input: data,
      },
    });
    if (ee.errors) {
      console.log('error');
    }
    if (!ee.errors && ee.data.login.user.is_draft) {
      const domainValue = process.env.REACT_APP_DOMAIN;
      await Cookies.set('draft_userId', ee.data.login.user.id, { domain: domainValue });
      await Cookies.set('access_token', ee.data.login.access_token, { domain: domainValue });
      router(`/join/${ee.data.login.user.registration_role}/sign_up`);
    } else if (!ee.errors && ee.data.login.access_token) {
      const domainValue = process.env.REACT_APP_DOMAIN;
      Cookies.set('access_token', ee.data.login.access_token, { domain: domainValue });
      Cookies.set('userId', ee.data.login.user.id, { domain: domainValue });
      Cookies.set('calculatorId', ee.data.login.user.calculator_id, { domain: domainValue });
      connectSocket(ee.data.login.user.id);
      router('/dashboard/feed');
    }
    changeUserData(ee.data.login.user);
  };

  const onSubmit = async (data) => {
    await approved(data);
  };
  return (
    <FormBody>
      <SupportChat
        them={laSalleGreenTheme}
        showSupportChat={showSupportChat}
        setShowSupportChat={setShowSupportChat}
        companyName="TT Concierge"
        apiUrl={REACT_APP_CONCIERGE_URL} // API url
        apiSecret={REACT_APP_CONCIERGE_SECRETS} // API Authorization token
        supportMail="support@transparenterra.com"
        user={null}
      />
      <FormItemLeft lines={FORM_TITLE_LINES} />
      <FormItemRight onSubmit={handleSubmit(onSubmit)} formName="login">
        <Input
          name="username"
          type="text"
          placeholder="E-mail or phone number *"
          errorText="Incorrect login"
          key="login"
          style={{
            borderBottomColor: errors.username?.message && '#bb1d20',
          }}
          onChange={(e) => setValue('username', e.target.value)}
        />
        <Input
          name="password"
          type="password"
          placeholder="Password *"
          key="password"
          style={{
            borderBottomColor: errors.password?.message && '#bb1d20',
          }}
          onChange={(e) => setValue('password', e.target.value)}
        />
        {!errors.password?.message && !errors.username?.message && error && (
          <div className={styles.loginError}>
            {error.trim() === 'Authentication exception' ? (
              <p>Username or password incorrect</p>
            ) : (
              <p>Server Error</p>
            )}
          </div>
        )}
        <div className={styles.formLinks}>
          <Link href="/reset">
            <p className={`${styles.formLink} ${styles.linkReset}`}>Reset password</p>
          </Link>
        </div>
        <Button
          type="white"
          isSubmit
          style={{ marginBottom: '40px', width: '100%', maxWidth: 'unset' }}
        >
          Sign in
        </Button>
        <div className={styles.formLinks}>
          <Link href="/join">
            <p className={`${styles.formLink} ${styles.linkReset}`}>Create an account</p>
          </Link>
        </div>
      </FormItemRight>
      <PartialLoader loading={isSignInLoading} />
      <BlockedModal
        open={isBlockedModalOpen}
        onClose={() => setIsBlockedModalOpen(false)}
        openSupport={() => {
          setIsBlockedModalOpen(false);
          setShowSupportChat(true);
        }}
      />
    </FormBody>
  );
};

export default SignInPage;
