import React, { useEffect, useState } from 'react';
import styles from './Comments.module.scss';
import { Avatar, IconButton, TextField } from '@mui/material';
import Comment from './Comment';
import { ClipIcon, SendIcon, SortAscIcon, SortDescIcon } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import { createPostComment, getPostFileUploadLink } from '../../../api/posts/endpoints/posts';
import { v1 as uuidv1 } from 'uuid';
import axios from 'axios';
import { useMutation } from '@apollo/client';
import { DELETE_FROM_PUBLIC } from '../../../api';
import { imageFileTypes } from '../../../constants/addPost';
import { FilePreview } from '../../Modals/NewPostModal/Components/Previews/FilePreview';
import Cookies from 'js-cookie';

const Comments = ({ user, comments, postId, setCommentsFetch }) => {
  const userID = typeof window !== 'undefined' ? Cookies.get('userId') : null;
  const [newCommentText, setNewCommentText] = useState('');
  const [orderComments, setOrderComments] = useState('new');
  const [allFiles, setAllFiles] = useState([]);
  const [isNewFilesAdded, setIsNewFilesAdded] = useState(false);
  const [commentInputError, setCommentInputError] = useState('');

  const [deletePublic] = useMutation(DELETE_FROM_PUBLIC);

  const updateFileInList = (newItem, id) => {
    setAllFiles((prevState) => {
      const items = [...prevState];
      const newItems = items.map((item) => {
        if (item.id === id) {
          return newItem;
        }
        return item;
      });

      return newItems;
    });
  };

  const setErrorMessage = (text) => {
    openNotification({
      message: text,
      type: 'error',
    });
  };

  const uploadFile = (item) => {
    if (!item) return;

    updateFileInList({ ...item, loading: true, error: false }, item.id);

    const type = item.file.type.split('/')[0];
    const fileExt = item.file.name.match(/\.([^.]+)$/)[1];
    const fileName = `${uuidv1()}.${fileExt}`;
    let path;
    let fileType = '';

    switch (type) {
      case 'image':
        path = `${userID}/comments/image_file`;
        fileType = 'image';
        break;
      case 'video':
        path = `${userID}/comments/video_file`;
        fileType = 'video';
        break;
      case 'audio':
        path = `${userID}/comments/audio_file`;
        fileType = 'audio';
        break;

      default:
        path = `${userID}/comments/doc_file`;
        fileType = 'doc';
        break;
    }

    const toUpload = { path: path, name: fileName };

    getPostFileUploadLink(toUpload)
      .then((response) => {
        const urlToUpload = response.data?.url;

        if (urlToUpload) {
          axios
            .put(urlToUpload, item.file, {
              headers: {
                'Content-type': item.file.type,
                'Access-Control-Allow-Origin': '*',
              },
            })
            .then((uploadResponse) => {
              if (uploadResponse.statusText === 'OK') {
                const updatedFile = {
                  ...item,
                  uuid: fileName,
                  url: `${'https://'}${response.data?.download_url}`,
                  loading: false,
                  fileType: fileType,
                };

                updateFileInList(updatedFile, item.id);
              }
            })
            .catch(() => {
              setErrorMessage('Error while uploading file');
              updateFileInList(
                { ...item, fileType: fileType, loading: false, error: true },
                item.id
              );
            });
        } else {
          setErrorMessage('Error while uploading file');
          updateFileInList({ ...item, fileType: fileType, loading: false, error: true }, item.id);
        }
      })
      .catch(() => {
        setErrorMessage('Error while uploading file');
        updateFileInList({ ...item, fileType: fileType, loading: false, error: true }, item.id);
      });
  };

  const createComment = (replyId = undefined) => {
    if ((!newCommentText || newCommentText === '') && allFiles.length === 0) {
      setCommentInputError('Comment can`t be empty');
      return;
    }

    const commentData = {
      post_id: postId,
      user_id: user.id,
      reply_user_id: replyId,
      body: newCommentText || '',
      files_image: [],
      files_video: [],
      files_audio: [],
      files_doc: [],
    };

    allFiles.forEach((value) => {
      const newPostFileData = { uuid_name: value.uuid, original_name: value.file.name };

      switch (value.fileType) {
        case 'image':
          commentData.files_image.push(newPostFileData);
          break;
        // case 'video':
        //   commentData.files_video.push(newPostFileData);
        //   break;
        // case 'audio':
        //   commentData.files_audio.push(newPostFileData);
        //   break;

        default:
          commentData.files_doc.push(newPostFileData);
          break;
      }
    });

    createPostComment(commentData)
      .then((response) => {
        if (response.status === 200) {
          setAllFiles([]);
          setNewCommentText('');
          setCommentsFetch(true);
        }
      })
      .catch((e) => {
        setErrorMessage('Error while sending comment. Try again later.');
      });
  };

  const handleRemoveFile = (fileId, uuid, url) => {
    const newSelectedFiles = allFiles.filter((item) => item.id !== fileId);
    setAllFiles(newSelectedFiles);

    const fileToDel = { path: url, name: uuid };
    deletePublic({ variables: { input: fileToDel } });
  };

  const uploadNewFiles = () => {
    allFiles.forEach((item) => {
      if (!item.loading && !item.url) {
        uploadFile(item);
      }
    });
  };

  const addUploadFiles = (files, uploadType) => {
    const selectedFilesArray = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileType = uploadType || file.type.split('/')[0];

      if (selectedFilesArray.length === 1) {
        setErrorMessage('Only up to 1 files can be selected');
        break;
      }

      if (fileType === 'image') {
        selectedFilesArray.push({
          file: file,
          preview: URL.createObjectURL(file),
          url: '',
          uuid: '',
          loading: false,
          error: false,
          id: `${file.name}_${file.size + file.lastModified}`,
        });
      }

      const allLength = selectedFilesArray.length + allFiles.length;
      if (allLength <= 1) {
        setAllFiles([...allFiles, ...selectedFilesArray]);
        setIsNewFilesAdded(true);
      }
    }
  };

  useEffect(() => {
    if (isNewFilesAdded) {
      uploadNewFiles();
      setIsNewFilesAdded(false);
    }
  }, [isNewFilesAdded]);

  return (
    <div className={styles.commentsContainer}>
      {user.id ? (
        <div className={styles.newCommentWrapper}>
          <div className={styles.newCommentBlock}>
            <Avatar src={user?.avatar} className={styles.avatar}>
              {user?.first_name
                ? `${user.first_name} ${user.last_name}`
                    .split(' ')
                    .map((i) => i[0])
                    .join('')
                : ''}
            </Avatar>
            <TextField
              className={`${
                commentInputError ? styles.newCommentInputError : styles.newCommentInput
              }`}
              placeholder="Comment..."
              value={newCommentText}
              onChange={(e) => {
                setCommentInputError('');
                setNewCommentText(e.target.value);
              }}
              error={!!commentInputError}
              helperText={commentInputError}
            />
            <IconButton className={styles.newCommentButton}>
              <input
                id="post-img-upload"
                type="file"
                hidden
                onChange={(e) => addUploadFiles(e.target.files || [], 'image')}
                accept={imageFileTypes}
              />
              <label
                htmlFor="post-img-upload"
                // className={disableImage ? styles.disabledUploadButton : ''}
              >
                <ClipIcon style={{ width: 24, height: 24 }} />
              </label>
            </IconButton>
            <IconButton
              className={styles.newCommentButton}
              onClick={() => {
                createComment();
              }}
            >
              <SendIcon style={{ width: 24, height: 24 }} />
            </IconButton>
          </div>
          <FilePreview
            selectedFiles={allFiles}
            handleRemoveFile={handleRemoveFile}
            handleFileUpload={uploadFile}
            updateFileMethod={updateFileInList}
            imageFullWidth
          />
        </div>
      ) : null}

      {comments.length > 0 ? (
        <>
          <div className={styles.filterContainer}>
            {orderComments === 'new' ? (
              <div onClick={() => setOrderComments('old')}>
                <SortDescIcon style={{ width: 22, height: 22 }} />
                <span>New first</span>
              </div>
            ) : (
              <div onClick={() => setOrderComments('new')}>
                <SortAscIcon style={{ width: 22, height: 22 }} />
                <span>Old first</span>
              </div>
            )}
          </div>

          <div className={styles.commentsWrapper}>
            {comments.map((item) => (
              <Comment
                key={item.id}
                postId={postId}
                commentInstance={item}
                user={user}
                replies={item.replies}
                setCommentsFetch={setCommentsFetch}
              />
            ))}
          </div>

          <div className={styles.commentsFooter}>
            {comments.length > 10 ? (
              <div className={styles.commentsShowMore}>View more</div>
            ) : (
              <div />
            )}
            <div>
              {comments.length > 10 ? comments.length / 10 : comments.length} of {comments.length}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Comments;
