import {
  SET_APP_LOADING,
  SET_APP_ERROR,
  SET_APP_COVER_LOADING,
  SET_APP_COVER_SECOND_LOADING,
} from '../types';

const appReducer = (state, action) => {
  switch (action.type) {
    case SET_APP_COVER_LOADING:
      return {
        ...state,
        coverLoading: action.payload,
      };
    case SET_APP_COVER_SECOND_LOADING:
      return {
        ...state,
        coverSecondLoading: action.payload,
      };
    case SET_APP_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_APP_ERROR:
      return {
        ...state,
        isError: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
