import clsx from 'clsx';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import styles from './SharingResourceModal.module.scss';
import infoStyles from './InfoModal.module.scss';
import { ReactComponent as IcoLink } from '../../../assets/svg/ph_link.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { HelpTooltip, Select, Modal, CheckBox, Button, TextInput } from 'tt-ui-kit';
import SharingUserSearchSelect from './SharingUserSearchSelect';
import TextField from '@mui/material/TextField';

import {
  SHARING_TYPES,
  GET_USER_COMPANIES,
  GET_SHARING_BY_ID,
  CREATE_SHARING,
  UPDATE_SHARING,
} from '../../../api';
import { useSelectedContext } from 'context/contextSelector';
import { Radio, Link, RadioGroup, FormControlLabel, MenuItem } from '@mui/material';
import SharingAccessContent from './SharingAccessContent';

const tooltipTexts = {
  accessLevel: `Configure access to your data: public, private,
    or permission-based. You have the ability to selectively
    share data with specific individuals, determine the value
    of the disclosed data, and request a pre signature
    non-disclosure agreement (NDA).`,
  autoApprove: `To automate the approval process, you can use a
    checkbox. This will allow you to avoid manually approving access
    to your data.`,
  requestNDA: `Prioritizing the implementation of a Non-Disclosure
    Agreement (NDA) before providing access to sensitive data can
    effectively protect privacy and information. As a requirement,
    any external party requesting access to such data must affix
    their signature on the NDA. Once the signed NDA is received,
    a notification will be issued to confirm its receipt`,
};

const modes = { MAIN: 'mainFrame', PERSONAL: 'personalShare', MANAGEMENT: 'accessManage' };
const currencies = ['USD', 'EUR', 'GBP'];

const SharingResourceModal = ({ sharingId, type, targetId, open, onClose }) => {
  const windowWidth = useRef(window.innerWidth);
  const [isValid, setIsValid] = useState(false);
  const [sharingTypes, setSharingTypes] = useState([]);
  const [selectedSharingType, setSelectedSharingType] = useState('');
  const [sharingTypeMode, setSharingTypeMode] = useState({});
  const [accessType, setAccessType] = useState('free');
  const [price, setPrice] = useState(0.01);
  const [currency, setCurrency] = useState('USD');
  const [autoapprove, setAutoapprove] = useState(false);
  const [needNDA, setNeedNDA] = useState(false);
  const [isCompanyExist, setIsCompanyExist] = useState(false);
  const [isOkModalOpened, setIsOkModalOpened] = useState(false);

  const [sharing, setSharing] = useState(false);

  const { user } = useSelectedContext((context) => context.user);

  const { data: sharingTypesData } = useQuery(SHARING_TYPES);

  const [getCompany, { data: userCompanies }] = useLazyQuery(GET_USER_COMPANIES, {
    variables: { user_id: user.market_id },
  });
  const [getSharing, { data: sharingData }] = useLazyQuery(GET_SHARING_BY_ID);

  const [createSharing, { data: createdSharing }] = useMutation(CREATE_SHARING);
  const [updateSharing, { data: updatedSharing }] = useMutation(UPDATE_SHARING);

  const [viewTypeMode, setViewTypeMode] = useState(modes.MAIN);
  const returnToMain = () => setViewTypeMode(modes.MAIN);

  const onCloseModal = () => {
    if (viewTypeMode !== modes.MAIN) returnToMain();
    else onClose();
  };

  const saveSharing = async () => {
    try {
      if (!sharingId) {
        const newSharing = await createSharing({
          variables: {
            input: {
              user_id: user.id,
              security_type_id: selectedSharingType,
              target_id: targetId,
              target_type: type.toUpperCase(),
              is_auto_approve: autoapprove,
              is_need_pay: accessType === 'paid',
              is_need_nda: needNDA,
              price: price,
              currency: currency,
            },
          },
        });
        if (newSharing) {
          setSharing(newSharing.data.createSharing);
        }
      } else {
        const updSharing = await updateSharing({
          variables: {
            input: {
              id: sharingId,
              security_type_id: selectedSharingType,
              is_auto_approve: autoapprove,
              is_need_pay: accessType === 'paid',
              is_need_nda: needNDA,
              price: price,
              currency: currency,
            },
          },
        });
        if (updSharing) {
          setSharing(updSharing.data.updateSharing);
        }
      }
      setIsOkModalOpened(true);
    } catch (e) {
      console.log(e);
      // TODO: показывать ошибку, если запрос не прошёл
      // см. комментарий в Local.jsx
    }
  };

  useEffect(() => {
    setIsValid(accessType === 'free' || (accessType === 'paid' && price > 0));
  }, [accessType, price]);

  useEffect(() => {
    if (sharingId) {
      getSharing({ variables: { id: sharingId } });
    }
  }, []);

  useEffect(() => {
    if (!sharingData || !sharingData.getSharingById) return;
    if (sharingData.getSharingById) {
      const newSharing = sharingData.getSharingById;

      setSharing(newSharing);
      if (newSharing.securityType.id) {
        setSelectedSharingType(newSharing.securityType.id);
      }
      setCurrency(newSharing.currency ?? 'USD');
      setAccessType(newSharing.isNeedPay ? 'paid' : 'free');
      setPrice(newSharing.price);
      setAutoapprove(newSharing.isAutoApprove);
      setNeedNDA(newSharing.isNeedNDA);
    }
  }, [sharingData]);

  useEffect(() => {
    if (!user.id) return;
    getCompany();
  }, [user]);

  useEffect(() => {
    if (!user.id || !userCompanies || !userCompanies.showAllCompaniesByUserId) return;
    if (userCompanies.showAllCompaniesByUserId) {
      setIsCompanyExist(userCompanies.showAllCompaniesByUserId.length > 0);
    }
  }, [userCompanies]);

  useEffect(() => {
    if (!createdSharing || !createdSharing.createSharing) return;
    if (createdSharing.createSharing) {
      getSharing({ variables: { id: createdSharing.id } });
    }
  }, [createdSharing]);

  useEffect(() => {
    if (!updatedSharing || !updatedSharing.updateSharing) return;
    if (updatedSharing.updateSharing) {
      getSharing({ variables: { id: updatedSharing.id } });
    }
  }, [updatedSharing]);

  useEffect(() => {
    if (sharingTypesData) {
      const newSecurityTypes = sharingTypesData.getSharingSecurityType.map((el) => ({
        name: el.name,
        value: el.id,
        code: el.code,
      }));
      setSharingTypes(newSecurityTypes);
      if (!sharingId) {
        const initTypeId = sharingTypesData.getSharingSecurityType.find(
          (el) => el.code === 'private'
        )?.id;
        if (initTypeId) setSelectedSharingType(initTypeId);
      }
    }
  }, [sharingTypesData]);

  useEffect(() => {
    const mode = sharingTypes.reduce(
      (res, el) => ({
        ...res,
        [el.code]: selectedSharingType === el.value,
      }),
      {}
    );
    setSharingTypeMode(mode);
  }, [selectedSharingType, sharingTypes]);

  const changePrice = (e) => setPrice(e.target.value);

  const changeSharingType = (e) => setSelectedSharingType(e.target.value);

  const changeCurrency = (e) => setCurrency(e.target.value);

  const renderSelect = (value) => sharingTypes.find((el) => el.value === value)?.name ?? '';

  const getMainFrame = () => (
    <div className={styles.content}>
      <div>
        <div>
          <h4>Access settings</h4>
        </div>
        <HelpTooltip tooltipText={tooltipTexts.accessLevel} />
      </div>
      <div className={clsx(styles.paddings, styles.selectAccess)}>
        <Select
          label="Access type"
          name="access type"
          renderValue={(value) => renderSelect(value)}
          onChange={changeSharingType}
          value={selectedSharingType}
          items={sharingTypes}
        />
      </div>
      {sharingTypeMode.permited && (
        <>
          <div className={styles.paddings}>
            <RadioGroup row value={accessType} onChange={(e) => setAccessType(e.target.value)}>
              <FormControlLabel value="free" control={<Radio />} label="Free access" />
              <FormControlLabel
                value="paid"
                disabled={!isCompanyExist}
                control={<Radio />}
                label="Paid access"
              />
            </RadioGroup>
          </div>
          {accessType === 'paid' && (
            <div className={clsx(styles.paidBlock, styles.wide)}>
              <TextInput
                className={styles.paidInput}
                name="price"
                type="number"
                onChange={changePrice}
                required={accessType === 'paid'}
                value={price}
                filter={/^(?!0+([.,]00?)?$)\d+(?:[.,]\d+)?$/}
                InputProps={{
                  endAdornment: (
                    <TextField
                      className={styles.paidSelect}
                      select
                      name="currency"
                      onChange={changeCurrency}
                      value={currency}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    >
                      {currencies.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  ),
                }}
              />
            </div>
          )}
          <div>
            <CheckBox
              name="autoapprove"
              label="Approve automatically"
              value={autoapprove}
              onChange={() => setAutoapprove((value) => !value)}
            />
            <HelpTooltip tooltipText={tooltipTexts.autoApprove} />
          </div>
          <div>
            <CheckBox
              name="needNda"
              label="Request NDA before access is granted"
              value={needNDA}
              onChange={() => setNeedNDA((value) => !value)}
            />
            <HelpTooltip tooltipText={tooltipTexts.requestNDA} />
          </div>
        </>
      )}
      {(sharingTypeMode.permited || sharingTypeMode.private) && (
        <div className={clsx(styles.wide, sharingTypeMode.private && styles.paddings)}>
          <div>Access management</div>
          <Link onClick={() => setViewTypeMode(modes.MANAGEMENT)}>Manage</Link>
        </div>
      )}
      <div className={clsx(styles.wide, styles.paddings, styles.buttons)}>
        {(sharingTypeMode.permited || sharingTypeMode.private) && (
          <Button
            type="default"
            onClick={() => setViewTypeMode(modes.PERSONAL)}
            disabled={!sharing?.id}
          >
            {windowWidth.current >= 1024 ? 'Create personal access' : 'Personal access'}
          </Button>
        )}
        {sharingTypeMode.public && (
          <div className={styles.publicButtons}>
            <Button
              type="default"
              onClick={() => setViewTypeMode(modes.PERSONAL)}
              disabled={!sharing?.id}
            >
              Share
            </Button>
            <Button
              type="default"
              onClick={() => navigator.clipboard.writeText('http://TODO_LINK')}
              disabled={!sharing?.id}
            >
              <IcoLink />
              Copy link
            </Button>
          </div>
        )}
        <Button type="primary" onClick={() => saveSharing()} disabled={!isValid}>
          {`${!sharing?.id ? 'Create' : 'Save'}`}
        </Button>
      </div>
    </div>
  );

  const getHeader = () => {
    if (viewTypeMode === modes.MAIN) {
      return 'Share';
    }
    return (
      <div className={styles.headerBlock}>
        <Button type="icon" onClick={() => returnToMain()}>
          <ArrowBackIosIcon />
        </Button>
        <h3>{viewTypeMode === modes.PERSONAL ? 'Personal Access' : 'Access Management'}</h3>
      </div>
    );
  };

  return (
    <>
      <Modal
        open={isOkModalOpened}
        onClose={() => setIsOkModalOpened(false)}
        className={infoStyles.infoModal}
      >
        <p style={{ marginBottom: 20 }}>The sharing was created successfully!</p>
        <Button onClick={() => setIsOkModalOpened(false)}>OK</Button>
      </Modal>
      <Modal
        open={open}
        className={styles.modal}
        onClose={() => onCloseModal()}
        onClick={(e) => e.stopPropagation()}
        title={getHeader()}
      >
        {viewTypeMode === modes.MAIN && getMainFrame()}
        {viewTypeMode === modes.PERSONAL && (
          <SharingUserSearchSelect sharing={sharing} goBack={returnToMain} />
        )}
        {viewTypeMode === modes.MANAGEMENT && <SharingAccessContent sharingId={sharing.id} />}
      </Modal>
    </>
  );
};

export default SharingResourceModal;
