import React, { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import VideoBg from 'components/layout/VideoBg';
import Header from 'components/layout/Header';
import StepItem from 'components/StepItem';
import { useWindowSize } from 'utils/useWindowSize';
import { EXTRA_ROLES_LIST } from 'constants/joinPage';
import { FourOhFour } from 'views';
import { getPlatform } from 'utils/defineUserPlatform';
import styles from './RolePage.module.scss';
import FirstStepContent from './FirstStepContent';

const MAX_MOBILE_WIDTH = 678;
const FIRST_STEP = 1;
const LAST_STEP = 6;

const RolePage = () => {
  const [activeStep, setActiveStep] = useState(FIRST_STEP);
  const [touchStart, setTouchStart] = useState(null);
  const [roleData, setRoleData] = useState();
  const [isFourOhFour, setIsFourOhFour] = useState(false);
  const [code, setCode] = useState('');

  const windowSize = useWindowSize();
  const { role } = useParams();
  const platform = getPlatform();
  const urlParams = useSearchParams();

  let prevTime = new Date().getTime();

  useEffect(() => {
    if (urlParams) {
      const codeParam = urlParams[0].get('code');

      if (codeParam) {
        setCode(codeParam);
      }
    }
  }, []);

  const macintoshChangeStepOnWheel = (e, step, setStep) => {
    const curTime = new Date().getTime();
    if (typeof prevTime !== 'undefined') {
      const timeDiff = curTime - prevTime;
      if (timeDiff > 200) {
        if (e.deltaY > 0 && step < LAST_STEP) {
          setStep(step + 1);
        } else if (e.deltaY < 0 && step > FIRST_STEP) {
          setStep(step - 1);
        }
      }
    }
    prevTime = curTime;
  };

  const changeStepOnWheel = (e, step, setStep) => {
    if (e.deltaY > 0 && step < LAST_STEP) {
      setStep(step + 1);
    } else if (e.deltaY < 0 && step > FIRST_STEP) {
      setStep(step - 1);
    }
  };

  const handleOnTouchStart = (e) => {
    const start = e.changedTouches[0];
    setTouchStart(start);
  };

  const handleOnTouchEnd = (e, step, setStep) => {
    const end = e.changedTouches[0];
    if (end.screenY - touchStart.screenY < 0 && step < LAST_STEP) {
      setStep(step + 1);
    } else if (end.screenY - touchStart.screenY > 0 && step > FIRST_STEP) {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    if (role) {
      setIsFourOhFour(false);
      const data = EXTRA_ROLES_LIST.find((d) => d.roleName === role);
      if (data) {
        setRoleData(data);
      } else {
        setIsFourOhFour(true);
      }
    }
  }, [role]);

  return (
    <>
      {roleData && (
        <div
          className={styles.sectionsWrapper}
          onWheel={
            platform?.device === 'Macintosh'
              ? (e) => macintoshChangeStepOnWheel(e, activeStep, setActiveStep)
              : (e) => changeStepOnWheel(e, activeStep, setActiveStep)
          }
          onTouchStart={(e) => handleOnTouchStart(e)}
          onTouchEnd={(e) => handleOnTouchEnd(e, activeStep, setActiveStep)}
        >
          <Header isHeaderNav isRolePage />
          <VideoBg
            src={roleData.videoPath}
            srcImg={roleData.bgImgPath}
            isAutoPlay={windowSize.width > MAX_MOBILE_WIDTH}
          />
          <section className={styles.roles}>
            {roleData.sectionsContent.map((stepContent, index) => (
              <div key={stepContent.step}>
                {index === 0 && activeStep === 1 ? (
                  <FirstStepContent roleData={roleData} />
                ) : (
                  <div
                    className={`${styles.rolePageContent} ${
                      stepContent.step === activeStep && styles.active
                    }`}
                    key={stepContent.step}
                  >
                    <div className={styles.roleTitleLeft}>
                      <p>{roleData.roleName}</p>
                    </div>
                    <div
                      className={styles.content}
                      dangerouslySetInnerHTML={{ __html: `${stepContent.content}` }}
                    />
                    {activeStep === LAST_STEP && (
                      <div className={styles.buttonWrapper}>
                        <Link
                          to={`/join/${roleData.roleName}/sign_up${
                            code ? `?direct_code=${code}` : ''
                          }`}
                          passhref="true"
                        >
                          <div className={styles.signUpBtn}>Sign up</div>
                        </Link>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
            <nav className={styles.stepWrapper}>
              {roleData.sectionsContent.map((stepContent) => (
                <StepItem
                  id={stepContent.step}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  roleIndex={roleData.index}
                  key={stepContent.step + roleData.index}
                  isClickable
                />
              ))}
            </nav>
          </section>
        </div>
      )}
      {isFourOhFour && <FourOhFour />}
    </>
  );
};

export default RolePage;
