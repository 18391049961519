import React, { useState } from 'react';
import { SignInUpModalWrapper } from 'components/Modals/SignInUpModal/SignInUpModal';
import { MobileNavbar } from '../../modules/tt-mobile-navmenu';
import { InviteModal } from '../../modules/tt-modals';
import { GET_ROLE, SEND_INVITE } from '../../api';
import { useNavigate } from 'react-router';
import { useSelectedContext } from 'context/contextSelector';
import { URL_CALCS } from 'constants/calculatorsPage';

export const MobileNavbarWrapper = () => {
  const { user } = useSelectedContext((context) => context.user);
  const [isInviteModal, setInviteModal] = useState(false);
  const [anchorLoginEl, setAnchorLoginEl] = useState(null);
  const open = Boolean(anchorLoginEl);
  const navigate = useNavigate();

  const onCloseInviteModal = () => setInviteModal(false);

  const handleLoginClose = () => {
    setAnchorLoginEl(null);
  };

  return (
    <div>
      <MobileNavbar
        user={user}
        setAnchorLoginEl={setAnchorLoginEl}
        createAnInvitation={() => setInviteModal(true)}
        openCalculator={() => navigate(URL_CALCS)}
        openCalendar={() => {
          console.log('Calendar');
        }}
        openBookmark={() => {
          console.log('Bookmark');
        }}
        homeRedirectUrl="/dashboard/feed"
        marketTTUrl={process.env.REACT_APP_MARKET || ''}
        digitalAssetsTTUrl={process.env.REACT_APP_DIGITAL_ASSETS || ''}
        qaTTUrl={process.env.REACT_APP_QA}
      />
      <SignInUpModalWrapper open={open} closeModal={handleLoginClose} />
      <InviteModal
        visible={isInviteModal}
        title="Create an invitation"
        onClose={onCloseInviteModal}
        roleQuery={GET_ROLE}
        sendInviteQuery={SEND_INVITE}
      />
    </div>
  );
};
