/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import zxcvbn from 'zxcvbn';
import styles from './PasswordStrengthMeter.module.scss';

const PasswordStrengthMeter = ({ password, description }) => {
  const [testedResult, setTestedResult] = useState(null);
  const [strength, setStrength] = useState(null);

  const createPasswordLabel = (result) => {
    if (result) {
      switch (result.score) {
        case 0:
          setStrength('weak');
          break;
        case 1:
          setStrength('weak');
          break;
        case 2:
          setStrength('good');
          break;
        case 3:
          setStrength('strong');
          break;
        case 4:
          setStrength('strong');
          break;
        default:
          setStrength('weak');
      }
    }
  };

  const allCharactersSame = (s) => {
    const n = s.length;
    for (let i = 1; i < n; i++) {
      if (s[i] !== s[0]) return false;
    }

    return true;
  };

  useEffect(() => {
    if (password && password !== '') {
      if (password.length > 1 && allCharactersSame(password)) {
        setTestedResult({ password, score: 0 });
      } else {
        setTestedResult(zxcvbn(password));
      }
    } else {
      setTestedResult(null);
    }
  }, [password]);

  useEffect(() => {
    if (password) {
      createPasswordLabel(testedResult);
    }
  }, [testedResult]);

  return (
    <div className={styles.indicatorWrapper}>
      <label className={`${styles.label} ${styles[strength]}`}>
        {testedResult && strength && (
          <>
            <span className={styles.strength}>{strength}</span> <span>password</span>
          </>
        )}
        {(!password || password === '') && <div>{description}</div>}
      </label>
      <progress
        className={`${styles.progress} ${styles[strength]}`}
        value={(testedResult && testedResult.score) || 0}
        max="3"
      />
    </div>
  );
};

export default PasswordStrengthMeter;
