/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState, useRef } from 'react';
import { Image } from 'tt-ui-kit';
import { Avatar } from '@mui/material';
import styles from './Participant.module.scss';

const Participant = ({
  deviceExist,
  participant,
  participantAudioMuted,
  participantVideoOff,
  localeAudioMuted,
  localeVideoOff,
  user,
  isLocale,
}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    const trackSubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((tracks) => [...tracks, track]);
      } else if (track.kind === 'audio') {
        setAudioTracks((tracks) => [...tracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((tracks) => tracks.filter((v) => v !== track));
      } else if (track.kind === 'audio') {
        setAudioTracks((tracks) => tracks.filter((a) => a !== track));
      }
    };

    if (!deviceExist.includes('audio')) {
      setAudioTracks(trackpubsToTracks(participant.audioTracks));
    }

    if (!deviceExist.includes('video')) {
      setVideoTracks(trackpubsToTracks(participant.videoTracks));
    }

    if (!deviceExist.includes('video') || !deviceExist.includes('audio')) {
      participant.on('trackSubscribed', trackSubscribed);
      participant.on('trackUnsubscribed', trackUnsubscribed);

      if (isLocale) {
        participant.on('trackPublished', (track) => trackSubscribed(track.track));
      }

      return () => {
        setVideoTracks([]);
        setAudioTracks([]);
        participant.removeAllListeners();
      };
    }
  }, [participant]);

  useEffect(() => {
    const firstVideoTrack = videoTracks[0];
    if (firstVideoTrack) {
      firstVideoTrack.attach(videoRef.current);
      return () => {
        firstVideoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <div className={styles.participant}>
      {participantAudioMuted && !isLocale && (
        <div className={styles.micIcon}>
          <Image src="/images/chat/mic-white-mute-icon.svg" width={13} height={13} />
        </div>
      )}
      {user && !isLocale && (
        <div className={styles.userName}>{`${user.first_name} ${user.last_name}`}</div>
      )}
      {participantVideoOff && (
        <div className={styles.videoOffContent}>
          <div>
            {user && (
              <div className={styles.userAvatar}>
                <Avatar sx={{ width: 100, height: 100 }} src={user.avatar ? user.avatar : null}>
                  {`${user.first_name.slice(0, 2)} ${user?.last_name?.slice(0, 2)}`}
                </Avatar>
              </div>
            )}
            <p className={styles.webOnline}>Meet “WebOnline”</p>
          </div>
        </div>
      )}
      <video
        className={`${styles.video} ${localeVideoOff ? styles.videoHide : ''}`}
        ref={videoRef}
        autoPlay={true}
      />
      <audio className={styles.audio} ref={audioRef} autoPlay={true} muted={false} />
    </div>
  );
};

export default Participant;
