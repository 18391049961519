/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
export const isEmptyObj = (object) => {
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};
