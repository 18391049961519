import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import ReactPhoneInput from 'react-phone-input-2';
import { FormControl, Button, Modal, PartialLoader, Select, SendIcon, TextInput } from 'tt-ui-kit';
import styles from './Modal.module.scss';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { parseErrors } from '../../utils/validation';
import { openNotification } from 'tt-ui-lib/core';
import * as yup from 'yup';

const whitespacesReg = /^\s*\S.*$/;
const specialCharReg = /^[^<>'"/;`%]*$/;
const numberReg = /^([^0-9]*)$/;

const schema = yup.object({
  first_name: yup
    .string()
    .required('The first name field must not be empty.')
    .max(255, 'Too many characters.')
    .matches(whitespacesReg, 'The field cannot contain only spaces.')
    .matches(specialCharReg, 'Special characters are prohibited.')
    .matches(numberReg, 'Numbers are prohibited.'),
  last_name: yup
    .string()
    .max(255, 'Too many characters.')
    .matches(whitespacesReg, 'The field cannot contain only spaces.')
    .matches(specialCharReg, 'Special characters are prohibited.')
    .matches(numberReg, 'Numbers are prohibited.'),
  role_id: yup.string().required('This is a required field.'),
  email: yup
    .string()
    .required('Please enter your email address.')
    .email('Please enter a valid email address, e.g. joe@mail.com.'),
});

export const ModalInv = ({
  visible = false,
  title = '',
  onClose,
  roleQuery,
  sendInviteQuery,
  setInvitations = false,
}) => {
  const [roleData, setRoleData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [phoneInputLabel, setPhoneInputLabel] = useState('');
  const [getRoles] = useLazyQuery(roleQuery);
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      type: 0,
      first_name: '',
      last_name: '',
      email: '',
      tel: '',
      role_id: '',
      comment: '',
      note: '',
      max_count: 1,
    },
  });

  const [sendModal] = useMutation(sendInviteQuery, {
    onError: (graphQLErrors) => {
      const errorsList = parseErrors({ errors: graphQLErrors });
      if (errorsList.email) {
        setError('email', { type: 'custom', message: 'The email has already been taken.' });
      }
    },
  });

  const getRolesFromApi = async () => {
    const roleBase = await getRoles();
    setRoleData(roleBase?.data?.roles || []);
  };

  const Close = () => {
    onClose();
  };

  useEffect(() => {
    getRolesFromApi().then();
  }, []);

  const submit = async (formData) => {
    setLoader(true);
    const data = await sendModal({ variables: { input: formData } });

    if (data?.errors) {
      openNotification({
        message: 'Something went wrong, try again',
        type: 'error',
      });
      setLoader(false);
    }

    if (!data?.errors) {
      if (setInvitations) {
        setInvitations((current) => [data.data.sendInvitation.invitation, ...current.slice(0, -1)]);
      }
      openNotification({
        message: 'Your invitation has been sent successfully',
        type: 'success',
      });
      setLoader(false);
      reset();
      openNotification({
        message: 'Invitation sent successfully !',
        type: 'success',
      });
      Close();
    }
  };

  const validateBeforeSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(async (data) => {
      await submit(data);
    })();
  };

  const onChangeInput = (name, value) => {
    setValue(name, value);
  };

  const renderSelectValue = (val) => {
    const roles =
      roleData?.map((element) => ({
        name: element.name.charAt(0).toUpperCase() + element.name.slice(1),
        value: element.id,
      })) || [];
    const roleItem = roles.find((item) => item.value === val);
    return roleItem?.name || null;
  };

  return (
    <Modal
      open={visible}
      onClose={() => onClose()}
      onClick={(e) => e.stopPropagation()}
      title={title}
    >
      <form
        onSubmit={async (e) => {
          await validateBeforeSubmit(e);
        }}
        className={styles.modalBody}
      >
        <div className={styles.modal_content}>
          <div className={styles.modal_content_first}>
            <div className={styles.row}>
              <div className={styles.modal_input}>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <TextInput
                      {...field}
                      required={false}
                      label="First Name"
                      value={value}
                      disableUnderline
                      onChange={(e) => onChangeInput('first_name', e.target.value)}
                      style={{ width: '100%' }}
                    />
                  )}
                />
                {errors.first_name?.message && (
                  <p className={styles.errorMessage}>{errors.first_name?.message}</p>
                )}
              </div>
              <div className={styles.modal_input}>
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <TextInput
                      {...field}
                      required={false}
                      label="Last Name"
                      value={value}
                      disableUnderline
                      onChange={(e) => onChangeInput('last_name', e.target.value)}
                      style={{ width: '100%' }}
                    />
                  )}
                />
                {errors.last_name?.message && (
                  <p className={styles.errorMessage}>{errors.last_name?.message}</p>
                )}
              </div>
              <FormControl>
                <div className={styles.modal_select}>
                  <Controller
                    name="role_id"
                    control={control}
                    render={({ field: { value, onChange, ...field } }) => (
                      <FormControl type="select" className={styles.select_container}>
                        <Select
                          {...field}
                          value={value}
                          sx={{ '.MuiFormControl-root': { width: '100%' } }}
                          items={
                            roleData?.map((element) => ({
                              name: element.name.charAt(0).toUpperCase() + element.name.slice(1),
                              value: element.id,
                            })) || []
                          }
                          renderValue={renderSelectValue}
                          label="Suggest a role"
                          onChange={(e) => onChangeInput('role_id', e.target.value)}
                          size="medium"
                        />
                      </FormControl>
                    )}
                  />
                  {errors.role_id?.message && (
                    <p className={styles.errorMessage}>{errors.role_id?.message}</p>
                  )}
                </div>
              </FormControl>
            </div>
            <div className={styles.row}>
              <div className={styles.modal_input} style={{ width: '360px' }}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <TextInput
                      {...field}
                      required={false}
                      label="Email*"
                      value={value}
                      disableUnderline
                      onChange={(e) => onChangeInput('email', e.target.value)}
                      style={{ width: '100%' }}
                    />
                  )}
                />
                {errors.email?.message && (
                  <p className={styles.errorMessage}>{errors.email?.message}</p>
                )}
              </div>
              <div>
                <Controller
                  name="tel"
                  control={control}
                  render={({ field: { value } }) => (
                    <ReactPhoneInput
                      buttonClass={styles.phone}
                      inputClass={styles.phoneInput}
                      value={value}
                      onChange={(el) => onChangeInput('tel', el)}
                      onFocus={() => setPhoneInputLabel('Phone number')}
                      onBlur={() => setPhoneInputLabel('')}
                      specialLabel={value ? 'Phone number' : phoneInputLabel}
                      placeholder="Phone number"
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div>
            <Controller
              name="comment"
              control={control}
              render={({ field: { value } }) => (
                <textarea
                  style={{ padding: '10px' }}
                  className={styles.modal_textArea}
                  inputMode="text"
                  placeholder="Comment for the invitee"
                  value={value}
                  onChange={(e) => onChangeInput('comment', e.target.value)}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="note"
              control={control}
              render={({ field: { value } }) => (
                <textarea
                  style={{ padding: '10px' }}
                  className={styles.modal_textArea}
                  placeholder="Note for me"
                  value={value}
                  onChange={(e) => onChangeInput('note', e.target.value)}
                />
              )}
            />
          </div>
        </div>
        <div className={styles.modal_button}>
          <div style={{ display: 'flex', justifyItems: 'flex-start' }}>
            <Button type="default" onClick={Close}>
              CANCEL
            </Button>
          </div>
          <div style={{ display: 'flex', justifyItems: 'flex-end' }}>
            <Button className={styles.modal_buttonSend} disabled={loader} type="primary" isSubmit>
              <SendIcon className={styles.sendIcon} />
              SEND
            </Button>
          </div>
        </div>
      </form>

      {loader === true && (
        <div>
          <PartialLoader />
        </div>
      )}
    </Modal>
  );
};
