import styles from './CompanyNameConsentModal.module.scss';
import ConsentModal from '../ConsentModal';

const CompanyNameConsentModal = ({
  isModelOpen,
  setIsOpen,
  handleClose,
  setIsAgree,
  isAgree,
  checkboxText,
  text,
}) => (
  <ConsentModal
    isModelOpen={isModelOpen}
    setIsOpen={setIsOpen}
    handleClose={handleClose}
    setIsAgree={setIsAgree}
    isAgree={isAgree}
    checkboxText={checkboxText}
  >
    <div className={styles.text}>{text}</div>
  </ConsentModal>
);

export default CompanyNameConsentModal;
