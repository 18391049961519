/* eslint-disable no-nested-ternary */
import { Image, CloseCrossIcon, FileDownloadedIcon, ReloadIcon } from 'tt-ui-kit';
import clsx from 'clsx';
import styles from './UploadedFile.module.scss';

const UPLOADING_STATUS = 'uploading';
const UPLOADED_STATUS = 'uploaded';
const ERROR_STATUS = 'error';
const UPLOADING_ICON = '/images/templates/add-doc-grey.png';
const UPLOADED_ICON = '/images/templates/add-doc-green.svg';
const ERROR_ICON = '/images/templates/add-doc-red.png';

const UploadedFile = ({ files, file, index, deleteFile, checkNameLength, isMultiple }) => (
  <>
    {file && (
      <div
        className={clsx(
          styles.uploadedFileWrapper,
          file.status === UPLOADING_STATUS ? styles.uploading : '',
          file.status === UPLOADED_STATUS ? styles.uploaded : '',
          file.status === ERROR_STATUS ? styles.error : ''
        )}
      >
        <div className={styles.uploadedIconWrapper}>
          {file.status === UPLOADING_STATUS ? (
            <FileDownloadedIcon style={{ width: 20, height: 20 }} className={styles.defaultIcon} />
          ) : file.status === UPLOADED_STATUS ? (
            <FileDownloadedIcon style={{ width: 20, height: 20 }} className={styles.successIcon} />
          ) : file.status === ERROR_STATUS ? (
            <ReloadIcon style={{ width: 20, height: 20 }} className={styles.errorIcon} />
          ) : null}
        </div>
        <div className={styles.fileName}>{checkNameLength(file.oldName)}</div>
        <div
          className={styles.closeIconWrapper}
          onClick={(e) => deleteFile(e, files, file)}
          aria-hidden
        >
          <CloseCrossIcon style={{ width: 20, height: 20 }} />
        </div>
      </div>
    )}
  </>
);

export default UploadedFile;
