/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
  IconButton,
  Divider,
  InputBase,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import { CirclePicker } from 'react-color';
import { COLORS } from 'constants/colorPicker';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styles from '../ChatCategories.module.scss';
import { Button, Modal, Image } from 'tt-ui-kit';
import { style } from '@mui/system';
import styled from '@emotion/styled';

export const CreateChatCategory = ({
  open = false,
  onClose,
  searchValue,
  setSearchValue,
  chats = [],
  create,
  changeFormData,
  formData = {},
  onScrollUsers,
  getMore,
}) => {
  const defaultColor = '#C4C4C4';
  const [isColorPicker, setIsColorPicker] = useState(false);
  const [color, setColor] = useState(defaultColor);
  const [isDarkColor, setIsDarkColor] = useState(false);
  const [errorName, setErrorName] = useState(false);

  const resetForm = () => {
    changeFormData('name', '');
    changeFormData('color', '');
    changeFormData('chats', []);
    setIsColorPicker(false);
    setColor(defaultColor);
  };

  const back = () => {
    if (!isColorPicker) {
      onClose();
      resetForm();
    }
    setIsColorPicker(false);
  };

  const changeColor = (val) => {
    if (val) {
      const selectedColor = COLORS.find((item) => item.color === val.hex);
      setColor(selectedColor.color);
      setIsDarkColor(selectedColor.isDark);
    }
  };

  const createChatFolder = () => {
    if (formData.name !== '') {
      if (formData.name.length <= 15) {
        resetForm();
        onClose();
        create();
        setErrorName(false);
      } else {
        setErrorName(true);
      }
    } else {
      setErrorName(true);
    }
  };

  const closeDialog = () => {
    setColor(defaultColor);
    onClose();
  };

  const saveColor = () => {
    if (color !== '') {
      setIsColorPicker(false);
      changeFormData('color', color);
    }
  };

  const appendChat = (id) => {
    const index = formData.chats.findIndex((item) => item === id);
    if (index >= 0) {
      const chatsList = [...formData.chats];
      chatsList.splice(index, 1);
      changeFormData('chats', chatsList);
    } else {
      const chatsList = [...formData.chats, id];
      changeFormData('chats', chatsList);
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeDialog}
      title="Create new folder"
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.dialogContent}>
        {!isColorPicker && (
          <div>
            <div className={styles.folderNameInputContent}>
              <IconButton
                style={{ background: color }}
                className={styles.folderColorBtn}
                onClick={() => setIsColorPicker(true)}
              >
                <div>
                  {isDarkColor ? (
                    <Image src="/images/chat/folder-icon-white.svg" width={21} height={15} />
                  ) : (
                    <Image src="/images/chat/folder-icon.svg" width={21} height={15} />
                  )}
                </div>
                <div className={styles.folderPenIcon}>
                  <Image src="/images/chat/pen-icon-white.svg" width={8} height={8} />
                </div>
              </IconButton>
              <div style={{ position: 'relative' }}>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Name of new group"
                  value={formData.name}
                  style={errorName ? { border: 'solid 1px red' } : null}
                  className={styles.folderNameInput}
                  onChange={(e) => {
                    changeFormData('name', e.target.value);
                    setErrorName(false);
                  }}
                />
                {errorName ? <p className={styles.errorText}>Too long name</p> : null}
              </div>
            </div>
            <Divider />
            <div className={styles.usersListContent}>
              <div className={styles.usersListContentHeader}>
                <div className={styles.addChatsContent}>
                  <p className={styles.addChatsTitle}>Add chats</p>
                  <p className={styles.addChatsCount}>
                    {formData.chats.length} / {chats.length}
                  </p>
                </div>
                <Paper component="form" className={styles.searchInputContent}>
                  <div className={styles.searchInputIcon}>
                    <Image src="/images/templates/chatSearch.png" width={17} height={17} />
                  </div>
                  <InputBase
                    sx={{ flex: 1 }}
                    value={searchValue}
                    placeholder="Search"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </Paper>
              </div>
              <List
                onScroll={onScrollUsers}
                sx={{ width: '100%', height: '50vh', overflowY: 'scroll' }}
              >
                {chats &&
                  chats.map((chat) => (
                    <ListItem
                      key={chat.id}
                      sx={{ px: 0 }}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => appendChat(chat.id)}
                        >
                          {formData.chats.includes(chat.id) ? (
                            <RemoveIcon sx={{ color: '#212121' }} />
                          ) : (
                            <AddIcon sx={{ color: '#212121' }} />
                          )}
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          sx={{ width: 48, height: 48, fontSize: 14 }}
                          src={chat.partner_id_user ? chat.partner_id_user.avatar : null}
                        >
                          {`${chat.partner_id_user && chat.partner_id_user.first_name.slice(0, 2)} 
                            ${chat.partner_id_user && chat.partner_id_user.last_name.slice(0, 2)}`}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${chat.partner_id_user && chat.partner_id_user.first_name} 
                                  ${chat.partner_id_user && chat.partner_id_user.last_name}`}
                      />
                    </ListItem>
                  ))}
                {getMore && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress
                      sx={{
                        width: '40px !important',
                        height: '40px !important',
                      }}
                    />
                  </div>
                )}
              </List>
            </div>
          </div>
        )}
        {isColorPicker && (
          <div>
            <p className={styles.colorPickerLabel}>Choose a color</p>
            <div className={styles.colorPickerContent}>
              <div className={styles.colorPickerFolder} style={{ background: color }}>
                {isDarkColor ? (
                  <Image src="/images/chat/folder-icon-white.svg" width={21} height={15} />
                ) : (
                  <Image src="/images/chat/folder-icon.svg" width={21} height={15} />
                )}
              </div>
              <CirclePicker
                onChangeComplete={(value) => changeColor(value)}
                circleSize={20}
                width="400px"
                colors={COLORS.map((item) => item.color)}
                circleSpacing={8}
              />
            </div>
          </div>
        )}
      </div>
      <DialogActions className={styles.createDialogActions}>
        <Button type="default" className={styles.createDialogActionButton} onClick={() => back()}>
          Back
        </Button>
        {!isColorPicker ? (
          <Button
            type="primary"
            className={styles.createDialogActionButton}
            onClick={() => createChatFolder()}
          >
            Create
          </Button>
        ) : (
          <Button
            type="primary"
            className={styles.createDialogActionButton}
            onClick={() => saveColor()}
          >
            Save color
          </Button>
        )}
      </DialogActions>
    </Modal>
  );
};
