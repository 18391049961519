/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import ModalContainer from '../../../../../components/ModalContainer';
import styles from './InviteCodeModal.module.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PartInput } from 'tt-ui-kit';
import { useMutation } from '@apollo/client';
import { ACCEPT_INVITE } from '../../../../../api';

const InviteCodeModal = ({ open, setOpen, setCodeInForm, setGlobalLoading, role }) => {
  const navigate = useNavigate();
  const urlParams = useSearchParams();

  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [getConfirmInviteCode] = useMutation(ACCEPT_INVITE, {
    variables: { input: {} },
  });

  const submitDirectCode = async (param) => {
    if (!param) {
      setError('Please, enter your invitation code.');
      return;
    }

    setGlobalLoading(true);

    const res = await getConfirmInviteCode({
      variables: {
        input: {
          code: param,
        },
      },
      onError: (graphQLErrors) => {
        if (graphQLErrors) {
          setError(`\n${graphQLErrors}`.split('Error:')[1]);
        }
      },
    });

    if (!res.errors && res.data.acceptInvitation.url) {
      setCodeInForm(param);
      setCode(param);
      setOpen(false);
    } else {
      setError('Your code is invalid, try another code.');
    }

    setGlobalLoading(false);
  };

  useEffect(() => {
    if (urlParams) {
      const codeParam = urlParams[0].get('code');
      const codeDirectParam = urlParams[0].get('direct_code');

      if (codeParam) {
        setCode(codeParam);
      } else if (codeDirectParam) {
        submitDirectCode(codeDirectParam);
      }
    }
  }, []);

  useEffect(() => {
    setError('');
  }, [code]);

  const closeModal = () => {
    setOpen(false);
    setCode('');
  };

  const clickSubmit = async () => {
    if (!code) {
      setError('Please, enter your invitation code.');
      return;
    }

    setIsLoading(true);

    const res = await getConfirmInviteCode({
      variables: {
        input: {
          code: code,
        },
      },
      onError: (graphQLErrors) => {
        if (graphQLErrors) {
          setError(`\n${graphQLErrors}`.split('Error:')[1]);
        }
      },
    });

    if (!res.errors && res.data.acceptInvitation.url) {
      setCodeInForm(code);
      closeModal();
    } else {
      setError('Your code is invalid, try another code.');
    }

    setIsLoading(false);
  };

  const clickIndividual = () => {
    if (role.url === 'individual') {
      closeModal();
    } else {
      navigate('/join/individual');
    }
  };

  const clickClose = () => {
    if (role.url === 'individual') {
      closeModal();
    } else {
      navigate('/join');
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => closeModal()}
      BackdropProps={{
        onClick: (event, reason) => {
          if (reason === 'click') {
            event.stopPropagation();
          }
        },
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer
        background="rgba(255, 255, 255, 0.4)"
        className={styles.modal}
        handleClose={() => closeModal()}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalTitleRow}>
            <div className={styles.modalTitle}>Please, add your invitation code:</div>
            <img src="/images/close.svg" alt="" onClick={clickClose} />
          </div>
          <div className={styles.modalContent}>
            <div className={styles.inputWrapper}>
              <PartInput code={code} setCode={setCode} />
            </div>

            {error && <div className={styles.error}>{error}</div>}

            <div className={styles.modalText}>
              If you do not have an invitation code, you can still sign up as an Individual and
              subsequently request a role change. This will allow you full access to the functions
              and features of the platform.
            </div>

            <div className={styles.buttonsWrapper}>
              <button className={styles.firstBtn} type="button" onClick={clickIndividual}>
                {role.url === 'individual' ? 'Sign up without code' : 'Sign up as an Individual'}
              </button>
              <button
                className={styles.secondBtn}
                disabled={Boolean(error) || isLoading}
                type="submit"
                onClick={clickSubmit}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default InviteCodeModal;
