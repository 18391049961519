import React from 'react';
import { ReactComponent as Co2 } from '../../../../assets/svg/co2.svg';
import { ReactComponent as Local } from '../../../../assets/svg/local.svg';
import { ReactComponent as Global } from '../../../../assets/svg/global.svg';
import { ReactComponent as Product } from '../../../../assets/svg/product.svg';
import { ReactComponent as Package } from '../../../../assets/svg/package.svg';
import { ReactComponent as SouthPole } from '../../../../assets/svg/southpole.svg';
import { ReactComponent as Reporting } from '../../../../assets/svg/logo-report.svg';
import { ReactComponent as RepFiles } from '../../../../assets/svg/logo-report-files.svg';
import { CALC_TYPES } from 'utils/calculatorUtils';

const CalcIcon = ({ type }) => {
  const getIcon = () => {
    if (type === CALC_TYPES.LOCAL) return <Local />;
    if (type === CALC_TYPES.GLOBAL) return <Global />;
    if (type === CALC_TYPES.CARBON) return <Co2 />;
    if (type === CALC_TYPES.PROJECT) return <Package />;
    if (type === CALC_TYPES.CUSTOM_REPORT) return <Reporting />;
    if (type === CALC_TYPES.CUSTOM_FILES) return <RepFiles />;
    return null;
  };

  return <>{getIcon()}</>;
};

export default CalcIcon;
