import { Modal } from '@mui/material';
import { useNavigate } from 'react-router';
import ModalContainer from 'components/ModalContainer';
import styles from './ThxModal.module.scss';

const ThxModal = ({ isModelOpen, setIsOpen, handleClose, text, subtext }) => {
  const navigate = useNavigate();

  const goToFeed = () => {
    setIsOpen(false);
    navigate('/dashboard/feed');
  };

  return (
    <Modal
      open={isModelOpen}
      onClose={() => handleClose(setIsOpen)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer
        background="rgba(255, 255, 255, 0.4)"
        handleClose={() => handleClose(setIsOpen)}
      >
        <div className={styles.text}>
          Thank you! <br />
          {text} <br /> <br />
          {subtext && subtext}
        </div>
        <button className={styles.secondBtn} type="button" onClick={goToFeed}>
          GO TO YOUR PROFILE
        </button>
      </ModalContainer>
    </Modal>
  );
};

export default ThxModal;
