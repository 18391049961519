export const TAB_OPTIONS = [
  {
    name: 'TT ESG Calculators',
    key: 'calculators',
    subMenu: [
      { name: 'Score', key: 'score' },
      { name: 'Drafts', key: 'drafts' },
      { name: 'External assessment', key: 'external' },
      { name: 'Start new', key: 'newassessment' },
    ],
  },
  {
    name: 'Sustainability Reporting',
    key: 'sustainability_reporting',
    subMenu: [
      { name: 'Reports', key: 'reports' },
      { name: 'Drafts', key: 'drafts' },
      { name: 'Start new', key: 'newassessment' },
    ],
  },
  {
    name: 'Customizable Reporting',
    key: 'customizable_reporting',
    subMenu: [
      { name: 'Reports', key: 'reports' },
      { name: 'Drafts', key: 'drafts' },
      { name: 'Templates', key: 'templates' },
      { name: 'Methodology', key: 'methodology' },
      { name: 'Start new', key: 'newassessment' },
    ],
  },
];
// common url
export const SR_URL = `/dashboard/score_and_reporting`;

// menu urls and routes
export const SR_MENU = `${SR_URL}/menu`;

export const ROUTE_CALCS = `/calculators`;
export const ROUTE_CALCS_SCORE = `${ROUTE_CALCS}/score`;
export const ROUTE_CALCS_DRAFTS = `${ROUTE_CALCS}/drafts`;
export const ROUTE_CALCS_EXTERNAL = `${ROUTE_CALCS}/external`;
export const ROUTE_CALCS_NEW = `${ROUTE_CALCS}/newassessment`;

export const ROUTE_SUST_REPORTS = `/sustainability_reporting`;
export const ROUTE_SUST_REPORTS_REPORTS = `${ROUTE_SUST_REPORTS}/reports`;
export const ROUTE_SUST_REPORTS_DRAFTS = `${ROUTE_SUST_REPORTS}/drafts`;
export const ROUTE_SUST_REPORTS_NEW = `${ROUTE_SUST_REPORTS}/newassessment`;

export const ROUTE_CUSTOM_REPORTS = `/customizable_reporting`;
export const ROUTE_CUSTOM_REPORTS_REPORTS = `${ROUTE_CUSTOM_REPORTS}/reports`;
export const ROUTE_CUSTOM_REPORTS_DRAFTS = `${ROUTE_CUSTOM_REPORTS}/drafts`;
export const ROUTE_CUSTOM_REPORTS_TEMPLATES = `${ROUTE_CUSTOM_REPORTS}/templates`;
export const ROUTE_CUSTOM_REPORTS_METHODOLOGY = `${ROUTE_CUSTOM_REPORTS}/methodology`;
export const ROUTE_CUSTOM_REPORTS_NEW = `${ROUTE_CUSTOM_REPORTS}/newassessment`;

export const URL_CALCS = `${SR_MENU}${ROUTE_CALCS}`;
export const URL_CALCS_SCORE = `${SR_MENU}${ROUTE_CALCS_SCORE}`;
export const URL_CALCS_DRAFTS = `${SR_MENU}${ROUTE_CALCS_DRAFTS}`;
export const URL_CALCS_EXTERNAL = `${SR_MENU}${ROUTE_CALCS_EXTERNAL}`;
export const URL_CALCS_NEW = `${SR_MENU}${ROUTE_CALCS_NEW}`;

export const URL_SUST_REPORTS = `${SR_MENU}${ROUTE_SUST_REPORTS}`;
export const URL_SUST_REPORTS_REPORTS = `${SR_MENU}${ROUTE_SUST_REPORTS_REPORTS}`;
export const URL_SUST_REPORTS_DRAFTS = `${SR_MENU}${ROUTE_SUST_REPORTS_DRAFTS}`;
export const URL_SUST_REPORTS_NEW = `${SR_MENU}${ROUTE_SUST_REPORTS_NEW}`;

export const URL_CUSTOM_REPORTS = `${SR_MENU}${ROUTE_CUSTOM_REPORTS}`;
export const URL_CUSTOM_REPORTS_REPORTS = `${SR_MENU}${ROUTE_CUSTOM_REPORTS_REPORTS}`;
export const URL_CUSTOM_REPORTS_DRAFTS = `${SR_MENU}${ROUTE_CUSTOM_REPORTS_DRAFTS}`;
export const URL_CUSTOM_REPORTS_TEMPLATES = `${SR_MENU}${ROUTE_CUSTOM_REPORTS_TEMPLATES}`;
export const URL_CUSTOM_REPORTS_METHODOLOGY = `${SR_MENU}${ROUTE_CUSTOM_REPORTS_METHODOLOGY}`;
export const URL_CUSTOM_REPORTS_NEW = `${SR_MENU}${ROUTE_CUSTOM_REPORTS_NEW}`;

// details urls and routes
export const SR_DETAILS = `${SR_URL}/details`;

export const ROUTE_SCORE_DETAILS = `/score`;
export const ROUTE_ANSWERS_DETAILS = `/answers`;
export const ROUTE_REPORT_DRAFT_DETAILS = `/report-draft`;
export const ROUTE_REPORT_RESULT_DETAILS = `/report-result`;

export const URL_SCORE_DETAILS = `${SR_DETAILS}${ROUTE_SCORE_DETAILS}`;
export const URL_ANSWERS_DETAILS = `${SR_DETAILS}${ROUTE_ANSWERS_DETAILS}`;
export const URL_REPORT_DRAFT_DETAILS = `${SR_DETAILS}${ROUTE_REPORT_DRAFT_DETAILS}`;
export const URL_REPORT_RESULT_DETAILS = `${SR_DETAILS}${ROUTE_REPORT_RESULT_DETAILS}`;

// menu tabs
export const TAB_URLS = [
  {
    name: 'TT ESG Calculators',
    url: URL_CALCS,
    subMenu: [
      { name: 'Score', url: URL_CALCS_SCORE },
      { name: 'Drafts', url: URL_CALCS_DRAFTS },
      { name: 'External assessment', url: URL_CALCS_EXTERNAL },
      { name: 'Start new', url: URL_CALCS_NEW },
    ],
  },
  {
    name: 'Sustainability Reporting',
    url: URL_SUST_REPORTS,
    subMenu: [
      { name: 'Reports', url: URL_SUST_REPORTS_REPORTS },
      { name: 'Drafts', url: URL_SUST_REPORTS_DRAFTS },
      { name: 'Start new', url: URL_SUST_REPORTS_NEW },
    ],
  },
  {
    name: 'Customizable Reporting',
    url: URL_CUSTOM_REPORTS,
    subMenu: [
      { name: 'Reports', url: URL_CUSTOM_REPORTS_REPORTS },
      { name: 'Drafts', url: URL_CUSTOM_REPORTS_DRAFTS },
      { name: 'Templates', url: URL_CUSTOM_REPORTS_TEMPLATES },
      { name: 'Methodology', url: URL_CUSTOM_REPORTS_METHODOLOGY },
      { name: 'Start new', url: URL_CUSTOM_REPORTS_NEW },
    ],
  },
];
