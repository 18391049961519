import React, { useState } from 'react';
import { MenuItem } from '@mui/material';
import {
  CloseCrossIcon,
  ConfirmModal,
  CopyIcon,
  EditIcon,
  InvisibleIcon,
  LitterBinIcon,
  SaveIcon,
  WarningIcon,
} from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import { deletePost } from '../../../api/posts/endpoints/posts';
import { EditPostModal } from '../../Modals/NewPostModal/EditModal';

const PostActions = ({ user, postInstance, closeMenu, onDeletePost, isArticle }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);

  const copyPostLink = () => {
    const link = `${process.env.REACT_APP_FEED_LOCATION}/post/${postInstance.id}`;

    navigator.clipboard.writeText(link).then(() => {
      openNotification({
        type: 'success',
        message: 'Link copied in clipboard',
      });
      closeMenu();
    });
  };

  const removePost = () => {
    setLoadingAction(true);

    deletePost(postInstance.id, user.id)
      .then((response) => {
        if (response.status === 200) {
          openNotification({
            message: 'Your post has been successfully deleted.',
            type: 'success',
          });
          setShowDeleteModal(false);
          closeMenu();
          onDeletePost();
        }
      })
      .catch((e) => {
        openNotification({
          message: 'Error while removing post. Try again later.',
          type: 'error',
        });
      })
      .finally(() => {
        setLoadingAction(false);
      });
  };

  const userPostActions = (
    <>
      {/* <MenuItem */}
      {/*  onClick={() => { */}
      {/*    // TODO */}
      {/*  }} */}
      {/* > */}
      {/*  <SaveIcon style={{ width: 18, height: 18, marginRight: 10 }} /> */}
      {/*  Save */}
      {/* </MenuItem> */}
      <MenuItem
        onClick={() => {
          setShowEditModal(true);
        }}
      >
        <EditIcon style={{ width: 18, height: 18, marginRight: 10 }} />
        Edit
      </MenuItem>
      <MenuItem
        onClick={() => {
          copyPostLink();
        }}
      >
        <CopyIcon style={{ width: 18, height: 18, marginRight: 10 }} />
        Copy link
      </MenuItem>
      <MenuItem
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        <LitterBinIcon
          className="icon-red-fill"
          style={{ width: 18, height: 18, marginRight: 10 }}
        />
        Delete
      </MenuItem>
    </>
  );

  const otherPostActions = (
    <>
      {/* <MenuItem */}
      {/*  onClick={() => { */}
      {/*    // TODO */}
      {/*  }} */}
      {/* > */}
      {/*  <SaveIcon style={{ width: 18, height: 18, marginRight: 10 }} /> */}
      {/*  Save */}
      {/* </MenuItem> */}
      <MenuItem
        onClick={() => {
          copyPostLink();
        }}
      >
        <CopyIcon style={{ width: 18, height: 18, marginRight: 10 }} />
        Copy link
      </MenuItem>
      {/* <MenuItem */}
      {/*  onClick={() => { */}
      {/*    // TODO */}
      {/*  }} */}
      {/* > */}
      {/*  <InvisibleIcon style={{ width: 18, height: 18, marginRight: 10 }} /> */}
      {/*  Don&apos;t show the post */}
      {/* </MenuItem> */}
      {/* <MenuItem */}
      {/*  onClick={() => { */}
      {/*    // TODO */}
      {/*  }} */}
      {/* > */}
      {/*  <CloseCrossIcon style={{ width: 18, height: 18, marginRight: 10 }} /> */}
      {/*  Unfollow */}
      {/* </MenuItem> */}
      {/* <MenuItem */}
      {/*  onClick={() => { */}
      {/*    // TODO */}
      {/*  }} */}
      {/* > */}
      {/*  <WarningIcon className="icon-red-fill" style={{ width: 18, height: 18, marginRight: 10 }} /> */}
      {/*  Report */}
      {/* </MenuItem> */}
    </>
  );

  return (
    <>
      {user.id === postInstance?.user?.id ? userPostActions : otherPostActions}

      <ConfirmModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        title={`Do you want to delete ${isArticle ? 'an article' : 'a post'}?`}
        subTitle={`Your ${isArticle ? 'article' : 'post'} will be deleted permanently`}
        okText="Delete"
        loading={loadingAction}
        onSubmit={() => removePost()}
        onClose={() => closeMenu()}
      />

      <EditPostModal
        visible={showEditModal}
        onClose={() => {
          closeMenu();
          setShowEditModal(false);
        }}
        onFinish={onDeletePost}
        user={user}
        editId={postInstance.id}
      />
    </>
  );
};

export default PostActions;
