import React, { useState, useEffect } from 'react';
import SendCreditsModal from './SendCredits/SendCreditsModal';
import { useParams } from 'react-router-dom';

import {
  Button,
  HelpTooltip,
  Loader,
  Tabs,
  Tooltip,
  Collapse,
  openNotification,
} from 'tt-ui-lib/core';
import { DownloadIcon, LeftIcon, MetaMaskTokensIcon, ShareIcon } from 'tt-ui-lib/icons';

import styles from './CertificatePage.module.scss';
import FCEMLogo from './Components/FcemLogo';
import InfoModal from './Components/InfoModal';
import RegisterModal from './Components/RegisterModal';
import GetModal from './Components/GetModal';
import ConfirmMoveModal from './Components/ConfirmMoveModal';
import RegisterTab from './Components/RegisterTab';
import BalanceView, { calculateBalanceWithUnit } from './Components/BalanceView';
import { downloadFileFromURL } from 'modules/tt-tokenized-assets/utils';

const { REACT_APP_SSO_API } = process.env;

const tabs = [
  {
    name: 'Credit',
    id: 'credit',
  },
  {
    name: 'Offset',
    id: 'offset',
  },
  {
    name: 'Register',
    id: 'register',
  },
];

const buildCertOffsetImgLink = (payload) => {
  const parentProjectData = JSON.parse(payload?.parentCertificate?.project || '{}');

  return `${REACT_APP_SSO_API}/certificate/offset-digitization/jpg?certificate_no=${payload?.id}&issued_by=${payload?.issued_by}&project=${parentProjectData?.name}&project_link=${payload?.parentCertificate?.project_link}&beneficiary=${payload?.beneficiary || '-'}&company=${payload?.issued_for || '-'}&amount=${`${calculateBalanceWithUnit(payload?.quantity)} (${payload?.quantity} gramms)`}&description=${payload?.description}&digital=${payload?.parentCertificate?.smart_contract_address}&date=${payload?.date}`;
};

const OffsetImage = ({ item }) => {
  const imgLink = buildCertOffsetImgLink(item);

  const [status, setStatus] = useState('loading');

  const finishLoading = () => {
    setStatus('done');
  };

  return (
    <div>
      <div className={styles.offsetCertBlock}>
        <div>{item?.date}</div>
        <div>
          <Button
            type="icon"
            onClick={() => {
              downloadFileFromURL(imgLink, item?.id);
            }}
            rounded
          >
            <DownloadIcon style={{ width: 22, height: 22 }} />
          </Button>
          <Button
            type="icon"
            onClick={() => {
              navigator.clipboard.writeText(imgLink).then(() => {
                openNotification({
                  type: 'success',
                  message: 'Link copied in clipboard',
                });
              });
            }}
            rounded
          >
            <ShareIcon style={{ width: 22, height: 22 }} />
          </Button>
        </div>
      </div>

      <div>
        {status === 'loading' && <Loader />}

        <img
          className={styles.offsetImage}
          src={buildCertOffsetImgLink(item)}
          alt="No certificate found..."
          onLoad={finishLoading}
        />
      </div>
    </div>
  );
};

const CertificatePage = ({
  navigate,
  account,
  data,
  isLoading,
  backUrl,
  certLoading,
  sendCheck = undefined,
  sendConfirm = undefined,
  getCertificate,
  getOffsetDataCertificate,
  createRegister,
  moveToWallet = undefined,
  connectWalletHandler = undefined,
  detachWallet = undefined,
  getCertificateRegisters,
  offsetData,
  mode = 'DA',
  urlDA = undefined,
  setUpdateCertImage,
  isMoveLoading,
  walletStatus,
}) => {
  const { id } = useParams();

  const [selectedTab, setSelectedTab] = useState(0);

  const [openSendModal, setOpenSendModal] = useState(false);
  const [confirmMoveModalMode, setConfirmMoveModalMode] = useState(false);
  const [openConfirmMoveModal, setOpenConfirmMoveModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openGetModal, setOpenGetModal] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  // const [isDeposit, setIsDeposit] = useState(true);
  // const [isRegister, setIsRegister] = useState(true);

  const parsedInternalData = JSON.parse(data?.project || '{}') || null;
  const certLogoUrl = `https://dev-market-back.s3.eu-central-1.amazonaws.com/carbon/logo/${parsedInternalData?.type?.logo}`;

  const handleChangeTabs = (newValue) => {
    setSelectedTab(newValue);
  };

  const moveToWalletWrap = () => {
    if (account) {
      setConfirmMoveModalMode(true);
    } else {
      setConfirmMoveModalMode(false);
    }

    setOpenConfirmMoveModal(true);
  };

  useEffect(() => {
    if (selectedTab === 1) {
      setUpdateCertImage(true);
    }
  }, [selectedTab]);

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <Button type="icon" onClick={() => navigate(backUrl || '/dashboard/financials')} rounded>
          <LeftIcon style={{ width: 24, height: 24 }} />
        </Button>
        <h2>Certificate {id}</h2>
      </div>

      {isLoading ? (
        <div
          className={styles.certLoadWrapper}
          style={{ height: 400, background: '#FFF', borderRadius: 12 }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div className={styles.mainContainer}>
            <div className={styles.mainContainerHeader}>
              <div>Digitized carbon credit and information about it.</div>
            </div>

            <div className={styles.mainInfoContainer}>
              <div className={styles.certImageBlock} onClick={() => setOpenInfoModal(true)}>
                <img src={certLogoUrl} alt="" />
              </div>

              <div className={styles.certDataBlock}>
                <div className={styles.infoItem}>
                  <div>Vintage</div>
                  <span>
                    {parsedInternalData?.vintage_from || ''}
                    {' - '}
                    {parsedInternalData?.vintage_to || ''}
                  </span>
                </div>
                <div className={styles.infoItem}>
                  <div>Standard</div>
                  <span>{parsedInternalData?.standard?.name || '-'}</span>
                </div>
                <div className={styles.infoItem}>
                  <div>Tokenization date</div>
                  <span>{data?.created_at || '-'}</span>
                </div>

                <div className={styles.divider} />

                <div className={styles.infoItem}>
                  <div>Supplier</div>
                  <span>{parsedInternalData?.provider?.name || '-'}</span>
                </div>
                <div className={styles.infoItem}>
                  <div>Project Type</div>
                  <span>{parsedInternalData?.type?.name || '-'}</span>
                </div>
                <div className={styles.infoItem}>
                  <div>Project ID</div>
                  <span>{parsedInternalData?.type?.id || '-'}</span>
                </div>
                <div className={styles.infoItem}>
                  <div>Region</div>
                  <span>{parsedInternalData?.region?.name || '-'}</span>
                </div>
                <div className={styles.infoItem}>
                  <div>Goals</div>
                  <span>
                    {parsedInternalData?.goals?.length > 0
                      ? parsedInternalData?.goals?.map(
                          (item, index) =>
                            index < 4 && (
                              <Tooltip
                                title={item.name.replaceAll('_', ' ').toUpperCase()}
                                placement="top"
                                arrow
                              >
                                <div
                                  style={{ cursor: 'pointer' }}
                                  // onClick={() => scrollToGoalDescription(item.id)}
                                >
                                  <img
                                    alt={item.name}
                                    src={`/images/company/${item.name}.svg`}
                                    style={{ borderRadius: '4px', width: 28, height: 28 }}
                                  />
                                </div>
                              </Tooltip>
                            )
                        )
                      : '-'}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.tabs}>
            <Tabs tabs={tabs} selectedTab={selectedTab} onChangeTab={handleChangeTabs} />
          </div>

          {selectedTab === 0 ? (
            <div className={styles.bottomContent}>
              <div className={styles.block}>
                <div className={styles.blockHeader}>
                  <h2>Credit balance</h2>
                  <div>
                    <BalanceView balance={data?.quantity || '-'} />
                    {/* TODO */}
                    {mode === 'DA' && (
                      <Button type="default" disabled={!data}>
                        <MetaMaskTokensIcon style={{ width: 22, height: 22 }} />
                      </Button>
                    )}
                  </div>
                </div>

                {data?.deposit && data?.deposit > 0 && (
                  <div className={styles.depositBlock}>
                    <div>
                      <FCEMLogo /> <span>FCEM deposit</span> <HelpTooltip tooltipText="Soon..." />
                    </div>
                    <div>{data?.deposit || '-'} FCEM</div>
                  </div>
                )}

                <div className={styles.blockText}>
                  <p>Here you can manage your carbon credit.</p>
                  <p>You can find a detailed description of this credit above.</p>
                  <p>
                    To add this asset to the Metamask interface, click on the &quot;Fox&quot; button
                    and complete the action in the Metamask.
                  </p>
                  <p>To retire the carbon credit, click on the &quot;Retire&quot; button.</p>
                  <p>To send it, use the &quot;Send&quot; button.</p>
                </div>
                <div className={styles.blockFooter}>
                  <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                    {mode === 'DA' ? (
                      <>
                        <Button disabled={!data} onClick={() => setOpenSendModal(true)}>
                          Send
                        </Button>
                        <Button
                          onClick={() => {
                            if (detachWallet) detachWallet();
                          }}
                          disabled
                        >
                          Detach wallet
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          disabled={!data}
                          onClick={() => {
                            moveToWalletWrap();
                          }}
                        >
                          Move to your wallet
                        </Button>
                      </>
                    )}
                  </div>
                  <Button
                    disabled={!data}
                    type="primary"
                    onClick={() => {
                      setOpenGetModal(true);
                    }}
                  >
                    Get a certificate
                  </Button>
                </div>
              </div>
            </div>
          ) : selectedTab === 1 ? (
            <div className={styles.bottomContent}>
              <div className={styles.block}>
                <div className={styles.blockHeader}>
                  <h2>Carbon offset certificate</h2>
                  <div>
                    <BalanceView balance={data?.quantity || '-'} />
                    {/* TODO */}
                    {mode === 'DA' && (
                      <Button type="default" disabled={!data}>
                        <MetaMaskTokensIcon style={{ width: 22, height: 22 }} />
                      </Button>
                    )}
                  </div>
                </div>

                <div className={styles.blockText}>
                  This certificates is a confirmation of the offset of your carbon footprint.
                </div>

                {certLoading ? (
                  <div>
                    <Loader />
                  </div>
                ) : offsetData?.length > 0 ? (
                  <Collapse
                    appearance="expanded"
                    items={offsetData.map((item, index) => ({
                      key: index,
                      label: `${item?.beneficiary} (${calculateBalanceWithUnit(item?.quantity)})`,
                      children: <OffsetImage item={item} key={`${index + 1}`} />,
                    }))}
                  />
                ) : (
                  <>
                    <p>Certificate not found...</p>
                    <p>You need to get a certificate first.</p>
                  </>
                )}

                {mode !== 'DA' && (
                  <div style={{ marginTop: 10 }}>
                    <Button
                      disabled={!data}
                      onClick={() => {
                        moveToWalletWrap();
                      }}
                    >
                      Move to your wallet
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className={styles.bottomContent}>
              <RegisterTab
                id={id}
                data={data}
                setOpenRegisterModal={setOpenRegisterModal}
                getCertificateRegisters={getCertificateRegisters}
              />
            </div>
          )}
        </>
      )}

      <InfoModal
        open={openInfoModal}
        setOpen={setOpenInfoModal}
        id={id}
        image={certLogoUrl}
        balance={calculateBalanceWithUnit(data?.quantity)}
        offsetted="" // TODO
        transactions={[]} // TODO
      />

      <RegisterModal
        open={openRegisterModal}
        setOpen={setOpenRegisterModal}
        id={id}
        createRegister={createRegister}
      />

      <GetModal
        open={openGetModal}
        setOpen={setOpenGetModal}
        offset={data?.credit_id || '-'}
        balance={calculateBalanceWithUnit(data?.quantity)}
        id={id}
        getCertificate={getCertificate}
        getOffsetDataCertificate={getOffsetDataCertificate}
        setSelectedTab={setSelectedTab}
        certData={data}
      />

      <SendCreditsModal
        open={openSendModal}
        setOpen={setOpenSendModal}
        credits={data?.credit_id || '-'}
        balance={calculateBalanceWithUnit(data?.quantity)}
        id={id}
        sendCheck={sendCheck}
        sendConfirm={sendConfirm}
      />

      <ConfirmMoveModal
        open={openConfirmMoveModal}
        setOpen={setOpenConfirmMoveModal}
        confirmHandler={moveToWallet}
        connectWalletHandler={connectWalletHandler}
        mode={!!account}
        isMoveLoading={isMoveLoading}
        walletStatus={walletStatus}
      />
    </div>
  );
};

export default CertificatePage;
