import { axiosClient, axiosSSOConfigPrivate } from '../../api';
import {
  API_COMMENTS,
  API_COMMENTS_LIKES,
  API_POSTS,
  API_POSTS_GET_PREVIEW_LINK,
  API_POSTS_LIKES,
  API_POSTS_MAIN,
  API_POSTS_UPLOAD,
  API_REACTIONS_COMMENTS,
  API_REACTIONS_POST,
} from '../urls';

export const getPostsList = (userId, page = 1) =>
  axiosClient.post(
    `${API_POSTS_MAIN}/p/index?page=${page}`,
    { user_id: userId },
    axiosSSOConfigPrivate()
  );

export const getPost = (id, userId) =>
  axiosClient.post(`${API_POSTS_MAIN}/get/${id}`, { user_id: userId }, axiosSSOConfigPrivate());

export const addPost = (postData) => {
  const data = {
    ...postData,
  };

  return axiosClient.post(`${API_POSTS_MAIN}/p/store`, data, axiosSSOConfigPrivate());
};

export const updatePost = (postId, data) =>
  axiosClient.put(`${API_POSTS}/${postId}`, data, axiosSSOConfigPrivate());

export const deletePost = (postId, userId) =>
  axiosClient.post(
    `${API_POSTS_MAIN}/p/delete/${postId}`,
    { user_id: userId },
    axiosSSOConfigPrivate()
  );

export const getPostComments = (postId, userId) =>
  axiosClient.post(
    `${API_POSTS_MAIN}/comments/${postId}`,
    { user_id: userId },
    axiosSSOConfigPrivate()
  );

export const createPostComment = (data) =>
  axiosClient.post(`${API_COMMENTS}`, data, axiosSSOConfigPrivate());

export const deletePostComment = (commentId, userId) =>
  axiosClient.post(
    `${API_POSTS_MAIN}/c/delete/${commentId}`,
    { user_id: userId },
    axiosSSOConfigPrivate()
  );

export const getPostFileUploadLink = (data) =>
  axiosClient.post(`${API_POSTS_UPLOAD}`, data, axiosSSOConfigPrivate());

export const updateLikePost = (data) =>
  axiosClient.post(`${API_POSTS_LIKES}`, data, axiosSSOConfigPrivate());

export const updateLikeComment = (data) =>
  axiosClient.post(`${API_COMMENTS_LIKES}`, data, axiosSSOConfigPrivate());

export const getContentList = (userId, type, page = 1) =>
  axiosClient.post(
    `${API_POSTS_MAIN}/u/p/index?page=${page}`,
    { user_id: userId, type: type },
    axiosSSOConfigPrivate()
  );

export const fetchPostReactions = (id) =>
  axiosClient.get(`${API_REACTIONS_POST}/${id}`, axiosSSOConfigPrivate());

export const fetchCommentsReactions = (id) =>
  axiosClient.get(`${API_REACTIONS_COMMENTS}/${id}`, axiosSSOConfigPrivate());

export const fetchPostLinkPreview = (link) => {
  const data = new FormData();
  data.append('url', link);

  return axiosClient.post(API_POSTS_GET_PREVIEW_LINK, data, axiosSSOConfigPrivate());
};
