/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-children-prop */
import { useState, useRef, useEffect } from 'react';
import { getElementDimensions } from 'utils/getElementDimensions';
import styles from './Accordion.module.scss';

export const Accordion = (props) => {
  const { defaultExpanded, expanded, children, onClick, className, title, isContact, ...rest } =
    props;
  const [contentHeight, setContentHeight] = useState(0);
  const contentTextRef = useRef(null);

  useEffect(() => {
    if (contentTextRef.current) {
      setContentHeight(getElementDimensions(contentTextRef.current).height);
    }
  }, []);

  const handleClick = (event) => {
    onClick?.(event, !expanded);
  };

  return (
    <div className={`${styles.accordion} ${className}`} onClick={handleClick} aria-hidden {...rest}>
      <div className={styles.header}>
        <label children={title} />
        <button>{expanded ? '–' : '+'}</button>
      </div>
      <div
        className={`${styles.content} ${expanded ? styles.expanded : ''}`}
        style={{ maxHeight: expanded ? contentHeight : undefined }}
      >
        <p ref={contentTextRef} className={styles.text}>
          {children}
          {isContact && (
            <div className={styles.buttonWrapper}>
              <button className={styles.contactUsButton} type="button" disabled>
                Contact us
              </button>
            </div>
          )}
        </p>
      </div>
    </div>
  );
};
