/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Header from 'components/layout/Header';
import VideoBg from 'components/layout/VideoBg';
import { useWindowSize } from 'utils/useWindowSize';
import { TERMS_DATA } from 'constants/termPage';
import { FourOhFour } from 'views';
import { Accordion } from './Accordion';
import styles from './TermPage.module.scss';

const VIDEO_PATH = '/video/term_bg.mp4';
const BG_IMAGE_PATH = '/images/term-body-bg.jpg';
const MAX_MOBILE_WIDTH = 678;

const TermPage = () => {
  const { term } = useParams();
  const [termData, setTermData] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [isFourOhFour, setIsFourOhFour] = useState(false);

  const windowSize = useWindowSize();

  const handleAccordionClick = (expanded, index) => {
    setExpandedIndex(!expanded ? undefined : index);
  };

  useEffect(() => {
    if (term) {
      setIsFourOhFour(false);
      const data = TERMS_DATA.find((d) => d.route === term);
      if (data) {
        setTermData(data);
      } else {
        setIsFourOhFour(true);
      }
    }
  }, []);

  return (
    <>
      <div id="imageBg" className={styles.imageBg}>
        <Header isHeaderNav isSignUpPage />
        <VideoBg
          src={VIDEO_PATH}
          srcImg={BG_IMAGE_PATH}
          isAutoPlay={windowSize.width > MAX_MOBILE_WIDTH}
        />
        {termData && (
          <section className={styles.sectionWrapper}>
            <h1 className={styles.title}>{termData.title}</h1>
            {termData.subTitle && (
              <div
                className={styles.subTitle}
                dangerouslySetInnerHTML={{ __html: termData.subTitle }}
              />
            )}
            {termData.points.map(({ title, text, isContact }, index) => (
              <Accordion
                title={title}
                isContact={isContact}
                expanded={index === expandedIndex}
                onClick={(e, expanded) => handleAccordionClick(expanded, index)}
                key={title + index}
              >
                <span dangerouslySetInnerHTML={{ __html: text }} />
              </Accordion>
            ))}
          </section>
        )}
      </div>
      {isFourOhFour && <FourOhFour />}
    </>
  );
};

export default TermPage;
