import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router';
import CustomResultDetail from './CustomResultDetail';
import styles from './ReportDetails.module.scss';
import { CALC_TYPES } from 'utils/calculatorUtils';
import { Alert, Snackbar } from '@mui/material';

const ReportResultDetails = () => {
  const { id, type } = useParams();
  const location = useLocation();
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorText, setErrorText] = useState('');

  const onErrorClose = () => {
    setOpenErrorAlert(false);
    setErrorText('');
  };

  return (
    <div className={styles.container}>
      {type === CALC_TYPES.CUSTOM_REPORT && (
        <CustomResultDetail params={{ id: id, setErrorText, setOpenErrorAlert }} />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openErrorAlert}
        onClose={onErrorClose}
      >
        <Alert
          onClose={onErrorClose}
          severity="error"
          sx={{ width: '100%', backgroundColor: '#D32F2F', color: '#ffffff' }}
        >
          {errorText ?? 'Something went wrong, try again'}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ReportResultDetails;
