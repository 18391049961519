import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useWindowSize } from 'utils/useWindowSize';
import clsx from 'clsx';
import styles from './GoalDescription.module.scss';

const MOBILE_WIDTH = 480;

const GoalDescription = ({ goal, setGoal, isMobile }) => {
  const [firstBtn, setFirstBtn] = useState('previous');
  const [secondBtn, setSecondBtn] = useState('next');
  const windowSize = useWindowSize();

  const navigate = useNavigate();

  const goToPrevious = (currentGoal) => {
    const previousGoal = currentGoal === 1 ? null : currentGoal - 1;
    setGoal(previousGoal);
    navigate(currentGoal === 1 ? `/sdgs/` : `/sdgs/goal_${previousGoal}`);
  };

  const goToNext = (currentGoal) => {
    const nextGoal = currentGoal === 17 ? null : currentGoal + 1;
    setGoal(nextGoal);
    navigate(currentGoal === 17 ? `/sdgs/` : `/sdgs/goal_${nextGoal}`);
  };

  useEffect(() => {
    if (goal) {
      if (goal.goal === 1) {
        setFirstBtn('back');
        setSecondBtn('next');
      }
      if (goal.goal > 1) {
        setFirstBtn('previous');
        setSecondBtn('next');
      }
      if (goal.goal === 17) {
        setFirstBtn('previous');
        setSecondBtn('finish');
      }
    }
  }, [goal]);

  return (
    <>
      {goal && (
        <div
          className={clsx(
            styles.sectionWrapper,
            isMobile ? styles.mobileWrapper : '',
            windowSize.width <= MOBILE_WIDTH ? styles.goalMobileWrapper : ''
          )}
        >
          <div
            className={styles.imgBgWrapper}
            style={{
              backgroundImage: windowSize.width > MOBILE_WIDTH ? `url(${goal.goalBg})` : '',
            }}
          >
            <div className={styles.lineIconMobileWrapper}>
              <div className={styles.lineMobile} style={{ backgroundColor: `${goal.stroke}` }}>
                <div className={styles.titleMobile}>{`№${goal.goal} ${goal.title}`}</div>
              </div>
              <div
                className={styles.iconWrapperMobile}
                style={{
                  background: `no-repeat url(${goal.iconXL}) center/contain`,
                  width: `${goal.iconLWidth}px`,
                  height: `${goal.iconLHeight}px`,
                  left: `calc(92% - ${goal.iconLWidth / 2 + 24}px)`,
                }}
              />
            </div>
            <div className={styles.descriptionWrapper}>
              <div className={styles.line} style={{ backgroundColor: `${goal.stroke}` }} />
              <div className={styles.descriptionSecondWrapper}>
                <div className={styles.firstTitle}>{`Goal ${goal.goal}`}</div>
                <div
                  className={styles.title}
                  dangerouslySetInnerHTML={{ __html: `${goal.title}` }}
                />
                <div
                  className={styles.subTitle}
                  dangerouslySetInnerHTML={{ __html: `${goal.subTitle}` }}
                />
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: `${goal.description}` }}
                />
              </div>
              <div
                className={styles.iconWrapper}
                style={{
                  backgroundImage: `url(${goal.iconXL})`,
                  width: `${goal.iconXlWidth}`,
                  height: `${goal.iconXlHeight}`,
                }}
              />
            </div>
          </div>
          <div className={styles.buttonsWrapper}>
            <button className={styles.btn} type="button" onClick={() => goToPrevious(goal.goal)}>
              {firstBtn}
            </button>
            <button className={styles.btn} type="button" onClick={() => goToNext(goal.goal)}>
              {secondBtn}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default GoalDescription;
