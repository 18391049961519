/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState, useRef } from 'react';
import styles from './AppAvatar.module.scss';
import AvatarBadge from './AvatarBadgeDashboard';
import { ReactComponent as ArrowUp } from '../../assets/svg/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/svg/arrow-down.svg';
import { KycModal } from '../../../tt-modals';
import { Avatar, Collapse, DownIcon, UpIcon } from 'tt-ui-kit';
import { useWindowSize } from '../../utils/useWindowSize';
import AvatarDrawer from './Drawer';
import { getAvatarPathBySize } from '../../utils/common';

const AppAvatar = ({
  children,
  size = 'md',
  selectedCompany,
  userName,
  role,
  nav,
  logOut,
  logoutUserHandler,
  openOptions,
  arrowButton,
  selectProfile,
  selectSettings,
  selectHelp,
  userCompanies,
  selectCompany,
  selectToPersonalProfile,
  selectValidatorAgreement,
  user,
  mainTTUrl,
  marketTTUrl,
  digitalAssetsTTUrl,
  onAvatarClick,
  onClickShowProfile = false,
  mode,
  ...props
}) => {
  const sizes = {
    xs: '40px',
    xl: '44px',
    sm: '56px',
    md: '63px',
    lg: '128px',
  };

  const fontSizes = {
    xs: '12px',
    xl: '12px',
    sm: '12px',
    md: '16px',
    lg: '42px',
  };

  const [openCollapse, setOpenCollapse] = useState(false);
  const [visOption, setVisOption] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isKycModelOpen, setIsKycModalOpen] = useState(false);
  const [isIndividual, setIsIndividual] = useState(true);

  const dropdownRef = useRef();
  const windowSize = useWindowSize();

  const onChangeOpen = () => {
    if (windowSize.width > 1024) {
      setVisOption((value) => !value);
    } else {
      setOpenDrawer(true);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setVisOption(false);
    }
  };

  const selectPassKYC = () => {
    onChangeOpen();
    setIsKycModalOpen(true);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (user?.roles && user?.roles.length) {
      const roleExpert = user?.roles?.find((elem) => elem.name === 'expert');
      const roleBusiness = user?.roles?.find((elem) => elem.name === 'business');
      const roleInvestor = user?.roles?.find((elem) => elem.name === 'investor');
      if (roleExpert || roleBusiness || roleInvestor) {
        setIsIndividual(false);
      } else {
        const roleHolder = user?.roles?.find((elem) => elem.name === 'holder');
        if (roleHolder) {
          setIsIndividual(false);
        } else {
          const roleIndividual = user?.roles?.find((elem) => elem.name === 'individual');

          if (roleIndividual) {
            setIsIndividual(true);
          } else {
            setIsIndividual(false);
          }
        }
      }
    }
  }, [user]);

  const initials = userName
    ? userName
        .split(' ')
        .map((i) => i[0])
        .join('')
    : '';

  const onSelectProfile = () => {
    if (selectedCompany?.isCompanySelected) {
      window.location.replace(
        `${marketTTUrl || ''}/dashboard/company/${selectedCompany.selectedCompany.id}/edit`
      );
    } else {
      window.location.replace(`${mainTTUrl || ''}/dashboard/profile/${user?.id}`);
    }
  };

  return (
    <div>
      <div className={styles.avatarOverlay} ref={dropdownRef}>
        {nav === 2 && (
          <div className={styles.avatarRow}>
            <Avatar
              {...props}
              src={
                selectedCompany?.isCompanySelected
                  ? selectedCompany.selectedCompany.logos[0]?.path || ''
                  : user?.avatar
              }
              className={styles.avatar}
              style={{ cursor: onAvatarClick || onClickShowProfile ? 'pointer' : 'default' }}
              onClick={() => {
                if (onAvatarClick) {
                  onAvatarClick();
                } else if (onClickShowProfile) {
                  onSelectProfile();
                } else {
                  onChangeOpen();
                }
              }}
            >
              <>
                {children}
                {initials}
              </>
            </Avatar>
          </div>
        )}
        {nav === 1 && (
          <Avatar
            {...props}
            sx={{
              width: `${sizes[size]} !important`,
              height: `${sizes[size]} !important`,
              fontSize: `${fontSizes[size]} !important`,
              backgroundColor: 'primary.black-10',
              color: 'primary.black-80',
              fontWeight: '500',
            }}
            className={styles.avatar}
            style={{ cursor: onAvatarClick || onClickShowProfile ? 'pointer' : 'default' }}
            onClick={() => {
              if (onAvatarClick) {
                onAvatarClick();
              } else if (onClickShowProfile) {
                onSelectProfile();
              } else {
                onChangeOpen();
              }
            }}
          >
            <>
              {children}
              {initials}
            </>
          </Avatar>
        )}
        {nav === 0 && (
          <Avatar
            {...props}
            sx={{
              width: sizes[size],
              height: sizes[size],
              fontSize: fontSizes[size],
              bgcolor: 'primary.black-10',
              color: 'primary.black-80',
              fontWeight: '500',
            }}
            className={styles.avatarMobile}
            style={{ cursor: onAvatarClick || onClickShowProfile ? 'pointer' : 'default' }}
            onClick={() => {
              if (onAvatarClick) {
                onAvatarClick();
              } else if (onClickShowProfile) {
                onSelectProfile();
              } else {
                onChangeOpen();
              }
            }}
          >
            <>
              {children}
              {initials}
            </>
          </Avatar>
        )}
        {arrowButton === 1 && (
          <div
            className={styles.downArrowDiv}
            style={{ backgroundColor: `${visOption ? '#143471' : 'white'}` }}
            onClick={() => onChangeOpen()}
          >
            {!visOption ? (
              <ArrowDown className={styles.arrow} />
            ) : (
              <ArrowUp className={styles.arrowActive} fill="white" />
            )}
          </div>
        )}
        {role && (size === 'md' || size === 'sm') && <AvatarBadge size={size} role={role} />}
        {openOptions === 1 && (
          <div hidden={!visOption} className={styles.dropdownBox}>
            <ul className={styles.dropdown} hidden={!visOption} aria-label="mailbox folders">
              <li
                className={styles.dropdownItem}
                onClick={() => {
                  onSelectProfile();
                  setVisOption(false);
                }}
              >
                {selectedCompany?.isCompanySelected ? 'Company profile' : 'Profile'}
              </li>
              <li
                className={styles.dropdownItem}
                onClick={() => {
                  selectSettings();
                  setVisOption(false);
                }}
              >
                Settings and security
              </li>
              <li
                className={styles.dropdownItem}
                onClick={() => {
                  selectHelp();
                  setVisOption(false);
                }}
              >
                Help
              </li>

              {/* {user.role_name !== 'individual' && (
                <div className={styles.dropdownCollapse} onClick={(e) => onSelecetBusiness(e)}>
                  <li className={styles.dropdownItemCollapse}>Business account</li>
                  {openCollapse ? (
                    <div className={styles.dropdownArrowCollapse}>
                      <ExpandLess />
                    </div>
                  ) : (
                    <div className={styles.dropdownArrowCollapse}>
                      <ExpandMore />
                    </div>
                  )}
                </div>
              )} */}
              <>
                {userCompanies && userCompanies.length > 0 && (
                  <li className={`${styles.dropdownNestedRoot} ${styles.dropdownItem}`}>
                    <span
                      className={styles.dropdownTitle}
                      onClick={() => setOpenCollapse((val) => !val)}
                    >
                      <span>Select company</span>
                      {openCollapse ? (
                        <UpIcon style={{ width: 18, height: 18 }} />
                      ) : (
                        <DownIcon style={{ width: 18, height: 18 }} />
                      )}
                    </span>

                    <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                      <div className={styles.dropdownBody}>
                        {userCompanies.map((item) => (
                          // eslint-disable-next-line react/jsx-key
                          <div
                            className={styles.dropdownItemCompany}
                            onClick={() => selectCompany(item)}
                          >
                            {item.logos.length > 0 ? (
                              <Avatar
                                alt=""
                                src={getAvatarPathBySize(item.logos, 'smallPath')}
                                sx={{ width: 24, height: 24 }}
                              />
                            ) : (
                              <Avatar alt="" src="" sx={{ width: 24, height: 24 }} />
                            )}
                            <span>{item.company_name || item.companyName || '-'}</span>
                          </div>
                        ))}
                      </div>
                    </Collapse>
                  </li>
                )}
              </>
              {selectedCompany?.isCompanySelected && (
                <li className={`${styles.dropdownItem}`} onClick={selectToPersonalProfile}>
                  Personal account
                </li>
              )}

              {!isIndividual &&
                user?.kyc !== 'success' &&
                user?.kyc !== 'pending' &&
                user?.kyc !== 'created' && (
                  <li
                    className={styles.dropdownItem}
                    onClick={() => {
                      selectPassKYC();
                      setVisOption(false);
                    }}
                  >
                    Pass KYC
                  </li>
                )}

              {user?.roles_list &&
                JSON.parse(user.roles_list).includes('validator') &&
                user.agreement?.validator_agreement !== 1 && (
                  <li
                    className={styles.dropdownItem}
                    onClick={() => {
                      selectValidatorAgreement();
                      setVisOption(false);
                    }}
                  >
                    Validator agreement
                  </li>
                )}

              <li
                className={styles.dropdownItem}
                onClick={() => {
                  logOut();
                  setVisOption(false);
                }}
              >
                Log out
              </li>
            </ul>
          </div>
        )}

        {openOptions === 1 && (
          <div className={styles.settingModal}>
            <AvatarDrawer
              isIndividual={isIndividual}
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
              logOut={logoutUserHandler}
              user={user}
              selectedCompany={selectedCompany}
              selectPassKYC={selectPassKYC}
              mainTTUrl={mainTTUrl}
              marketTTUrl={marketTTUrl}
              digitalAssetsTTUrl={digitalAssetsTTUrl}
              userCompanies={userCompanies}
              selectCompany={selectCompany}
              selectToPersonalProfile={selectToPersonalProfile}
              mode={mode}
            />
          </div>
        )}
      </div>

      <KycModal
        isOpen={isKycModelOpen}
        setIsOpen={setIsKycModalOpen}
        userId={user?.id}
        userKyc={user?.kyc}
      />
    </div>
  );
};

export default AppAvatar;
