import clsx from 'clsx';
import CalcInfoBlock from 'components/CalcInfoBlock/CalcInfoBlock';
import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import SearchIcon from '@mui/icons-material/Search';
import { SHOW_ACTIVE_USER_COMPANIES } from 'api';
import { Box, List, ListItem, ListItemButton, MenuItem, Select, Tab, Tabs } from '@mui/material';
import styles from '../CommonStyles/StartNew.module.scss';
import { useSelectedContext } from 'context/contextSelector';
import { TextInput, HelpTooltip, Button, Modal } from 'tt-ui-kit';
import CompanyBlock from 'components/CompanyBlock/CompanyBlock';
import {
  CALCS_PER_PAGE,
  CALC_DESCRIPTIONS,
  CALC_NAMES,
  CALC_PAGE_TYPES,
  CALC_TYPES,
} from 'utils/calculatorUtils';
import CalcIcon from '../../components/CalcIcon/CalcIcon';

const isProduction = process.env.REACT_APP_ENV === 'production';
const calcInDevelopnentList = {
  [CALC_TYPES.SUST_CSRD_ESRD]: true,
  [CALC_TYPES.SUST_GRI]: true,
  [CALC_TYPES.SUST_ISSB]: true,
  [CALC_TYPES.SUST_CDP]: true,
  [CALC_TYPES.CUSTOM_FILES]: isProduction,
  [CALC_TYPES.CUSTOM_REPORT]: isProduction,
  [CALC_TYPES.GLOBAL]: isProduction,
};

const menu = [
  { value: 'my', name: 'My company' },
  { value: 'external', name: 'External company' },
];

const colors = {
  blue: '#143471',
  gray: '#212121',
};

const tabStyles = {
  '& .MuiTabs-indicator': { color: colors.blue, backgroundColor: colors.blue },
  '& .MuiTab-root': {
    color: colors.gray,
    fontWeight: 500,
    textTransform: 'none',
    marginRight: '60px',
    padding: '0',
    fontSize: '16px',
    lineHeight: '32px',
  },
  '& .Mui-selected': { color: colors.blue, fontWeight: 700 },
};

const searchTextStyles = {
  border: '1px solid #EFEFEF',
  borderRadius: '12px',
  padding: '0px',
  marginTop: '16px',
  width: '100%',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#757575',
};

const StartNew = ({ targetWidth, pageType }) => {
  const { user } = useSelectedContext((context) => context.user);

  const [getCompany, { data: userCompanies }] = useLazyQuery(SHOW_ACTIVE_USER_COMPANIES, {
    fetchPolicy: 'network-only',
  });

  const [companyId, setCompanyId] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [isCarbonModalOpen, setIsCarbonModalOpen] = useState(false);
  const [isNeedCompanyModalOpen, setIsNeedCompanyModalOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [extCompanies, setExtCompanies] = useState([]);
  const [tabValue, setTabValue] = useState('my');
  const [searchValue, setSearchValue] = useState('');

  const [choosenCalc, setChoosenCalc] = useState(null);

  const endpointMarket = process.env.REACT_APP_MARKET || '';
  const endpointCalculator = process.env.REACT_APP_ESG_CALC || '';
  const endpointReport = process.env.REACT_APP_REPORT || '';

  const redirectMarket = (url) => {
    window.location.href = `${endpointMarket}${url}`;
  };

  const redirectCalculator = (url) => {
    window.location.href = `${endpointCalculator}${url}`;
  };

  const redirectReport = (url) => {
    window.location.href = `${endpointReport}${url}`;
  };

  useEffect(() => {
    if (!choosenCalc || !selectedCompany) return;
    if (pageType === CALC_PAGE_TYPES.BENCHMARK)
      redirectCalculator(`/startCalculator/${choosenCalc?.name}/${selectedCompany}`);
    else redirectReport(`/startReport?type=${choosenCalc.name}&companyId=${selectedCompany}`);
  }, [choosenCalc, selectedCompany]);

  const onChoiseCompany = () => {
    if (!companyId) return;

    setSelectedCompany(companyId);
    setIsCompanyModalOpen(false);
  };

  const onTabChange = (event, newValue) => {
    setTabValue(newValue);
    setCompanyId(null);
    setSelectedCompany(null);
  };

  const onSelectChange = (event) => {
    setTabValue(event.target.value);
    setCompanyId(null);
    setSelectedCompany(null);
  };

  const onSearchChange = (e) => {
    setSearchValue(e.target.value.toLowerCase());
    setCompanyId(null);
    setSelectedCompany(null);
  };

  const onCalculatorStart = (e, name) => {
    setChoosenCalc({ name });
    if (user?.role_name === 'individual') {
      setIsRoleModalOpen(true);
      return;
    }
    if (!companies.length) {
      setIsNeedCompanyModalOpen(true);
      return;
    }
    if (!selectedCompany) {
      setIsCompanyModalOpen(true);
    }
  };

  const companyValidateList = {
    companyName: 'Company name',
    establishedDate: 'Establish date',
    addressLocation: 'Country, Town/City, Location, Zip/Postal code',
    address: 'Town/City, Location',
    countryId: 'Country',
    postcode: 'Zip/Postal code',
    industryGroupId: 'Industry group',
    subIndustryId: 'Prime subindustry',
    legalTypeId: 'Company legal type',
  };

  const getCompanyWarning = (c) => {
    const warn = Object.keys(companyValidateList).reduce((res, key) => {
      if (!c[key] && !c.addressLocation[key]) res.push(companyValidateList[key]);
      return res;
    }, []);
    return warn;
  };

  const getCompanyWarningText = (c) =>
    `The company does not have information about such parameters: ${getCompanyWarning(c).join(
      ','
    )}`;

  useEffect(() => {
    if (!user.id) return;
    getCompany();
  }, [user]);

  useEffect(() => {
    if (!userCompanies || !userCompanies.showActiveUserCompanies) return;
    if (userCompanies.showActiveUserCompanies) {
      setCompanies([...userCompanies.showActiveUserCompanies]);
    }
  }, [userCompanies]);

  const isFilterEnabled =
    (tabValue === 'my' && companies.length > 4) ||
    (tabValue === 'external' && extCompanies.length > 4);

  return (
    <>
      <Modal
        open={isCarbonModalOpen}
        onClose={() => setIsCarbonModalOpen(false)}
        onClick={(e) => e.stopPropagation()}
        title="TT Carbon Footprint calculator"
        containerClassName={styles.modalRoot}
        className={styles.modal}
      >
        <div className={styles.carbonModalBlock}>
          <CalcInfoBlock
            headerText="Annual assessment"
            description={`Harnessing the "Annual Assessment" option, you can assess your
              company's carbon footprint for a specific year. The company calculator
              comprehensively analyzes the annual carbon footprint of your business
              activities. As per the GHG protocol, this carbon footprint assessment
              is categorized into three scopes.`}
            onButtonClick={(e) => onCalculatorStart(e, 'carbon')}
          />
          <CalcInfoBlock
            headerText="Project assessment"
            description={`Harnessing the "Project Assessment," you can assess the carbon
              footprint of various company projects, from house construction to
              organizing events or producing batches of products. It includes
              calculators for company operations, production, and packaging.`}
            onButtonClick={(e) => onCalculatorStart(e, 'project')}
          />
        </div>
      </Modal>
      <Modal
        open={isRoleModalOpen}
        onClose={() => setIsRoleModalOpen(false)}
        onClick={(e) => e.stopPropagation()}
        title="Upgrade your&nbsp;role"
        className={styles.modal}
      >
        <div className={styles.text}>
          We&apos;re sorry. This functionality is not available for your role.
        </div>
        <div className={clsx(styles.buttonBlock, styles.roleBB)}>
          <Button type="default" onClick={() => setIsRoleModalOpen(false)}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => {}}>
            Upgrade your role
          </Button>
        </div>
      </Modal>
      <Modal
        open={isNeedCompanyModalOpen}
        onClose={() => setIsNeedCompanyModalOpen(false)}
        onClick={(e) => e.stopPropagation()}
        title="Create a&nbsp;company profile"
        className={styles.modal}
      >
        <div className={styles.text}>
          In order to use this functionality, please create a profile of your company.
        </div>
        <div className={clsx(styles.buttonBlock, styles.companyBB)}>
          <Button type="default" onClick={() => setIsNeedCompanyModalOpen(false)}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => redirectMarket('/dashboard/companiesList')}>
            Create a company profile
          </Button>
        </div>
      </Modal>
      <Modal
        open={isCompanyModalOpen}
        onClose={() => setIsCompanyModalOpen(false)}
        onClick={(e) => e.stopPropagation()}
        title="Select company"
        className={styles.modal}
      >
        <Box sx={{ width: '100%', typography: 'body1', marginBottom: '12px' }}>
          <div className={styles.tabWrapper}>
            <Tabs
              value={tabValue}
              onChange={onTabChange}
              aria-label="companies tabs"
              sx={{ ...tabStyles }}
            >
              {menu.map((item) => (
                <Tab key={item.value} label={item.name} value={item.value} />
              ))}
            </Tabs>
          </div>
          <div className={styles.selectWrapper}>
            <Select
              value={tabValue}
              className={styles.selectType}
              onChange={onSelectChange}
              size="medium"
              sx={{
                '.MuiOutlinedInput-notchedOutline': { border: '0 !important' },
                '.MuiSelect-select': { padding: '12px 0 12px 18px !important' },
              }}
            >
              {menu.map((item) => (
                <MenuItem
                  className={clsx(tabValue === item.value ? styles.active : '')}
                  key={item.value}
                  value={item.value}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          {isFilterEnabled && (
            <TextInput
              className={clsx(styles.filter, styles.searchFilter)}
              startIcon={<SearchIcon />}
              name="search"
              label="Search"
              value={searchValue}
              onChange={onSearchChange}
              required={false}
              sx={searchTextStyles}
            />
          )}
        </Box>
        <div className={clsx(styles.listWrapper, isFilterEnabled && styles.filter)}>
          {(companies.length || extCompanies.length) && (
            <List sx={{ width: '100%', paddingRight: '10px' }}>
              {(tabValue === 'my' ? companies : extCompanies)
                .filter((item) => item.companyName.toLowerCase().includes(searchValue))
                .map((com, idx) => (
                  <ListItem key={`${idx.toString()}${com.companyName}`} alignItems="flex-start">
                    <ListItemButton
                      className={clsx(styles.listItem, com.id === companyId && styles.selected)}
                      disabled={getCompanyWarning(com).length > 0}
                      onClick={() => setCompanyId((value) => (value === com.id ? null : com.id))}
                    >
                      <CompanyBlock company={com} />
                    </ListItemButton>
                    {!!getCompanyWarning(com).length && (
                      <HelpTooltip tooltipText={getCompanyWarningText(com)} />
                    )}
                  </ListItem>
                ))}
            </List>
          )}
          {((tabValue === 'my' && !companies.length) ||
            (tabValue === 'external' && !extCompanies.length)) && (
            <div>There are no external companies yet</div>
          )}
        </div>
        <div className={styles.buttonBlock}>
          <Button type="default" onClick={() => setIsCompanyModalOpen(false)}>
            Cancel
          </Button>
          <Button type="primary" onClick={onChoiseCompany} disabled={!companyId}>
            Select
          </Button>
        </div>
      </Modal>
      <div className={styles.container}>
        <>
          {CALCS_PER_PAGE[pageType]
            .filter((calc) => calc !== CALC_TYPES.PROJECT)
            .map((calc) => (
              <CalcInfoBlock
                icon={<CalcIcon type={calc} />}
                headerText={CALC_NAMES[calc]}
                description={CALC_DESCRIPTIONS[calc]}
                onButtonClick={(e) =>
                  calc === CALC_TYPES.CARBON
                    ? setIsCarbonModalOpen(true)
                    : onCalculatorStart(e, calc)
                }
                isDisabled={calcInDevelopnentList[calc]}
                targetWidth={targetWidth}
              />
            ))}
        </>
      </div>
    </>
  );
};

export default StartNew;
