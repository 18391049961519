import { Grid, Card, IconButton, ButtonBase } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Heart2Icon, Image, MessageIcon, OrdersIcon } from 'tt-ui-kit';
import { AppAvatar } from 'components/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useNavigate } from 'react-router-dom';
import styles from '../../../CommunityPage.module.scss';
import { GET_USER } from 'api';
import { useLazyQuery } from '@apollo/client';

const GridListItem = ({ user }) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [statisUser, setStatusUser] = useState();
  const router = useNavigate();

  const [getUser] = useLazyQuery(GET_USER, { variables: { id: user.id } });

  const getUserValue = async () => {
    const userValue = await getUser();
    if (userValue.data.user) {
      setStatusUser(userValue.data.user.status);
    }
  };

  // useEffect(() => {
  //   getUserValue();
  // }, []);

  return (
    <Grid item key={user.id} xs={6} sm={4} md={3} xl={3} className={styles.cardBlock}>
      <Card className={styles.card}>
        <div className={styles.cardImageBlock}>
          <div
            className={styles.cardImage}
            onClick={() => router(`/dashboard/profile/${user?.id}`)}
          >
            <AppAvatar
              userName={`${user.first_name} ${user.last_name}`}
              role={user.roles && user.roles[0]?.name}
              src={user.avatarSmall}
              nav={0}
              size="lg"
            />
          </div>

          <div
            className={styles.gridCardName}
            onClick={() => router(`/dashboard/profile/${user?.id}`)}
          >{`${user.first_name} ${user.last_name}`}</div>
        </div>
        {user.experiences && user.experiences.length > 0 && (
          <div
            className={styles.gridCardDescription}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <OrdersIcon style={{ marginRight: 5, width: 14, height: 14, flexShrink: 0 }} />
            <p>
              {user.experiences[0]?.title} in{' '}
              <span style={{ color: '#4CAF50' }}>{user.experiences[0]?.company_name}</span>
            </p>
          </div>
        )}
        <div
          className={styles.gridCardDescription}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}
        >
          {statisUser === 'blocked' ? (
            <p style={{ color: 'red' }}>This user is blocked</p>
          ) : user.ceo_of ? (
            <p>
              Expert in <span style={{ color: '#143471' }}>{user.ceo_of}</span>
            </p>
          ) : (
            <>
              {user.roles[0].name === 'expert' ? (
                <p className={styles.rolesName} style={{ color: '#0ab2ab' }}>
                  Expert
                </p>
              ) : user.roles[0].name === 'investor' ? (
                <p className={styles.rolesName} style={{ color: '#3C7097' }}>
                  Investor
                </p>
              ) : null}
            </>
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {user.profession && (
            <>
              <Image src="/images/templates/bag-icon.svg" style={{ marginRight: 5 }} />
              <div className={styles.cardMoreInfo}>{user.profession}</div>
            </>
          )}
        </div>
        <div className={styles.cardActions}>
          <IconButton disabled={statisUser === 'blocked'}>
            <Heart2Icon
              className={statisUser === 'blocked' ? styles.blockedItemIcon : styles.itemIcon}
            />
          </IconButton>
          {/* TODO Gregory message */}
          {user.id !== 'c3c2c22e-2baf-41cc-afbb-1dc3f9d2d1f4' && (
            <ButtonBase
              type="button"
              sx={{ marginRight: '20px' }}
              className={styles.currentButton}
              onClick={() => router(`/dashboard/messaging?profile=${user.id}`)}
              disabled={statisUser === 'blocked'}
            >
              <MessageIcon
                className={statisUser === 'blocked' ? styles.blockedItemIcon : styles.itemIcon}
              />
            </ButtonBase>
          )}
          {/* <IconButton onClick={() => setOpenOptions(user.id)}>
            <MoreVertIcon sx={{ color: '#18397A' }} />
          </IconButton>
          {openOptions && (
            <div
              style={{ marginTop: 50, marginRight: -36 }}
              className={styles.optionsWindow}
              onMouseLeave={() => setOpenOptions(false)}
            >
              <ButtonBase className={styles.options} onClick={() => setOpenOptions(false)}>
                Follow
              </ButtonBase>
              <ButtonBase
                className={styles.options}
                onClick={() => setOpenOptions(false)}
                sx={{ marginTop: '16px' }}
              >
                Connect
              </ButtonBase>
            </div>
          )} */}
        </div>
      </Card>
    </Grid>
  );
};

export default GridListItem;
