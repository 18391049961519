/* eslint-disable camelcase */
/* eslint-disable no-useless-escape */
import * as Yup from 'yup';

export const getValidationSchema = () =>
  Yup.object({
    email: Yup.string().email().required('Please fill out the rest fields. This is mandatory.'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Please fill out the rest fields. This is mandatory.'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Confirmation doesn`t match your password')
      .required('Please fill out the rest fields. This is mandatory.'),
  });
