import { CardIcon, MailIcon, PhoneIcon } from 'tt-ui-kit';

export const PROFILE_MENU_CURRENT_USER_EXPERT = [
  { name: 'About' },
  { name: 'Professional background' },
  // { name: 'Documents' },
  // { name: 'Reviews' },
];

export const PROFILE_MENU_CURRENT_USER = [
  { name: 'About' },
  // { name: 'Documents' },
  // { name: 'Reviews' },
];

export const CONFIRMED_ITEMS = [
  {
    text: 'Phone number',
    icon: (props) => <PhoneIcon {...props} />,
    iconWidth: 22,
    iconHeight: 22,
  },
  {
    text: 'Email address',
    icon: (props) => <MailIcon {...props} />,
    iconWidth: 22,
    iconHeight: 22,
  },
  {
    text: 'Credit card',
    icon: (props) => <CardIcon {...props} />,
    iconWidth: 22,
    iconHeight: 22,
  },
];

export const PROF_BACKGROUND_ITEMS = [
  {
    title: 'Education',
    key: 'educations',
  },
  {
    title: 'Experience',
    key: 'experiences',
  },
  {
    title: 'Collaborations',
    key: 'projects',
  },
];

export const FAKE_LOCATION_DATA = {
  address: '36A, Trakt Lubelski, Nadwiśle, Вавер, Warsaw, Masovian Voivodeship, 04-870, Poland',
  data: {
    country: 'Poland',
    postcode: '04-870',
    locality: '',
    latLng: {
      lat: '52.1756393',
      lng: '21.153994568194445',
    },
  },
};
