export const SLICES_AMOUNT = 17;

export const CHART640 = {
  size: 640,
  radius: 249.044586,
  strokeWidthExternal: 140,
  strokeWidthInternal: 110,
  circumference: 1564,
  sliceLength: 92,
  offset: 368,
  chartTitle: 'Sustainable Development Goals',
  fontSize: '24px',
  lineHeight: '36px',
  titleWidth: 180,
  titleHeight: 108,
};

export const CHART320 = {
  size: 320,
  radius: 124.522293,
  strokeWidthExternal: 70,
  strokeWidthInternal: 55,
  circumference: 782,
  sliceLength: 46,
  offset: 184,
  chartTitle: 'Sustainable Development Goals',
  fontSize: '16px',
  lineHeight: '24px',
  titleWidth: 118,
  titleHeight: 68,
};

const iconSrc = (name) => `/images/templates/sdg/${name}.png`;
const iconSrcXL = (name) => `/images/templates/sdg/${name}-xl.png`;
const goalBg = (name) => `/images/sdg-bg/${name}-bg.png`;

export const SLICES = [
  {
    name: 'poverty',
    goal: 1,
    stroke: '#E5243B',
    icon: iconSrc('poverty'),
    iconXL: iconSrcXL('poverty'),
    goalBg: goalBg('poverty'),
    iconWidth: '12.9%',
    iconHeight: '6.48%',
    iconXlWidth: '280px',
    iconXlHeight: '140px',
    iconLWidth: 98,
    iconLHeight: 40,
    top: '12.5%',
    left: '53.5%',
    title: 'NO POVERTY',
    subTitle: 'End poverty in all its forms everywhere.',
    description: `Eradicating poverty is not a task for charities; it's an act of justice and the key to unlocking enormous human potential. Still, nearly half of the world’s population lives in poverty, and lack of food and clean water is killing thousands of people every day.
      <br/><br/>
      Many local producers and farmers around the world are forced to sell their products at undervalued prices without direct access to markets. Traceability gives them access to fair market exchange and enables farmers to receive fair money for their labor. 
      <br/><br/>
      As a digital platform, TransparenTerra provides a number of tools that give seamless access to larger local or even global markets. Traceable value chains and corporate data transparency are required to make the private sector fairer and a place where employees receive the wages they deserve.
    `,
  },
  {
    name: 'hunger',
    goal: 2,
    stroke: '#DDA63A',
    icon: iconSrc('hunger'),
    iconXL: iconSrcXL('hunger'),
    goalBg: goalBg('hunger'),
    iconWidth: '6.56%',
    iconHeight: '5%',
    iconXlWidth: '129.54px',
    iconXlHeight: '98px',
    iconLWidth: 48,
    iconLHeight: 31,
    top: '19%',
    left: '69%',
    title: 'ZERO HUNGER',
    subTitle:
      'End hunger, achieve food security and improved nutrition and promote sustainable agriculture.',
    description: `Hunger is the leading cause of death in the world. Our planet has provided us with tremendous resources, but unequal access and inefficient handling of food leaves millions of people malnourished.
      <br/><br/>
      TransparenTerra supports efforts to overcome this global issue by:
      <br/><br/>
      •	Providing digital tools for developing the local rural population.
      <br/>
      •	Bridging the gap between wealthy and poor parts of the world and within the local areas of countries.
      <br/>
      •	Supporting NGOs and charity organizations efficiently realize their programs harnessing new technologies.
      <br/>
      •	Shining a spotlight on local problems to make the global population aware of them.
      <br/>
      •	Directing donations from around the world directly to starving people.
    `,
  },
  {
    name: 'health',
    goal: 3,
    stroke: '#4C9F38',
    icon: iconSrc('health'),
    iconXL: iconSrcXL('health'),
    goalBg: goalBg('health'),
    iconWidth: '8.44%',
    iconHeight: '5.7%',
    iconXlWidth: '167.39px',
    iconXlHeight: '113.56px',
    iconLWidth: 48,
    iconLHeight: 33,
    top: '29%',
    left: '76%',
    title: 'GOOD HEALTH AND WELL-BEING',
    subTitle: 'Ensure healthy lives and promote well-being for all at all ages.',
    description: `Over the last 15 years, the number of childhood deaths has been cut in half. This proves that it is possible to win the fight against almost every disease.
      <br/><br/>
      TransparenTerra supports companies that support the values of a healthy lifestyle. Businesses in the TransparenTerra marketplace offer affordable, sustainable, and healthy products, and bloggers promote the values of healthy living. Vendors, experts, and customers join the TransparenTerra platform to create a healthy and prosperous environment and make a healthy lifestyle more accessible. 
    `,
  },
  {
    name: 'book',
    goal: 4,
    stroke: '#C5192D',
    icon: iconSrc('book'),
    iconXL: iconSrcXL('book'),
    goalBg: goalBg('book'),
    iconWidth: '6.88%',
    iconHeight: '5.47%',
    iconXlWidth: '136.51px',
    iconXlHeight: '108.11px',
    iconLWidth: 40,
    iconLHeight: 31,
    top: '41.5%',
    left: '81.5%',
    title: 'QUALITY EDUCATION',
    subTitle:
      'Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.',
    description: `Education liberates the intellect, unlocks the imagination, and is fundamental for self-respect. It is the key to prosperity and opens a world of opportunities, making it possible for each of us to contribute to a progressive, healthy society. Learning benefits every human being and should be available to all.
      <br/><br/>
      TransparenTerra has a range of tools that grant access to quality education anywhere where there is internet access. Internal video chat via an interactive whiteboard allows teachers to reach pupils and students underdeveloped regions of the world and provides a high-quality and affordable educational methodology.
    `,
  },
  {
    name: 'gender',
    goal: 5,
    stroke: '#FF3A21',
    icon: iconSrc('gender'),
    iconXL: iconSrcXL('gender'),
    goalBg: goalBg('gender'),
    iconWidth: '6.25%',
    iconHeight: '8.2%',
    iconXlWidth: '93.33px',
    iconXlHeight: '121.98px',
    iconLWidth: 27,
    iconLHeight: 35,
    top: '53.5%',
    left: '82%',
    title: 'GENDER EQUALITY',
    subTitle: 'Achieve gender equality and empower all women and girls.',
    description: `Gender bias is undermining our social fabric and devalues all of us. It is not just a human rights issue; it is a tremendous waste of the world’s human potential. By denying women equal rights, we deny half the population a chance to live life at its fullest. Political, economic, and social equality for women will benefit all the world’s citizens.
      <br/><br/>
      TransparenTerra supports equality. TransparenTerra supports women and makes their efforts in business and non-profit organizations visible. The platform highlights the value of women's efforts at all levels. TransparenTerra's traceability system, digital notarization, and other tools prevent women from being paid unfairly. 
    `,
  },
  {
    name: 'water',
    goal: 6,
    stroke: '#26BDE2',
    icon: iconSrc('water'),
    iconXL: iconSrcXL('water'),
    goalBg: goalBg('water'),
    iconWidth: '5.47%',
    iconHeight: '7.27%',
    iconXlWidth: '81.21px',
    iconXlHeight: '108.11px',
    iconLWidth: 24,
    iconLHeight: 31,
    top: '66.5%',
    left: '78%',
    title: 'CLEAN WATER AND SANITATION',
    subTitle: 'Ensure availability and sustainable management of water and sanitation for all.',
    description: `One in three people live without sanitation. This is causing unnecessary disease and death. Although huge strides have been made to improve access to clean drinking water, lack of sanitation is undermining these advances.
      <br/><br/>
      TransparenTerra supports the mindful and careful consumption of fresh water and the reuse of water resources. One of TransparenTerra's main tools is a water usage calculator that companies, experts, and customers can use. Accurate estimation of water consumption is necessary to identify the right ways to conserve water resources. 
      The calculator identifies processes that require large quantities of water and offers basic options for optimizing these processes. For more detailed and effective water reduction planning, users can get advice from platform experts.
      <br/><br/>
      TransparenTerra's blockchain-based solutions also support the collection of IoT data for permanent monitoring and instance alarms in the event of environmental accidents.
    `,
  },
  {
    name: 'light',
    goal: 7,
    stroke: '#FCC30B',
    icon: iconSrc('light'),
    iconXL: iconSrcXL('light'),
    goalBg: goalBg('light'),
    iconWidth: '7.77%',
    iconHeight: '7.77%',
    iconXlWidth: '115.89px',
    iconXlHeight: '115.89px',
    iconLWidth: 40,
    iconLHeight: 40,
    top: '75.75%',
    left: '68%',
    title: 'AFFORDABLE AND CLEAN ENERGY',
    subTitle: 'Ensure access to affordable, reliable, sustainable and modern energy for all.',
    description: `Renewable energy solutions are becoming cheaper, more reliable, and more efficient every day. Our current reliance on fossil fuels is unsustainable and harmful to the planet, which is why we must change the way we produce and consume energy. Implementing these new energy solutions as quickly as possible is essential to counter the effects of climate change, one of the biggest threats to the long-term survival of humanity.
      <br/><br/>
      Energy consumption calculators are part of TransparenTerra's core toolkit. Tracking energy sources and calculating energy consumption is the first step to conserving energy and switching to clean energy sources. The calculators also identify the most energy-consuming processes and suggest basic optimization options. TransparenTerra's experts offer detailed advice for the management of the electrical footprint.
      <br/><br/>
      Trustworthy traceability solutions help track and trace the origin and destination of clean energy. 
    `,
  },
  {
    name: 'growth',
    goal: 8,
    stroke: '#A21942',
    icon: iconSrc('growth'),
    iconXL: iconSrcXL('growth'),
    goalBg: goalBg('growth'),
    iconWidth: '7.41%',
    iconHeight: '7.58%',
    iconXlWidth: '110.44px',
    iconXlHeight: '113.12px',
    iconLWidth: 32,
    iconLHeight: 33,
    top: '80.75%',
    left: '55.5%',
    title: 'DECENT WORK AND ECONOMIC GROWTH',
    subTitle:
      'Promote sustained, inclusive, and sustainable economic growth, full and productive employment, and decent work for all.',
    description: `Economic growth should be a positive force for the whole planet. This is why we must ensure that financial progress creates decent and fulfilling jobs while not harming the environment. We must protect labor rights and permanently end modern slavery and child labor.
      <br/><br/>
      TransparenTerra provides a favorable and fair environment for sustainable small businesses. Transparency, traceability, and document flow tools track product origin and employee merit and prevent unfair pay.
      <br/><br/>
      TransparenTerra brings together experts, businesses, investors, and individuals to build transparency and ultimate trust for professional, personal, and social good. TransparenTerra supports local SMEs and communities by providing digital tools for successful local businesses.
    `,
  },
  {
    name: 'industry',
    goal: 9,
    stroke: '#FD6925',
    icon: iconSrc('industry'),
    iconXL: iconSrcXL('industry'),
    goalBg: goalBg('industry'),
    iconWidth: '7.73%',
    iconHeight: '7.73%',
    iconXlWidth: '115.11px',
    iconXlHeight: '112.78px',
    iconLWidth: 33,
    iconLHeight: 33,
    top: '82.5%',
    left: '42.5%',
    title: 'INDUSTRY, INNOVATION AND INFRASTRUCTURE',
    subTitle:
      'Build resilient infrastructure, promote inclusive and sustainable industrialization, and foster innovation.',
    description: `Functioning and resilient infrastructure is the foundation of every successful community. To meet future challenges, our industries and infrastructure must be upgraded.
      <br/><br/>
      TransparenTerra provides disruptive digital tools for sustainable industrial development, including a blockchain-based immutable data ledger, tracking of the safety and sustainability of production from raw material extraction to the end consumer, monitoring and discovery opportunities for the circular economy (recycling and reuse of materials), and tools for data-driven planning of efficient and sustainable business models. TransparenTerra's expert community also promotes solutions for business sustainability and efficiency.
    `,
  },
  {
    name: 'inequality',
    goal: 10,
    stroke: '#DD1367',
    icon: iconSrc('inequality'),
    iconXL: iconSrcXL('inequality'),
    goalBg: goalBg('inequality'),
    iconWidth: '8.23%',
    iconHeight: '8.23%',
    iconXlWidth: '122.89px',
    iconXlHeight: '122.89px',
    iconLWidth: 35,
    iconLHeight: 35,
    top: '78.5%',
    left: '29.5%',
    title: 'REDUCED INEQUALITY',
    subTitle: 'Reduce inequality within and among countries.',
    description: `Too much of the world’s wealth is held by a very small group of people. This state of affairs leads to financial and social discrimination. In order for nations to flourish, equality and prosperity must be available to everyone regardless of gender, race, religious belief, or economic status.
      <br/><br/>
      The transparency, traceability, quality, and sustainability assurance provided by TransparenTerra give local companies and communities access to global markets and international collaboration. By showing proof of quality and origin validation, businesses in developing countries gain access to the international marketplace, fair fees, and a trustworthy reputation. Simultaneously, local communities gain access to market data, which is crucial to their sustainable development.
    `,
  },
  {
    name: 'city',
    goal: 11,
    stroke: '#FD9D24',
    icon: iconSrc('city'),
    iconXL: iconSrcXL('city'),
    goalBg: goalBg('city'),
    iconWidth: '7.73%',
    iconHeight: '5.78%',
    iconXlWidth: '154px',
    iconXlHeight: '115.08px',
    iconLWidth: 44,
    iconLHeight: 33,
    top: '71%',
    left: '19%',
    title: 'SUSTAINABLE CITIES AND COMMUNITIES',
    subTitle: 'Make cities and human settlements inclusive, safe, resilient, and sustainable.',
    description: `The world’s population is constantly increasing. To accommodate everyone, we need to build modern, sustainable cities. To survive and prosper, we need new, intelligent urban planning that creates safe, affordable, and resilient cities that offer green and culturally inspiring living conditions.
      <br/><br/>
      Since transparency is a basic value for the TransparenTerra community, the platform supports the responsible use of natural resources, environmental protection, and sustainable community development. TransparenTerra provides tools for building sustainable human settlement infrastructure, assessment methodologies, learning, and value exchange.
    `,
  },
  {
    name: 'renewal',
    goal: 12,
    stroke: '#BF8B2E',
    icon: iconSrc('renewal'),
    iconXL: iconSrcXL('renewal'),
    goalBg: goalBg('renewal'),
    iconWidth: '7.66%',
    iconHeight: '3.91%',
    iconXlWidth: '151.67px',
    iconXlHeight: '77.78px',
    iconLWidth: 43,
    iconLHeight: 22,
    top: '61.25%',
    left: '12.75%',
    title: 'RESPONSIBLE CONSUMPTION AND PRODUCTION',
    subTitle:
      'Our planet has provided us with an abundance of natural resources. But we have not used them responsibly and currently consume far beyond what our planet can provide. We must develop and maintain sustainable consumption and production patterns.',
    description: `TransparenTerra promotes and encourages sustainable resource consumption and environmental impact reduction. TransparenTerra offers a suite of tools to measure resource consumption, evaluate its efficiency, and define paths to optimize it. TransparenTerra's carbon footprint calculators and expert community aim to support companies that want to make their business models rational and sustainable.
      <br/><br/>
      TransparenTerra supports the potential to build a circular economy. Connecting companies within a unified traceability system provides the best opportunity to foster mindful resource consumption.
    `,
  },
  {
    name: 'climate',
    goal: 13,
    stroke: '#3F7E44',
    icon: iconSrc('climate'),
    iconXL: iconSrcXL('climate'),
    goalBg: goalBg('climate'),
    iconWidth: '8.67%',
    iconHeight: '4.45%',
    iconXlWidth: '172.67px',
    iconXlHeight: '88.67px',
    iconLWidth: 49,
    iconLHeight: 25,
    top: '47.75%',
    left: '9.75%',
    title: 'CLIMATE ACTION',
    subTitle: 'Take urgent action to combat climate change and its impacts.',
    description: `Climate change is a real and undeniable threat to human civilization. The effects are already visible and will be catastrophic unless we act now. Through education, innovation, and adherence to our climate commitments, we can make the necessary changes to protect the planet.
      <br/><br/>
      TransparenTerra actively supports the movement towards a zero-waste, carbon-free economy.  TransparenTerra's team developed and validated a unique CO2 footprint calculator for SMEs. The CO2 calculator is a core digital tool that makes climate impact visible, measurable, and improvable. The platform's expert community proactively provides advice on GHG emissions reduction.
    `,
  },
  {
    name: 'ocean',
    goal: 14,
    stroke: '#0A97D9',
    icon: iconSrc('ocean'),
    iconXL: iconSrcXL('ocean'),
    goalBg: goalBg('ocean'),
    iconWidth: '7.66%',
    iconHeight: '6.48%',
    iconXlWidth: '114.19px',
    iconXlHeight: '96.6px',
    iconLWidth: 33,
    iconLHeight: 28,
    top: '34%',
    left: '13%',
    title: 'LIFE BELOW WATER',
    subTitle:
      'Conserve and sustainably use the oceans, seas, and marine resources for sustainable development.',
    description: `Healthy oceans and seas are essential to our existence. Oceans cover 70 percent of our planet, and we rely on them for food, energy, and water. Yet, we have managed to inflict tremendous damage to these precious resources.
      <br/><br/>
      It is essential to have quality data on human activity in and on the world’s oceans and to track this impact on the marine, lake, and river ecosystems to map sustainable and careful use of sea resources. TransparenTerra provides tools to transparently track fishing products from rod/net to table and an expert advisory network ready to support optimization of the supply chain. Consumption transparency and mindful self-limitation are the first and indispensable steps in maintaining resilient ecosystems. 
    `,
  },
  {
    name: 'land',
    goal: 15,
    stroke: '#56C02B',
    icon: iconSrc('land'),
    iconXL: iconSrcXL('land'),
    goalBg: goalBg('land'),
    iconWidth: '7.27%',
    iconHeight: '7.11%',
    iconXlWidth: '108.16px',
    iconXlHeight: '106.05px',
    iconLWidth: 31,
    iconLHeight: 30,
    top: '22%',
    left: '20%',
    title: 'LIFE ON LAND',
    subTitle:
      'Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and biodiversity loss.',
    description: `Flourishing life on land is the foundation of our life on this planet. We are all part of the planet’s ecosystem but have caused severe damage to it through deforestation, loss of natural habitats, and land degradation.
      <br/><br/>
      Deforestation and land degradation threaten not only the success of agri-industry but also political and social stability in developing countries. The TransparenTerra IoT platform collects data from independent IoT data providers who are ready to share this data with the IoT community on a subscription basis.
      <br/><br/>
      Seamless access to this data enables the creation of independent reports about the state of deforestation, biodiversity, and land degradation. Wearable IoT sensors can prevent poaching and trafficking of protected species.
    `,
  },
  {
    name: 'peace',
    goal: 16,
    stroke: '#00689D',
    icon: iconSrc('peace'),
    iconXL: iconSrcXL('peace'),
    goalBg: goalBg('peace'),
    iconWidth: '7.5%',
    iconHeight: '7.34%',
    iconXlWidth: '112px',
    iconXlHeight: '110.21px',
    iconLWidth: 33,
    iconLHeight: 32,
    top: '14%',
    left: '30.5%',
    title: 'PEACE, JUSTICE AND STRONG INSTITUTIONS',
    subTitle:
      'Promote peaceful and inclusive societies for sustainable development, provide access to justice for all, and build effective, accountable, and inclusive institutions at all levels.',
    description: `Compassion and a strong moral compass are essential to every democratic society. Yet, persecution, injustice, and abuse still run rampant and are tearing the very fabric of civilization.
      <br/><br/>
      Transparency is vital to a fair and sustainable society. TransparenTerra fosters responsible and inclusive communities at all levels, from small communities to global corporations and governments. The platform brings together experts, investors, projects, companies, and customers to act together to combat pressing contemporary issues including climate change, social inequality, economic stagnation, hunger, and poverty.
      With the tools and blockchain ecosystem by FCE, TransparenTerra promotes transparency at all steps of the value chain, paper flows, management, and governance to make corporate and governmental accountability tangible.
    `,
  },
  {
    name: 'partnership',
    goal: 17,
    stroke: '#19486A',
    icon: iconSrc('partnership'),
    iconXL: iconSrcXL('partnership'),
    goalBg: goalBg('partnership'),
    iconWidth: '7.19%',
    iconHeight: '7.19%',
    iconXlWidth: '106.56px',
    iconXlHeight: '105.78px',
    iconLWidth: 31,
    iconLHeight: 31,
    top: '11%',
    left: '43.5%',
    title: 'PARTNERSHIP FOR THE GOALS',
    subTitle:
      'Strengthen the means of implementation and revitalize the global partnership for sustainable development.',
    description: `The Global Goals can only be met if we work together. International investment and support is needed to ensure innovative technological development, fair trade, and market access, especially for developing countries.
      <br/><br/>
      As a global data platform, TransparenTerra provides an effective environment for local and global partnerships of experts, ESG investors, business owners, and individuals to change the current global environment. TransparenTerra sets sustainability goals for itself and every member of the platform, from regular users to large companies, venture capitalists, and high-level experts. The TransparenTerra community is a multi-stage and multi-faceted collaborative business initiative aimed at sustainability, improved living conditions, and a better quality of life for everyone.
    `,
  },
];

export const DESCRIPTION_TITLE = 'TransparenTerra’s contribution<br/>to the SDGs’ achievement';
export const DESCRIPTION_SUBTITLE =
  'TransparenTerra joins the UN<br/>initiative to help achieve the SDGs.';

export const PARAGRAPH_FIRST =
  'The UN Sustainable Development Goals were introduced in 2015 and are supposed to be realized by 2030.';

export const PARAGRAPH_SECOND = `The SDGs consist of 17 goals that focus on economic development, 
  environmental protection, and social well-being through the prism 
  of five pillars. SDGs put people, the planet, prosperity, peace, and partnership at the center.`;

export const PARAGRAPH_THIRD = `Achieving them requires the joint efforts of government, civil society, 
  and business. TransparenTerra is a place where these efforts can 
  come together. TransparenTerra is an advanced digital interactive 
  platform that enables professionals, companies, and ordinary people 
  to track their impact on the SDGs. TransparenTerra provides innovative 
  solutions for better and faster achievement of the SDGs and supports 
  the Community in these endeavors.`;

export const BTN_MAIN_TEXT = 'to the goals';
