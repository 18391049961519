import React from 'react';
import { PersonalAreaBoxWrapper } from './components/PersonalAreaBox';

export const calculateLinks = (componentMode = 'main') => {
  const endpointMain = process.env.REACT_APP_MAIN || '';
  const endpointMarket = process.env.REACT_APP_MARKET || '';
  const endpointDigitalAssets = process.env.REACT_APP_DIGITAL_ASSETS || '';

  const redirectMain = (url) => `${endpointMain}${url}`;
  const redirectMarket = (url) => `${endpointMarket}${url}`;
  const redirectDigitalAssets = (url) => `${endpointDigitalAssets}${url}`;

  switch (componentMode) {
    case 'main':
      return [
        {
          href: redirectMarket('/dashboard/companiesList'),
          name: 'Company Profile',
        },
        {
          href: redirectMarket('/dashboard/marketplace'),
          name: 'Marketplace',
        },
        { href: '/dashboard/financials', name: 'Financials' },
        {
          href: `/dashboard/score_and_reporting`,
          name: 'Score & Reporting',
        },
        {
          href: `/dashboard/tokenize`,
          name: 'Tokenize & Trace',
          disabled: true,
        },
        {
          href: `/dashboard/decarbonizator`,
          name: 'Decarbonizator',
          disabled: true,
        },
        {
          href: `/dashboard/notarizator`,
          name: 'Notarizator',
          disabled: true,
        },
        {
          href: redirectDigitalAssets('/dashboard/digital_assets'),
          name: 'Digital Assets',
        },
        {
          href: `/dashboard/compliance`,
          name: 'Compliance',
          disabled: true,
        },
        { href: '/dashboard/content', name: 'My Content' },
        { href: '/dashboard/events', name: 'Events' },
        {
          href: `/dashboard/saved-folders`,
          name: 'Saved Folders',
          disabled: true,
        },
        {
          href: `/dashboard/archived`,
          name: 'Archived',
          disabled: true,
        },
      ];

    case 'DA':
      return [
        {
          href: redirectMarket('/dashboard/companiesList'),
          name: 'Company Profile',
        },
        {
          href: redirectMarket('/dashboard/marketplace'),
          name: 'Marketplace',
        },
        { href: redirectMain('/dashboard/financials'), name: 'Financials' },
        {
          href: redirectMain('/dashboard/score_and_reporting'),
          name: 'Score & Reporting',
        },
        {
          href: redirectMain(`/dashboard/tokenize`),
          name: 'Tokenize & Trace',
          disabled: true,
        },
        {
          href: redirectMain(`/dashboard/decarbonizator`),
          name: 'Decarbonizator',
          disabled: true,
        },
        {
          href: redirectMain(`/dashboard/notarizator`),
          name: 'Notarizator',
          disabled: true,
        },
        {
          href: '/dashboard/digital_assets',
          name: 'Digital Assets',
        },
        {
          href: redirectMain(`/dashboard/compliance`),
          name: 'Compliance',
          disabled: true,
        },
        { href: redirectMain('/dashboard/content'), name: 'My Content' },
        { href: redirectMain('/dashboard/events'), name: 'Events' },
        {
          href: redirectMain(`/dashboard/saved-folders`),
          name: 'Saved Folders',
          disabled: true,
        },
        {
          href: redirectMain(`/dashboard/archived`),
          name: 'Archived',
          disabled: true,
        },
      ];

    case 'market':
      return [
        {
          href: '/dashboard/companiesList',
          name: 'Company Profile',
        },
        {
          href: '/dashboard/marketplace',
          name: 'Marketplace',
        },
        { href: redirectMain('/dashboard/financials'), name: 'Financials' },
        {
          href: redirectMain('/dashboard/score_and_reporting'),
          name: 'Score & Reporting',
        },
        {
          href: redirectMain(`/dashboard/tokenize`),
          name: 'Tokenize & Trace',
          disabled: true,
        },
        {
          href: redirectMain(`/dashboard/decarbonizator`),
          name: 'Decarbonizator',
          disabled: true,
        },
        {
          href: redirectMain(`/dashboard/notarizator`),
          name: 'Notarizator',
          disabled: true,
        },
        {
          href: redirectDigitalAssets('/dashboard/digital_assets'),
          name: 'Digital Assets',
        },
        {
          href: redirectMain(`/dashboard/compliance`),
          name: 'Compliance',
          disabled: true,
        },
        { href: redirectMain('/dashboard/content'), name: 'My Content' },
        { href: redirectMain('/dashboard/events'), name: 'Events' },
        {
          href: redirectMain(`/dashboard/saved-folders`),
          name: 'Saved Folders',
          disabled: true,
        },
        {
          href: redirectMain(`/dashboard/archived`),
          name: 'Archived',
          disabled: true,
        },
      ];

    default:
      return [];
  }
};

export const PersonalAreaBox = ({
  user,
  pathname,
  firstNameProps,
  lastNameProps,
  avaProps,
  inviteProps,
  ceoProps,
  kycProps,
  mode = 'main', // 'main' | 'DA' | 'market'
  ...restProps
}) => {
  const links = calculateLinks(mode) || [];

  return (
    <PersonalAreaBoxWrapper
      user={user}
      pathname={pathname}
      firstNameProps={firstNameProps}
      lastNameProps={lastNameProps}
      avaProps={avaProps}
      inviteProps={inviteProps}
      ceoProps={ceoProps}
      kycProps={kycProps}
      links={links}
      {...restProps}
    />
  );
};
