import { useState } from 'react';
import styles from './CheckboxWithButton.module.scss';

const CheckboxWithButton = ({
  setIsAgree,
  text,
  handleClose,
  setIsOpen,
  isShouldScroll,
  isScrolled,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles.checkboxWrapper}>
        <input
          className={styles.checkbox}
          id="checkbox"
          type="checkbox"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
          disabled={isShouldScroll && !isScrolled}
        />
        <label htmlFor="checkbox">{text}</label>
      </div>
      <button
        type="button"
        className={styles.btn}
        disabled={!isChecked}
        onClick={() => {
          setIsAgree(true);
          handleClose(setIsOpen);
        }}
      >
        Proceed
      </button>
    </div>
  );
};

export default CheckboxWithButton;
