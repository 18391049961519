/* eslint-disable no-irregular-whitespace */
export const TERMS_DATA = [
  {
    route: 'honor',
    pageTitle: 'TransparenTerra - Honor Code',
    title: 'Honor Code & Community Guideline',
    subTitle: null,
    points: [
      {
        title: 'Welcome to TarsparenTerra',
        text: `TransparenTerra is a Community of like-minded people and experts concerned with the future of the planet, sustainable development, trustworthiness, integrity, and transparency in communications and cooperation. 
          <br/><br/>
          We set ourselves a noble goal—to Restore Trust—and we can achieve it together only by following rules based
          on transparency, mutual respect, and support. 
          <br/>
          We distinguish between privacy and transparency. Our Rules are based on respect for your privacy. In turn, we expect mutual respect and compliance with the transparency requirement, which is the fundamental element of the principal value of our platform: Trust. We invite you to read carefully the Rules of our Community below.
        `,
      },
      {
        title: 'Our Principles',
        text: `TransparenTerra is a new generation of digital platforms combining the elements of social media, expert networks, marketplaces, and investor hubs.<br />
          The TransparenTerra Community aggregates responsible and honest people. We expect all Community members to contribute to maintaining a respectful environment of intelligence, honesty, robust debate, openness, and appreciation for other Community members.        
        `,
      },
      {
        title: 'Roles',
        text: `As a member of the TransparenTerra Community, you can play one or more roles:<br />
        •	Basic user;<br />
        •	Business owner;<br />
        •	Expert; and<br />
        •	Investor.<br />
        <br />
        Depending on your role, you can invite other users, experts, investors, and business owners. Send your invitations to people you know and respect. Inviters are responsible for the members of the Community they invited. All the invitations have unique numbers to track who sent an invitation and who was invited. <br />
        Regardless of your role, we expect you to be respectful and tolerant. Correct and compliant communication is vital to build reliable social relationships and maximize the benefits of being part of a professional network.
        `,
      },
      {
        title: 'For Individuals ',
        text: `Signing up on the platform is enough to become a TransparenTerra Individual. To gain full access to the platform’s options, users must pass the verification process. On our platform, individuals have access to a global marketplace where sellers present their environmentally friendly and sustainable products and services.<br />
        <br />
        You can:<br />
        • Follow and subscribe to stores<br />
        • Add products to your wish list or cart<br />
        • Purchase products and services online<br />
        • Choose payment and delivery methods<br />
        • Donate and support experts and startups<br />
        • Communicate with sellers and experts using the multifunctional chat function with integrated video calls and webinars and<br />
        • Participate in conferences and chat rooms.<br />
        <br />
        Individuals can follow bloggers and experts, as well as their posts, webinars, and podcasts. As a user, you can also create a blog, post articles, express your opinion, look for like-minded people, and expand your network of contacts. You can use chat or video calls to communicate.<br />
        To change your role from one individual to another (Business, Expert, or ESG Investor), you either need to submit a request to TransparenTerra or get an invitation from another member of the Community with a status equal to that which you seek. <br />
        Individuals can recommend experts and shops for registration on TransparenTerra. The recommendation will be referred to TransparenTerra and the board of experts. After a positive review, the request will be accepted, and the new status will be approved.<br />
        `,
      },
      {
        title: 'For Business Owners',
        text: `You can create an&nbsp; online store on&nbsp; the TransparenTerra platform with a&nbsp; Basic, Standard, Business, or&nbsp; Corporate account. To&nbsp; do&nbsp; so, you must receive an&nbsp; invitation from TransparenTerra or&nbsp; another Business Owner, Investor, or&nbsp; Expert on&nbsp; the platform.<br />
          Sustainable businesses are more than welcome to&nbsp; join TransparenTerra. To&nbsp; add products with special certification, you need to&nbsp; attach documents to&nbsp; validate the said certification. Any accompanying documents should be&nbsp; validated by&nbsp; appropriate links, authorities, or&nbsp; on&nbsp; the blockchain. Our Community experts will help you improve your business sustainability. Verified business owners are indicated as&nbsp; such using special signs.<br />
         
          With Basic, Business, and Corporate accounts, you can:<br />
          • process transactions on&nbsp; the platform,<br />
          • accept payments in&nbsp; any convenient method,<br />
          • set delivery methods and options,<br />
          • assign administrators to&nbsp; your store,<br />   
          • chat with your customers or&nbsp; make video calls,<br />
          • maintain a&nbsp; blog,<br />
          • host webinars and audio conferencing,<br />
          • conduct podcasts,<br />
          • acquire products and services from other sellers,<br />
          • receive donations,<br />
          • search for ESG (- Environment, Sustainability, Governance) investors,<br />
          • subscribe to&nbsp; other members of&nbsp; the Community, and<br />
          • invite other users and business owners to&nbsp; the Community.<br />
          
          You can also recommend new experts or&nbsp; investors for the Community.
        `,
      },
      {
        title: 'For Experts',
        text: `You can become a&nbsp; TransparenTerra Community expert with an&nbsp; invitation from TransparenTerra or&nbsp; another expert registered on&nbsp; the platform. Experts are entirely independent Community members presenting independent assessments and advice to&nbsp; other members of&nbsp; the Community. They can act as&nbsp; guarantors of&nbsp; the sustainability of&nbsp; the products, services, or&nbsp; companies. Certified experts have the right to&nbsp; provide consulting services. They can create their stores, host and moderate webinars, post articles, add podcasts, provide online consultations on&nbsp; the platform, and invite other experts, investors, business owners, and users to&nbsp; join the Community. Experts authorized to&nbsp; issue certificates will be&nbsp; highlighted with special signs.
        `,
      },
      {
        title: 'For Investors',
        text: `You can obtain ESG investor status with an&nbsp; invitation from TransparenTerra or&nbsp; experts or&nbsp; investors already verified by&nbsp; TransparenTerra. Investors can search and sort companies to&nbsp; conduct their own due diligence using hashtags, requests, online chats, and consultations and act as&nbsp; influencers and opinion leaders. TransparenTerra blockchain is&nbsp; available for registered investors to&nbsp; notarize their investments, contracts, commitments, and liabilities.<br />
          Investors can invite other users, business owners, and investors. Investors can also recommend experts to&nbsp; the Community.
        `,
      },
      {
        title: 'Rules',
        text: `When you register on&nbsp; TransparenTerra, you agree to&nbsp; follow the Rules below.<br />
          The TransparenTerra Community is&nbsp; a&nbsp; reputation-based network. Reputation has a&nbsp; crucial value for each member of&nbsp; the Community.<br />
          <br />
          By posting any content on the Website you represent and warrant that<br />
          •	you are the creator and owner of the posted content or have all
          necessary rights from other people or companies to use, and permit other users to use your posted content on the Website;<br />
          &nbsp; •	 &nbsp; your posted content does not infringe any third party right, including copyright and other intellectual property rights, privacy rights, rights of publicity; and you agree to hold the Website owner harmless from any claims and indemnify it from any losses incurred by or asserted against the Website owner and resulting from your breach of such representations and warranties.
          <br /><br />
          You also agree not to&nbsp; provoke or&nbsp; encou rage other users to&nbsp; violate the established Rules. Otherwise, this may result in&nbsp; banishment from the Community.<br />
          &nbsp; •	 &nbsp; Members are responsible for the users, business owners, experts, and investors they invited. If&nbsp; an&nbsp; invited member carries out a&nbsp; significant violation of&nbsp; the Rules, it&nbsp; may lead to&nbsp; a&nbsp; warning or&nbsp; banishment from the Community of&nbsp; the invited user or&nbsp; inviter, or&nbsp; both. Besides, the user who invited an&nbsp; unscrupulous member will be&nbsp; held accountable and share risks because of&nbsp; the destructive actions of&nbsp; the invited user to&nbsp; destabilize the functioning of&nbsp; the Community.<br />
          &nbsp; •	 &nbsp; Each member of&nbsp; the Community undertakes to&nbsp; provide accurate personal- and business-related data (company name and real name and address);<br />
          &nbsp; •	 &nbsp; Members are responsible for ensuring that their personal information provided upon registration is&nbsp; accurate, complete, and valid. This information includes the following:<br />
          &nbsp; &nbsp; &nbsp; •	 &nbsp; First and last names<br />
          &nbsp; &nbsp; &nbsp; •	 &nbsp; Billing address<br />
          &nbsp; &nbsp; &nbsp; •	 &nbsp; Residential address<br />
          &nbsp; &nbsp; &nbsp; •	 &nbsp; Delivery address<br />
          &nbsp; &nbsp; &nbsp; •	 &nbsp; Telephone numbers<br />
          &nbsp; •	 &nbsp; When the phone number, place of&nbsp; work, etc., change, members must update the information in&nbsp; their personal accounts.<br />
          &nbsp; •	 &nbsp; TransparenTerra will not accept responsibility for the consequences of&nbsp; incorrect, outdated, invalid, or&nbsp; missing information.<br />
          &nbsp; •	 &nbsp; Members accept all and any risks related to&nbsp; disclosure of&nbsp; the intellectual property they share (for example, trademarks, designs, pictures, texts, etc.);<br />
          &nbsp; •	 &nbsp; Members must maintain only one user account and not allow another person to&nbsp; use their login details;<br />
          &nbsp; •	 &nbsp; The age of&nbsp; Community members must exceed 18 years;<br />
          &nbsp; •	 &nbsp; Members of&nbsp; the Community have no&nbsp; right to&nbsp; resort to&nbsp; abuse, intimidation, or&nbsp; harassment of&nbsp; people and companies;<br />
          &nbsp; •	 &nbsp; Members of&nbsp; the Community cannot discriminate against other members of&nbsp; the Community (based on, for example, race, ethnicity, religion, age, gender, sexuality orientation, gender identity, marital status, disability, medical or&nbsp; genetic condition, or&nbsp; any other category), threaten violence, or&nbsp; cause harm to&nbsp; any member of&nbsp; the Community;<br />
          &nbsp; •	 &nbsp; Members of&nbsp; the Community cannot share with others any personal information received from other users without their written permission and approval;<br />
          &nbsp; •	 &nbsp; Members cannot record or&nbsp; download information received on&nbsp; TransparenTerra; you are entirely responsible for ensuring that you do&nbsp; not disclose any information that is&nbsp; protected by&nbsp; confidentiality agreements;<br />
          &nbsp; •	 &nbsp; Members cannot upload pictures, information, videos, or&nbsp; articles that violate any intellectual or&nbsp; any other property rights;<br />
          &nbsp; •	 &nbsp; Members cannot spread spam, fake, or&nbsp; false information;<br />
          &nbsp; •	 &nbsp; Members cannot plagiarize or&nbsp; engage in&nbsp; any form of&nbsp; dishonesty;<br />
          &nbsp; •	 &nbsp; Members cannot make attempts to&nbsp; decipher, bypass, re-identify, deanonymize, read the reverse hash, or&nbsp; reconstruct hidden platform data;<br />
          &nbsp; •	 &nbsp; Members cannot disseminate information that could harm any person/company or&nbsp; group of&nbsp; people;<br />
          &nbsp; •	 &nbsp; Members cannot publish sexual, pornographic, or&nbsp; extremist information;<br />
          &nbsp; •	 &nbsp; Members cannot advertise resources that contain such information; and<br />
          &nbsp; •	 &nbsp; Members cannot use the platform to&nbsp; commit any illegal actions.
        `,
      },
      {
        title: 'Groups and Channels',
        text: `The discussion group chats and channels serve as&nbsp; a&nbsp; platform for collaboration and enhanced experience through dynamic engagement and productive debate.
          <br /><br />
          The following content and practices are prohibited on&nbsp; the discussion group chats and channels:<br />
          &nbsp; •	 &nbsp; Content that defames or&nbsp; threatens others;<br />
          &nbsp; •	 &nbsp; Harassing statements or&nbsp; content that violates the law;<br />
          &nbsp; •	 &nbsp; Content that discusses illegal activities with the intent to&nbsp; commit them;<br />
          &nbsp; •	 &nbsp; Content that is&nbsp; not your own or&nbsp; that infringes on&nbsp; another’s&nbsp; intellectual property, including, but not limited to, copyrights, trademarks, or&nbsp; trade secrets;<br />
          &nbsp; •	 &nbsp; Content related to&nbsp; partisan political activities;<br />
          &nbsp; •	 &nbsp; Material that contains obscene (i.e., pornographic) language or&nbsp; images;<br />
          &nbsp; •	 &nbsp; Advertising or&nbsp; any form of&nbsp; commercial solicitation or&nbsp; promotion, including links to&nbsp; other sites<br />
          &nbsp; •	 &nbsp; Otherwise unlawful content;<br />
          &nbsp; •	 &nbsp; Intentionally incomplete, misleading, or&nbsp; inaccurate content;<br />
          &nbsp; •	 &nbsp; Violations of&nbsp; the Guideline;<br />
          &nbsp; •	 &nbsp; Posting material or&nbsp; sharing links to&nbsp; material that is&nbsp; sexually explicit or&nbsp; suggestive, racist, harassing, intimidating, or&nbsp; defamatory;<br />
          &nbsp; •	 &nbsp; Impersonating another user or&nbsp; person;<br />
          &nbsp; •	 &nbsp; Posting information or&nbsp; material that encourages or&nbsp; condones piracy or&nbsp; infringes on&nbsp; intellectual property rights;<br />
          &nbsp; •	 &nbsp; Excessive cross-posting and spam; and<br />
          &nbsp; •	 &nbsp; Posting anything to&nbsp; garner support for petitions, rallies, protests, and political or&nbsp; religious groups.<br />
          <br />
          TransparenTerra reserves the right to&nbsp; remove any postings that contravene the above prohibitions.<br />
          If&nbsp; you have witnessed a&nbsp; violation of&nbsp; the current Rules, follow the instructions given below.
        `,
      },
      {
        title: 'Security',
        text: `If you have noticed or suffered from the unscrupulous actions of other members of the Community, immediately report it by Live Chat or contact support.
          <br /><br />
          You can easily let us&nbsp; know about unacceptable content right on&nbsp; the website.<br />
          In&nbsp; addition, you can:<br />
          &nbsp; •	 &nbsp; Reject the call offer;<br />
          &nbsp; •	 &nbsp; Cancel your subscriptions;<br />
          &nbsp; •	 &nbsp; Add users to&nbsp; the blocklist;<br />
          &nbsp; •	 &nbsp; Report offenses by&nbsp; «Live Chat» or&nbsp; by&nbsp; email.<br />
          <br />
          All the personal information of&nbsp; the applicant of&nbsp; the complaint concerning a&nbsp; violation of&nbsp; Community Rules will remain classified. We&nbsp; will not hand it&nbsp; over to&nbsp; third parties. Disciplinary action will be&nbsp; taken at&nbsp; the discretion of&nbsp; the Community administration.<br />
          If&nbsp; you are a&nbsp; violator and are subject to&nbsp; expulsion from the Community, you may appeal.
        `,
      },
      {
        title: 'If You Have Received a Breach Notification',
        text: `We&nbsp; will notify you about an&nbsp; issued warning by&nbsp; email. We&nbsp; will also inform you about:<br />
          &nbsp; •	 &nbsp; The removed content;<br />
          &nbsp; •	 &nbsp; Reasons for removal; and<br />
          &nbsp; •	 &nbsp; Effects on&nbsp; your profile.<br />
        You can appeal the removal in&nbsp; a&nbsp; return letter.
        `,
      },
      {
        title: 'Changes ',
        text: `We&nbsp; reserve the right to&nbsp; change parts of&nbsp; the Honor Code & Community Guideline. If&nbsp; we&nbsp; do, we&nbsp; will publish all changes in&nbsp; the TransparenTerra newsletter. We&nbsp; take the Principles and Rules of&nbsp; the Community seriously and ask you to&nbsp; follow them. We&nbsp; also understand that the Community will develop both Principles and Rules that can change.<br />
          We&nbsp; appreciate your questions or&nbsp; suggestions for the improvement of&nbsp; the Community. Thank you for being part of&nbsp; our Sustainable Society!<br />
          Check out our Terms of&nbsp; Service and Privacy Policy.
        `,
      },
      {
        isContact: true,
        title: 'Contact us',
        text: `If you have questions or comments about this Privacy Policy, please:`,
      },
    ],
  },
  {
    route: 'privacy-policy',
    pageTitle: 'TransparenTerra - Privacy policy',
    title: 'Privacy policy',
    subTitle: `FCE GROUP AG and its affiliates (“we” or “us” or “our”) respect the privacy of our users (“user” or “you”) as per the applicable data protection laws, in particular, the Swiss Data Protection Act (DPA) and – if applicable to your personal data – the European General Data Protection Regulation (GDPR). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our websites including www.transparenterra.com (the “Site“) and other websites, devices, products, services, and mobile applications that reference this Privacy Policy (collectively "TransparenTerra Services"). Please read this Privacy Policy carefully. By using TransparenTerra Services, you are consenting to the practices described in this Privacy Policy.
      <br /><br />
      We reserve the right to make changes to this Privacy Policy at any time and for any reason.  We will alert you about any changes by updating the “Last Updated” date of this Privacy Policy.  Any changes or modifications will be effective immediately upon posting the updated Privacy Policy on the Site, and you waive the right to receive specific notice of each such change or modification.
      <br /><br />
      You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Site after the date such revised Privacy Policy is posted. 
    `,
    points: [
      {
        title: 'I. COLLECTION OF DATA',
        text: `We may collect various data in a variety of ways when you use TransparenTerra Services including:
          <br /><br />
          Personal Identification Information<br />
          Personal identification information, such as your name, address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register or place an order for any of our products or services, voluntarily complete a customer survey or provide feedback on any of our message boards or via email. You are under no obligation to provide us with personal information of any kind, however, your refusal to do so may prevent you from using certain features of Tranansparanterra Services.
          <br /><br />
          Financial Data<br />
          Financial information, such as data related to your payment method (e.g. valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about TransparenTerra Services. We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processor, including inter alia Amazon Payments, Authornize.Net, Braintree Payments, Chargify, Dwolla, Google Checkout, Paypal, SafeCharge, Stripe, WePay, 2Checkout, and other payment processors with whom we may partner in the future, and you are encouraged to review their privacy policy and contact them directly for responses to your questions.
          <br /><br />
          Automatically collected derivative data<br />
          The information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.
          <br /><br />
          If you access the Site from a mobile device, information about your mobile devices, such as your mobile device ID, model, and manufacturer, and information about the location of your device, is also collected.
          <br /><br />
          If you are using our mobile application, this information may also include your device name and type, your operating system, your phone number, your country, and various interactions with the application and other users via server log files, as well as any other information you choose to provide.
          <br /><br />
          Approximate location permission (non-continuous)<br />
          Used for accessing the user's approximate device location. Our Site may collect, use, and share user location data in order to provide location-based services. The geographic location of the user is determined in a manner that isn't continuous. This means that it is impossible for our Site to derive the approximate position of the user on a continuous basis.
          <br /><br />
          Precise location permission (non-continuous)<br />
          Used for accessing the user's precise device location. Our Site may collect, use, and share user location data in order to provide location-based services. The geographic location of the user is determined in a manner that isn't continuous. This means that it is impossible for our Site to derive the exact position of the user on a continuous basis.
          <br /><br />
          Information from Other Sources<br />
          In order to provide a functional Site and to provide you with the declared contents and services, we might receive information about you from other sources. The processing of personal data received from third parties only takes place based on the appropriate legal basis and as permitted by law. The examples of information about you we receive from third parties include inter alia:<br />
          &nbsp; •	 &nbsp; credit history information from credit bureaus which we use to help prevent and detect fraud;<br />
          &nbsp; •	 &nbsp; information from ALM/KYC service providers;<br />
          &nbsp; •	 &nbsp; information from payment processor companies;<br />
          &nbsp; •	 &nbsp; information from carrier companies.
        `,
      },
      {
        title: 'II.	PROCESSING AND USE OF YOUR DATA',
        text: `We only process your personal data if this is necessary to provide a functional Site and the TransparenTerra Services. The processing of personal data only takes place based on the appropriate legal basis and as permitted by law, including:<br />
          &nbsp; •	 &nbsp; processing of personal data is required for the performance of a contract to which you are a party or pre-contractual services or measures;<br />
          &nbsp; •	 &nbsp; processing of personal data is required to fulfill a legal obligation to which we are subject;<br />
          &nbsp; •	 &nbsp; processing of personal data is required for your or another person’s vital interests;<br />
          &nbsp; •	 &nbsp; processing is necessary to safeguard a legitimate interest of our company or a third party.<br />
          <br />
          We only store your personal data for as long as necessary to serve the purpose of the processing and we delete personal data or block access to it as soon as such purpose ceases to apply.
          <br /><br />
          Furthermore, personal data may be stored if this has been provided for by the applicable law (for example for bookkeeping or mandatory archiving purposes). The data will also be blocked or deleted if a storage period prescribed by applicable law expires unless there is a need for further storage of the data for the conclusion or fulfillment of a contract.
          <br /><br />
          Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience.  In particular, we may use information collected about you via the Site and other TransparenTerra Services to:<br />
          &nbsp; •	 &nbsp; manage your account;<br />
          &nbsp; •	 &nbsp; fulfill and manage purchases, orders, payments, and other transactions related to the TransparenTerra Services;<br />
          &nbsp; •	 &nbsp; email you regarding your account or order;<br />
          &nbsp; •	 &nbsp; enable user-to-user communications;<br />
          &nbsp; •	 &nbsp; deliver targeted advertising, newsletters, and other information regarding promotions;<br />
          &nbsp; •	 &nbsp; analyze performance, fix errors, increase functionality and improve the effectiveness of the TransparenTerra Services;<br />
          &nbsp; •	 &nbsp; monitor and analyze usage and trends to improve and personalize your experience with the TransparenTerra Services;<br />
          &nbsp; •	 &nbsp; prevent fraudulent transactions, monitor against theft, protect against criminal activity and assist law enforcement;<br />
          &nbsp; •	 &nbsp; comply with legal obligations.
        `,
      },
      {
        title: 'III.  DISCLOSURE OF YOUR DATA',
        text: `In accordance with applicable law, a transfer of your data to third parties for purposes other than those listed below does not take place unless you give consent to such transfer.
          <br /><br />
          By Law or to Protect Rights<br />
          If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.  This includes exchanging information with other entities for fraud protection and credit risk reduction.
          <br /><br />
          Third-Party Service Providers<br />
          We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance, to the extent, it is permitted by applicable law.
          <br /><br />
          Marketing Communications<br />
          With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.
          <br /><br />
          Third-Party Advertisers<br />
          We may use third-party advertising companies to serve ads when you visit the Site. These companies may use information about your visits to the Site and other websites that are contained in web cookies in order to provide advertisements about goods and services of interest to you.
        `,
      },
      {
        title: 'IV.	TRACKING TECHNOLOGIES',
        text: `Cookies<br />
          We may use cookies, web beacons, tracking pixels, and other tracking technologies to help customize and improve your experience with TransparenTerra Services. When you access the Site, your personal information is not collected through the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the Site. You may not decline web beacons. However, they can be rendered ineffective by declining all cookies or by modifying your web browser’s settings to notify you each time a cookie is tendered, permitting you to accept or decline cookies on an individual basis.
          <br /><br />
          Website Analytics<br />
          We may also partner with selected third-party vendors, such as Google Analytics and Yandex Metrika, to allow tracking technologies and remarketing services on the Site through the use of first-party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Site, determine the popularity of certain content and better understand online activity. By accessing the Site, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors. However, if you do not want any information to be collected and used by tracking technologies, you can visit the third-party vendor.
          <br /><br />
          You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.
        `,
      },
      {
        title: 'V.	THIRD-PARTY WEBSITES',
        text: `The Site may contain links to third-party websites and applications of interest, including advertisements and external services, that are not affiliated with us. Once you have used these links to leave the Site, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third-party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Site.`,
      },
      {
        title: 'VI.	SECURITY OF YOUR DATA',
        text: `We use administrative, technical, and physical security measures to protect your personal data.  While we have taken reasonable steps to secure the personal data you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.  Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.
          <br /><br />
          It is also important for you to protect against unauthorized access to your password and to your computers, devices, and applications. We recommend using a unique password for your account that is not used for other online accounts. Be sure to sign off when finished using a shared computer.
        `,
      },
      {
        title: 'VII.	POLICY FOR CHILDREN',
        text: `If you are under 18, you may use the full range of TransparenTerra Services only with the involvement of a parent or guardian.<br /> 
          We do not knowingly collect personal information from children under the age of 13 without the consent of the child's parent or guardian. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below.  
        `,
      },
      {
        title: 'VIII.	YOUR PROTECTION RIGHTS',
        text: `We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
          <br /><br />
          &nbsp; •	 &nbsp; The right to access – You have the right to request us for copies of your personal data. We may charge you a small fee for this service.<br />
          &nbsp; •	 &nbsp; The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request us to complete the information you believe is incomplete.<br />
          &nbsp; •	 &nbsp; The right to erasure – You have the right to request that we erase your personal data, under certain conditions.<br />
          &nbsp; •	 &nbsp; The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.<br />
          &nbsp; •	 &nbsp; The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.<br />
          &nbsp; •	 &nbsp; The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.<br />
          <br />
          If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
        `,
      },
      {
        isContact: true,
        title: 'IX.	CONTACT US',
        text: `If you have questions or comments about this Privacy Policy, please:`,
      },
    ],
  },
  {
    route: 'terms',
    pageTitle: 'TransparenTerra - Terms',
    title: 'Terms of Services',
    subTitle: null,
    points: [
      {
        title: 'I. LEGAL AGREEMENT',
        text: `These Terms of Service (the "Terms") constitute a legally binding agreement by and between FCE GROUP AG, the owner of website www.transparenterra.com (the "Website") (hereinafter, "we" or "TransparenTerra" ) and you ("you" or "your") concerning your use of the Website  and the services available through the Website (the "Services"). By using the Website and Services, you represent and warrant that you have read and understood, and agree to be bound by, these Terms and TransparenTerra Privacy Policy (the "Privacy Policy"), which is incorporated herein by reference. IF YOU DO NOT UNDERSTAND THESE TERMS, OR DO NOT AGREE TO BE BOUND BY IT OR THE PRIVACY POLICY, YOU MUST IMMEDIATELY LEAVE THE WEBSITE AND CEASE USING THE SERVICES.`,
      },
      {
        title: 'II.	PRIVACY POLICY',
        text: `By using the Website, you consent to the collection and use of certain information about you, as specified in the Privacy Policy. We encourage users of the Website to frequently check Privacy Policy for changes.`,
      },
      {
        title: 'III.	CHANGES TO THESE TERMS AND PRIVACY POLICY',
        text: `Internet technology and the applicable laws, rules, and regulations change frequently. Accordingly, we reserve the right to change these Terms and the Privacy Policy at any time upon notice to you to be given by posting of a new version or a change notice on the Website. It is your responsibility to review these Terms and the Privacy Policy periodically. If at any time you find either unacceptable, you must immediately leave the Website and cease using the Services. Unless TransparenTerra obtains your express consent, any revised Privacy Policy will apply only to information collected by TransparenTerra after such time as the revised Privacy Policy takes effect, and not to information collected under any earlier Privacy Policies.`,
      },
      {
        title: 'IV.	ADDITIONAL TERMS FOR SPECIFIC SERVICES',
        text: `Additional terms and conditions listed on the Website or otherwise made available to you may apply to specific Services. If you use those Services, then those additional terms become part of these Terms. If any of the applicable additional terms conflict with these Terms, the additional terms will prevail while you are using the Services to which they apply.`,
      },
      {
        title: 'V.	USER ELIGIBILITY',
        text: `No one under 18 is allowed to create an account on the Website or use the Services. By creating an account or using the Services you represent, warrant, and agree that you can form a binding contract with TransparenTerra.
          <br /><br />
          If you are using the Services on behalf of a business or some other entity, you represent that you are authorized to bind that business or entity to these Terms and you agree to these Terms on behalf of that business or entity (and all references to “you” and “your” in these Terms will mean both you as the end-user and that business or entity).
        `,
      },
      {
        title: 'VI.	USERS: MEMBERS AND VISITORS',
        text: `When you register on the Website and join the Services, you become a member of the TransparenTerra community ("TransparenTerra member"). As a TransparenTerra member you can assume one or more roles described in the TransparenTerra Honor Code & Community Guideline. If you have chosen not to register for our Services, you may access certain features of the Website as a “visitor”.`,
      },
      {
        title: 'VII.	REGISTRATION, VERIFICATION, AND MEMBER PROFILE',
        text: `To register on the Website you need to receive an invitation from another TransparenTerra member and complete the registration form by entering your personal information (alternatively, you can sign on through your profile on social networks). You can choose to register as (1) a basic user; or (2) a business owner; or (3) an expert; or (4) a professional investor; with different access rights to various Services. In order to register as “professional investor” you need to have a “qualified investor” or “professional investor” status under the applicable law in the jurisdiction of your domicile. We reserve the right to request from you the relevant documentary confirmation. By registering as “professional investor” on the Website you represent and warrant that you have this legal status under the law of your domicile. 
          <br /><br />
          Certain Services are available to members only upon completion of (a) the verification processed by TransparenTerra; and/or (b) the KYC checks processed by third-party providers.  These processes are necessary to ensure trust and safety in the TransparenTerra community. As part of the verification/KYC process, you may be asked to provide copies of your passport, driver's license, registration, tax, and other certificates for your business, and other documents. We have the right to deny you verification without giving a reason.
          <br /><br />
          Upon registration, a member will create a public account that contains certain personal information. The information you provide about yourself must be current, complete, and accurate and must not violate the law and third-party rights. It is your responsibility to update your TransparenTerra account as needed. TransparenTerra does not, and cannot, investigate information contained in member public profiles. Accordingly, TransparenTerra does not represent, warrant, or guarantee the currency or accuracy of public profile information, and hereby disclaims all responsibility and liability for any information provided by TransparenTerra members by means of public profiles or otherwise in connection with the Services.
          <br /><br />
          By registering on the Website and each time you log on to TransparenTerra, you warrant that you have all the powers required to honor and execute these Terms.
          <br /><br />
          It is your sole responsibility to keep your login details confidential. Everything performed through your TransparenTerra account is considered as performed by you unless you report misuse of your account. You agree to notify us immediately of any unauthorized use of your account, user name, or password. TransparenTerra shall not be liable for any loss that you incur as a result of someone else using your password, either with or without your knowledge.
          <br /><br />
          By registering with the Website, you hereby consent to receive (1) periodic email communications regarding the Services, new product offers, promotions, and other matters; and (2) electronic communications, including email and instant messages from other TransparenTerra members.
        `,
      },
      {
        title: 'VIII.	INTELLECTUAL PROPERTY RIGHTS',
        text: `All copyright and other intellectual property rights subsisting in the Website and the Services, including without limitation all text, images, graphics, and code contained in the Website, and in its look and feel (collectively, the "Contents") are owned by TransparenTerra, or by third-party providers. TransparenTerra hereby grants you a worldwide, royalty-free, non-assignable, non-exclusive, revocable, and non-sublicensable license to use the Website and the Services. This license is for the sole purpose of using and enjoying the Website and the Services in a way that these Terms, Privacy Policy and TransparenTerra Honor Code & Community Guideline allow. Other than as specified above, neither the Website nor any of the Contents may be modified or copied in whole or part in any form, including by framing, incorporation into other websites or other publication, or be used to create any derivative work. No links to the Website may be included in any other website without our prior written permission.`,
      },
      {
        title:
          'IX.	THE WEBSITE DOES NOT PROVIDE PROFESSIONAL INVESTMENT ADVISORY SERVICES. RISK ASSUMPTION',
        text: `TransparenTerra provides the Website as a platform for experts, businesses, investors, and users where they can share thoughts, information, and opinions with other users, purchase products and services, including professional expert advice, participate in webinars and online conferences in accordance with these Terms and TransparenTerra Honor Code & Community Guideline. 
          <br /><br />
          The Website itself does not contain or constitute, and should not be interpreted as, financial, accounting, investment, or other professional advice on any subject matter and is not a substitute for professional advice or opinion. TransparenTerra is not a fiduciary by virtue of any person’s use of or access to the Website or Services. You alone assume the sole responsibility of evaluating the merits and risks associated with the use of any information on the Website before making any decisions based on such information. In exchange for using the Website, you agree not to hold TransparenTerra, its affiliates or any third party service provider liable for any possible claim for damages arising from any decision you make based on information made available to you through the Website.
          <br /><br />
          Transparenterra’s role is to help connect TransparenTerra members offering their services, such as professional expert advice, with TransparenTerra members seeking services. TransparenTerra does not perform nor employs individuals to perform these services. You acknowledge that TransparenTerra does not supervise, direct, control, or monitor TransparenTerra members in the performance of these services and agree that:<br />
            (1)  we are not responsible for the offering, performance, or procurement of these services,<br />
            (2)  we do not endorse any member’s offered services, and<br />
            (3)  nothing shall create an employment, agency, or joint venture relationship between us and any member offering services.<br />
          <br /><br />
          This is the sole responsibility of the member seeking professional services and products via TransparenTerra to ensure that the member providing professional services is duly licensed, has required legal status (such as status of professional investor under the relevant applicable laws) or otherwise authorized to provide such services and products.
          <br /><br />
          If you are a TransparenTerra member offering services or products, by making such services and products available to other members via TransparenTerra you represent and warrant that you have all the required licenses, legal status, authorisations and expertise and will provide services consistent with all applicable laws, these Terms and TransparenTerra Honor Code & Community Guideline.
          <br /><br />
          Similarly, TransparenTerra may help you register for and/or attend events organized by other TransparenTerra members. In this connection, you agree that:<br />
            (1)  we are not responsible for the conduct of any of the members organizing such events,<br />
            (2)  we do not endorse any event organized by any member, and<br />
            (3)  we do not review and/or monitor any of these events.
        `,
      },
      {
        title: 'X.	RELIANCE ON THIRD-PARTY CONTENT',
        text: `Opinions, advice, statements, or other information made available by means of the Website and Services by other TransparenTerra members and/or visitors, are those of their respective authors, and should not necessarily be relied on. Such authors are solely responsible for such content. You acknowledge and understand that TransparenTerra has not, and does not, in any way: (a) screen its members; (b) inquire into the backgrounds of its members; or (c) review or verify the statements of its members. By using the Services, you may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful. You hereby agree to exercise reasonable precaution in all interactions with other members.`,
      },
      {
        title: 'XI.	THIRD-PARTY WEBSITES',
        text: `The Website is linked with the websites of third parties ("Third-Party Websites"). We do not have control over the content and performance of Third-Party Websites. You agree that we have not reviewed, and cannot review or control, all of the material, including computer software, goods, or services, made available on or through Third-Party Websites.`,
      },
      {
        title: 'XII.	USER CONTENT',
        text: `"User Content" is any content, materials or information, that you upload or post to, or transmit, display, perform or distribute by means o, the Website, whether in connection with your use of the Services or otherwise. You hereby grant TransparenTerra and its officers, directors, employees, agents, affiliates, representatives, sublicensees, successors, and assigns (collectively, the "TransparenTerra Parties") a perpetual, fully paid-up, worldwide, sublicensable, irrevocable, assignable license to copy, distribute, transmit, publicly display or perform, edit, translate, reformat and otherwise use User Content in connection with the operation of the Website, the Services or any other similar or related business, in any medium now existing or later devised, including without limitation in advertising and publicity. You further agree that the TransparenTerra Parties may publish or otherwise disclose your name and/or any user name of yours in connection with their exercise of the license granted under this section. You agree to waive, and hereby waive, any claims arising from or relating to the exercise by the TransparenTerra Parties of the rights granted under this section, including without limitation any claims relating to your rights of personal privacy and publicity. You will not be compensated for any exercise of the license granted under this section.
          <br /><br />
          By posting User Content on the Website you represent and warrant that in connection with your posted User Content:<br />
            (1)   you are the creator and owner of it and have all necessary rights from other people or companies to use, and permit others to use it; and<br />
            (2)  it does not infringe any third party right, including copyright and other intellectual property, privacy and rights of publicity;<br />
            and you agree to hold the TransparenTerra Parties harmless from any claims and indemnity them from any losses incurred by or asserted against them and resulting from your breach of such representations and warranties.
        `,
      },
      {
        title: 'XIII.	PUBLIC FORUMS',
        text: `"Public Forum" is any area, site, or feature offered as part of the Website (including without limitation public profiles, discussion forums, message boards, blogs, chat rooms, emails, or instant messaging features) that enables you (a) to upload, submit, post, display, perform, distribute and/or view User Content, and/or (b) to communicate, share, or exchange User Content with other TransparenTerra members or other Website visitors. You acknowledge that Public Forums and features contained therein, are for public and not private communications. You further acknowledge that anything you upload, submit, post, transmit, communicate, share or exchange by means of any Public Forum may be viewed on the Internet by the general public, and therefore, you have no expectation of privacy with regard to any such submission or posting (where we have made settings available, we will honor the choices you make about who can see User Content or information). You are, and shall remain, solely responsible for the User Content you upload, submit, post, transmit, communicate, share or exchange by means of any Public Forum and for the consequences of submitting or posting the same. TransparenTerra disclaims any perceived, implied or actual duty to monitor Public Forums and specifically disclaims any responsibility or liability for information provided thereon.`,
      },
      {
        title: 'XIV.	YOUR RESPONSIBILITY FOR DEFAMATORY COMMENTS',
        text: `You agree and understand that you may be held legally responsible for damages suffered by other TransparenTerra members, visitors, or third parties as to the result of your comments, remarks, information, feedback or other User Content posted or made available on the Website that is deemed defamatory or otherwise legally actionable. TransparenTerra is not legally responsible, nor can it be held liable for damages of any kind, arising out of or in connection to any defamatory or otherwise legally actionable User Content posted or made available on the Website.`,
      },
      {
        title: 'XV.	PROHIBITED ACTIONS',
        text: `When using the Website and the Services you hereby agree NOT TO:<br />
          &nbsp; •	 &nbsp; post any materials that violate any applicable law, including but not limited to libel, slander, antitrust, trademark, copyright, patent, or unfair competition;<br />
          &nbsp; •	 &nbsp; advocate or discuss illegal activity;<br />
          &nbsp; •	 &nbsp; post anything which could encourage or facilitate discussions or any agreement that either expressly or impliedly leads to price fixing, a boycott of another's business, or other conduct intended to illegally restrict free trade;<br />
          &nbsp; •	 &nbsp; post any materials that do not pertain to the discussion, topic or theme of the forum;<br />
          &nbsp; •	 &nbsp; infringe upon third-party copyright, nor invade the privacy or publicity rights of others;<br />
          &nbsp; •	 &nbsp; post anything protected by copyright without the permission of the copyright owner;<br />
          &nbsp; •	 &nbsp; post anything that is knowingly false, defamatory, profane, vulgar, obscene, threatening, abusive, hateful, bigoted, racially offensive, pornographic, embarrassing, or that incite, encourage or threaten physical harm against another; promote or glorify racial intolerance, use hate and/or racist terms, or signify hate towards any person or group of people; glamorize the use of hardcore illegal substances and drugs; or has any potential to cause any harm or damage to anyone as determined by TransparenTerra in its sole discretion;<br />
          &nbsp; •	 &nbsp; post anything of a sexual nature;<br />
          &nbsp; •	 &nbsp; use the Website for junk mail, spam, chain letters, or pyramid schemes;<br />
          &nbsp; •	 &nbsp; impersonate anyone or misrepresent who you are.<br />
          TransparenTerra has no obligation to, and does not in its normal course, prescreen, monitor or review User Content, nor does it exert any editorial control over User Content. Without limiting any of its other remedies, TransparenTerra reserves the right to terminate your use of the Website and Services or your uploading, posting, transmission, display, performance or distribution of User Content that is not in compliance with this section. TransparenTerra, in its sole discretion, may delete any such User Content from its servers. TransparenTerra intends to cooperate fully with any law enforcement officials or agencies in the investigation of any violation of these Terms or of any applicable laws.
        `,
      },
      {
        title: 'XVI.	DISCLAIMER',
        text: `Access to the Website, and use of the Services, is at your sole discretion and risk. Whilst we have taken reasonable measures to ensure that the Website and its content is accurate and up-to-date, we accept no responsibility for any action taken by any person or organization as a result, direct or otherwise, of information contained in, or accessed through, the Website, whether provided by us or a third party. The Website and the Services are provided on an "AS-IS" basis, and we make no warranties or representations about the Website or any of the Services, including without limitation (i) the timeliness, currency, accuracy, completeness, reliability, continued availability, or fitness for any particular purpose, (ii) that the use of the Website will be error-free, secure, and uninterrupted, (iii) that we will correct any defects or that the Website will be free from viruses or other harmful codes, and (iv) that the Services or the Website do not infringe any third-party rights. To the greatest extent permissible by applicable laws, we exclude any such warranties and representations that may otherwise be implied and exclude all liability with respect to the Website, the Services, or any use thereof.`,
      },
      {
        title: 'XVII.	LIMITATION OF LIABILITY',
        text: `TransparenTerra will not be liable to you for any direct, incidental, consequential, or similar damages arising (i) out of your access, use, or inability to use the Website, the Services or any linked website, or (ii) in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or system failure.`,
      },
      {
        title: 'XVIII.	INDEMNIFICATION',
        text: `You agree to indemnify and hold the TransparenTerra Parties harmless from all liabilities, claims, and expenses (including reasonable attorneys' fees and expenses) resulting from your (i) breach of these Terms, (ii) misuse of the Website or any of the Services, or (iii) infringement of any of our intellectual-property rights.`,
      },
      {
        title: 'XIX.	GOVERNING LAW AND DISPUTE RESOLUTION',
        text: `These Terms shall be governed by and construed in accordance with the law of Switzerland. Any dispute, controversy, or claim arising out of, or in relation to, these Terms, including the validity, invalidity, breach, or termination thereof, shall be resolved by arbitration in accordance with the Swiss Rules of International Arbitration of the Swiss Arbitration Centre in force on the date on which the Notice of Arbitration is submitted in accordance with those Rules. The arbitration shall be held in Zurich, Switzerland, and the arbitral proceeding shall be conducted in the English language.`,
      },
      {
        title: 'XX.	MODIFYING THE SERVICES AND TERMINATION',
        text: `We are relentlessly improving our Services and creating new ones all the time. That means we may add or remove features, products, or functionalities, and we may also suspend or stop the Services altogether. We may take any of these actions at any time for any reason, and when we do so, we may not provide you with any notice beforehand.<br />
          While we hope you remain a lifelong TransparenTerra member, you can terminate these Terms at any time and for any reason by deleting your TransparenTerra account.<br />
          We may terminate or temporarily suspend your access to the Services if you fail to comply with these Terms, TransparenTerra Honor Code & Community Guideline, or the law without advanced notice. That means that we may terminate these Terms, stop providing you with all or any part of the Services, or impose new or additional limits on your ability to use our Services. And while we’ll try to give you reasonable notice beforehand, we can’t guarantee that notice will be possible in all circumstances.
        `,
      },
      {
        title: 'XXI.	NOTICES',
        text: `All notices required or permitted to be given under these Terms must be in writing. TransparenTerra shall give any notice by email sent to the most recent email address listed in your TransparenTerra profile. You agree that any notice received from TransparenTerra electronically satisfies any legal requirement that such notice be in writing. You bear the sole responsibility of ensuring that your email address on your TransparenTerra profile is accurate and current and notice to you shall be deemed effective upon the sending by TransparenTerra of an email to that address. You shall give any notice to TransparenTerra by means of info@trasparenterra.com.`,
      },
      {
        title: 'XXII.	GENERAL',
        text: `These Terms constitute the entire agreement between you and TransparenTerra concerning your use of the Website and the Services. These Terms may be modified unilaterally by TransparenTerra by posting such amended version on the Website. If any part of these Terms is held invalid or unenforceable, that part will be construed to reflect the parties original intent, and the remaining portions will remain in full force and effect.`,
      },
    ],
  },
];
