import PageContainerImgBg from 'components/layout/PageContainer/PageContainerImgBg';
import SDGPage from 'core/views/SDGPage';

const SDG = () => (
  <PageContainerImgBg imageBg="/images/sdg-main-bg.png" isSdgPage>
    <SDGPage />
  </PageContainerImgBg>
);

export default SDG;
