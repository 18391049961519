/* eslint-disable no-nested-ternary */
export const COMPLETE_REG_KYC = {
  title: `To complete your
    <br />
    <b>Registration</b>`,
  text: `<p>
        Please pass the KYC process. To pass through KYC successfully, make sure that:
        </p>
        <ul>
          <li>You are ready for the face recognition</li>
          <li>You have your ID documents next to you</li>
          <li>Your browser has permission to use the camera</li>
          <li>Your webcam is working.</li>
        </ul>
        <br />
        <p>
          Only fully verified users can be full members of the TransparenTerra community.
        </p>`,
  firstBtnText: 'Skip',
  secondBtnText: 'Proceed',
};

export const THX_SIGN_UP = {
  text: 'Thank you! You have been signed up on TransparenTerra.',
  btnText: 'Go to your profile',
};

export const THX_REQUEST_INVITE = {
  text: 'Thank you!<br />We have added your request to our waiting list.',
};

export const COMPLETE_REG_REQUEST_INVITE = (role) => {
  const roles =
    role.url === 'expert'
      ? 'Expert'
      : role.url === 'investor'
        ? 'Expert or Investor'
        : role.url === 'business'
          ? 'Expert, Investor or Business'
          : null;

  return {
    text: `TransparenTerra is a global community with a sharing responsibility. <br /> <br />To have an invitation is mandatory to sign up on TransparenTerra. <br /> <br />
      To complete your registration, you must have an invitation from the ${roles} already registered on the platform. <br /> <br />
      You can share your contact list to let us find and forward your request to this ${roles}.<br /> <br />
      Until you receive an invitation, you can explore the potential 
      of our platform as an Individual.`,
    firstBtnText: 'Skip',
    secondBtnText: 'Share  My Contacts',
  };
};

export const COMPLETE_REG_SHARE_EMAILS_IND = {
  text: `To simplify your onboarding, 
    we recommend you to share your email list.<br /> <br />
    To become a member of TransparenTerra expert community you have to have an invitation from existing expert.<br /> <br />
    To simplify your onboarding we recommend you to share your email list. 
    When the system finds someone on your email list who is a TransparenTerra member, we\`ll ask them to send you an invitation.<br /> <br />
    Until you receive an invitation, you can explore the possibilities and features of our platform as an individual.`,
  firstBtnText: 'Skip',
  secondBtnText: 'Share email list',
};

export const COMPLETE_REG_SHARE_EMAILS = {
  text: `Thank you for filling out the form.<br /> <br />
    If the system finds someone on your 
    email list who is a TransparenTerra member, we’ll ask them to send you an invitation.`,
  firstBtnText: 'Skip',
  secondBtnText: 'Share email list',
};
