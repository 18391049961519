import { TITLES } from 'constants/pagesTitles';
import TermPage from 'core/views/TermPage';
import PageContainer from 'components/layout/PageContainer';

const Term = ({ term }) => (
  <PageContainer title={TITLES.termPage} isImgBg>
    <TermPage term={term} />
  </PageContainer>
);

export default Term;
