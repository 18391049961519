function* uniqueIdGenerator() {
  let i = 0;
  while (true) {
    yield i++;
  }
}

const idGenerator = uniqueIdGenerator();

export const generateUniqueId = () => idGenerator.next().value;
