import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import styles from './HeaderRolesMenu.module.scss';

const HeaderRolesMenu = ({ roles, selectedRole, isSignUpPage, isScrolled }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openMenu = () => {
    if (!isSignUpPage) {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  return (
    <div
      className={clsx(
        styles.rolesMenuSelect,
        isSignUpPage ? styles.signUpPageSelect : '',
        isScrolled ? styles.scrolled : ''
      )}
    >
      <div className={styles.rolesSelectH}>
        <div className={styles.rolesMenuLabel}>Your Role:</div>
        <div
          id="roles__menu_selected"
          className={styles.selected}
          onClick={() => openMenu()}
          aria-hidden="true"
        >
          <span className={`${styles.rolesMenuElPrev} ${styles[selectedRole.index]}`}>
            {selectedRole.index}
          </span>
          <span
            id="roles__menu_triangle"
            className={`${styles.triangle} ${isMenuOpen && styles.open}`}
          />
        </div>
      </div>

      <div
        id="roles__menu_select_box"
        className={`${styles.selectBox} ${isMenuOpen && styles.open}`}
        aria-hidden
      >
        {roles.map((role) => (
          <div
            className={styles.selectEl}
            key={role.index}
            onClick={() => setIsMenuOpen(false)}
            aria-hidden
          >
            <Link to={`/join/${role.url}`}>
              <span className={`${styles.rolesMenuElPrev} ${styles[role.index]}`}>
                {role.index}
              </span>
              <span className={styles.label}>{role.label}</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeaderRolesMenu;
