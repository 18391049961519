import React from 'react';
import styles from './videoBg.module.scss';

const VideoBg = ({ src, srcImg, isAutoPlay }) => (
  <div className={styles.videoBg}>
    <div className={styles.bgDarken} />
    <video
      key={src}
      className={styles.hd}
      loop
      muted
      poster={srcImg}
      autoPlay={isAutoPlay}
      playsInline
    >
      {isAutoPlay && <source src={src} type="video/mp4" />}
    </video>
  </div>
);

export default VideoBg;
