import React from 'react';
import { PolicyModal } from '../../../modules/tt-modals';

const PolicyModalWrapper = ({
  isModelOpen,
  setIsOpen,
  handleClose,
  setIsAgree,
  isAgree,
  checkboxText,
  text,
  loading,
}) => (
  <PolicyModal
    isModelOpen={isModelOpen}
    setIsOpen={setIsOpen}
    handleClose={handleClose}
    setIsAgree={setIsAgree}
    isAgree={isAgree}
    checkboxText={checkboxText}
    text={text}
    loading={loading}
  />
);

export default PolicyModalWrapper;
