/* eslint-disable no-unused-vars */
import { format, parseISO } from 'date-fns';
import { Button, Image } from 'tt-ui-kit';

export const Call = (props) => {
  const { idUser, message, styles, startDirectCall, selectedCompanyId } = props;

  const statuses = {
    pending: 'Missing call',
    outgoing: 'Outgoing call',
    answered: 'Incoming call',
    incoming: 'Incoming call',
    rejected: 'Cancelled call',
    cancelled: 'Cancelled call',
  };

  return (
    <div key={message.id}>
      <div
        className={`${
          message.sender_id === idUser || message.sender_id === selectedCompanyId
            ? styles.messageBubbleBlue
            : styles.messageBubble
        } ${styles.callBubble}`}
        id="text"
      >
        <div>
          <span className={styles.messageText}>
            {message.me && message.status === 'answered'
              ? statuses.outgoing
              : !message.me && message.status === 'answered'
                ? statuses.answered
                : statuses[message.status]}
          </span>
          <div>
            {message.status === 'answered' && message.me ? (
              <Image src="/images/chat/outgoing-call-icon.svg" width={10} height={10} />
            ) : !message.me && message.status === 'answered' ? (
              <Image src="/images/chat/incoming-call-icon.svg" width={10} height={10} />
            ) : (
              <Image src={`/images/chat/${message.status}-call-icon.svg`} width={10} height={10} />
            )}
            <span className={styles.callTime}>
              {`${format(parseISO(message.created_at), 'H:mm')}${
                message.duration ? `, ${message.duration}` : ''
              }`}
            </span>
          </div>
        </div>
        <div className={styles.callBtnWrapper}>
          <Button type="icon" className={styles.callIcon} onClick={() => startDirectCall()}>
            <Image src="/images/chat/call-white-icon.svg" width={13} height={13} />
          </Button>
        </div>
      </div>
    </div>
  );
};
