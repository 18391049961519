import { Modal } from '@mui/material';
import { TBSTooltip } from 'components/TBSTooltip/TBSTooltip';
import ModalContainer from 'components/ModalContainer';
import styles from './CompleteRegModal.module.scss';

const CompleteRegModal = ({
  isModelOpen,
  setIsOpen,
  handleClose,
  title,
  text,
  firstBtnText,
  secondBtnText,
  handleSecondBtnClick,
  handleFirstBtnClick,
}) => (
  <Modal
    open={isModelOpen}
    onClose={() => handleClose(setIsOpen)}
    BackdropProps={{
      onClick: (event, reason) => {
        if (reason === 'click') {
          event.stopPropagation();
        }
      },
    }}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <ModalContainer
      background="rgba(255, 255, 255, 0.4)"
      handleClose={() => handleClose(setIsOpen)}
    >
      {title && (
        <div className={styles.modalTitle} dangerouslySetInnerHTML={{ __html: `${title}` }} />
      )}
      {text && (
        <div
          className={styles.modalText}
          dangerouslySetInnerHTML={{ __html: `${text}` }}
          style={{ textAlign: title ? 'left' : 'center' }}
        />
      )}
      <div className={styles.buttonsWrapper}>
        <button className={styles.firstBtn} type="button" onClick={handleFirstBtnClick}>
          {firstBtnText}
        </button>
        {handleSecondBtnClick ? (
          <button className={styles.secondBtn} type="submit" onClick={handleSecondBtnClick}>
            {secondBtnText}
          </button>
        ) : (
          <TBSTooltip place="bottom">
            <button className={styles.secondBtn} type="submit">
              {secondBtnText}
            </button>
          </TBSTooltip>
        )}
      </div>
    </ModalContainer>
  </Modal>
);

export default CompleteRegModal;
