import React from 'react';
import styles from './Loader.module.scss';
import { CircularProgress } from '@mui/material';

export const Loader = () => (
  <div className={styles.loader}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export const LoaderOn = () => (
  <div className={styles.loaderOnWrapper}>
    <CircularProgress className={styles.loaderOn} disableShrink />
  </div>
);
