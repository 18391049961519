/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import { useState, useEffect, useMemo, createRef, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'utils/useWindowSize';
import { TBSTooltip } from 'components/TBSTooltip/TBSTooltip';
import { getBrowser } from 'utils/defineBrowser';
import styles from './MenuCarousel.module.scss';

const CAROUSEL_SIZE = 7;
const CLICK_SCROLL_DIFFERENCE_PX = 5;

const MenuCarousel = ({ data, setSelectedItem, setIsOnItemClick, isOnItemClick }) => {
  const [itemHeight, setItemHeight] = useState(null);
  const [menuMainContainerHeight, setMenuMainContainerHeight] = useState(null);
  const [touchStart, setTouchStart] = useState(null);
  const [browser, setBrowser] = useState();

  const windowsSize = useWindowSize();

  const itemsToRender = useMemo(() => {
    const items = [...data, ...data, ...data];

    while (items.length < CAROUSEL_SIZE * 3) {
      items.push(...data);
      items.unshift(...data);
    }

    return items;
  }, [data]);

  const fakeItemsCount = useMemo(
    () => (itemsToRender.length - data.length) / 2,
    [itemsToRender, data]
  );

  const [firstVisibleIndex, setFirstVisibleIndex] = useState(fakeItemsCount);

  const carouselRef = useRef(null);
  const itemRef = createRef();

  const scrollToItem = (index) => {
    let indexToScroll = index;

    if (index < fakeItemsCount || index >= fakeItemsCount + data.length) {
      const positionInDataset = index % data.length;
      indexToScroll = fakeItemsCount + positionInDataset;
      carouselRef.current?.scrollTo({
        top: carouselRef.current.scrollTop + (indexToScroll - index) * itemHeight,
      });
      setFirstVisibleIndex(firstVisibleIndex + indexToScroll - index);
    }

    setTimeout(() => {
      setFirstVisibleIndex(indexToScroll - 3);
      const scrollParams = browser?.isSafari
        ? {
            top: (indexToScroll - 3) * itemHeight,
          }
        : {
            top: (indexToScroll - 3) * itemHeight,
            behavior: 'smooth',
          };
      carouselRef.current?.scrollTo(scrollParams);
    }, 100);
  };

  const lastWheelRef = useRef(0);

  const onItemClick = (subList) => {
    if (windowsSize && windowsSize.width < 480 && subList) {
      setIsOnItemClick(true);
    }
  };

  const handleItemClick = (index, item) => {
    if (index !== firstVisibleIndex + 3) {
      scrollToItem(index);
    } else {
      onItemClick(item.subList);
    }
  };

  const handleWheel = (e) => {
    if (Date.now() - lastWheelRef.current < 100) return;
    lastWheelRef.current = Date.now();

    scrollToItem(firstVisibleIndex + 3 + (e.deltaY >= 0 ? 1 : -1));
  };

  const handleOnTouchStart = (e) => {
    const start = e.changedTouches[0];
    setTouchStart(start);
  };

  const handleOnTouchEnd = (e) => {
    if (Date.now() - lastWheelRef.current < 100) return;
    lastWheelRef.current = Date.now();
    const end = e.changedTouches[0];
    const clickScrollDifference = end.screenY - touchStart.screenY;
    if (
      clickScrollDifference > CLICK_SCROLL_DIFFERENCE_PX ||
      clickScrollDifference < -CLICK_SCROLL_DIFFERENCE_PX
    ) {
      scrollToItem(firstVisibleIndex + 3 + (end.screenY - touchStart.screenY > 0 ? -1 : 1));
    }
  };

  const renderCarousel = () => {
    const { height } = itemRef.current.getBoundingClientRect();
    setItemHeight(height);
    setMenuMainContainerHeight(height * CAROUSEL_SIZE);
    carouselRef.current?.scrollTo({ top: fakeItemsCount * itemHeight });
    scrollToItem(firstVisibleIndex + 3);
  };

  useEffect(() => {
    if (windowsSize && windowsSize.width >= 480) {
      setIsOnItemClick(false);
    }

    setBrowser(getBrowser());
    renderCarousel();
  }, [windowsSize, isOnItemClick]);

  useEffect(() => {
    if (itemHeight) {
      carouselRef.current?.scrollTo({ top: fakeItemsCount * itemHeight });
    }
    scrollToItem(firstVisibleIndex + 3);
  }, [itemHeight]);

  useEffect(() => {
    if (itemsToRender && firstVisibleIndex) {
      const selectedItem = itemsToRender[firstVisibleIndex + 3];
      setSelectedItem(selectedItem);
    }
  }, [itemsToRender, firstVisibleIndex]);

  return (
    <div
      className={styles.menuCarouselContainer}
      ref={carouselRef}
      onWheel={handleWheel}
      onTouchStart={(e) => handleOnTouchStart(e)}
      onTouchEnd={(e) => handleOnTouchEnd(e)}
      style={{ height: menuMainContainerHeight }}
    >
      {itemsToRender.map((item, index) => {
        let sizeClassName = '';
        switch (index) {
          case firstVisibleIndex + 3:
            sizeClassName = styles.menuCarouselItemSizeFirst;
            break;
          case firstVisibleIndex + 2:
          case firstVisibleIndex + 4:
            sizeClassName = styles.menuCarouselItemSizeSecond;
            break;
          case firstVisibleIndex + 1:
          case firstVisibleIndex + 5:
            sizeClassName = styles.menuCarouselItemSizeThird;
            break;
          default:
            break;
        }

        return (
          <div
            key={item.label + index}
            ref={itemRef}
            onClick={() => handleItemClick(index, item)}
            className={`${styles.menuCarouselItem} ${sizeClassName}`}
            aria-hidden
          >
            <div>{item.label}</div>
            {item.toBeSoon ? (
              <TBSTooltip>
                <Link to={item.url}>{item.label}</Link>
              </TBSTooltip>
            ) : item.isTarget ? (
              <a href={item.url} rel="noreferrer" target="_blank">
                {item.label}
              </a>
            ) : (
              <Link to={item.url}>{item.label}</Link>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MenuCarousel;
