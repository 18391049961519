import React, { useState } from 'react';
import { NewPost } from 'core/NewPost';
import { PostsList } from 'core/PostsList';
import styles from './AllBlogsTab.module.scss';
import { useSelectedContext } from 'context/contextSelector';
import { Box, ButtonBase, IconButton, InputBase, Stack } from '@mui/material';
import { SearchIcon, SelectDownIcon, SelectUpIcon, SortAscIcon, SortDescIcon } from 'tt-ui-kit';

export const AllBlogsTab = ({ blogs }) => {
  const { user, changeUserData } = useSelectedContext((context) => context.user);
  const [fetchPosts, setFetchPosts] = useState(true);
  const [filterType, setFilterType] = useState(true);
  const [page, setPage] = useState(1);

  const [filters, setFilters] = useState({
    role: 'activeRole',
    limit: 15,
    order_dir: 'desc',
    page: 1,
    q: '',
  });
  const changeOrder = () => {
    if (filterType) {
      setFilters((current) => ({ ...current, order_by: 'created_at', page: 1 }));
    } else {
      setFilters((current) => ({ ...current, order_by: null, page: 1 }));
    }
    setPage(1);
    setFilterType(!filterType);
  };
  return (
    <>
      <NewPost user={user} onClose={() => setFetchPosts(true)} />
      <Box className={styles.filters}>
        <Box className={styles.inputGroup}>
          <InputBase
            className={styles.searchInput}
            placeholder="Search"
            // value={searchValue}
            // onChange={(e) => changeSearchValue(e.target.value)}
          />
          <Box className={styles.searchIcon}>
            <SearchIcon style={{ width: 20, height: 20 }} />
          </Box>
        </Box>
        <Box onClick={() => changeOrder()} className={styles.sortSelect}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton style={{ padding: 0, width: 30, height: 30, marginLeft: 10 }}>
              {filterType ? (
                <SortAscIcon className={styles.filterIcons} />
              ) : (
                <SortDescIcon className={styles.filterIcons} />
              )}
            </IconButton>
            <ButtonBase className={styles.filterNewFirst}>New first</ButtonBase>
          </div>
          {filterType ? (
            <SelectDownIcon className={styles.selectArrow} />
          ) : (
            <SelectUpIcon className={styles.selectArrow} />
          )}
        </Box>
      </Box>
      {blogs.length > 0 ? (
        <PostsList
          pageType="group_blog"
          isFetchPosts={fetchPosts}
          setIsFetchPosts={setFetchPosts}
        />
      ) : (
        <Box className={styles.noPost}>
          <Box className={styles.mainMessage}>Currently, there are no posts available.</Box>
          <Box className={styles.subMessage}>
            The group has not added any articles, news, posts, videos, or podcasts to the platform
            yet.
          </Box>
        </Box>
      )}
    </>
  );
};
