import React, { useEffect, useState } from 'react';
import styles from './Content.module.scss';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import clsx from 'clsx';
import FeedLoader from '../../core/PostsList/FeedLoader';
import PostFeedView from '../../components/PostView/PostFeedView';
import { allowedReactions } from '../../constants/addPost';
import { useSelectedContext } from 'context/contextSelector';
import { getContentList } from '../../api/posts/endpoints/posts';
import { Loader, openNotification } from 'tt-ui-kit';
import Box from '@mui/material/Box';
import { NewPost } from '../../core/NewPost';

const sections = [
  { name: 'All', id: 'all' },
  { name: 'Posts', id: 'post' },
  // { name: 'Videos', id: 'videos' },
  { name: 'Articles', id: 'article' },
  // { name: 'News', id: 'news' },
  // { name: 'Podcasts', id: 'podcasts' },
  // { name: 'Activity', id: 'activity' },
];

const ContentPage = () => {
  const { user } = useSelectedContext((context) => context.user);

  const [section, setSection] = useState('all');
  const [isFetchContent, setIsFetchContent] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [contentItems, setContentItems] = useState([]);
  const [postsPage, setPostsPage] = useState(1);
  const [postsCount, setPostsCount] = useState(0);
  const [postsTotal, setPostsTotal] = useState(0);
  const [postsNewLoading, setPostsNewLoading] = useState(false);

  const fetchContent = (page = 1, onFinally = null) => {
    getContentList(user.id, section, page)
      .then((response) => {
        if (response?.status === 200 && response?.data) {
          if (page === 1) {
            setContentItems(response.data.data || []);
          } else {
            setContentItems((prevPosts) => [...prevPosts, ...response.data.data]);
          }
          setPostsTotal(response.data.total);
        } else {
          openNotification({
            message: 'Error while fetching content. Try again later.',
            type: 'error',
          });
        }
      })
      .catch(() => {
        openNotification({
          message: 'Error while fetching content. Try again later.',
          type: 'error',
        });
        setContentItems([]);
      })
      .finally(() => {
        if (onFinally) {
          onFinally();
        }
      });
  };

  const handleScroll = () => {
    const container = document.querySelector('#dashboardContent');

    if (
      window.innerHeight + container.scrollTop + 1 >= container.scrollHeight &&
      !postsNewLoading
    ) {
      setPostsPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const container = document.querySelector('#dashboardContent');
    container?.addEventListener('scroll', () => handleScroll());
    return () => {
      container?.removeEventListener('scroll', () => handleScroll());
    };
  }, []);

  useEffect(() => {
    if (contentItems) {
      setPostsCount(contentItems.length);
    }
  }, [contentItems]);

  useEffect(() => {
    if (postsPage !== 1 && postsCount < postsTotal) {
      setPostsNewLoading(true);
      fetchContent(postsPage, () => setPostsNewLoading(false));
    }
  }, [postsPage]);

  useEffect(() => {
    setPostsPage(1);
    setPostsCount(0);
    setPostsTotal(0);
    setIsFetchContent(true);
  }, [section]);

  useEffect(() => {
    if (isFetchContent) {
      setIsLoading(true);
      fetchContent(1, () => setIsLoading(false));
      setIsFetchContent(false);
    }
  }, [isFetchContent]);

  return (
    <>
      <div className={styles.tabsContainer}>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: '#143471' } }}
          centered
          value={section}
          onChange={(e, newValue) => setSection(newValue)}
          className={styles.tabs}
          scrollButtons
        >
          {sections.map((item) => (
            <Tab
              value={item.id}
              label={item.name}
              className={[styles.tabItem, section === item.id ? styles.activeItem : null]}
              sx={{ margin: '0px 30px', padding: 0 }}
            />
          ))}
        </Tabs>
      </div>
      <Select
        value={section}
        className={styles.navbarSelectWrapper}
        onChange={(e) => setSection(e.target.value)}
        size="medium"
        sx={{
          '.MuiOutlinedInput-notchedOutline': { border: '0 !important' },
          '.MuiSelect-select': { padding: '12px 0 12px 18px !important' },
        }}
      >
        {sections &&
          sections.length &&
          sections.map((item) => (
            <MenuItem
              className={clsx(styles.navbarButton, section === item.id ? styles.active : '')}
              key={item.name}
              value={item.id}
            >
              {item.name}
            </MenuItem>
          ))}
      </Select>

      {user.id && (
        <div className={styles.fixedTopElement}>
          <Box className={styles.newPost}>
            <NewPost user={user} onClose={() => setIsFetchContent(true)} />
          </Box>
        </div>
      )}

      <div className={styles.contentWrapper}>
        {isLoading ? (
          <FeedLoader />
        ) : contentItems?.length > 0 ? (
          contentItems.map((element) => {
            let body = '';
            let type = true;

            try {
              body = JSON.parse(element.body);
            } catch {
              body = element.body;
              type = false;
            }

            return (
              <PostFeedView
                postInstance={element}
                postBody={body}
                postTyped={type}
                user={user}
                allowedReactions={allowedReactions}
                onDeletePost={() => setIsFetchContent(true)}
                isContent
              />
            );
          })
        ) : (
          <div className={styles.noContentWrapper}>
            <div className={styles.noContentHead}>
              Please share your articles, videos and podcasts
            </div>
            <div className={styles.noContentText}>You can create a new Content.</div>
          </div>
        )}
        <div className={styles.feedPartLoader}>{postsNewLoading && <Loader />}</div>
      </div>
    </>
  );
};

export default ContentPage;
