/* eslint-disable import/prefer-default-export */
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';

const httpLink = ApolloLink.from([
  new MultiAPILink({
    endpoints: {
      user: process.env.REACT_APP_SSO_GRAPHQL_ENDPOINT,
      marketPlace: process.env.REACT_APP_MARKET_PLACE_GRAPHQL,
      messager: 'https://dev-sso.transparenterra.com/api/tt-consulting',
      calculator: process.env.REACT_APP_CALCULATOR_GRAPHQL_ENDPOINT,
      market: process.env.REACT_APP_MARKET_GRAPHQL_ENDPOINT,
    },
    httpSuffix: '',
    createHttpLink: () => createHttpLink(),
  }),
]);

const authLink = setContext((_, { headers }) => {
  const domainValue = process.env.REACT_APP_DOMAIN;
  const token = Cookies.get('access_token', { domain: domainValue });
  // it is a crutch to auth user on market
  const userId = Cookies.get('userId', { domain: domainValue });
  return {
    headers: {
      ...headers,
      userId: userId,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  cache: new InMemoryCache(),
});
export default client;
