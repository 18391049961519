import { TITLES } from 'constants/pagesTitles';
import MenuPage from 'core/views/MenuPage';
// import { NotAuthorizedMenu } from 'components/pages/MenuPage';
import PageContainerImgBg from 'components/layout/PageContainer/PageContainerImgBg';

const Menu = () => (
  <PageContainerImgBg
    imageBg="/images/section-bckg.jpg"
    title={TITLES.menuPage}
    isSignInPage
    isMenuPage
  >
    {/* <NotAuthorizedMenu /> */}
    <MenuPage />
  </PageContainerImgBg>
);

export default Menu;
