import React, { useState } from 'react';
import { Modal } from 'tt-ui-kit';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { EmailInviteForm } from './EmailInviteForm';
import { CommunityInviteForm } from './CommunityInviteForm';
import { Divider, Box } from '@mui/material';
import styles from './InviteGroupModal.module.scss';

const radioSx = {
  '&, &.Mui-checked': {
    color: '#212121',
  },
  '&, &.MuiRadio-root': {
    margin: '0 8px 0 0',
    padding: 0,
  },
};
const formControlSx = {
  display: 'flex',
  alignItems: 'flex-start',
  marginLeft: 0,
  marginRight: 0,
};
const InviteGroupModal = ({ open, onClose, group }) => {
  const [inviteMethod, setInviteMethod] = useState('email');

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      onClick={(e) => e.stopPropagation()}
      title={`Invite to ${group.name}`}
    >
      <Box className={styles.content}>
        <RadioGroup
          defaultValue={inviteMethod}
          onChange={(e) => setInviteMethod(e.target.value)}
          style={{
            flexDirection: 'column',
            gap: 12,
          }}
          row
        >
          <FormControlLabel
            value="email"
            sx={formControlSx}
            control={<Radio sx={radioSx} defaultChecked />}
            label={
              <Box className={styles.radioBlock}>
                <Typography className={[styles.radioLabel]}>Invite via email</Typography>
                <Typography className={styles.subLabel}>
                  Send an invitation to join TransparenTerra and the group
                </Typography>
              </Box>
            }
          />
          <FormControlLabel
            value="community"
            control={<Radio sx={radioSx} />}
            sx={formControlSx}
            label={
              <Box className={styles.radioBlock}>
                <Typography className={styles.radioLabel}>Invite via my community</Typography>
                <Typography className={styles.subLabel}>
                  Invite TransparenTerra member to become a part of the group
                </Typography>
              </Box>
            }
          />
        </RadioGroup>
        <Divider />
        {inviteMethod === 'email' ? (
          <EmailInviteForm onClose={onClose} />
        ) : (
          <CommunityInviteForm onClose={onClose} />
        )}
      </Box>
    </Modal>
  );
};

export default InviteGroupModal;
