/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import { GET_NOTIFICATIONS } from 'api';
import { HeaderNavbar } from '../../modules/tt-navmenu';
// import 'tt-navmenu/dist/index.css';
import { SignInUpModalWrapper } from '../Modals/SignInUpModal/SignInUpModal';
import { useSelectedContext } from 'context/contextSelector';

const Navbar = (props) => {
  const { avaProps, logOut, setOpenSupportChat, marketTTUrl } = props;

  const { user } = useSelectedContext((context) => context.user);

  const [showNeedKYCModal, setShowNeedKYCModal] = useState(false);
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
  const [anchorLoginEl, setAnchorLoginEl] = useState(null);
  const open = Boolean(anchorLoginEl);

  const handleLoginClose = () => {
    setAnchorLoginEl(null);
  };

  return (
    <>
      <HeaderNavbar
        avaProps={avaProps}
        getNotificationsQuery={GET_NOTIFICATIONS}
        anchorLoginEl={anchorLoginEl}
        setAnchorLoginEl={setAnchorLoginEl}
        user={user}
        notificationSocket={process.env.REACT_APP_WS}
        setShowNeedKYCModal={setShowNeedKYCModal}
        setIsAgreementModalOpen={setIsAgreementModalOpen}
        marketTTUrl={marketTTUrl}
        logOut={logOut} // TODO check if need
        mode="main"
      />

      <SignInUpModalWrapper
        anchorEl={anchorLoginEl}
        open={open}
        closeModal={handleLoginClose}
        setOpenSupportChat={setOpenSupportChat}
      />
    </>
  );
};

export default Navbar;
