import * as d3 from 'd3';
import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getBrowser } from 'utils/defineBrowser';
import styles from './PieChartD3.module.scss';

const isDesktopSize = (node) => node.offsetWidth >= 600 && node.offsetHeight >= 640;

const PieChartD3 = ({ chartData, hasDesktopFormat, onChartClick, score, goal, isGoalPage }) => {
  const [isMobileChart, setIsMobileChart] = useState(false);
  const [indent, setIndent] = useState(0);

  const chartRef = useRef(null);
  const chartWrapperRef = useRef(null);
  const secondChartWrapperRef = useRef(null);

  const navigate = useNavigate();

  const renderChart = (svg, svgWrapper, secondWrapper, data) => {
    svg.selectAll('*').remove();

    const isMobileDevice = !isDesktopSize(svgWrapper);
    setIsMobileChart(isMobileDevice);

    svg.classed('clickable', !!onChartClick);
    // common sizes
    const fullWidth = secondWrapper.offsetWidth;
    const fullHeight = secondWrapper.offsetHeight;

    let marginSize = 0;
    if (!hasDesktopFormat) marginSize = 0;

    const margin = {
      top: marginSize,
      right: marginSize,
      bottom: marginSize,
      left: marginSize,
    };

    const width = fullWidth - margin.left - margin.right;
    const height = fullHeight - margin.top - margin.bottom;
    const baseSize = Math.min(width, height);
    const optimalScoreBase = Math.min(baseSize, 440);

    // chart sizes
    const innerRadius = baseSize * 0.27;
    const betweenBarCirclesMargin = baseSize * 0.06;
    const textCircleRadius = innerRadius * 0.7;
    const miniCirclesRadius = 3;
    const outerCircleRadius = baseSize / 2 - miniCirclesRadius;
    const outerRadius = outerCircleRadius - betweenBarCirclesMargin;
    const underOuterCircleRadius = outerCircleRadius - betweenBarCirclesMargin * 0.4;
    const strokeWidth = underOuterCircleRadius * 0.45;

    // X scale
    const x = d3
      .scaleBand()
      .range([0.1, 2 * Math.PI + 0.1])
      .domain(data.map((d) => d.name));

    // circles width shadows
    svg
      .append('g')
      .classed(styles.shadowedBaseSheet, true)
      .attr('transform', `translate(${fullWidth / 2}, ${fullHeight / 2})`)
      .each(function updateScoreText() {
        // circle with inner shadow
        d3.select(this)
          .append('circle')
          .attr('r', underOuterCircleRadius / 1.33)
          .attr('fill', `transparent`)
          .attr('stroke', `url(#${styles.gradient})`)
          .attr('stroke-width', strokeWidth);

        // gradient for inner-shadowed circle
        d3.select(this)
          .append('radialGradient')
          .attr('id', styles.gradient)
          .each(function updateBars() {
            d3.select(this).append('stop').attr('offset', '75%').attr('stop-color', '#f3f3f3');
            d3.select(this).append('stop').attr('offset', '82%').attr('stop-color', '#f3f3f3');
            d3.select(this).append('stop').attr('offset', '95%').attr('stop-color', '#f3f3f3');
            d3.select(this).append('stop').attr('offset', '100%').attr('stop-color', '#f3f3f3');
          });

        // score circle
        d3.select(this)
          .append('circle')
          .classed(styles.scoreCircle, true)
          .attr('r', textCircleRadius);
        d3.select(this)
          .append('text')
          .classed(styles.scoreText, true)
          .classed(styles.bold, !onChartClick && score !== null)
          .each(function updateBars() {
            if (secondWrapper.offsetWidth === 300) {
              const lines = 'SDGs';
              d3.select(this).append('tspan').attr('dy', indent).attr('x', 0).text(lines);
            } else {
              const lines = 'Sustainable Development Goals'.split(' ');
              d3.select(this)
                .append('tspan')
                .attr('dy', -optimalScoreBase * 0.06)
                .attr('x', 0)
                .text(lines[0]);
              d3.select(this)
                .append('tspan')
                .attr('dy', optimalScoreBase * 0.08)
                .attr('x', 0)
                .text(lines[1]);
              d3.select(this)
                .append('tspan')
                .attr('dy', optimalScoreBase * 0.092)
                .attr('x', 0)
                .text(lines[2]);
            }
          });
      });

    // add active bar
    svg
      .append('g')
      .classed(styles.barChart, true)
      .attr('transform', `translate(${fullWidth / 2}, ${fullHeight / 2})`)
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')
      .classed(styles.bar, true)
      .attr('fill', 'none')
      .attr('stroke-width', (d) =>
        goal && d.name === goal.name ? underOuterCircleRadius * 0.06 : 0
      )
      .attr('stroke', '#f3f3f3')
      .attr(
        'd',
        d3
          .arc()
          .innerRadius(underOuterCircleRadius * 0.95)
          .outerRadius((d) => (goal && d.name === goal.name ? underOuterCircleRadius * 1.01 : 0))
          .startAngle((d) => x(d.name) + 0.03)
          .endAngle((d) => x(d.name) + x.bandwidth() - 0.03)
      );

    // add bars
    svg
      .append('g')
      .classed(styles.barChartSheet, true)
      .attr('transform', `translate(${fullWidth / 2}, ${fullHeight / 2})`)
      .selectAll('path')
      .data(data)
      .enter()
      .append('path')
      .classed(styles.bar, true)
      .attr('fill', (d) => d.stroke)
      .attr(
        'd',
        d3
          .arc()
          .innerRadius(innerRadius)
          .outerRadius((d) =>
            goal && d.name === goal.name ? underOuterCircleRadius * 0.978 : outerRadius
          )
          .startAngle((d) => x(d.name))
          .endAngle((d) => x(d.name) + x.bandwidth())
          .padRadius(innerRadius)
      )
      .classed('clickable', true)
      .on('click', (e, bar) => {
        if (goal && bar.name === goal.name) return;
        navigate(`/sdgs/goal_${bar.goal}`);
      });

    svg
      .append('g')
      .classed(styles.iconsWrapper, true)
      .selectAll('path')
      .data(data)
      .enter()
      .append('svg:image')
      .classed(styles.iconWrapper, true)
      .attr('xlink:href', (d) => d.icon)
      .attr('width', (d) => d.iconWidth)
      .attr('height', (d) => d.iconHeight)
      .attr('x', (d) => d.left)
      .attr('y', (d) => d.top)
      .classed('clickable', true)
      .on('click', (e, bar) => {
        if (goal && bar.name === goal.name) return;
        navigate(`/sdgs/goal_${bar.goal}`);
      });
  };

  useEffect(() => {
    if (indent || indent === 0) {
      const chartElem = d3.select(chartRef.current);
      const wrapper = chartWrapperRef.current;
      const secondWrapper = secondChartWrapperRef.current;
      const onResize = () => {
        renderChart(
          chartElem,
          wrapper,
          secondWrapper,
          chartData,
          score === null ? 'ESG score' : score
        );
      };
      onResize();
      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
    }
  }, [indent, goal]);

  useEffect(() => {
    const browser = getBrowser();
    if (browser && browser.isSafari) {
      setIndent(10);
    } else {
      setIndent(0);
    }
  }, []);

  return (
    <div
      className={`${styles.chartWrapper} ${isGoalPage && styles.chartWrapperGoalPage} ${
        isMobileChart && styles.mobileWrapper
      }`}
      ref={chartWrapperRef}
    >
      <div className={styles.secondChartWrapper} ref={secondChartWrapperRef}>
        <svg
          className={`${styles.circularBarplot} ${isMobileChart && styles.mobile}`}
          ref={chartRef}
          onClick={onChartClick}
        />
      </div>
    </div>
  );
};

export default PieChartD3;
