import React from 'react';
import DOMPurify from 'dompurify';

import styles from './Parser.module.scss';

export const ContentParser = ({ content }) => {
  const cleanHtml = (html) =>
    DOMPurify.sanitize(
      `${html}`
        .replaceAll('<style', '&lt;style')
        .replaceAll('<style>', '&lt;style&gt;')
        .replaceAll('</style>', '&lt;/style&gt;')
        .replaceAll('<form', '&lt;form')
        .replaceAll('<input', '&lt;input')
        .replaceAll('<button', '&lt;button')
        .replaceAll('<select', '&lt;select')
        .replaceAll('<script', '&lt;script')
        .replaceAll('<iframe', '&lt;iframe')
        .replaceAll('<object', '&lt;object')
        .replaceAll('<meta', '&lt;meta')
        .replaceAll('<param', '&lt;param')
        .replaceAll('<html', '&lt;html')
        .replaceAll('<body', '&lt;body')
        .replaceAll('<search', '&lt;search')
        .replaceAll('<noscript', '&lt;noscript')
        .replaceAll('<head', '&lt;head')
        .replaceAll('<base', '&lt;base')
        .replaceAll('<dialog', '&lt;dialog')
        .replaceAll('<footer', '&lt;footer')
        .replaceAll('<header', '&lt;header')
        .replaceAll('<track', '&lt;track')
        .replaceAll('<details', '&lt;details')
        .replaceAll('<source', '&lt;source')
    );

  const renderContent = () => {
    try {
      return (
        <div className={styles.parserContentWrapper}>
          <div
            dangerouslySetInnerHTML={{
              __html: content ? cleanHtml(content) : '',
            }}
          />
        </div>
      );
    } catch (e) {
      return '';
    }
  };

  return content ? renderContent() : '';
};
