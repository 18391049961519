/* eslint-disable */
import { Checkbox, Grid, FormControlLabel } from '@mui/material';
import { CheckBoxOutlined } from '@mui/icons-material';
import styles from './DisclaimerAgreement.module.scss';
import { Image, Button, Modal } from 'tt-ui-kit';
import PartialLoader from 'components/layout/PartialLoader';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Chart from 'components/Charts/RadialChart/Chart';
import { GET_DRAFT_BY_ID, GET_PRELIMINARY_ASSESSMENT } from '../../api';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSelectedContext } from 'context/contextSelector';
import clsx from 'clsx';
import { URL_CALCS_DRAFTS } from 'constants/calculatorsPage';

const DisclaimerAgreement = ({ cost, isModalOpen, setIsModalOpen, confirmChange }) => {
  const navigate = useNavigate();

  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [draft, setDraft] = useState();
  const [score, setScore] = useState({});
  const windowWidth = useRef(window.innerWidth);
  const [isDataLoaded, setIsDataLoaded] = useState({});
  const { user } = useSelectedContext((context) => context.user);

  const [getResultDraft, { data: preCalculate }] = useMutation(GET_PRELIMINARY_ASSESSMENT, {
    variables: { id: cost.draftId },
  });

  const [getDrafts, { data: draftById }] = useLazyQuery(GET_DRAFT_BY_ID, {
    variables: { id: cost.draftId },
  });

  useEffect(() => {
    setIsDataLoaded({});
    setIsAgreed(false);
    getDrafts().finally(() => {
      setIsDataLoaded((val) => ({ ...val, draft: true }));
    });
    getResultDraft().finally(() => {
      setIsDataLoaded((val) => ({ ...val, result: true }));
    });
  }, []);

  useEffect(() => {
    const getTotal = (data) => {
      const {
        environmental,
        social,
        governance,
        productTotal,
        packageTotal,
        companyTotal,
        globalTotal,
      } = data;

      switch (cost.calcType) {
        case 'local':
          return parseFloat(environmental) + parseFloat(social) + parseFloat(governance);
        case 'global':
          return parseFloat(globalTotal || 0);
        case 'package':
        case 'product':
        case 'company':
        case 'carbon':
          return (
            parseFloat(productTotal || 0) +
            parseFloat(packageTotal || 0) +
            parseFloat(companyTotal || 0)
          );
        default:
          return -1;
      }
    };

    if (preCalculate && preCalculate.preliminaryAssessment) {
      const {
        environmental,
        social,
        governance,
        productTotal,
        packageTotal,
        companyTotal,
        globalTotal,
        testYear,
      } = preCalculate.preliminaryAssessment;
      const value = getTotal(preCalculate.preliminaryAssessment);
      setScore({
        environmental: parseFloat(environmental),
        social: parseFloat(social),
        governance: parseFloat(governance),
        total: Math.round(value * 100) / 100,
        productTotal: productTotal ? parseFloat(productTotal) : 0,
        packageTotal: packageTotal ? parseFloat(packageTotal) : 0,
        companyTotal: companyTotal ? parseFloat(companyTotal) : 0,
        globalTotal: globalTotal ? parseFloat(globalTotal) : 0,
        testYear,
      });
    }
  }, [preCalculate]);

  useEffect(() => {
    if (draftById && draftById.draftById) {
      const newDraft = {
        ...draftById.draftById,
        stateGraphics: JSON.parse(draftById.draftById.stateGraphics),
        createdAt: new Date(draftById.draftById.createdAt),
        completedAt: new Date(draftById.draftById.completedAt),
        updatedAt: new Date(draftById.draftById.updatedAt),
        reportDate: new Date(draftById.draftById.reportDate),
      };
      setDraft(newDraft);
      setIsModalOpen(true);
    }
  }, [draftById]);

  const navigateToDrafts = () => {
    navigate(URL_CALCS_DRAFTS);
  };

  const scrolledToEnd = (e) => {
    const { target } = e;
    setIsCheckboxEnabled(
      target.offsetHeight + Math.ceil(target.scrollTop) >= target.scrollHeight * 0.95
    );
  };

  const getTotal = () =>
    Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
      score.total
    );

  return (
    <Modal
      open={isModalOpen}
      onClick={(e) => e.stopPropagation()}
      onClose={() => setIsModalOpen(false)}
      title="Release and Send to the Blockchain"
      fullScreenOnResponse
      closeOnlyByControls
      containerClassName={styles.modalRoot}
      className={styles.modal}
    >
      <div className={styles.disclaimerModalWrapper}>
        <Grid
          item
          container
          alignItems="stretch"
          justifyContent="center"
          className={styles.container}
        >
          <div className={styles.chartArea}>
            <Chart
              chartData={(draft && draft.stateGraphics) ?? []}
              score={score.total ? score.total.toString() : ''}
              hasDesktopFormat
            />
          </div>
          <Grid item container direction="row" className={styles.textArea}>
            <div className={styles.owerflowContainer} onScroll={scrolledToEnd}>
              <div className={styles.text}>
                {cost.calcType === 'local' && (
                  <>
                    {`Based on your self-assessment, your ESG Excellence score for the
                    year ${score.testYear} is ${score.total}.`}
                    <br />
                  </>
                )}
                {(cost.calcType === 'carbon' ||
                  cost.calcType === 'product' ||
                  cost.calcType === 'package' ||
                  cost.calcType === 'company') && (
                  <>
                    <p>You have completed your calculations.</p>
                    <p>The carbon footprint for year {score.testYear} is:</p>
                    <p>Product carbon footprint {score.productTotal}</p>
                    <p>Packaging carbon footprint {score.packageTotal}</p>
                    <p>Company carbon footprint {score.companyTotal}.</p>
                  </>
                )}
                {cost.calcType === 'global' && (
                  <>
                    <p>You have completed your calculations.</p>
                    <p>
                      The ESG Benchmark result for year {score.testYear} is:
                      {score.globalTotal}.
                    </p>
                  </>
                )}
                <div>
                  <br />
                  <p>
                    <strong>
                      {`Your self-assessment results will be encrypted, and the hash
                      will be deployed and notarized on the FCE Blockchain.It is a
                      paid option. Please proceed with payment to get access to your
                      assessment.`}
                    </strong>
                  </p>
                  <br />
                </div>
                <h3>Disclaimer</h3>
                <br />
                <div>
                  <p>
                    {`To become visible to interested investors, you need to submit
                    the data to the blockchain.`}
                  </p>
                  <p>
                    {`The data will be sent to FCE Group's blockchain via the
                    TransparenTerra platform.`}
                  </p>
                  <p>
                    {`We will not send raw data to the blockchain, only data hashes.
                    All your data will be stored on the profile page, hashed there
                    and sent to the blockchain in encrypted form. If someone wants
                    to compare or verify the data on the blockchain, they will
                    compare the hashes, not the data itself. The hash is generated
                    on the profile page.`}
                  </p>
                  <p>
                    {`The responsibility for the quality of the data lies solely with
                    those who enter the data. The data in the blockchain is
                    immutable and will be stored forever. To respect your rights
                    under GDPR, we will send hashes of your ESG score results there.
                    In your profile, experts, investors and any interested parties
                    who have access to this data can compare the validity of your
                    data with the hashes.`}
                  </p>
                  <p>
                    {`The results sent to the blockchain contribute to the full
                    transparency of your company and become attractive for
                    investment.`}
                  </p>
                  <p>
                    {`All blockchain transactions are public and chargeable. Once
                    published, the data cannot be deleted or changed, but you can
                    always complete a new calculation and publish the updated
                    results.`}
                  </p>
                </div>
                <br />
                <div className={styles.agreeCheck}>
                  <div className={styles.checkboxContainer}>
                    <FormControlLabel
                      className={styles.checkbox}
                      control={
                        <Checkbox
                          onChange={() => setIsAgreed((value) => !value)}
                          checked={isAgreed}
                          checkedIcon={<CheckBoxOutlined />}
                          variant="unlighted"
                          disabled={windowWidth.current >= 1024 && !isCheckboxEnabled}
                        />
                      }
                      label="I have read and agree"
                      labelPlacement="end"
                    />
                  </div>
                </div>
              </div>
            </div>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="space-between"
              className={styles.buttonCont}
            >
              <Button onClick={navigateToDrafts}>Go to my drafts</Button>
              <Button type="primary" onClick={() => confirmChange()} disabled={!isAgreed}>
                Release
              </Button>
            </Grid>
          </Grid>
          <PartialLoader loading={!isDataLoaded.draft || !isDataLoaded.result} isLightContainer />
        </Grid>
      </div>
    </Modal>
  );
};

export default DisclaimerAgreement;
