import React from 'react';
import { InviteWrapper } from 'components/Invite';
import { ESGWidget } from '../../modules/tt-esg-widget';
import '../../modules/tt-esg-widget/components/ESGWiget/EsgFeed.module.scss';
import styles from './DashboardWidgets.module.scss';
import { useWindowSize } from 'utils/useWindowSize';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import { ReactComponent as OpenIcon } from './Icons/ArrowOpen.svg';
import { ReactComponent as CloseIcon } from './Icons/ArrowClose.svg';
import { ReactComponent as ESGFeedIcon } from './Icons/ESGFeedIcon.svg';
import { ReactComponent as IviteIcon } from './Icons/InviteIcon.svg';

const drawerWidth = 372;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DashboardWidgets = ({ user, kyc, showNavbar }) => {
  const theme = useTheme();
  const { width } = useWindowSize();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return width > 1580 || width < 1024 ? (
    <div
      className={styles.rightColumn}
      style={{
        top: showNavbar ? 195 : 125,
        visibility:
          kyc !== 'success' ||
          (!user?.roles_list && !JSON.parse(user.roles_list).includes('transparenterra'))
            ? 'hidden'
            : 'inherit',
      }}
    >
      {kyc === 'success' ||
      (user?.roles_list && JSON.parse(user.roles_list).includes('transparenterra')) ? (
        <div className={styles.widgetItem}>
          <InviteWrapper />
        </div>
      ) : null}
    </div>
  ) : (
    <div
      className={styles.drawerWrapper}
      style={{
        top: showNavbar ? 195 : 125,
        visibility:
          kyc !== 'success' ||
          (!user?.roles_list && !JSON.parse(user.roles_list).includes('transparenterra'))
            ? 'hidden'
            : 'inherit',
      }}
    >
      <Drawer
        variant="permanent"
        open={open}
        style={{
          borderRadius: 12,
          boxShadow: open ? '0 0 20px 2px rgba(0,0,0,0.20)' : 'none',
        }}
      >
        <DrawerHeader
          style={{
            display: 'flex',
            justifyContent: open ? 'space-between' : 'center',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {open ? <div style={{ marginLeft: 15, fontWeight: 600 }}>Widgets</div> : null}
          <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? <CloseIcon /> : <OpenIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          className={styles.drawerList}
          style={{ maxHeight: showNavbar ? 'calc(90vh - 200px)' : 'calc(90vh - 130px)' }}
        >
          {kyc === 'success' ||
          (user?.roles_list && JSON.parse(user.roles_list).includes('transparenterra')) ? (
            <ListItem disablePadding sx={{ display: 'block' }}>
              <div className={styles.drawerItem}>
                {open ? (
                  <InviteWrapper />
                ) : (
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <IviteIcon />
                  </ListItemIcon>
                )}
              </div>
            </ListItem>
          ) : null}
        </List>
      </Drawer>
    </div>
  );
};

export default DashboardWidgets;
