import clsx from 'clsx';
import styles from './SignUpField.module.scss';

const SignUpField = ({ isDragActive, children, disabled = false }) => (
  <div
    className={clsx(
      styles.field,
      isDragActive ? styles.dragActive : '',
      disabled ? styles.disabled : ''
    )}
  >
    {children}
  </div>
);

export default SignUpField;
