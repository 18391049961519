import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';
import CalcIcon from '../../../CalcIcon/CalcIcon';
import { CALC_TYPES } from 'utils/calculatorUtils';
import ReportBlock from './ReportBlock';
import ReportBlockMobile from './ReportBlockMobile';
import { Button, Modal } from 'tt-ui-kit';
import { useSelectedContext } from 'context/contextSelector';
import styles from './ReportDraft.module.scss';
import { URL_REPORT_DRAFT_DETAILS } from 'constants/calculatorsPage';
import { deleteDraft, updateDraft } from 'api/calc/endpoints/calc';

const ttRestHost = `${process.env.REACT_APP_SSO_API}/tt-ai`;

const ReportDraft = ({ targetWidth, draft, refresh }) => {
  const navigate = useNavigate();

  const { user } = useSelectedContext((context) => context.user);

  const [editId, setEditId] = useState(null);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [isGenerateModalOpened, setGenerateModalOpen] = useState(false);
  const [draftForDelete, setDraftForDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  // width control end
  const onProceedDraft = (id) => {
    window.location.replace(`${process.env.REACT_APP_REPORT}/editReport/${id}`);
  };

  const onRenameDraft = async (e) => {
    const { value } = e.target;
    await updateDraft({
      id: editId,
      name: value,
    });
    setEditId(null);
    refresh();
  };

  const onDeleteDraft = async () => {
    setLoading(true);
    if (!draftForDelete) {
      setLoading(false);
      return;
    }
    await deleteDraft({ id: draftForDelete.id });
    setDraftForDelete(null);
    setLoading(false);
    refresh();
  };

  const modalDeleteOpen = () => {
    setDraftForDelete(draft);
    setIsDeleteModalOpened(true);
  };

  const closeDeleteModal = () => {
    setDraftForDelete(null);
    setIsDeleteModalOpened(false);
  };

  const onViewClick = () => {
    navigate(`${URL_REPORT_DRAFT_DETAILS}/${draft.type}/${draft.id}`);
  };

  const onGenerateClick = () => {
    setGenerateModalOpen(true);
  };

  const GenerateReport = () => {
    const token = Cookies.get('access_token');
    const headers = {
      authorization: token ? `Bearer ${token}` : '',
      Userid: user.id,
    };
    const params = {
      source_id: draft.reportId,
      source: 'custom_report_file',
    };
    const url = `${ttRestHost}/reports/generate`;

    axios
      .get(url, { headers, params })
      .then((response) => {
        const { data } = response;
        if (data?.success) {
          setGenerateModalOpen(false);
        }
      })
      .catch(() => {
        // TODO Log error
      });
  };

  return (
    <>
      <Modal
        open={isGenerateModalOpened}
        onClose={() => setGenerateModalOpen(false)}
        onClick={(e) => e.stopPropagation()}
        title="Are you sure you are ready to start generating report?"
        className={styles.warningModal}
      >
        <div style={{ padding: '16px 0 20px 0' }}>
          {`Please check the questions and answers before starting report
          generation. Only successfully processed data will be included in the report.`}
        </div>
        <div className={styles.buttonWrapper}>
          <Button type="default" onClick={() => setGenerateModalOpen(false)}>
            Cancel
          </Button>
          <Button type="primary" onClick={GenerateReport}>
            Generate Report
          </Button>
        </div>
      </Modal>
      <Modal
        open={isDeleteModalOpened && draftForDelete}
        onClose={closeDeleteModal}
        title="Delete draft"
        className={styles.warningModal}
      >
        <div style={{ padding: '16px 0 20px 0' }}>
          Do you really want to delete draft &quot;
          {draftForDelete ? draftForDelete.name : ''}&quot;?
        </div>
        <div className={styles.buttonWrapper}>
          <Button type="default" onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button type="primary" onClick={onDeleteDraft} disabled={loading}>
            Delete
          </Button>
        </div>
      </Modal>
      {targetWidth >= 600 && (
        <ReportBlock
          props={{
            draft: draft,
            targetWidth: targetWidth,
            calcLogo: CALC_TYPES.CUSTOM_REPORT === draft.type && <CalcIcon type={draft.type} />,
            editId: editId,
            setEditId: setEditId,
            onProceedDraft: onProceedDraft,
            onDelete: modalDeleteOpen,
            onRenameDraft: onRenameDraft,
            onViewClick: onViewClick,
            onGenerateClick: onGenerateClick,
          }}
        />
      )}
      {targetWidth < 600 && (
        <ReportBlockMobile
          props={{
            draft: draft,
            targetWidth: targetWidth,
            calcLogo: CALC_TYPES.CUSTOM_REPORT === draft.type && <CalcIcon type={draft.type} />,
            editId: editId,
            setEditId: setEditId,
            onProceedDraft: onProceedDraft,
            onDelete: modalDeleteOpen,
            onRenameDraft: onRenameDraft,
            onViewClick: onViewClick,
            onGenerateClick: onGenerateClick,
          }}
        />
      )}
    </>
  );
};

export default ReportDraft;
