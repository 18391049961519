import React from 'react';
import styles from './CompanyBlock.module.scss';
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';
import { ReactComponent as KybIcon } from '../../assets/svg/kyb-sm.svg';
import { Button } from 'tt-ui-kit';
import { Avatar } from '@mui/material';

const CompanyBlock = ({ company, onClose, onClick }) => {
  const onBlockClick = (value) => onClick && onClick(value);

  const getAvatarPath = (files) => {
    const avatar = files.find((i) => i.type === 'company_logo_main');
    if (avatar && avatar.path) {
      return avatar.path;
    }
    return '';
  };

  return (
    <div className={styles.companyRow} onClick={() => onBlockClick(company.id)}>
      <div>
        <Avatar src={getAvatarPath(company?.logos)} className={styles.logo} />
      </div>
      <div className={styles.name}>
        <p>{company?.companyName}</p>
        {/* TODO перевернуть проверку KYC */}
        {!company?.kyb && <KybIcon />}
      </div>
      {onClose && (
        <Button type="icon" onClick={() => onClose(company.id)}>
          <CloseIcon />
        </Button>
      )}
    </div>
  );
};

export default CompanyBlock;
