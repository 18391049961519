export const generateUidForFile = (file) => `${file.size + file.lastModified}-${Date.now()}`;

export const isAudioFileOverlength = (file, maxDuration = 3600) =>
  new Promise((resolve, reject) => {
    const audio = new Audio();
    audio.src = URL.createObjectURL(file);

    audio.onloadedmetadata = () => {
      const { duration } = audio;

      if (duration > maxDuration) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
  });
