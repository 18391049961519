import React, { useState } from 'react';
import { VideoPlayerComponent } from 'core/VideoPlayer';
import styles from '../../NewPostModal.module.scss';
import { VideoEdit } from '../VideoEdit/VideoEdit';

export const VideoPreview = ({
  file,
  handleRemoveFileWithPrev,
  updateFileMethod,
  isEdit = false,
}) => {
  const [openEditModal, setOpenEditModal] = useState(false);

  return (
    <div className={styles.uploadVideoBox}>
      <div
        className={styles.uploadImgBoxLoader}
        style={{ display: file.loading ? 'flex' : 'none' }}
      >
        <div className={styles.uploadImgBoxLoaderProgressBar}>
          <div className={styles.uploadImgBoxLoaderProgress} />
        </div>
      </div>

      <div
        className={styles.uploadImgBoxLoader}
        style={{ display: file.error ? 'flex' : 'none', border: '1px solid red' }}
      >
        <div
          onClick={() => {
            // reuploadFile(file);
          }}
        >
          <img src="/images/add-post/reload.svg" alt="" width={24} height={24} />
        </div>
      </div>

      <div className={file.loading || file.error ? 'blur-content' : styles.uploadVideoBoxPreview}>
        {(file.url || file.path) && <VideoPlayerComponent src={file.url || file.path || ''} />}
      </div>

      <div className={styles.videoPreviewButtons}>
        {/* <button */}
        {/*  type="button" */}
        {/*  style={{ */}
        {/*    display: file.loading ? 'none' : 'block', */}
        {/*    zIndex: file.error ? 2000 : 100, */}
        {/*  }} */}
        {/*  onClick={() => { */}
        {/*    setOpenEditModal(true); */}
        {/*  }} */}
        {/* > */}
        {/*  <img src="/images/add-post/edit-white.svg" alt="" /> */}
        {/* </button> */}

        <button
          type="button"
          className={styles.videoPreviewClose}
          style={{
            display: file.loading ? 'none' : 'block',
            zIndex: file.error ? 2000 : 100,
          }}
          onClick={() => {
            if (isEdit) {
              handleRemoveFileWithPrev(
                file.id,
                file.uuid || file.id,
                file.url || file.path,
                !!file.uuid
              );
            } else {
              handleRemoveFileWithPrev(file.id, file.uuid, file.url);
            }
          }}
        >
          <img src="/images/close.svg" alt="x" />
        </button>
      </div>

      <VideoEdit
        file={file}
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        updateFileMethod={updateFileMethod}
        isEdit={isEdit}
      />
    </div>
  );
};
