import { TITLES } from 'constants/pagesTitles';
import PageContainerImgBg from 'components/layout/PageContainer/PageContainerImgBg';
import ConfirmPage from 'core/views/ConfirmPage';

const Confirm = () => (
  <PageContainerImgBg
    imageBg="/images/section-bckg.jpg"
    titles={TITLES.confirmPage}
    isLogoHidden
    isSignInPage
  >
    <ConfirmPage />
  </PageContainerImgBg>
);

export default Confirm;
