/* eslint-disable react/no-array-index-key */
/* eslint-disable global-require */
import React, { useEffect, useState, createRef, useLayoutEffect } from 'react';
import { AddPhotoIcon, FileAddIcon } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import clsx from 'clsx';
import { v1 as uuidv1 } from 'uuid';
import {
  GET_URL_FOR_PUBLIC_UPLOAD,
  GET_URL_FOR_PRIVATE_UPLOAD,
  DELETE_FROM_PUBLIC,
  DELETE_FROM_PRIVATE,
} from 'api';
import { useMutation } from '@apollo/client';
import SignUpField from '../SignUpField';
import styles from './DragDropField.module.scss';
import UploadedFile from './UploadedFile';
import { isEmptyObj } from '../../../../../utils/isEmptyObj';
import { AvatarCropModal } from '../../../../../modules/tt-modals';

const NAME_LENGTH_LIMIT = 14;
const SIZE_LIMIT = 2048000;
const UPLOADING_STATUS = 'uploading';
const UPLOADED_STATUS = 'uploaded';
const ERROR_STATUS = 'error';

const DragDropField = ({
  title,
  fieldName,
  dragDropDesc,
  add,
  description,
  maxFiles,
  isMultiple,
  isImage,
  files,
  setFiles,
  path,
  filesNames,
  setFilesNames,
  formErrors,
  clearErrors,
  forModal = false,
  photoError,
}) => {
  const [errors, setErrors] = useState([]);
  const [isRightGradientVisible, setIsRightGradientVisible] = useState(false);
  const [isLeftGradientVisible, setIsLeftGradientVisible] = useState(false);
  const [isScrollVisible, setIsScrollVisible] = useState(false);

  const [filesListToUpload, setFilesListToUpload] = useState([]);
  const [avatarFile, setAvatarFile] = useState(null);
  const [isAvatarModal, setIsAvatarModal] = useState(false);

  useEffect(() => {
    if (formErrors && !isEmptyObj(formErrors) && formErrors[fieldName]) {
      setErrors([formErrors[fieldName].message]);
    } else if (!errors.length > 0) {
      setErrors([]);
    }
  }, [formErrors]);

  const [getPublicUrl] = useMutation(GET_URL_FOR_PUBLIC_UPLOAD);
  const [getPrivateUrl] = useMutation(GET_URL_FOR_PRIVATE_UPLOAD);
  const [deletePublic] = useMutation(DELETE_FROM_PUBLIC);
  const [deletePrivate] = useMutation(DELETE_FROM_PRIVATE);

  const wrapperRef = createRef();

  const updateFiles = (ollFiles, currentFile, newName, isMultipleField, status) => {
    const updatedFiles = ollFiles.map((file) =>
      file.oldName === currentFile.name ? { ...file, status } : file
    );

    return updatedFiles;
  };

  const getNewName = (file, fNames) => {
    const fileFromArr = fNames.find((f) => f.oldName === file.name);
    return fileFromArr?.newName;
  };

  const uploadFile = async (file, newFiles) => {
    try {
      const fileName = getNewName(file, newFiles);
      const toUpload = { path, name: fileName };
      const { data } = isMultiple
        ? await getPrivateUrl({
            variables: { input: toUpload },
          })
        : await getPublicUrl({
            variables: { input: toUpload },
          });
      const url = isMultiple ? data?.getUrlForPrivateUpload?.url : data?.getUrlForPublicUpload?.url;

      const putData = await axios.put(url, file, {
        headers: {
          'Content-type': file.type,
          'Access-Control-Allow-Origin': '*',
        },
      });

      if (putData) {
        setFilesNames(updateFiles(newFiles, file, fileName, isMultiple, UPLOADED_STATUS));
      } else {
        setFilesNames(updateFiles(newFiles, file, fileName, isMultiple, ERROR_STATUS));
      }
    } catch (error) {
      const fileName = isMultiple ? getNewName(file, newFiles) : file.name;
      setFilesNames(updateFiles(newFiles, file, fileName, isMultiple, ERROR_STATUS));
    }
  };

  const deleteFromS3 = async (name) => {
    try {
      const file = { path, name };
      const res = isMultiple
        ? await deletePrivate({ variables: { input: file } })
        : await deletePublic({ variables: { input: file } });
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const openCropModal = (file, fileToUpload) => {
    const filesize = (file.size / 1024 / 1024).toFixed(2);

    if (filesize <= 2) {
      setAvatarFile(file);
      setIsAvatarModal(true);
      setFilesListToUpload(fileToUpload);
    } else {
      openNotification({
        message: 'Allowed files with 2MB or below.',
        type: 'info',
      });
    }
  };

  const setAvatarAfterCrop = async (file) => {
    Object.assign(file, {
      preview: URL.createObjectURL(file),
    });
    setAvatarFile(file);

    await uploadFile(file, filesListToUpload);
  };

  const onDropHandle = async (acceptedFiles, isMultipleInput) => {
    const fileToUpload = acceptedFiles.reduce(
      (arr, f) => {
        const fileNameSplitted = f.name.split('.');
        const extension = fileNameSplitted[fileNameSplitted.length - 1];
        const fileName = `${uuidv1()}.${extension}`;
        const newFiles = isMultiple
          ? [
              ...arr,
              {
                oldName: f.name,
                newName: fileName,
                status: UPLOADING_STATUS,
              },
            ]
          : [
              {
                oldName: f.name,
                newName: fileName,
                status: UPLOADING_STATUS,
              },
            ];
        return newFiles;
      },
      [...filesNames]
    );

    setFilesNames(fileToUpload);

    if (isImage && maxFiles === 1) {
      openCropModal(acceptedFiles[0], fileToUpload);
    } else {
      await acceptedFiles.map(async (f) => {
        await uploadFile(f, fileToUpload);
      });
    }

    const newFiles =
      isMultipleInput && files
        ? [
            ...files,
            ...acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            ),
          ]
        : [
            ...acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            ),
          ];

    if (newFiles.length > 0) {
      setErrors([]);
      clearErrors(fieldName);
      setFiles(newFiles);
    }
  };

  const checkNameLength = (name) =>
    name.length > NAME_LENGTH_LIMIT ? `${name.substr(0, NAME_LENGTH_LIMIT)}...` : name;

  const onDropRejectedHandle = (fileRejections) => {
    if (fileRejections && fileRejections.length) {
      const errorsList = fileRejections.reduce(
        (list, f) => [...list, `${checkNameLength(f.file.name)} - ${f.errors[0].message}`],
        []
      );
      setErrors(errorsList);
    }
  };

  const checkFileSize = (file) => {
    if (file.size > SIZE_LIMIT) {
      return {
        code: 'size-limit',
        message: `File must be no larger than 2 Mb`,
      };
    }
    return null;
  };

  const { isDragActive, isDragAccept, getInputProps, getRootProps } = useDropzone({
    accept: isImage
      ? { 'image/jpeg': ['.jpeg'], 'image/png': ['.png'] }
      : { 'application/*': ['*'] },
    onDrop: async (acceptedFiles) => {
      await onDropHandle(acceptedFiles, isMultiple);
    },
    onDropRejected: (fileRejections) => onDropRejectedHandle(fileRejections),
    validator: checkFileSize,
    maxFiles: { maxFiles },
    multiple: false,
  });

  const deleteFile = async (e, file) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const index = filesNames.indexOf(file);
      const name = filesNames[index].newName;
      filesNames.splice(index, 1);
      setFiles([...filesNames]);

      if (file.status === UPLOADED_STATUS) {
        const res = await deleteFromS3(name);
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPreviewSrc = (file) => {
    if (file && file.preview) {
      return file.preview;
    }
    return null;
  };

  const handleScroll = (e) => {
    const isMaxScroll = e.target.scrollWidth - e.target.scrollLeft - e.target.clientWidth < 1;
    if (e.target.scrollLeft === 0) {
      setIsRightGradientVisible(true);
      setIsLeftGradientVisible(false);
    } else if (isMaxScroll) {
      setIsRightGradientVisible(false);
      setIsLeftGradientVisible(true);
    } else {
      setIsRightGradientVisible(true);
      setIsLeftGradientVisible(true);
    }
  };

  useLayoutEffect(() => {
    if (window && wrapperRef && wrapperRef.curren) {
      if (wrapperRef.current.clientWidth < wrapperRef.current.scrollWidth) {
        if (!isScrollVisible) {
          setIsRightGradientVisible(true);
          setIsScrollVisible(true);
        }
      }
      if (wrapperRef.current.clientWidth >= wrapperRef.current.scrollWidth) {
        if (isRightGradientVisible) {
          setIsRightGradientVisible(false);
          setIsScrollVisible(false);
        }
      }
    }
  }, [wrapperRef]);

  useEffect(() => {
    if (files && files.length > 0) {
      files?.forEach((file) => URL.revokeObjectURL(file.preview));
    }
  }, [files]);

  useEffect(() => {
    if (window) {
      window.Buffer = window.Buffer || require('buffer').Buffer;
    }
  }, []);

  return (
    <SignUpField isDragActive={isDragActive} propsStyles={forModal ? { margin: 0 } : {}}>
      <div
        {...getRootProps({
          className: clsx(
            styles.dragDropFieldWrapper,
            isDragActive ? styles.dragDragActive : '',
            photoError ? styles.errorInputWrapper : ''
          ),
        })}
      >
        <div
          className={styles.dragDropFieldSecondWrapper}
          style={{ padding: forModal ? 0 : '12px 30px 12px 32px' }}
        >
          <div className={`${description ? styles.dropZone : styles.dropZoneOther}`}>
            <input {...getInputProps()} />
            {isImage && files && files.length > 0 && (
              <div
                className={styles.iconWrapper}
                style={{
                  background: `center / cover no-repeat url(${
                    isImage && maxFiles === 1
                      ? avatarFile?.preview || null
                      : getPreviewSrc(files[0])
                  })`,
                }}
              />
            )}
            {(!isImage || (isImage && (!files || files.length === 0))) && (
              <div className={styles.iconWrapper}>
                {fieldName === 'photo' ? (
                  <AddPhotoIcon className={styles.fieldIconDefaultStroke} />
                ) : fieldName === 'documents' ? (
                  <FileAddIcon className={styles.fieldIconDefaultFill} />
                ) : (
                  <FileAddIcon className={styles.fieldIconDefaultFill} />
                )}
              </div>
            )}
            <div className={`${description ? styles.textWrapper : styles.textFull}`}>
              <div className={styles.title}>{title}</div>
              <div className={styles.description}>{dragDropDesc}</div>
              <div className={styles.add}>{add}</div>
              {filesNames && filesNames.length > 0 && (
                <div className={styles.uploadedFilesWrapper}>
                  <div
                    ref={wrapperRef}
                    className={styles.uploadedFilesSecondWrapper}
                    onScroll={handleScroll}
                  >
                    <div
                      className={`${styles.linearGradientLeft} ${
                        isLeftGradientVisible && styles.visible
                      }`}
                    />
                    <div
                      className={`${styles.linearGradientRight} ${
                        isRightGradientVisible && styles.visible
                      }`}
                    />
                    {filesNames.map((file, index) => (
                      <div key={file.newName}>
                        <UploadedFile
                          files={filesNames}
                          file={file}
                          index={index}
                          deleteFile={async (e) => {
                            await deleteFile(e, file);
                          }}
                          checkNameLength={checkNameLength}
                          isMultiple={isMultiple}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {errors && errors.length > 0 && <div className={styles.error}>{errors[0]}</div>}
              {/* {formErrors && !isEmptyObj(formErrors) && formErrors[fieldName] && ( */}
              {/*  <div className={styles.error}>{formErrors[fieldName].message}</div> */}
              {/* )} */}
            </div>
          </div>
          {description && (
            <div className={styles.descriptionWrapper}>
              {photoError ? (
                <div className={styles.error}>{photoError}</div>
              ) : (
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: `${description}` }}
                />
              )}
            </div>
          )}
          {filesNames && filesNames.length > 0 && (
            <div className={styles.uploadedFilesWrapper}>
              <div className={styles.uploadedFilesSecondWrapper} onScroll={handleScroll}>
                <div
                  className={`${styles.linearGradientLeft} ${
                    isLeftGradientVisible && styles.visible
                  }`}
                />
                <div
                  className={`${styles.linearGradientRight} ${
                    isRightGradientVisible && styles.visible
                  }`}
                />
                {filesNames.map((file, index) => (
                  <div key={file.newName}>
                    <UploadedFile
                      files={filesNames}
                      file={file}
                      index={index}
                      deleteFile={async (e) => {
                        await deleteFile(e, file);
                      }}
                      checkNameLength={checkNameLength}
                      isMultiple={isMultiple}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <AvatarCropModal
        open={isAvatarModal}
        setOpen={setIsAvatarModal}
        avatarFile={avatarFile}
        setAvatar={setAvatarAfterCrop}
      />
    </SignUpField>
  );
};

export default DragDropField;
