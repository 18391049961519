import React, { useState } from 'react';
import styles from './Comments.module.scss';
import { AppAvatar } from '../../Avatar';
import { ImageSlider } from '../../../core/CustomImageSlider';
import { IconButton } from '@mui/material';
import { DropdownMenu, MoreVIcon } from 'tt-ui-kit';
import CommentsActions from './CommentsActions';

const ITEM_HEIGHT = 48;

const Reply = ({ item, user, index, onChangeComment }) => {
  const [anchorReplyEl, setAnchorReplyEl] = useState(null);
  const openReply = Boolean(anchorReplyEl);

  const handleReplyClick = (event) => {
    setAnchorReplyEl(event.currentTarget);
  };

  const handleReplyClose = () => {
    setAnchorReplyEl(null);
  };

  const parseReplyBody = (_body) => {
    let body = _body;
    let replyUsername = '';

    try {
      const bodyJSON = JSON.parse(_body);
      body = bodyJSON.body;
      replyUsername = bodyJSON.replyUsername;
    } catch (e) {
      body = _body;
      replyUsername = '';
    }

    if (replyUsername) {
      const indexInsertion = body.indexOf(replyUsername);
      const resultString =
        body.substring(0, indexInsertion) +
        body.substring(indexInsertion + replyUsername.length + 2);

      return (
        <div>
          <a
            href={`/dashboard/profile/${item.reply_user_id}`}
            style={{ color: '#18397A', fontWeight: 500 }}
          >
            {replyUsername}
          </a>
          , {resultString}
        </div>
      );
    }

    return <div>{body}</div>;
  };

  return (
    <div
      key={`${index + 1}`}
      className={styles.replyContainer}
      style={index === 0 ? { border: 'none' } : undefined}
    >
      <div>
        <AppAvatar
          nav={1}
          size="xs"
          userName={`${item.user.first_name} ${item.user.last_name}`}
          src={item.user.avatar}
        />
      </div>

      <div className={styles.commentContent}>
        <div
          className={styles.commentUserName}
        >{`${item.user.first_name} ${item.user.last_name}`}</div>

        <p className={styles.commentText}>{parseReplyBody(item.body)}</p>

        {item.files_image?.length > 0 && (
          <div className={styles.fileBox}>
            <div
              className={`${styles.fileBox} ${styles.imagesWrapper}`}
              onClick={(event) => event.stopPropagation()}
            >
              <ImageSlider array={item.files_image} />
            </div>
          </div>
        )}
      </div>

      <div className={styles.menuContainer}>
        <IconButton className={styles.commentMenuButton} onClick={handleReplyClick}>
          <MoreVIcon style={{ width: 20, height: 20, flexShrink: 0, fontSize: 20 }} />
        </IconButton>
        <DropdownMenu
          anchorEl={anchorReplyEl}
          open={openReply}
          onClose={handleReplyClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
            },
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <CommentsActions
            user={user}
            commentInstance={item}
            closeMenu={handleReplyClose}
            onDeleteComment={onChangeComment}
            isReply
          />
        </DropdownMenu>
      </div>
    </div>
  );
};

export default Reply;
