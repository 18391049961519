import React from 'react';
import { ReactComponent as PasswordErr } from '../../assets/svg/formError.svg';

export const FormErrorsView = ({ errors, wrapperClassName, errClassName }) => (
  <div className={wrapperClassName}>
    {errors.map(
      (item) =>
        !item.match && (
          <div className={errClassName} key={item.errMessage}>
            <PasswordErr /> {item.errMessage}
          </div>
        )
    )}
  </div>
);
