/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useEffect, useState } from 'react';
import UserContext from './userContext';
import Cookies from 'js-cookie';

const UserState = ({ children }) => {
  const initialState = {
    id: '',
    email: '',
    first_name: '',
    last_name: '',
    created_at: '',
    avatar: '',
    invited_by_name: '',
    ceo_of: '',
    role_id: '',
    role_name: '',
    isCompanySelected: false,
    selectedCompany: null,
  };

  const companyInitialState = {
    isCompanySelected: false,
    selectedCompany: null,
  };

  const getCompanyInitialState = () => {
    const data = localStorage.getItem('currentCompanyData');
    return data ? JSON.parse(data) : companyInitialState;
  };

  const [userState, setUserState] = useState(initialState);
  const [companyState, setCompanyState] = useState(getCompanyInitialState);
  const [someUserState, setSomeUserState] = useState(initialState);

  const changeUserData = (user = {}) => {
    setUserState(user);
  };

  const changeSomeUserData = (user = {}) => {
    setSomeUserState(user);
  };

  const setSelectedCompany = (company) => {
    const domainValue = process.env.REACT_APP_DOMAIN;
    const data = {
      isCompanySelected: true,
      selectedCompany: company,
    };

    setCompanyState(data);
    localStorage.setItem('currentCompanyData', JSON.stringify(data));
    Cookies.set('companyId', company.id, { domain: domainValue });
  };

  const removeSelectedCompany = () => {
    const domainValue = process.env.REACT_APP_DOMAIN;

    setCompanyState((user) => ({
      ...user,
      isCompanySelected: false,
      selectedCompany: null,
    }));
    localStorage.removeItem('currentCompanyData');
    Cookies.remove('companyId', { domain: domainValue });
  };

  return (
    <UserContext.Provider
      value={{
        user: userState,
        someUser: someUserState,
        selectedCompany: companyState,
        changeUserData,
        changeSomeUserData,
        setSelectedCompany,
        removeSelectedCompany,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserState;
