import styles from './FirstStepContent.module.scss';

const FirstStepContent = ({ roleData }) => (
  <div className={styles.contentWrapper}>
    <span className={styles.heroTop}>
      You
      <span className={styles.heroTitle}>{roleData.roleName}</span>
      <span className={styles.heroMid}>Role:</span>
      <span className={styles.heroBot}>r</span>
      <span className={styles.heroRotate}>{roleData.roleName}</span>
    </span>
  </div>
);

export default FirstStepContent;
