/* eslint-disable no-nested-ternary */

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Image } from 'tt-ui-kit';
import clsx from 'clsx';
import styles from './HeaderNavItem.module.scss';

const NAV_IMAGE_SIZE = 24;

const HeaderNavItem = ({ href, imageSrc, icon, text, onClose, className }) => {
  const router = useNavigate();

  const itemIcon = icon || (
    <Image
      src={imageSrc}
      className={styles.headerNavImage}
      width={NAV_IMAGE_SIZE}
      height={NAV_IMAGE_SIZE}
    />
  );

  return (
    <div className={clsx(className, styles.headerNavItem)}>
      {href ? (
        <Link to={href}>
          {itemIcon}
          <span className={styles.headerNavText}>{text}</span>
        </Link>
      ) : onClose ? (
        <a onClick={() => onClose(router)} aria-hidden>
          {itemIcon}
          <span className={styles.headerNavText}>{text}</span>
        </a>
      ) : (
        <a onClick={() => router(-1)} aria-hidden>
          {itemIcon}
          <span className={styles.headerNavText}>{text}</span>
        </a>
      )}
    </div>
  );
};

export default HeaderNavItem;
