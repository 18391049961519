import React from 'react';
import { AllEventsTab } from './AllEventsTab';
import { UpcomingEventsTab } from './UpcomingEventsTab';
import { PastEventsTab } from './PastEventsTab';

export const EventsTab = ({ activeChildTab, events }) => (
  <>
    {activeChildTab === 0 ? (
      <AllEventsTab events={events} />
    ) : activeChildTab === 1 ? (
      <UpcomingEventsTab events={events} />
    ) : activeChildTab === 2 ? (
      <PastEventsTab events={events} />
    ) : null}
  </>
);
