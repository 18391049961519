import React, { useEffect, useState } from 'react';
import styles from './Comments.module.scss';
import { AppAvatar } from '../../Avatar';
import { Avatar, IconButton, TextField } from '@mui/material';
import { ClipIcon, DropdownMenu, MoreVIcon, SendIcon } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import EmojiPostPicker from '../EmojiPicker/EmojiPostPicker';
import { allowedReactions, imageFileTypes } from '../../../constants/addPost';
import { ImageSlider } from '../../../core/CustomImageSlider';
import CommentsActions from './CommentsActions';
import { useMutation } from '@apollo/client';
import { DELETE_FROM_PUBLIC } from '../../../api';
import { v1 as uuidv1 } from 'uuid';
import axios from 'axios';
import {
  createPostComment,
  getPostFileUploadLink,
  updateLikeComment,
} from '../../../api/posts/endpoints/posts';
import Cookies from 'js-cookie';
import { FilePreview } from '../../Modals/NewPostModal/Components/Previews/FilePreview';
import EmojiDisplay from '../EmojiPicker/EmojiDisplay';
import Reply from './Reply';

const ITEM_HEIGHT = 48;

const Comment = ({ commentInstance, postId, user, replies, setCommentsFetch }) => {
  const userID = typeof window !== 'undefined' ? Cookies.get('userId') : null;
  const [newCommentText, setNewCommentText] = useState('');
  const [newCommentReplyUsername, setNewCommentReplyUsername] = useState('');
  const [isReply, setIsReply] = useState(false);
  const [selectedReaction, setSelectedReaction] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [allFiles, setAllFiles] = useState([]);
  const [isNewFilesAdded, setIsNewFilesAdded] = useState(false);
  const [commentInputError, setCommentInputError] = useState('');
  const open = Boolean(anchorEl);

  const [deletePublic] = useMutation(DELETE_FROM_PUBLIC);

  const updateFileInList = (newItem, id) => {
    setAllFiles((prevState) => {
      const items = [...prevState];
      return items.map((item) => {
        if (item.id === id) {
          return newItem;
        }
        return item;
      });
    });
  };

  const setErrorMessage = (text) => {
    openNotification({
      message: text,
      type: 'error',
    });
  };

  const uploadFile = (item) => {
    updateFileInList({ ...item, loading: true, error: false }, item.id);

    const type = item.file.type.split('/')[0];
    const fileExt = item.file.name.match(/\.([^.]+)$/)[1];
    const fileName = `${uuidv1()}.${fileExt}`;
    let path;
    let fileType = '';

    switch (type) {
      case 'image':
        path = `${userID}/comments/image_file`;
        fileType = 'image';
        break;
      case 'video':
        path = `${userID}/comments/video_file`;
        fileType = 'video';
        break;
      case 'audio':
        path = `${userID}/comments/audio_file`;
        fileType = 'audio';
        break;

      default:
        path = `${userID}/comments/doc_file`;
        fileType = 'doc';
        break;
    }

    const toUpload = { path: path, name: fileName };

    getPostFileUploadLink(toUpload)
      .then((response) => {
        const urlToUpload = response.data?.url;

        if (urlToUpload) {
          axios
            .put(urlToUpload, item.file, {
              headers: {
                'Content-type': item.file.type,
                'Access-Control-Allow-Origin': '*',
              },
            })
            .then((uploadResponse) => {
              if (uploadResponse.statusText === 'OK') {
                const updatedFile = {
                  ...item,
                  uuid: fileName,
                  url: `${'https://'}${response.data?.download_url}`,
                  loading: false,
                  fileType: fileType,
                };

                updateFileInList(updatedFile, item.id);
              }
            })
            .catch(() => {
              setErrorMessage('Error while uploading file');
              updateFileInList(
                { ...item, fileType: fileType, loading: false, error: true },
                item.id
              );
            });
        } else {
          setErrorMessage('Error while uploading file');
          updateFileInList({ ...item, fileType: fileType, loading: false, error: true }, item.id);
        }
      })
      .catch(() => {
        setErrorMessage('Error while uploading file');
        updateFileInList({ ...item, fileType: fileType, loading: false, error: true }, item.id);
      });
  };

  const uploadNewFiles = () => {
    allFiles.forEach((item) => {
      if (!item.loading && !item.url) {
        uploadFile(item);
      }
    });
  };

  useEffect(() => {
    if (isNewFilesAdded) {
      uploadNewFiles();
      setIsNewFilesAdded(false);
    }
  }, [isNewFilesAdded]);

  const addUploadFiles = (files, uploadType) => {
    const selectedFilesArray = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileType = uploadType || file.type.split('/')[0];

      if (selectedFilesArray.length === 1) {
        setErrorMessage('Only up to 1 files can be selected');
        break;
      }

      if (fileType === 'image') {
        selectedFilesArray.push({
          file: file,
          preview: URL.createObjectURL(file),
          url: '',
          uuid: '',
          loading: false,
          error: false,
          id: `${file.name}_${file.size + file.lastModified}`,
        });
      }

      const allLength = selectedFilesArray.length + allFiles.length;
      if (allLength <= 1) {
        setAllFiles([...allFiles, ...selectedFilesArray]);
        setIsNewFilesAdded(true);
      }
    }
  };

  const handleRemoveFile = (fileId, uuid, url) => {
    const newSelectedFiles = allFiles.filter((item) => item.id !== fileId);
    setAllFiles(newSelectedFiles);

    const fileToDel = { path: url, name: uuid };
    deletePublic({ variables: { input: fileToDel } });
  };

  const createReply = (replyId) => {
    if ((!newCommentText || newCommentText === '') && allFiles.length === 0) {
      setCommentInputError('Reply can`t be empty');
      return;
    }

    const commentData = {
      post_id: postId,
      user_id: user.id,
      reply_user_id: replyId,
      comment_id: commentInstance.id,
      body: JSON.stringify({
        body: newCommentText || '',
        replyUsername: newCommentReplyUsername || '',
      }),
      files_image: [],
      files_video: [],
      files_audio: [],
      files_doc: [],
    };

    allFiles.forEach((value) => {
      const newPostFileData = { uuid_name: value.uuid, original_name: value.file.name };

      switch (value.fileType) {
        case 'image':
          commentData.files_image.push(newPostFileData);
          break;
        // case 'video':
        //   commentData.files_video.push(newPostFileData);
        //   break;
        // case 'audio':
        //   commentData.files_audio.push(newPostFileData);
        //   break;

        default:
          commentData.files_doc.push(newPostFileData);
          break;
      }
    });

    createPostComment(commentData)
      .then((response) => {
        if (response.status === 200) {
          setAllFiles([]);
          setNewCommentText('');
          setCommentsFetch(true);
        }
      })
      .catch(() => {
        setErrorMessage('Error while sending comment. Try again later.');
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeComment = () => {
    setCommentsFetch(true);
  };

  const selectReaction = (val) => {
    setSelectedReaction(val);

    const data = {
      user_id: userID,
      emoji_id: val,
      post_id: postId,
      comment_id: commentInstance.id,
    };

    updateLikeComment(data)
      .then(() => {
        setCommentsFetch(true);
      })
      .catch(() => {
        setSelectedReaction('');
        openNotification({
          message: 'Error while set reaction, try again later',
          type: 'error',
        });
      });
  };

  const clearReaction = () => {
    const prevReaction = selectedReaction;
    setSelectedReaction('');

    const data = {
      user_id: userID,
      post_id: postId,
      comment_id: commentInstance.id,
    };

    updateLikeComment(data)
      .then(() => {
        setCommentsFetch(true);
      })
      .catch(() => {
        setSelectedReaction(prevReaction);
        openNotification({
          message: 'Error while set reaction, try again later',
          type: 'error',
        });
      });
  };

  const callReply = () => {
    if (!isReply) {
      setNewCommentReplyUsername(
        `${commentInstance.user.first_name} ${commentInstance.user.last_name}`
      );
      setNewCommentText(`${commentInstance.user.first_name} ${commentInstance.user.last_name}, `);
    } else {
      setNewCommentText('');
      setNewCommentReplyUsername('');
    }

    setIsReply((prevVal) => !prevVal);
  };

  useEffect(() => {
    if (newCommentReplyUsername && !newCommentText.includes(newCommentReplyUsername)) {
      setNewCommentReplyUsername('');
    }
  }, [newCommentText]);

  useEffect(() => {
    if (commentInstance?.user_reaction) {
      setSelectedReaction(commentInstance?.user_reaction || '');
    }
  }, [commentInstance]);
  console.log(3, newCommentText, newCommentReplyUsername);
  return (
    <div className={styles.commentContainer}>
      <div>
        <AppAvatar
          nav={1}
          size="xs"
          userName={`${commentInstance.user.first_name} ${commentInstance.user.last_name}`}
          src={commentInstance.user.avatar}
        />
      </div>

      <div className={styles.commentContent}>
        <div
          className={styles.commentUserName}
        >{`${commentInstance.user.first_name} ${commentInstance.user.last_name}`}</div>

        <p className={styles.commentText}>{commentInstance.body}</p>

        {commentInstance.files_image.length > 0 && (
          <div className={styles.fileBox}>
            <div
              className={`${styles.fileBox} ${styles.imagesWrapper}`}
              onClick={(event) => event.stopPropagation()}
            >
              <ImageSlider array={commentInstance.files_image} />
            </div>
          </div>
        )}

        <div className={styles.commentActions}>
          {user.id ? (
            <div>
              {/* TODO check UX */}
              <EmojiPostPicker
                selectedEmoji={selectedReaction}
                allowedEmoji={allowedReactions}
                selectReaction={selectReaction}
                clearReaction={clearReaction}
                placeholder={<div>Like</div>}
                mode="comment"
              />
              {commentInstance?.reactions && commentInstance?.emoji_count ? (
                <>
                  <EmojiDisplay
                    allowedReactions={allowedReactions}
                    emojiList={commentInstance?.reactions?.map((item) => item.emoji_id) || []}
                  />
                  <div>{commentInstance.emoji_count > 0 ? commentInstance.emoji_count : null}</div>
                </>
              ) : null}
            </div>
          ) : (
            <div />
          )}
          {user.id ? <div onClick={() => callReply()}>Reply</div> : null}
        </div>

        {replies && replies.length > 0 ? (
          <div className={styles.repliesContainer}>
            {replies.map((item, index) => (
              <Reply item={item} user={user} index={index} onChangeComment={onChangeComment} />
            ))}
          </div>
        ) : null}

        {user.id && isReply ? (
          <div className={styles.newReplyContainer}>
            <div className={styles.newReplyBlock}>
              <Avatar src={user?.avatar} className={styles.avatar}>
                {user?.first_name
                  ? `${user.first_name} ${user.last_name}`
                      .split(' ')
                      .map((i) => i[0])
                      .join('')
                  : ''}
              </Avatar>
              <TextField
                className={`${
                  commentInputError ? styles.newCommentInputError : styles.newCommentInput
                }`}
                placeholder="Reply..."
                value={newCommentText}
                onChange={(e) => {
                  setCommentInputError('');
                  setNewCommentText(e.target.value);
                }}
                error={!!commentInputError}
                helperText={commentInputError}
              />
              <IconButton className={styles.newCommentButton}>
                <input
                  id="reply-img-upload"
                  type="file"
                  hidden
                  onChange={(e) => addUploadFiles(e.target.files || [], 'image')}
                  accept={imageFileTypes}
                />
                <label htmlFor="reply-img-upload">
                  <ClipIcon style={{ width: 24, height: 24 }} />
                </label>
              </IconButton>
              <IconButton
                className={styles.newCommentButton}
                onClick={() => {
                  createReply(commentInstance.user.id);
                }}
              >
                <SendIcon style={{ width: 24, height: 24 }} />
              </IconButton>
            </div>
            <div className={styles.commentsPreviewFiles}>
              <FilePreview
                selectedFiles={allFiles}
                handleRemoveFile={handleRemoveFile}
                handleFileUpload={uploadFile}
                updateFileMethod={updateFileInList}
                imageFullWidth
              />
            </div>
          </div>
        ) : null}
      </div>

      <div className={styles.menuContainer}>
        <IconButton className={styles.commentMenuButton} onClick={handleClick}>
          <MoreVIcon style={{ width: 20, height: 20, flexShrink: 0, fontSize: 20 }} />
        </IconButton>
        <DropdownMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
            },
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <CommentsActions
            user={user}
            commentInstance={commentInstance}
            closeMenu={handleClose}
            onDeleteComment={onChangeComment}
          />
        </DropdownMenu>
      </div>
    </div>
  );
};

export default Comment;
