import { Modal } from '@mui/material';
import ModalContainer from 'components/ModalContainer';
import styles from './WarningModal.module.scss';

const WarningModal = ({ isModelOpen, setIsOpen, handleClose, text }) => (
  <Modal
    open={isModelOpen}
    onClose={() => handleClose(setIsOpen)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <ModalContainer
      background="rgba(255, 255, 255, 0.4)"
      handleClose={() => handleClose(setIsOpen)}
    >
      <div className={styles.text}>{text}</div>
    </ModalContainer>
  </Modal>
);

export default WarningModal;
