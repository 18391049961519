import axios from 'axios';
import Cookies from 'js-cookie';
import { snakeToCamel } from 'utils/calculatorUtils';

const axiosClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

/* eslint-disable no-param-reassign */
axiosClient.interceptors.response.use(
  (response) => {
    if (response.data && response.config?.shouldSnakeToCamel)
      response.data = { ...snakeToCamel(response.data) };
    if (response.data && typeof response.config?.shouldTransformResponse === 'function')
      response.data = { ...response.config.shouldTransformResponse(response.data) };
    return response;
  },
  (error) => Promise.reject(error)
);
/* eslint-enable no-param-reassign */

const axiosBlockchainClient = async (url, data, headers, config) =>
  axiosClient.post(url, data, {
    headers: {
      ...headers,
      authorization: 'Basic YWRtaW46Z2ZoamttMDE=',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Origin': '*',
    },
    ...config,
  });

const axiosSSOConfigPrivate = (headers, options) => {
  const accessToken = Cookies.get('access_token');

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    ...options,
  };
};

const axiosQAConfigPrivate = (headers, options) => {
  const accessToken = process.env.REACT_APP_CONCIERGE_SECRETS;

  return {
    headers: {
      Authorization: `${accessToken}`,
      ...headers,
    },
    ...options,
  };
};

// axiosClient.interceptors.request.use(async (config) => {
//   const accessToken = await Cookies.get('access_token');
//   const newConfig = config;
//
//   newConfig.headers.Authorization = `Bearer ${accessToken}`;
//
//   return newConfig;
// });

export { axiosClient, axiosSSOConfigPrivate, axiosQAConfigPrivate, axiosBlockchainClient };
