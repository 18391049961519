export const getElementDimensions = (element) => {
  const height = element.offsetHeight;
  const width = element.offsetWidth;
  const style = getComputedStyle(element);

  return {
    height: height + parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10),
    width: width + parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10),
  };
};
