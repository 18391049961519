export const CALL_MENU_DATA = [
  {
    id: 1,
    name: 'Calendar',
    icon: '/images/templates/calendar.png',
    iconWidth: 20,
    iconHeight: 20,
  },
  {
    id: 2,
    name: 'Whiteboard',
    icon: '/images/templates/whiteboard.png',
    iconWidth: 20,
    iconHeight: 20,
  },
  {
    id: 3,
    name: 'Start recording',
    icon: '/images/templates/recording.png',
    iconWidth: 20,
    iconHeight: 20,
  },
  {
    id: 4,
    name: 'Report',
    icon: '/images/templates/gale-warning.png',
    iconWidth: 20,
    iconHeight: 20,
  },
];

export const STATUSES = [
  {
    id: 1,
    name: 'Online',
    iconWidth: 20,
    iconHeight: 20,
  },
  {
    id: 2,
    name: 'Offline',
    iconWidth: 20,
    iconHeight: 20,
  },
];
