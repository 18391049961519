const MAIN_PART = 'TransparenTerra - ';

export const TITLES = {
  signInPage: `${MAIN_PART}Sign In`,
  menuPage: `${MAIN_PART}Menu`,
  termPage: `${MAIN_PART}Terms`,
  resetPage: `${MAIN_PART}Reset`,
  confirmPage: `${MAIN_PART}Confirm`,
  feedPage: `${MAIN_PART}Feed`,
  messagingPage: `${MAIN_PART}Messaging`,
  sdgPage: `${MAIN_PART}SDG`,
  goalPage: `${MAIN_PART}Goal`,
  joinPage: `${MAIN_PART}Join`,
  rolePage: `${MAIN_PART}Role`,
  signUpPage: `${MAIN_PART}Sign Up`,
  presentations: `${MAIN_PART}Presentations`,
  tutorials: `${MAIN_PART}Tutorials`,
  tutorial: `${MAIN_PART}Tutorial`,
};
