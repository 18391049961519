import React, { useState, useEffect } from 'react';
import { InvisibleIcon, VisibleIcon } from 'tt-ui-kit';
import { getBrowser } from 'utils/defineBrowser';
import clsx from 'clsx';
import Error from './Error';
import styles from './Input.module.scss';

const Input = ({
  name,
  type,
  placeholder,
  errorText,
  successText,
  error,
  value,
  onChange,
  required,
  style,
}) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [browser, setBrowser] = useState();

  const changePasswordVisibility = (isVisible, set) => {
    set(!isVisible);
  };

  useEffect(() => {
    setBrowser(getBrowser());
  }, []);

  return (
    <div className={styles.inputWrapper}>
      <div>
        <input
          className={`${styles.formInput} ${styles.formPassword}`}
          type={isPasswordHidden ? type : 'text'}
          placeholder={placeholder}
          name={name}
          required={required}
          style={style}
          value={value}
          onChange={onChange}
        />
        {successText && <p className={styles.successText}>{successText}</p>}
        {error && <Error text={errorText} />}
      </div>
      {name === 'password' && (
        <button
          className={clsx(styles.formPassShowBtn, browser?.isSafari ? styles.safariEyeIcon : '')}
          type="button"
          onClick={() => changePasswordVisibility(isPasswordHidden, setIsPasswordHidden)}
          tabIndex="-1"
        >
          <div className={styles.iconWrapper}>
            {isPasswordHidden ? <VisibleIcon /> : <InvisibleIcon />}
          </div>
        </button>
      )}
    </div>
  );
};

export default Input;
