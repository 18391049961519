import React, { useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import ErrorModal from 'components/Modals/ErrorModal';
import { useWindowSize } from 'utils/useWindowSize';
import styles from './PageContainer.module.scss';
import LoaderAfterDOM from '../LoaderAfterDOM';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_VIEWED_USER } from '../../../api';
import { SEO } from './RolePageContainer/SEO';
import { closeAppLoader } from '../../../utils/appUtils';

const MIN_RATIO_APPARENT_HEIGHT_TO_FULL = 0.6;

const PageContainer = ({ children, keywords, title, isImgBg }) => {
  const location = useLocation();
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [dashboard, setDashboard] = useState(false);
  const [getUser] = useLazyQuery(GET_VIEWED_USER);

  const windowSize = useWindowSize();

  useEffect(() => {
    if (window) {
      if (windowSize.height / windowSize.availHeight <= MIN_RATIO_APPARENT_HEIGHT_TO_FULL) {
        setIsKeyboardOpen(true);
      }
    }
  }, [windowSize]);

  const changeMetaTitleAndDescription = (ogTitle, ogDescription) => {
    setMetaTitle(ogTitle);
    setMetaDescription(ogDescription);
  };

  useEffect(() => {
    if (!location.pathname.includes('/dashboard/profile/')) {
      setTimeout(() => closeAppLoader(), 1000);
    }

    (async () => {
      let userId = location.pathname.split('/dashboard/profile/');
      userId = userId[userId.length - 1];
      if (userId && location.pathname && location.pathname.includes('/dashboard/profile/')) {
        const userValue = await getUser({ variables: { id: userId } });
        if (userValue?.data?.user) {
          const user = userValue?.data?.user;
          changeMetaTitleAndDescription(`${user.first_name} ${user.last_name}`, user.role_name);
        } else {
          changeMetaTitleAndDescription(
            'TransparenTerra: Your global partner for comprehensive sustainability assessment, reporting, validation, and verification.',
            'Accurately measure and improve your environmental and social impact. Join our community to build trust, enhance compliance, strengthen your brand, and achieve net zero goals.'
          );
        }
      } else {
        changeMetaTitleAndDescription(
          'TransparenTerra: Your global partner for comprehensive sustainability assessment, reporting, validation, and verification.',
          'Accurately measure and improve your environmental and social impact. Join our community to build trust, enhance compliance, strengthen your brand, and achieve net zero goals.'
        );
      }
    })();
  }, [location]);

  useEffect(() => {
    if (location.pathname.includes('/dashboard')) {
      setDashboard(true);
    } else {
      setDashboard(false);
    }
  }, []);

  return (
    <>
      <SEO
        title={title || 'TransparenTerra'}
        ogTitle={metaTitle}
        ogDescription={metaDescription}
        description="Accurately measure and improve your environmental and social impact. Join our community to build trust, enhance compliance, strengthen your brand, and achieve net zero goals."
        name="TransparenTerra"
        type="article"
      />
      {/* eslint-disable-next-line react/no-unknown-property */}
      <div className={`${styles.pageContainer} ${isImgBg && styles.imgBg}`} unselectable="on">
        {children}
      </div>
      {/*  <Loader /> */}
      <LoaderAfterDOM />
      <CookieConsent
        buttonText="ACCEPT ESSENTIAL COOKIES"
        containerClasses={styles.cookieWrapper}
        buttonClasses={styles.btn}
        style={
          dashboard
            ? { background: 'rgba(0, 0, 0, 0.5)' }
            : { background: 'rgba(255, 255, 255, 0.4)' }
        }
      >
        Welcome to TransparenTerra! To provide a better experience for you, we use cookies. For more
        information, please review our{' '}
        <a href="/privacy-policy" target="_blank" className={styles.link} rel="noreferrer">
          Privacy Policy
        </a>
      </CookieConsent>
      <ErrorModal
        isModelOpen
        title="Please rotate your device"
        isKeyboardOpen={isKeyboardOpen}
        isOrientationModal
      />
    </>
  );
};

export default PageContainer;
