import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { ButtonBase, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './CallTimeoutModal.module.scss';

const CallTimeoutModal = ({ open, handleClose }) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Box className={styles.callTimeoutModal}>
      <Box className={styles.modalTitle}>
        Call time is out
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 30,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={styles.modalContent}>
        The call time has expired, you can continue by signing up through the calendar
      </Box>
      <Box className={styles.modalActions}>
        <ButtonBase className={styles.leftButton} onClick={handleClose}>
          BACK TO CHAT
        </ButtonBase>
        <ButtonBase className={styles.rightButton}>GO TO CALENDAR</ButtonBase>
      </Box>
    </Box>
  </Modal>
);
export default CallTimeoutModal;
