import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MenuItem, Select, TextField, Typography, FormHelperText } from '@mui/material';
import styles from '../ChatBotPage.module.scss';
import { Button } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import {
  createQuestion,
  getCurrentQuestionData,
  updateQuestion,
} from '../../../../../api/admin/endpoints/chatBot';
import { LoaderOn } from '../../../../../components/Loader';
import { ContentEditor } from '../../../../../components/ContentEditor/Editor';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { useFormContext, Controller } from 'react-hook-form';

export const AddQuestion = ({
  allBlocks,
  idQuestion = null,
  onFinish,
  setOpenAddCloseConfirm,
  languages,
  methods,
}) => {
  const [createLoading, setCreateLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [availabilityData, setAvailabilityData] = useState([]);
  const [answers, setAnswers] = useState([]);
  const {
    setValue,
    register,
    watch,
    getValues,
    setError,
    control,
    clearErrors,
    reset,
    formState: { errors },
  } = useFormContext();

  const getQuestionData = () => {
    setCreateLoading(true);
    getCurrentQuestionData(idQuestion)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          const questionData = response.data.data;
          const newAnswers = questionData.sections.map((answerItem) => ({
            ...answerItem,
            internalId: answerItem.id,
            files:
              answerItem.files?.map((fileItem) => ({
                name: fileItem.original_name,
                type: fileItem.type,
                path: fileItem.type,
                url: fileItem.file,
                uuid: fileItem.id,
                id: fileItem.id,
              })) || [],
            links: answerItem.links?.map((linkItem) => linkItem.url) || [],
          }));
          setValue('title', questionData.title);
          setValue('note', questionData.note);
          setValue('documentation_category_id', questionData.documentation_category_id);
          if (questionData?.available?.length) {
            setValue(
              'availability',
              questionData.available.map((item) => item.type)
            );
            setAvailabilityData(questionData.available);
          }
          setAnswers(newAnswers);
        }
      })
      .catch((err) => {
        openNotification({
          message: err?.message || 'Error while making request',
          type: 'error',
        });
      })
      .finally(() => {
        setCreateLoading(false);
      });
  };

  useEffect(() => {
    if (idQuestion) {
      getQuestionData();
    }
  }, [idQuestion]);

  const submitUpdateQuestion = (formData) => {
    setCreateLoading(true);
    let selectedAvailableData = [];
    if (availabilityData?.length) {
      selectedAvailableData = formData.availability.map(
        (item) => availabilityData.find((avItem) => avItem.type === item) || { type: item }
      );
    } else {
      selectedAvailableData = formData.availability.map((item) => ({
        type: item,
      }));
    }

    const data = {
      id: idQuestion,
      documentation_category_id: formData.documentation_category_id,
      title: formData.title,
      note: formData.note,
      is_active: 1,
      sections: answers?.map((answerItem) => ({
        ...answerItem,
        links: answerItem.links.map((linkItem) => ({
          url: linkItem,
          type: linkItem?.includes('youtube') || linkItem?.includes('youtu.be') ? 'youtube' : 'url',
        })),
        files: answerItem.files.map((fileItem) =>
          fileItem.new
            ? {
                uuid_name: fileItem.uuid,
                original_name: fileItem.name,
                type: fileItem.type,
              }
            : {
                ...fileItem,
                uuid_name: fileItem.uuid,
                original_name: fileItem.name,
                type: fileItem.type,
              }
        ),
      })),
      available: selectedAvailableData,
    };

    updateQuestion(data)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          reset();
          setAnswers([]);
          onFinish();
        }
      })
      .catch((err) => {
        openNotification({
          message: err?.message || 'Error while making request',
          type: 'error',
        });
      })
      .finally(() => {
        setCreateLoading(false);
      });
  };

  const submitQuestion = (formData) => {
    setCreateLoading(true);

    const data = {
      documentation_category_id: formData.documentation_category_id,
      title: formData.title,
      note: formData.note,
      is_active: 1,
      sections: answers?.map((answerItem) => ({
        ...answerItem,
        links: answerItem.links
          ? answerItem.links.map((linkItem) => ({
              url: linkItem,
              type:
                linkItem?.includes('youtube') || linkItem?.includes('youtu.be') ? 'youtube' : 'url',
            }))
          : [],
        files: answerItem.files
          ? answerItem.files.map((fileItem) => ({
              uuid_name: fileItem.uuid,
              original_name: fileItem.name,
              type: fileItem.type,
            }))
          : [],
      })),
      available: formData.availability,
    };

    createQuestion(data)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          reset();
          setAnswers([]);
          onFinish();
        }
      })
      .catch((err) => {
        openNotification({
          message: err?.message || 'Error while making request',
          type: 'error',
        });
      })
      .finally(() => {
        setCreateLoading(false);
      });
  };

  const cancelQuestion = () => {
    setOpenAddCloseConfirm(true);
  };

  const setAnswerText = (value, contentLength = undefined) => {
    if (value && isSubmitted) {
      clearErrors('text'); // Очищает ошибку для поля 'exampleField'
    }
    setAnswers((prevState) => {
      if (prevState.length > 0) {
        return prevState.map((item) => ({
          ...item,
          text: value,
        }));
      }
      return [
        ...prevState,
        {
          text: value,
        },
      ];
    });
  };

  const handleCheckboxChange = (value) => {
    const currentValues = watch('availability') || [];
    const updatedValues = currentValues?.includes(value)
      ? currentValues.filter((item) => item !== value)
      : [...currentValues, value];
    if (isSubmitted) {
      if (updatedValues.length) {
        clearErrors('availability');
      } else {
        setError('availability', {
          type: 'custom',
          message: 'Please fill out the rest fields. This is mandatory.',
        });
      }
    }
    setValue('availability', updatedValues);
  };

  return (
    <div className={`${styles.pageContainer} ${styles.addQuestion}`}>
      {createLoading ? (
        <LoaderOn />
      ) : (
        <>
          <Box className={styles.pageTitle}>
            <Typography className={styles.title}>Add Question</Typography>
            <Box className={styles.languages}>
              {languages.map((item) => (
                <Typography
                  className={[
                    styles.disabledLanguages,
                    item.name === 'EN' && styles.activeLanguage,
                  ]}
                >
                  {item.name}
                </Typography>
              ))}
            </Box>
          </Box>
          <Box className={styles.questionBlock}>
            <Controller
              name="documentation_category_id"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  displayEmpty
                  key={watch('documentation_category_id')}
                  className={`${styles.questionInput} ${styles.blockSelect}`}
                  defaultValue=""
                  error={!!errors.documentation_category_id}
                  {...field}
                >
                  <MenuItem value="" disabled>
                    Category
                  </MenuItem>
                  {allBlocks
                    .filter((item) => item.id !== 'all')
                    .map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                </Select>
              )}
            />
            <Box className={styles.textAreaWrapper}>
              <TextField
                {...register('title', {
                  required: 'Please fill out the rest fields. This is mandatory.',
                  pattern: {
                    value: /^[\s\S]{1,255}$/,
                    message: "You can't write more than 255 characters",
                  },
                })}
                className={`${styles.questionInput} ${styles.blockTextArea}`}
                label="Question"
                multiline
                rows={4}
                error={!!errors.title}
                helperText={errors.title?.message}
              />
              <TextField
                {...register('note')}
                className={`${styles.questionInput} ${styles.blockTextArea}`}
                label="Notification"
                multiline
                rows={4}
              />
            </Box>
            <ContentEditor
              error={!!errors.text}
              value={answers[0]?.text || ''}
              saveDataMethod={setAnswerText}
              placeholder="Answer"
              className={styles.articleEditor}
              // imageUploadPath={`${userID}/post/image_file`}
            />
            {errors && errors.text && errors.text.message && (
              <FormHelperText error>{errors.text.message}</FormHelperText>
            )}
          </Box>

          <Box className={styles.checkboxContainer}>
            <Controller
              name="availability[]"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'At least one checkbox must be selected',
                },
              }}
              render={({ field }) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checkedIcon={
                          <CheckBoxOutlinedIcon
                            style={{ color: '#212121', padding: '0 !important' }}
                          />
                        }
                        onChange={() => handleCheckboxChange('qa')}
                        checked={watch('availability')?.includes('qa')}
                      />
                    }
                    label="Display in Q&A"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checkedIcon={
                          <CheckBoxOutlinedIcon
                            style={{ color: '#212121', padding: '0 !important' }}
                          />
                        }
                        onChange={() => handleCheckboxChange('chatbot')}
                        checked={watch('availability')?.includes('chatbot')}
                      />
                    }
                    label="Display in the Сhatbot"
                    labelPlacement="end"
                  />
                  {errors.availability && (
                    <FormHelperText error>{errors.availability.message}</FormHelperText>
                  )}
                </>
              )}
            />
          </Box>
          <Box className={styles.formActions}>
            <Button
              type="mainButton"
              onClick={() => {
                cancelQuestion();
              }}
            >
              CANCEL
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setIsSubmitted(true);
                if (idQuestion) {
                  methods.handleSubmit(submitUpdateQuestion)();
                } else {
                  methods.handleSubmit(submitQuestion)();
                }
                if (!answers[0]?.text) {
                  setError('text', {
                    type: 'custom',
                    message: 'Please fill out the rest fields. This is mandatory.',
                  });
                }
              }}
            >
              SAVE
            </Button>
          </Box>
        </>
      )}
    </div>
  );
};
