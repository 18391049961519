import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';
import CalcIcon from '../CalcIcon/CalcIcon';
import { CALC_TYPES } from 'utils/calculatorUtils';
import DraftBlock from './DraftBlock';
import DraftBlockMobile from './DraftBlockMobile';
import { RENAME_DRAFT, DELETE_DRAFT } from '../../../../api';
import Modal from 'components/Modals/modal/Modal';
import { Modal as UIModal } from 'tt-ui-kit';
import ComingSoon from 'components/ComingSoon';

const CalcDraft = ({ targetWidth, draft, refresh, setErrorText }) => {
  const navigate = useNavigate();
  const [renameDraft] = useMutation(RENAME_DRAFT);
  const [deleteDraft] = useMutation(DELETE_DRAFT);

  const [editId, setEditId] = useState(null);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [draftForDelete, setDraftForDelete] = useState(null);
  const [isSoon, setIsSoon] = useState(false);

  // width control end
  const onProceedDraft = (id) => {
    window.location.replace(`${process.env.REACT_APP_ESG_CALC}/editCalculator/${id}`);
  };

  const onBuyCredits = (id) => {
    if (CALC_TYPES.GLOBAL === draft.type) setIsSoon(true);
    else navigate(`/dashboard/buy_credits/${id}`);
  };

  const onRenameDraft = async (e) => {
    const { value } = e.target;

    renameDraft({
      variables: {
        input: { id: editId, new_name: value },
      },
    })
      .then(() => refresh())
      .catch((err) => {
        console.log(err);
        setErrorText('Error renaming draft');
      })
      .finally(() => {
        setEditId(null);
      });
  };

  const onDeleteDraft = () => {
    if (!draftForDelete) return;
    deleteDraft({ variables: { id: draftForDelete.id } })
      .then((responce) => {
        const { data } = responce;
        if (data.deleteEsgDraft) refresh();
      })
      .catch((e) => {
        if (e.message === 'Not found') refresh();
        else setErrorText('Error deleting draft');
      })
      .finally(() => {
        setDraftForDelete(null);
      });
  };

  const modalDeleteOpen = () => {
    setDraftForDelete(draft);
    setIsDeleteModalOpened(true);
  };

  const closeDeleteModal = () => {
    setDraftForDelete(null);
    setIsDeleteModalOpened(false);
  };

  return (
    <>
      <UIModal
        onClick={(e) => e.stopPropagation()}
        open={isSoon}
        onClose={() => setIsSoon(false)}
        title="Coming soon"
      >
        <ComingSoon text="Draft payment in development." />
      </UIModal>
      <Modal
        opened={isDeleteModalOpened}
        closeModalFunc={closeDeleteModal}
        headerContent="Delete draft"
        okBtnText="Delete"
        backBtnText="Cancel"
        okFunc={onDeleteDraft}
        needAgreement={false}
        containedStyle
      >
        Do you really want to delete draft &quot;
        {draftForDelete ? draftForDelete.name : ''}&quot;?
      </Modal>
      {targetWidth >= 600 && (
        <DraftBlock
          props={{
            draft: draft,
            targetWidth: targetWidth,
            calcLogo: CALC_TYPES.PROJECT === draft.type && <CalcIcon type={draft.type} />,
            editId: editId,
            setEditId: setEditId,
            onProceedDraft: onProceedDraft,
            onDelete: modalDeleteOpen,
            onRenameDraft: onRenameDraft,
            onBuyCredits: onBuyCredits,
          }}
        />
      )}
      {targetWidth < 600 && (
        <DraftBlockMobile
          props={{
            draft: draft,
            targetWidth: targetWidth,
            calcLogo: CALC_TYPES.PROJECT === draft.type && <CalcIcon type={draft.type} />,
            editId: editId,
            setEditId: setEditId,
            onProceedDraft: onProceedDraft,
            onDelete: modalDeleteOpen,
            onRenameDraft: onRenameDraft,
            onBuyCredits: onBuyCredits,
          }}
        />
      )}
    </>
  );
};

export default CalcDraft;
