import React, { useState } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, IconButton } from '@mui/material';
import { CALL_MENU_DATA } from 'constants/messagePage';
import { Image } from 'tt-ui-kit';
import styles from './CallMenu.module.scss';

const CallMenu = ({ ...restProps }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const text = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#555555',
  };

  return (
    <div className={styles.callBtnContent}>
      <IconButton
        className={`${styles.btn} ${restProps?.className}`}
        style={{ ...restProps.style }}
        type="button"
        onClick={() => setIsMenuOpen((current) => !current)}
      >
        <Image src="/images/chat/menu-dots-icon.svg" width={13} height={13} />
      </IconButton>
      <div
        hidden={!isMenuOpen}
        onMouseLeave={() => setIsMenuOpen((value) => !value)}
        className={styles.dropdownBox}
      >
        <List
          className={styles.dropdown}
          hidden={!isMenuOpen}
          component="nav"
          aria-label="mailbox folders"
        >
          {CALL_MENU_DATA.map((item) => (
            <ListItem onClick={() => console.log(item.name)} key={item.id}>
              <ListItemIcon>
                <Image src={item.icon} width={item.iconWidth} height={item.iconHeight} />
              </ListItemIcon>
              <ListItemText primary={item.name} primaryTypographyProps={{ style: text }} />
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

export default CallMenu;
