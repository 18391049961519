import { API_BASE_API_SSO_URL } from '../urls';

export const API_CALC_GET_QUESTIONS = `${API_BASE_API_SSO_URL}/questions/getScopeQuestions`;
export const API_BASE_REPORT_API_URL = `${API_BASE_API_SSO_URL}/tt-custom-report`;

export const API_DRAFTS_URL = `${API_BASE_REPORT_API_URL}/drafts`;

export const API_DRAFTS_DELETE_URL = `${API_DRAFTS_URL}/del`;
export const API_DRAFTS_GET_URL = `${API_DRAFTS_URL}/filter`;
export const API_DRAFTS_UPDATE_URL = `${API_DRAFTS_URL}/set`;
export const API_DRAFT_BY_ID_GET_URL = `${API_DRAFTS_URL}/get`;

export const API_FILES_URL = `${API_BASE_REPORT_API_URL}/files`;

export const API_FILES_GET_URL = `${API_FILES_URL}/get`;
export const API_FILES_SET_URL = `${API_FILES_URL}/set`;
export const API_FILES_ADD_URL = `${API_FILES_URL}/add`;
export const API_FILES_DELETE_URL = `${API_FILES_URL}/del`;
export const API_FILES_GET_UPLOAD_URL = `${API_FILES_URL}/upload-url`;

export const API_RESULT_URL = `${API_BASE_REPORT_API_URL}/result`;

export const API_RESULT_GET_URL = `${API_RESULT_URL}/get`;
// export const API_RESULT_DELETE_URL = `${API_RESULT_URL}/del`;

export const API_CUSTOM_REPORT_URL = `${API_BASE_REPORT_API_URL}/custom-reports/draft`;

export const API_CUSTOM_REPORT_DATA_GET_URL = `${API_CUSTOM_REPORT_URL}/data/get`;
export const API_CUSTOM_REPORT_DATA_SET_URL = `${API_CUSTOM_REPORT_URL}/data/set`;

export const API_CUSTOM_RESULT_URL = `${API_BASE_REPORT_API_URL}/custom-reports/result`;
export const API_CUSTOM_RESULT_DATA_GET_URL = `${API_CUSTOM_RESULT_URL}/questions/get`;
