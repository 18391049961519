import { useState, useEffect } from 'react';
import clsx from 'clsx';
import styles from './StepItem.module.scss';

const StepItem = ({ id, activeStep, setActiveStep, roleIndex, isClickable }) => {
  const [isActive, setIsActive] = useState(id === activeStep);

  const handleStepClick = () => {
    setActiveStep(id);
  };

  useEffect(() => {
    if (activeStep && id !== activeStep) {
      setIsActive(false);
    } else if (activeStep) setIsActive(true);
  }, [activeStep]);

  return (
    <div
      className={clsx(
        styles.stepItem,
        isActive ? styles.active : '',
        isClickable ? '' : styles.notClickable
      )}
      key={id + roleIndex}
      onClick={isClickable ? handleStepClick : null}
      role="button"
      aria-hidden="true"
    >
      <div className={styles.container}>
        <div className={styles.title}>Step</div>
        <div className={styles.count}>{id}</div>
      </div>
    </div>
  );
};

export default StepItem;
