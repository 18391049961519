import React from 'react';
import { useNavigate } from 'react-router';
import { SignInUpModalWrapper } from '../Modals/SignInUpModal/SignInUpModal';
import Cookies from 'js-cookie';

const AuthWrapper = ({
  children,
  reloadAfterLogin = false,
  redirectAfterLoginTo,
  redirectOnCloseTo,
}) => {
  const modalOpen = true;
  const navigate = useNavigate();
  const domainValue = process.env.REACT_APP_DOMAIN;
  const userId = Cookies.get('userId', { domain: domainValue });

  const onClose = () => {
    navigate(redirectOnCloseTo || '/dashboard/feed');
  };
  return userId ? (
    children
  ) : (
    <>
      <div />

      <SignInUpModalWrapper
        open={modalOpen}
        closeModal={onClose}
        redirectAfterLoginTo={redirectAfterLoginTo}
        reloadAfterLogin={reloadAfterLogin}
      />
    </>
  );
};

export default AuthWrapper;
