import React from 'react';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import { MenuItem, Select, OutlinedInput } from '@mui/material';
import styles from '../AllItemsPage.module.scss';
import { Button } from 'tt-ui-kit';

const orderByFilter = [
  { name: 'All', value: 'all' },
  { name: 'By update date', value: 'updated_at' },
  { name: 'By date', value: 'created_at' },
  { name: 'By status', value: 'status' },
];

const filterItems = [
  { name: 'All', value: '' },
  { name: 'Active only', value: 'active' },
  { name: 'Hidden only', value: 'hidden' },
];

const Filter = ({
  filter,
  changeFilter,
  changeOrder,
  orderBy,
  search,
  inputValue,
  // setIsAddBlock,
}) => (
  <Box className={styles.filters}>
    <div className={styles.searchFilter}>
      <SearchIcon sx={{ color: '#555555' }} className={styles.searchFilterIcon} />
      <OutlinedInput
        value={inputValue}
        className={styles.searchFilterInput}
        placeholder="Search"
        onChange={(e) => search(e.target.value)}
      />
    </div>

    <Select
      displayEmpty
      value={filter.type}
      onChange={(e) => changeFilter({ ...filter, type: e.target.value })}
      className={styles.filterSelect}
      renderValue={(selected) => {
        if (!selected) {
          return <p className={styles.filterSelectPlaceholder}>All</p>;
        }
        return <p style={{ textTransform: 'capitalize' }}>{selected}</p>;
      }}
    >
      {filterItems.map((item) => (
        <MenuItem value={item.value}>
          <Box className={styles.menuItem}>{item.name}</Box>
        </MenuItem>
      ))}
    </Select>

    <Select
      displayEmpty
      value={orderBy}
      onChange={(e) => changeOrder(e.target.value)}
      className={styles.filterSelect}
      renderValue={(selected) => {
        if (!selected) {
          return <p className={styles.filterSelectPlaceholder}>Sort by</p>;
        }
        return orderByFilter.find((item) => item.value === selected).name;
      }}
    >
      <MenuItem disabled value="">
        <Box className={styles.menuItem}>Sort by</Box>
      </MenuItem>
      {orderByFilter.map((item) => (
        <MenuItem value={item.value}>
          <Box className={styles.menuItem}>{item.name}</Box>
        </MenuItem>
      ))}
    </Select>

    {/* <Button type="primary" className={styles.primaryButton} onClick={() => setIsAddBlock(true)}>
      <div className={styles.buttonWithImage}>
        <img src="/images/admin/plus.svg" alt="" /> <span>Add Question</span>
      </div>
    </Button> */}
  </Box>
);

export default Filter;
