import React from 'react';
import styles from './MessengerPage.module.scss';
import { Image, PersonIcon } from 'tt-ui-kit';
import ChatFoldersList from './ChatFoldersList';
import SearchNav from '../../components/Search';
import { AppAvatar } from '../../components/Avatar';
import { format, parseISO } from 'date-fns';
import { MessageList } from './MessageList';
import { CircularProgress } from '@mui/material';

const DesktopChat = ({
  setIsAddFolderModalOpen,
  activeFolderId,
  idUser,
  chatFolders,
  getChatsByCategory,
  allChats,
  openChatByID,
  lastMessage,
  lastMessages,
  userChatId,
  chatReceiverID,
  fullUser,
  userChatInfo,
  handleStartDirectCall,
  newMessageSent,
  createEmptyChat,
  isLoading,
  getAllChats,
  onScrollUsers,
  getMore,
  isLoadingList,
  connectedUsers,
}) => (
  <>
    <div className={styles.chatList}>
      <div className={styles.chatListOptions}>
        <button
          className={styles.buttonOptions}
          type="button"
          onClick={() => setIsAddFolderModalOpen(true)}
        >
          <Image src="/images/chat/chatSettingsIcon.svg" width={24} height={22} />
        </button>
        <button
          className={styles.buttonOptions}
          type="button"
          style={{ marginBottom: 8 }}
          onClick={getAllChats}
        >
          <Image src="/images/chat/allChatIcons.svg" width={24} height={24} />
          All chats
        </button>
        <ChatFoldersList
          activeFolderId={activeFolderId}
          folders={chatFolders}
          changeFolder={(id) => getChatsByCategory(id)}
        />
      </div>
      <div className={styles.chatListUsers}>
        <SearchNav />
        <div
          onScroll={onScrollUsers}
          className={styles.chatListUsersContent}
          style={isLoading ? { height: '100%' } : isLoadingList ? { overflowY: 'hidden' } : null}
        >
          {isLoading || isLoadingList ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress
                sx={{
                  width: '40px !important',
                  height: '40px !important',
                }}
              />
            </div>
          ) : (
            allChats.map((element) => (
              <div
                key={element.id}
                className={styles.userChat}
                onClick={() =>
                  openChatByID(element.id, element.partner_id_user, element.partner_company_id)
                }
              >
                {element.partner_id_user ? (
                  <>
                    <div className={styles.userAvatarContent}>
                      <AppAvatar
                        className={styles.userAvatar}
                        nav={0}
                        size="xs"
                        userName={`${element.partner_id_user.first_name} 
                        ${element.partner_id_user.last_name}`}
                        src={
                          !element.partner_company_id
                            ? element.partner_id_user.avatar === 'no avatar'
                              ? null
                              : element.partner_id_user.avatarSmall
                            : element.partner_id_user.avatar
                        }
                        isOnline={connectedUsers.includes(element.partner_id_user.id)}
                      />
                    </div>
                    <div className={styles.chatInfo}>
                      <div className={styles.userChatName}>
                        {`${element.partner_id_user.first_name} 
                        ${element.partner_id_user.last_name}`}
                      </div>
                      <div className={styles.userChatMessegeAndDate}>
                        {element.partner_id_user.status === 'blocked' ? (
                          <div
                            className={`${styles.userChatMessege} ${styles.deleteUserChatMessege}`}
                          >
                            Blocked account
                          </div>
                        ) : element.partner_id_user.status === 'not_blocked' ? (
                          <div className={styles.userChatMessege}>{lastMessage(element.id)}</div>
                        ) : (
                          <div
                            className={`${styles.userChatMessege} ${styles.deleteUserChatMessege}`}
                          >
                            User deleted
                          </div>
                        )}
                      </div>
                      <div className={styles.date}>
                        {lastMessages[element.id]
                          ? format(parseISO(lastMessages[element.id].created_at), 'H:mm')
                          : ''}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.userAvatarContent}>
                      <div className={styles.iconDeleted}>
                        <PersonIcon style={{ width: 20, height: 20 }} />
                      </div>
                    </div>
                    <div className={styles.chatInfo}>
                      <div className={styles.userChatName}>Deleted account</div>
                    </div>
                  </>
                )}
              </div>
            ))
          )}
          {getMore && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress
                sx={{
                  width: '40px !important',
                  height: '40px !important',
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
    {userChatId || chatReceiverID ? (
      <MessageList
        authUser={fullUser}
        chatId={userChatId}
        chatReceiverID={chatReceiverID}
        chatInfo={userChatInfo}
        startDirectCall={(receiver) => handleStartDirectCall(receiver)}
        messageSent={(message) => newMessageSent(message)}
        createChat={() => createEmptyChat()}
      />
    ) : (
      <MessageList />
    )}
  </>
);

export default DesktopChat;
