import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  LoaderOn,
  TextInput,
  VisibilityIcon,
  VisibilityOffIcon,
  MUICheckBox,
  GoogleIcon,
  // Image,
} from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import { Controller, useForm } from 'react-hook-form';
import AvatarUploader from '../../AvatarUploader';
import ReactPhoneInput from 'react-phone-input-2';
import clsx from 'clsx';
import { PolicyModalWrapper } from '../../../PolicyModal';
import { useLazyQuery } from '@apollo/client';
import { deleteNestedKeys } from '../../../../utils/validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useWindowSize } from '../../../../utils/useWindowSize';

import styles from '../../SignInModal.module.scss';
// import MapIconSVG from './assets/map-icon.svg';
// import LocationModal from '../../../LocationModal';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const whitespacesReg = /^\s*\S.*$/;
const specialCharReg = /^[^<>'"/;`%]*$/;
const schema = yup.object().shape(
  {
    first_name: yup
      .string()
      .required('Please fill out the rest fields. This is mandatory.')
      .max(35, 'Too many characters.')
      .matches(whitespacesReg, 'The field cannot contain only spaces.')
      .matches(specialCharReg, 'Special characters are prohibited.'),
    last_name: yup
      .string()
      .required('Please fill out the rest fields. This is mandatory.')
      .max(35, 'Too many characters.')
      .matches(whitespacesReg, 'The field cannot contain only spaces.')
      .matches(specialCharReg, 'Special characters are prohibited.'),
    tel: yup.string().when(['tel', 'phoneCode'], (val, phoneCode) => {
      if (val && val.length > 0 && val !== phoneCode) {
        return yup.string().matches(phoneRegExp, 'Phone number is not valid');
      }
      return yup.string().nullable().notRequired();
    }),
    email: yup
      .string()
      .required('Please fill out the rest fields. This is mandatory.')
      .email('Email is invalid'),
    password: yup
      .string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/(?=.*\d)/, 'Include at least one number')
      .matches(/(?=.*[!@#$%^&*])/, 'Include at least 1 special character (!@#$%^&*)')
      .matches(/(?=.*[a-z])/, 'Include at least 1 lowercase letter')
      .matches(/(?=.*[A-Z])/, 'Include at least 1 uppercase letter')
      .required('Please fill out the rest fields. This is mandatory.'),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Confirmation doesn`t match your password')
      .required('Please fill out the rest fields. This is mandatory.'),
  },
  [['tel', 'tel']]
);

const IndividualForm = ({ setMode, agreementSchema, handler, closeModal, signUpWithGoogle }) => {
  const { width } = useWindowSize();

  const [submitDisable, setSubmitDisable] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [phoneInputLabel, setPhoneInputLabel] = useState('');
  const [isPolicyAgree, setIsPolicyAgree] = useState(false);
  const [isPolicyLoading, setIsPolicyLoading] = useState(false);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);
  const [isHonorAgree, setIsHonorAgree] = useState(false);
  const [isHonorModalOpen, setIsHonorModalOpen] = useState(false);
  const [policyText, setPolicyText] = useState(null);
  const [honorText, setHonorText] = useState(null);
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  // const [address, setAddress] = useState(null);
  // const [locationData, setLocationData] = useState(null);
  // const [coordinates, setCoordinates] = useState(null);
  // const [location, setLocation] = useState('');
  // const [isLocationModal, setIsLocationModal] = useState(false);

  const [getAgreement] = useLazyQuery(agreementSchema, {});

  const {
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    control,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      tel: '',
      avatar: '',
      password: '',
      password_confirmation: '',
      // location: null,
    },
  });

  // const onClose = (setModal, loading) => {
  //   if (!loading) {
  //     setModal(false);
  //   }
  // };

  const onTelChange = async (value) => {
    setValue('tel', value);
  };

  const onChangeAvatar = async (value) => {
    clearErrors('avatar');
    setAvatar(value);
    setValue('avatar', value);
  };

  const handleModalClose = (setIsOpen) => setIsOpen(false);

  const onSubmit = (data) => {
    if (!data.avatar || data.avatar === '') {
      setError('avatar', {
        type: 'custom',
        message: 'Please fill out the rest fields. This is mandatory.',
      });
      return;
    }

    // if (!data.location) {
    //   setError('location', {
    //     type: 'custom',
    //     message: 'Please fill out the rest fields. This is mandatory.',
    //   });
    //   return;
    // }

    setIsSignUpLoading(true);

    handler(
      deleteNestedKeys(data, ['province', 'phoneCode']),
      () => {
        setIsSignUpLoading(false);
        closeModal();
      },
      (e) => {
        setIsSignUpLoading(false);

        if (e) {
          if (e.message) {
            openNotification({
              message: e.message,
              type: 'error',
            });
          } else {
            openNotification({
              message: 'Error while sign-up. Try again later.',
              type: 'error',
            });
          }
        }
      }
    );
  };

  const validateBeforeSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    const isStepValid = await trigger();

    if (!isPolicyAgree || !isHonorAgree) {
      openNotification({
        message: 'Please, accept Privacy Policy and Honor Code.',
        type: 'error',
      });
    } else if (!isStepValid) {
      openNotification({
        message: 'Please, fill mandatory fields.',
        type: 'error',
      });
    } else {
      await handleSubmit(async (data) => {
        await onSubmit(data);
      })();
    }
  };

  const getAgreementData = async () => {
    setIsPolicyLoading(true);

    const res = await getAgreement();

    if (res?.data?.agreementPrivacyAndHonor?.length > 0) {
      const policy = res.data.agreementPrivacyAndHonor.find(
        (agreement) => agreement.field === 'Privacy Policy'
      );

      setPolicyText(policy.body);

      const honor = res.data.agreementPrivacyAndHonor.find(
        (agreement) => agreement.field === 'Honor Code'
      );

      setHonorText(honor.body);
    }

    setIsPolicyLoading(false);
  };

  // const getLocation = (fullAddress) =>
  //   fullAddress
  //     .split(',')
  //     .reduce((res, item) => `${res && res.length ? `${res},` : res} ${item}`, '');

  // useEffect(() => {
  //   if (address && locationData) {
  //     try {
  //       setLocation(getLocation(address));
  //       setValue('location', {
  //         address: address,
  //         data: {
  //           latLng: {
  //             lat: coordinates?.lat.toString(),
  //             lng: coordinates?.lng.toString(),
  //           },
  //           country: locationData.country || '',
  //           postcode: locationData.postcode || '',
  //           locality: locationData.locality || '',
  //         },
  //       });
  //
  //       setValue('address', address);
  //     } catch (e) {
  //       setError('location', {
  //         type: 'custom',
  //         message: 'Invalid location.',
  //       });
  //     }
  //   }
  // }, [address, locationData]);

  useEffect(() => {
    if (isPolicyAgree && isHonorAgree) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [isPolicyAgree, isHonorAgree]);

  return (
    <div className={styles.body}>
      <div className={styles.roleTextBlock}>
        <div className={styles.roleDescription}>
          Individuals on TransparenTerra are conscious consumers who understand that every action
          they take has consequences for their health and the planet.
        </div>
      </div>

      <form
        style={{ marginTop: 10 }}
        name="signup"
        onSubmit={async (e) => {
          await validateBeforeSubmit(e);
        }}
      >
        <div className={styles.form}>
          <div className={`${styles.row} ${styles.verticalCenter}`}>
            <div>
              <AvatarUploader
                avatarSrc={avatar}
                changeAvatar={(value) => onChangeAvatar(value)}
                error={formSubmitted && !!errors.avatar}
                helperText={formSubmitted && errors.avatar?.message}
              />
            </div>

            <div className={styles.col}>
              <div className={styles.row}>
                <div className={styles.inputSignupWrapper}>
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field: { value, onChange, ...field } }) => (
                      <TextInput
                        {...field}
                        label="First Name"
                        disableUnderline
                        value={value}
                        onChange={onChange}
                        error={formSubmitted && !!errors.first_name}
                        helperText={formSubmitted && errors.first_name?.message}
                      />
                    )}
                  />
                </div>
                <div className={styles.inputSignupWrapper}>
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field: { value, onChange, ...field } }) => (
                      <TextInput
                        {...field}
                        label="Last Name"
                        disableUnderline
                        onChange={onChange}
                        value={value}
                        error={formSubmitted && !!errors.last_name}
                        helperText={formSubmitted && errors.last_name?.message}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.inputSignupWrapper}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { value, onChange, ...field } }) => (
                      <TextInput
                        {...field}
                        label="Email"
                        value={value}
                        onChange={onChange}
                        error={formSubmitted && !!errors.email}
                        helperText={formSubmitted && errors.email?.message}
                      />
                    )}
                  />
                </div>
                <div className={styles.inputSignupWrapper}>
                  <Controller
                    name="tel"
                    control={control}
                    render={({ field: { value, onChange, ...field } }) => (
                      <Box sx={{ width: '100%' }}>
                        <ReactPhoneInput
                          country="us"
                          onChange={onTelChange}
                          onFocus={() => setPhoneInputLabel('Phone number')}
                          onBlur={() => setPhoneInputLabel('')}
                          value={value}
                          id="phone-input"
                          specialLabel={value ? 'Phone number' : phoneInputLabel}
                          placeholder="Phone number"
                          buttonClass={styles.phone}
                          inputClass={clsx(
                            styles.phoneInput,
                            errors.tel?.message ? styles.errorInput : ''
                          )}
                        />
                        {errors.tel?.message && (
                          <div className={styles.errorMessage}>{errors.tel?.message}</div>
                        )}
                      </Box>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className={`${styles.row} ${styles.pdTopRow}`}> */}
          {/*  <TextInput */}
          {/*    style={{ width: '100%' }} */}
          {/*    label="Locations within this area, country/region, postal code" */}
          {/*    value={address} */}
          {/*    onClick={() => setIsLocationModal(true)} */}
          {/*    data-type="location" */}
          {/*    error={!!errors.location} */}
          {/*    helperText={errors.location?.message} */}
          {/*    InputProps={{ */}
          {/*      startAdornment: ( */}
          {/*        <InputAdornment position="start"> */}
          {/*          <Image src={MapIconSVG} width={26} height={22} /> */}
          {/*        </InputAdornment> */}
          {/*      ), */}
          {/*    }} */}
          {/*  /> */}
          {/* </div> */}

          <div className={`${styles.row} ${styles.pdTopRow}`}>
            <div className={styles.inputSignupWrapper}>
              <Controller
                name="password"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <TextInput
                    {...field}
                    label="Password"
                    autoComplete="new-password"
                    value={value}
                    onChange={onChange}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            type="icon"
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPassword((show) => !show);
                            }}
                            onMouseDown={(e) => {
                              e.preventDefault();
                            }}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    error={formSubmitted && !!errors.password}
                    helperText={formSubmitted && errors.password?.message}
                  />
                )}
              />
            </div>
            <div className={styles.inputSignupWrapper}>
              <Controller
                name="password_confirmation"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <TextInput
                    {...field}
                    label="Password confirmation"
                    autoComplete="new-password"
                    value={value}
                    onChange={onChange}
                    type={showPasswordConfirm ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            type="icon"
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPasswordConfirm((show) => !show);
                            }}
                            onMouseDown={(e) => {
                              e.preventDefault();
                            }}
                            edge="end"
                          >
                            {showPasswordConfirm ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    error={formSubmitted && !!errors.password_confirmation}
                    helperText={formSubmitted && errors.password_confirmation?.message}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className={styles.policyWrapper}>
          <FormControlLabel
            control={
              <MUICheckBox
                checked={isPolicyAgree}
                onChange={async () => {
                  if (isPolicyAgree) {
                    setIsPolicyAgree(false);
                  } else {
                    getAgreementData().then();
                    setIsPolicyModalOpen(true);
                  }
                }}
              />
            }
            label="Privacy Policy"
          />
          <FormControlLabel
            control={
              <MUICheckBox
                checked={isHonorAgree}
                onChange={async () => {
                  if (isHonorAgree) {
                    setIsHonorAgree(false);
                  } else {
                    getAgreementData().then();
                    setIsHonorModalOpen(true);
                  }
                }}
              />
            }
            label="Honor Code"
          />
        </div>

        <div className={styles.formBottom}>
          <Button className={styles.formSubmitButton} type="default" onClick={() => setMode('in')}>
            Cancel
          </Button>
          <div className={styles.rightButtons}>
            <button type="button" className={styles.googleBtn} onClick={signUpWithGoogle}>
              <GoogleIcon />
            </button>
            <Button
              className={styles.formSubmitButton}
              type="primary"
              loading={isSignUpLoading}
              isSubmit
            >
              {width > 768 ? 'Create account' : 'Create'}
            </Button>
          </div>
        </div>
      </form>

      {/* <LocationModal */}
      {/*  title="Map" */}
      {/*  isModelOpen={isLocationModal} */}
      {/*  handleClose={onClose} */}
      {/*  setIsOpen={setIsLocationModal} */}
      {/*  address={address} */}
      {/*  setAddress={setAddress} */}
      {/*  setLocationData={setLocationData} */}
      {/*  coordinates={coordinates} */}
      {/*  setCoordinates={setCoordinates} */}
      {/*  background="#FFFFFF" */}
      {/*  isProfileEditing */}
      {/* /> */}

      <PolicyModalWrapper
        isModelOpen={isPolicyModalOpen}
        setIsOpen={setIsPolicyModalOpen}
        handleClose={handleModalClose}
        setIsAgree={setIsPolicyAgree}
        isAgree={isPolicyAgree}
        text={policyText}
        checkboxText="I agree to the Privacy Policy"
        loading={isPolicyLoading}
      />
      <PolicyModalWrapper
        isModelOpen={isHonorModalOpen}
        setIsOpen={setIsHonorModalOpen}
        handleClose={handleModalClose}
        setIsAgree={setIsHonorAgree}
        isAgree={isHonorAgree}
        text={honorText}
        checkboxText="I accept the Honor Code of TransparenTerra members"
        loading={isPolicyLoading}
      />

      {isSignUpLoading && <LoaderOn />}
    </div>
  );
};

export default IndividualForm;
