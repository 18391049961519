import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { TextField } from '@mui/material';
import { Button, Image } from 'tt-ui-kit';
import FormItemLeft from 'core/FormItemLeft';
import FormItemRight from 'core/FormItemRight';
import FormBody from 'core/FormBody';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_TITLE_LINES } from 'constants/resetPage';
import { FORGOT_USER_PASSWORD } from 'api';
import { getValidationSchema } from 'utils/validationResetPasswordSchemaGenerator';
import { parseErrors } from 'utils/validation';
import { isEmptyObj } from 'utils/isEmptyObj';
import styles from './ResetPage.module.scss';

const PASSWORD_RESET_FORM_ID = 'passwordReset';

const ResetPage = () => {
  const validationSchema = getValidationSchema();
  const formOptions = { resolver: yupResolver(validationSchema) };
  const formMethods = useForm(formOptions, { mode: 'all' });
  const [showSuccess, setShowSuccess] = useState(false);
  const [customErrors, setCustomError] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = formMethods;

  const [resetPassword] = useMutation(FORGOT_USER_PASSWORD, {
    onError: (graphQLErrors) => {
      // console.log(graphQLErrors);
    },
  });

  const onSubmit = async (data) => {
    setCustomError(false);
    const response = await resetPassword({ variables: { input: data } });
    if (response?.errors) {
      const responseErrors = parseErrors(response);
      if (!isEmptyObj(responseErrors)) {
        return setCustomError(responseErrors);
      }
      setCustomError({
        email: {
          // message: response?.errors?.message,
          message: 'There is no user with this e-mail in our community',
        },
      });
    } else {
      setShowSuccess(true);
    }
  };

  return (
    <>
      <FormBody>
        <FormItemLeft lines={FORM_TITLE_LINES} />
        {showSuccess === false ? (
          <FormProvider {...formMethods}>
            <FormItemRight formName={PASSWORD_RESET_FORM_ID}>
              <div className={styles.formResetBox}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <TextField
                      className={styles.textInput}
                      placeholder="E-mail *"
                      type="email"
                      onChange={(e) => {
                        onChange(e);
                        setCustomError(false);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') e.preventDefault();
                      }}
                      error={!!errors.email || !!customErrors.email}
                      helperText={
                        errors?.email ? errors.email?.message : customErrors?.email?.message
                      }
                      variant="standard"
                      sx={{
                        '& .MuiInput-underline:before': {
                          borderBottomColor: 'hsla(0,0%,100%,.6)',
                        },
                        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                          borderBottomColor: 'hsla(0,0%,100%,.6)',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: 'hsla(0,0%,100%,.6)',
                        },
                        '& .MuiInput-underline.Mui-error input': {
                          color: 'red',
                        },
                      }}
                    />
                  )}
                />
                <p className={styles.formResetText}>
                  Enter your e-mail address to recover your login information and reset your
                  password
                </p>
              </div>
              <Button
                type="white"
                style={{ marginBottom: '40px', width: '100%', maxWidth: 'unset' }}
                form={PASSWORD_RESET_FORM_ID}
                onClick={handleSubmit(onSubmit)}
              >
                Send
              </Button>
            </FormItemRight>
          </FormProvider>
        ) : (
          <div className={styles.resetSuccess}>
            <div className={styles.successIcon}>
              <Image
                src="/images/templates/checkbox.svg"
                alt="icon"
                width={24}
                height={24}
                layout="responsive"
              />
            </div>
            <p className={styles.successText}>
              New login and password have been sent to e-mail {getValues('email')}
            </p>
          </div>
        )}
      </FormBody>
    </>
  );
};

export default ResetPage;
