import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_PRICING_DATA, GET_USER, GET_USER_COMPANIES_LIST } from 'api';
import Cookies from 'js-cookie';
import { MobileNavbarWrapper } from 'components/MobileNavbar';
import Modal from 'components/Modals/modal/Modal';
import { Button, ClickAwayListener, MenuItem, Select, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import styles from './PricingPage.module.scss';
import { HelpTooltip } from 'tt-ui-kit';
import { Link } from 'react-router-dom';

// TODO get from User Tariff
const UserTariff = 'free';
// TODO get from User UserPeriod = 'monthly';
const UserPeriod = 'annually';

const tariffsCodes = ['free', 'standard', 'pro', 'enterprise'];

const sortByField = (firstValue, secondValue, field, arrayFields) => {
  const index1 = arrayFields.indexOf(firstValue[field]);
  const index2 = arrayFields.indexOf(secondValue[field]);
  if (index1 < index2) return -1;
  if (index1 > index2) return 1;
  return 0;
};

const PricingPage = () => {
  const idUser = Cookies.get('userId');
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(false);
  const [period, setPeriod] = useState('annually');
  const [role, setRole] = useState('business');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [fullPriceList, setFullPriceList] = useState([]);
  const [priceList, setPriceList] = useState({});
  const [tariffFilter, setTariffFilter] = useState('');
  const [userRoleName, setUserRoleName] = useState('');
  const [isAgreeModalOpened, setAgreeModalOpened] = useState(false);
  const [isRedirectModalOpened, setRedirectModalOpened] = useState(false);
  const [isRegisterModalOpened, setRegisterModalOpened] = useState(false);
  const [isInfoModalOpened, setInfoModalOpened] = useState(false);
  const [isSoonModalOpened, setSoonModalOpened] = useState(false);
  const [isShowAll, setIsShowAll] = useState(false);
  const [isInvited, setIsInvited] = useState(false);
  const [openTooltips, setOpenTooltips] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [company, setCompany] = useState(null);
  const endpointMarket = process.env.REACT_APP_MARKET || '';

  const { data: pricingAll } = useQuery(GET_PRICING_DATA, {
    fetchPolicy: 'no-cache', // Doesn't check cache before making a network request
  });

  const { data: userData } = useQuery(GET_USER, { variables: { id: idUser } });
  const [getCompany] = useLazyQuery(GET_USER_COMPANIES_LIST, { variables: { user_id: idUser } });

  useEffect(() => {
    if (company === null) {
      (async () => {
        const companyValue = await getCompany();
        if (
          companyValue.data?.showAllCompaniesByUserId &&
          companyValue.data.showAllCompaniesByUserId.length > 0
        ) {
          setCompany(companyValue.data.showAllCompaniesByUserId);
        } else {
          setCompany(false);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (userData?.user) {
      setIsAuth(true);
      setUserRoleName('business');
      // setUserRoleName(userData.user.role_name ? userData.user.role_name : 'business');
      // TODO Remove default value 'business'
    }
  }, [userData]);

  useEffect(() => {
    if (!selectedPlan) return;
    if (isAuth) setSoonModalOpened(true);
    else setRegisterModalOpened(true);
    // if (selectedPlan === 'enterprise') setAgreeModalOpened(true);
    // else setRedirectModalOpened(true);
  }, [selectedPlan]);

  useEffect(() => {
    if (!isSoonModalOpened) setSelectedPlan(null);
  }, [isSoonModalOpened]);

  useEffect(() => {
    if (!isRegisterModalOpened) setSelectedPlan(null);
  }, [isRegisterModalOpened]);

  useEffect(() => {
    if (!isAgreeModalOpened && !isRedirectModalOpened && !isInfoModalOpened) setSelectedPlan(null);
  }, [isAgreeModalOpened, isRedirectModalOpened, isInfoModalOpened]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    if (windowWidth > 1024) setTariffFilter('');
    if (windowWidth <= 1024) setTariffFilter(tariffsCodes[0]);
  }, [windowWidth]);

  useEffect(() => {
    if (pricingAll) {
      console.log(pricingAll.getPricing);
      const newList = pricingAll.getPricing.map((list) => ({
        role: list.code,
        tariffs: list.tariffs
          .slice()
          .sort((a, b) => sortByField(a, b, 'code', tariffsCodes))
          .filter((tariff) => tariff.code !== 'invited'),
        itemsList: list.categories
          .slice()
          .sort((a, b) => {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          })
          .reduce(
            (all, category) => [
              ...all,
              {
                id: category.id,
                name: category.name,
                isCategory: true,
                shortList: category.activities.some((activity) => activity.shortList === '1'),
              },
              ...category.activities
                .slice()
                .sort((a, b) => {
                  if (a.order < b.order) return -1;
                  if (a.order > b.order) return 1;
                  return 0;
                })
                .map((activity) => ({
                  ...activity,
                  shortList: activity.shortList === '1',
                })),
            ],
            []
          ),
      }));
      console.log(newList);
      setFullPriceList([...newList]);
    }
  }, [pricingAll]);

  useEffect(() => {
    if (fullPriceList.length === 0) return;

    const [first] = fullPriceList.filter((list) => list.role === role);
    console.log(first, fullPriceList, role);
    setPriceList(first);
  }, [fullPriceList, role]);

  const redirectMarket = (url) => `${endpointMarket}${url}`;

  const getItemValue = (values) => values[period] ?? values.base;

  return (
    <>
      <Modal
        opened={isAgreeModalOpened}
        closeModalFunc={() => setAgreeModalOpened(false)}
        headerContent="Do you really want to apply for special conditions?"
        okBtnText="Yes"
        okFunc={() => {
          setAgreeModalOpened(false);
          setInfoModalOpened(true);
        }}
        backBtnText="Cancel"
        containedStyle
      />
      <Modal
        opened={isRedirectModalOpened}
        closeModalFunc={() => setRedirectModalOpened(false)}
        headerContent="You will be redirected to the payment page to complete the purchase of the tariff"
        okBtnText="Ok"
        okFunc={() => setRedirectModalOpened(false)}
        backBtnText="Cancel"
        containedStyle
      />
      <Modal
        opened={isInfoModalOpened}
        closeModalFunc={() => setInfoModalOpened(false)}
        headerContent="Your request has been sent"
        containedStyle
        needButtons={false}
      >
        Your request has been sent. Our manager will contact you soon to discuss all the details.
      </Modal>
      <Modal
        opened={isSoonModalOpened}
        closeModalFunc={() => setSoonModalOpened(false)}
        headerContent="Coming soon!"
        containedStyle
        needButtons={false}
      />
      <Modal
        opened={isRegisterModalOpened}
        closeModalFunc={() => setRegisterModalOpened(false)}
        headerContent="Greetings!"
        containedStyle
        okBtnText="Sign up"
        okFunc={() => navigate('/sign_in')}
      >
        Let&apos;s get started on setting up your TransparenTerra account. You have the option to
        create an account with or without an invitation code. If you receive an invitation code from
        a TransparenTerra user, it will assign you a specific role and make your onboarding process
        smoother. However, if you do not have an invitation link, you can still sign up as a regular
        user with restricted functions.
      </Modal>
      <div className={styles.content}>
        <div className={styles.pageContent}>
          <div className={styles.midContent}>
            <div className={styles.textHeaderContainer}>
              <h1>Choose your plan</h1>
              {/* TODO */}
              <div className={styles.companyCreate}>
                <p>
                  Our pricing and plans are designed to suit your business needs. You pay only for
                  what you use.
                </p>
                {/* {!company && (
                  <Link
                    to={redirectMarket('/dashboard/companyProfile')}
                    color="inherit"
                    underline="none"
                  >
                    <button className={styles.buttonCreate} type="button">
                      Create a company
                    </button>
                  </Link>
                )} */}
              </div>
            </div>
            <div className={styles.tabsContainer}>
              <div className={styles.tabsPeriod}>
                <div>
                  <button
                    className={clsx(styles.left, period === 'monthly' ? styles.active : '')}
                    type="button"
                    onClick={() => setPeriod('monthly')}
                  >
                    Monthly
                  </button>
                  <button
                    className={clsx(styles.right, period === 'annually' ? styles.active : '')}
                    type="button"
                    onClick={() => setPeriod('annually')}
                  >
                    Annually
                  </button>
                </div>
              </div>
              {/* блок временнно скрыт, до ввода ролей, TODO - remove condition */}
              {false && (
                <div className={styles.tabsRole}>
                  <button
                    className={clsx(styles.left, role === 'expert' ? styles.active : '')}
                    type="button"
                    onClick={() => setRole('expert')}
                  >
                    Expert
                  </button>
                  <button
                    className={clsx(styles.middle, role === 'investor' ? styles.active : '')}
                    type="button"
                    onClick={() => setRole('investor')}
                  >
                    Investor
                  </button>
                  <button
                    className={clsx(styles.right, role === 'business' ? styles.active : '')}
                    type="button"
                    onClick={() => setRole('business')}
                  >
                    Business
                  </button>
                </div>
              )}
            </div>
            {windowWidth <= 1024 && (
              <div className={styles.tariffFilter}>
                <Select
                  className={styles.tariffFilterSelect}
                  value={tariffFilter}
                  onChange={(e) => setTariffFilter(e.target.value)}
                  FullWidth
                >
                  {tariffsCodes.map((tariff) => (
                    <MenuItem value={tariff}>{tariff}</MenuItem>
                  ))}
                </Select>
              </div>
            )}
            <table className={styles.planContainer}>
              <thead className={styles.planHeader}>
                <tr>
                  {!tariffFilter && <th />}
                  {priceList &&
                    priceList.tariffs &&
                    priceList.tariffs
                      .filter((tariff) => tariff.code === tariffFilter || !tariffFilter)
                      .map((tariff) => (
                        <th key={tariff.id} colSpan={!tariffFilter ? 1 : 2}>
                          <div className={styles.tariffContainer}>
                            {tariff.code !== 'enterprise' ? (
                              <>
                                <div className={styles.planName}>{tariff.name}</div>
                                <div className={styles.planPrice}>{tariff[period]}</div>
                                <div className={styles.planDetail}>
                                  <div className={styles.monthDetailsCompany}>
                                    /{period}
                                    {tariff.code !== 'free' && <p>/per Company Account</p>}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className={styles.planName}>{tariff.name}</div>
                                <div className={styles.planEnterpr}>Custom</div>
                                <br />
                              </>
                            )}
                            <div className={styles.planButton}>
                              {isAuth &&
                              tariff.code === UserTariff &&
                              userRoleName === role &&
                              UserPeriod === period ? (
                                <button type="button" className={styles.current}>
                                  Your plan
                                </button>
                              ) : (
                                <button type="button" onClick={() => setSelectedPlan(tariff.code)}>
                                  {tariff.code !== 'enterprise' ? 'Get started' : 'Get in touch'}
                                </button>
                              )}
                            </div>
                          </div>
                        </th>
                      ))}
                </tr>
              </thead>
              <tbody className={styles.planTable}>
                {priceList &&
                  priceList.itemsList &&
                  priceList.itemsList
                    .filter((item) => !!item.id)
                    .filter((item) => isShowAll || item.shortList === !isShowAll)
                    .map((item) => (
                      <tr key={item.id} className={item.isCategory ? styles.categoryRow : ''}>
                        <td>
                          <div
                            className={
                              item.isCategory ? styles.planItemCategory : styles.planItemName
                            }
                          >
                            {item.name}
                            {!!item.description && (
                              <HelpTooltip tooltipText={item.description ?? ''} />
                            )}
                          </div>
                        </td>
                        {item.isCategory && (
                          <>
                            <td />
                            {!tariffFilter && (
                              <>
                                <td />
                                <td />
                                <td />
                              </>
                            )}
                          </>
                        )}
                        {!item.isCategory && (
                          <>
                            {tariffsCodes
                              .filter((code) => code === tariffFilter || !tariffFilter)
                              .map((tariffName) => (
                                <td key={`${item.id}${tariffName}`}>
                                  <div className={styles.planItemDetail}>
                                    {getItemValue(item[tariffName]) !== 'YES' &&
                                      getItemValue(item[tariffName]) !== 'NO' && (
                                        <div>{getItemValue(item[tariffName])}</div>
                                      )}
                                    {getItemValue(item[tariffName]) === 'YES' && (
                                      <div className={styles.yesIco}>
                                        <CheckIcon />
                                      </div>
                                    )}
                                    {getItemValue(item[tariffName]) === 'NO' && (
                                      <div className={styles.noIco}>
                                        <ClearIcon />
                                      </div>
                                    )}
                                  </div>
                                </td>
                              ))}
                          </>
                        )}
                      </tr>
                    ))}
              </tbody>
            </table>
            <div>
              <Link className={styles.typeList} onClick={() => setIsShowAll((value) => !value)}>
                {isShowAll ? 'Hide' : 'Full'} Price List
              </Link>
            </div>
            <div className={styles.footnote}>
              {`We reserve the right to change prices for our Services at any time upon notice 
              to you to be given by the posting of the updated pricelist and a change notice on the 
              Website. All updated prices will become effective upon the end of your current paid 
              subscription.`}
            </div>
          </div>
          <div className={styles.mobileNavigation}>
            <MobileNavbarWrapper />
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingPage;
