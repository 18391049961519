import { TITLES } from 'constants/pagesTitles';
import PageContainerImgBg from 'components/layout/PageContainer/PageContainerImgBg';
import ResetPage from 'core/views/ResetPage';

const Reset = () => (
  <PageContainerImgBg
    imageBg="/images/section-bckg.jpg"
    title={TITLES.resetPage}
    isLogoHidden
    isSignInPage
  >
    <ResetPage />
  </PageContainerImgBg>
);

export default Reset;
