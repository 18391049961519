import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { URL_CALCS } from 'constants/calculatorsPage';
import { FourOhFour } from 'views/FourOhFour';
import ScoreAndReportingMenuRouter from './ScoreAndReportingMenuRouter';
import ScoreAndReportingDetailsRouter from './ScoreAndReportingDetailsRouter';

/**
 * Represents the CalcsPage component.
 * This component displays the calculations page with tabs and subtabs.
 * It retrieves and displays assessments, drafts, and other data based on the selected tabs.
 */
const ScoreAndReportingRouter = () => {
  const SCORE_AND_REPORTING_ROUTES_INFO = [
    {
      path: '/',
      element: <Navigate to={URL_CALCS} />,
    },
    {
      path: '/menu',
      element: <Navigate to={URL_CALCS} />,
    },
    {
      path: '/menu/*',
      element: <ScoreAndReportingMenuRouter />,
    },
    {
      path: '/details/*',
      element: <ScoreAndReportingDetailsRouter />,
    },
    {
      path: '*',
      name: 'Not Found',
      element: <FourOhFour />,
    },
  ];

  return (
    <Routes>
      {SCORE_AND_REPORTING_ROUTES_INFO.map((routeInfo) => (
        <Route exact path={routeInfo.path} element={routeInfo.element} key={routeInfo.path} />
      ))}
    </Routes>
  );
};

export default ScoreAndReportingRouter;
