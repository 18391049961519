import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Avatar, FormControlLabel, IconButton, Select } from '@mui/material';
import {
  AudioIcon,
  Button,
  ButtonBase,
  CloseCrossIcon,
  FileAddIcon,
  FileIcon,
  ImageIcon,
  LinkIcon,
  LoaderOn,
  LogoIcon,
  MenuItem,
  Modal,
  MUICheckBox,
  NetworkIcon,
  ReloadIcon,
  RightIcon,
  TextInput,
  VideoIcon,
} from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import Cookies from 'js-cookie';
import { useMutation } from '@apollo/client';
import { DELETE_FROM_PUBLIC } from 'api';
import { ContentEditor } from '../../ContentEditor/Editor';
import styles from './NewPostModal.module.scss';
import { FilePreview } from './Components/Previews/FilePreview';
import {
  allAlowedFileTypes,
  audioFileTypes,
  docFileTypes,
  imageFileTypes,
  maxPostBodyLength,
  videoFileTypes,
} from 'constants/addPost';
import { v1 as uuidv1 } from 'uuid';
import axios from 'axios';
import {
  fetchPostLinkPreview,
  getPost,
  getPostFileUploadLink,
  updatePost,
} from '../../../api/posts/endpoints/posts';
import LinkPreview from './Components/Previews/LinkPreview';
import { setErrorMessage } from '../../../utils/appUtils';
import { AvatarCropModal } from '../../../modules/tt-modals';
import { generateUidForFile, isAudioFileOverlength } from '../../../utils/uploadUtils';

const selectVisionData = [
  {
    value: 'Anyone including search engines',
    id: 1,
    icon: <NetworkIcon style={{ width: 24, height: 24 }} />,
  },
  {
    value: 'Only TransparenTerra members',
    id: 2,
    icon: <LogoIcon style={{ width: 24, height: 24 }} />,
  },
  // {
  //   value: 'Only for my community',
  //   id: 3,
  //   icon: <CommunityIcon style={{ width: 24, height: 24 }} />,
  // },
  { value: 'By link only', id: 4, icon: <LinkIcon style={{ width: 24, height: 24 }} /> },
];

export const EditPostModal = ({ visible = false, onClose, onFinish, editId, user }) => {
  const allowedFileDropTypes = useMemo(() => allAlowedFileTypes.split(', '), [allAlowedFileTypes]);
  const userID = typeof window !== 'undefined' ? Cookies.get('userId') : null;
  const [editData, setEditData] = useState();

  const [wordCount, setWordCount] = useState(undefined);
  const [postStringData, setPostStringData] = useState('');
  const [articleTitle, setArticleTitle] = useState('');
  const [articlePreviewText, setArticlePreviewText] = useState('');
  const [articleCover, setArticleCover] = useState(null);
  const [articleCoverFile, setArticleCoverFile] = useState(null);
  const [articleCoverLoading, setArticleCoverLoading] = useState(false);
  const [articleCoverError, setArticleCoverError] = useState(false);
  const [articleStep, setArticleStep] = useState(1);
  const [linkPreview, setLinkPreview] = useState(null);
  const [isCropImageModal, setIsCropImageModal] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isNewFilesAdded, setIsNewFilesAdded] = useState(false);

  const [allFiles, setAllFiles] = useState([]);
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const [disableImage, setDisableImage] = useState(false);
  const [disableVideo, setDisableVideo] = useState(false);
  const [disableAudio, setDisableAudio] = useState(false);
  const [disableDoc, setDisableDoc] = useState(false);

  const [allowComments, setAllowComments] = useState(true);
  const [selectVision, setSelectVision] = useState(1);
  const [selectUser, setSelectUser] = useState();

  const [deletePublic] = useMutation(DELETE_FROM_PUBLIC);

  const submitDisable = useMemo(
    () => articleCoverLoading || !!allFiles.find((file) => file.loading === true),
    [articleCoverLoading, allFiles]
  );

  const closeModal = () => {
    setPostStringData('');
    setAllFiles([]);
    setDisableVideo(false);
    setDisableAudio(false);
    setDisableDoc(false);
    setDisableImage(false);
    setArticleTitle('');
    setArticlePreviewText('');
    setArticleCover(null);
    setArticleCoverError(false);
    setArticleCoverFile(null);
    setArticleStep(1);
    onClose();
  };

  useEffect(() => {
    if (visible) {
      setIsLoading(true);

      getPost(editId, user.id)
        .then((response) => {
          const { data } = response;
          setEditData(data);
          setAllowComments(data.is_available_comments === 1);
          setPostStringData(data.body);
          setAllFiles([
            ...data.files_image,
            ...data.files_audio,
            ...data.files_video,
            ...data.files_doc,
          ]);
          setWordCount(data.character_count || undefined);

          const postVisible = data.is_link_only === 1 ? 4 : data.is_only_tt === 1 ? 2 : 1;
          setSelectVision(postVisible);

          if (data.title) setArticleTitle(data.title);
          if (data.cover && data.cover[0])
            setArticleCover({
              id: data.cover[0].id,
              fileType: 'image',
              loading: false,
              preview: data.cover[0].url,
              url: data.cover[0].url,
              uuid: data.cover[0].file,
              original_name: data.cover[0].original_name,
            });
          if (data.preview) setArticlePreviewText(data.preview);
          if (data.link_preview) setLinkPreview(JSON.parse(data.link_preview) || null);

          setIsLoading(false);
        })
        .catch(() => {
          openNotification({
            message: `Error while receive information. Try again later.`,
            type: 'error',
          });
          setTimeout(() => {
            setIsLoading(false);
            closeModal();
          }, 1000);
        });
    }
  }, [visible]);

  const updateFileInList = (newItem, id) => {
    setAllFiles((prevState) => {
      const items = [...prevState];
      return items.map((item) => {
        if (item.id === id) {
          return newItem;
        }
        return item;
      });
    });
  };

  const safeStripHtml = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  const uploadFile = async (item) => {
    const type = item?.file?.type?.split('/')[0] || item.type;
    const fileExt = item.file.name.match(/\.([^.]+)$/)[1];
    const fileName = `${uuidv1()}.${fileExt}`;
    let path = '';
    let fileType = '';

    switch (type) {
      case 'image':
        path = `${userID}/post/image_file`;
        fileType = 'image';
        break;
      case 'video':
        path = `${userID}/post/video_file`;
        fileType = 'video';
        break;
      case 'audio':
        path = `${userID}/post/audio_file`;
        fileType = 'audio';
        break;

      default:
        path = `${userID}/post/doc_file`;
        fileType = 'doc';
        break;
    }

    if (fileType === 'audio' && !!(await isAudioFileOverlength(item.file))) {
      openNotification({
        type: 'info',
        message: 'Audio files longer that 1 hour are not allowed.',
      });
      return;
    }

    updateFileInList({ ...item, type: fileType, loading: true, error: false }, item.id);
    const toUpload = { path: path, name: fileName };

    getPostFileUploadLink(toUpload)
      .then((response) => {
        const urlToUpload = response.data?.url;

        if (urlToUpload) {
          axios
            .put(urlToUpload, item.file, {
              headers: {
                'Content-type': item.file.type,
                'Access-Control-Allow-Origin': '*',
              },
            })
            .then((uploadResponse) => {
              if (uploadResponse.statusText === 'OK') {
                const updatedFile = {
                  ...item,
                  uuid: fileName,
                  url: `${'https://'}${response.data?.download_url}`,
                  loading: false,
                  fileType: fileType,
                  type: fileType,
                };

                updateFileInList(updatedFile, item.id);
              }
            })
            .catch(() => {
              setErrorMessage('Error while uploading file');
              updateFileInList(
                { ...item, fileType: fileType, loading: false, error: true },
                item.id
              );
            });
        } else {
          setErrorMessage('Error while uploading file');
          updateFileInList({ ...item, fileType: fileType, loading: false, error: true }, item.id);
        }
      })
      .catch(() => {
        setErrorMessage('Error while uploading file');
        updateFileInList({ ...item, fileType: fileType, loading: false, error: true }, item.id);
      });
  };

  const uploadNewFiles = () => {
    allFiles.forEach((item) => {
      if (!item.loading && !item.url && !item.type && item.isNew) {
        uploadFile(item);
      }
    });
  };

  useEffect(() => {
    if (isNewFilesAdded) {
      uploadNewFiles();
      setIsNewFilesAdded(false);
    }
  }, [isNewFilesAdded]);

  useEffect(() => {
    const filteredFiles = allFiles.filter((i) => !i.to_delete);

    if (filteredFiles.length > 0) {
      setDisableVideo(true);
    }

    if (filteredFiles.length === 0) {
      setDisableVideo(false);
      setDisableDoc(false);
      setDisableImage(false);
      setDisableAudio(false);
    }

    if (filteredFiles.some((fileSearch) => fileSearch?.type === 'video')) {
      setDisableVideo(true);
      setDisableDoc(true);
      setDisableImage(true);
      setDisableAudio(true);
    }

    if (
      filteredFiles.length < 5 &&
      !filteredFiles.some(
        (fileSort) => fileSort?.file?.type?.includes('video') || fileSort?.type?.includes('video')
      )
    ) {
      setDisableDoc(false);
      setDisableImage(false);
      setDisableAudio(false);
    }
  }, [allFiles.length, allFiles]);

  useEffect(() => {
    if (user) {
      setSelectUser(user.id);
    }
  }, [user]);

  const onChangeVisionSelect = (e) => {
    setSelectVision(e.target.value);
  };

  const onChangeUserSelect = (e) => {
    setSelectUser(e.target.value);
  };

  const checked = () => {
    if (allowComments === false) {
      setAllowComments(true);
    } else {
      setAllowComments(false);
    }
  };

  const createPostInput = async () => {
    const linkPrev =
      linkPreview && editData.type !== 'article'
        ? JSON.stringify({
            url: linkPreview.link,
            image: linkPreview.image,
            title: linkPreview.title,
            description: linkPreview.description,
          })
        : null;

    const postData = {
      type: editData.type,
      body: postStringData,
      character_count: wordCount,
      title: editData.type === 'article' ? articleTitle : undefined,
      preview: editData.type === 'article' ? articlePreviewText : undefined,
      user_id: selectUser,
      is_available_comments: allowComments,
      is_only_tt: selectVision === 2,
      is_only_community: selectVision === 3,
      is_link_only: selectVision === 4,
      link_preview: linkPrev,
      files_image: [],
      files_video: [],
      files_audio: [],
      files_doc: [],
    };

    if (editData.type === 'article' && articleCover) {
      postData.files_image.push({
        id: articleCover.id || undefined,
        uuid_name: articleCover.uuid,
        original_name: articleCover.file?.name || articleCover.original_name,
        is_preview: true,
        to_delete: articleCover.to_delete || undefined,
      });
    }

    allFiles.forEach((value) => {
      const newPostFileData = {
        to_delete: value.to_delete || undefined,
        id: !value.uuid ? value.id : undefined,
        uuid_name: value.uuid || value.file,
        original_name: value.file?.name || value.original_name,
      };

      const fileType = value.fileType || value.type;

      switch (fileType) {
        case 'image':
          postData.files_image.push(newPostFileData);
          break;
        case 'video':
          postData.files_video.push(newPostFileData);
          break;
        case 'audio':
          postData.files_audio.push(newPostFileData);
          break;

        default:
          postData.files_doc.push(newPostFileData);
          break;
      }
    });

    return postData;
  };

  const sendPost = async () => {
    setIsLoading(true);

    const postData = await createPostInput();

    updatePost(editId, postData)
      .then((resp) => {
        if (resp.status === 200) {
          closeModal();
          onFinish();
        }
      })
      .catch((err) => {
        openNotification({
          message: `Error while updating ${editData.type || 'post'}. Try again later.`,
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const initials = user?.first_name
    ? `${user.first_name} ${user.last_name}`
        .split(' ')
        .map((i) => i[0])
        .join('')
    : '';

  const saveDataFromEditor = (editorData, editorDataWordCount = undefined) => {
    if (editorDataWordCount) setWordCount(editorDataWordCount);
    setPostStringData(editorData);
  };

  const handleFileSelect = (files, uploadType) => {
    const allowedTypes = ['image', 'audio', 'video', 'document'];
    const selectedFilesArray = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileType = uploadType || file.type.split('/')[0];
      const fileExt = file.name.match(/\.([^.]+)$/)[1];

      if (selectedFilesArray.length === 5) {
        setErrorMessage('Only up to 5 files can be selected');
        break;
      }

      if (!allowedFileDropTypes.includes(`.${fileExt}`)) {
        setErrorMessage('You can`t upload files of this type');
        break;
      }

      if (
        selectedFilesArray.some(
          (fileSearch) => fileSearch?.file?.type?.split('/')[0] === 'video'
        ) ||
        allFiles.some((fileSearch) => fileSearch?.file?.type?.split('/')[0] === 'video') ||
        allFiles.some((fileSearch) => fileSearch?.type === 'video')
      ) {
        setErrorMessage('Only one video can be uploaded');
        setDisableVideo(true);
        setDisableAudio(true);
        setDisableDoc(true);
        setDisableImage(true);
        break;
      }

      if (fileType === 'video') {
        if (
          selectedFilesArray.some(
            (fileSearch) => fileSearch?.file?.type?.split('/')[0] === 'video'
          ) ||
          allFiles.length > 0 ||
          selectedFilesArray.length > 0
        ) {
          setErrorMessage('Only one video can be uploaded');
          setDisableVideo(true);
          setDisableAudio(true);
          setDisableDoc(true);
          setDisableImage(true);
          break;
        } else {
          selectedFilesArray.push({
            file: file,
            videoName: file.name,
            thumbnail: '',
            url: '',
            uuid: '',
            loading: false,
            error: false,
            isNew: true,
            id: `${file.name}_${generateUidForFile(file)}`,
          });
          setDisableVideo(true);
          setDisableAudio(true);
          setDisableDoc(true);
          setDisableImage(true);
        }
      } else if (fileType === 'image') {
        selectedFilesArray.push({
          file: file,
          preview: URL.createObjectURL(file),
          url: '',
          uuid: '',
          loading: false,
          error: false,
          isNew: true,
          id: `${file.name}_${generateUidForFile(file)}`,
        });
      } else if (allowedTypes.includes(fileType)) {
        selectedFilesArray.push({
          file: file,
          url: '',
          uuid: '',
          loading: false,
          error: false,
          isNew: true,
          id: `${file.name}_${generateUidForFile(file)}`,
        });
      } else {
        setErrorMessage(`${fileType} files are not allowed`);
        setDisableVideo(true);
        setDisableAudio(true);
        setDisableDoc(true);
        setDisableImage(true);
        break;
      }

      const allLength = selectedFilesArray.length + allFiles.length;
      if (allLength <= 5) {
        setAllFiles([...allFiles, ...selectedFilesArray]);
        setIsNewFilesAdded(true);

        if (allLength === 5) {
          setDisableVideo(true);
          setDisableAudio(true);
          setDisableDoc(true);
          setDisableImage(true);
        }
      } else {
        setDisableVideo(true);
        setDisableAudio(true);
        setDisableDoc(true);
        setDisableImage(true);
        setErrorMessage('Only up to 5 files can be selected');
      }
    }
  };

  const handleRemoveFile = (fileId, uuid, url, isOld) => {
    if (isOld) {
      const newSelectedFiles = allFiles.find((item) => item.id === fileId);
      if (newSelectedFiles) {
        const newSelectedFile = {
          ...newSelectedFiles,
          to_delete: true,
        };
        setAllFiles([...allFiles.filter((i) => i.id !== fileId), newSelectedFile]);

        const fileToDel = { path: url, name: uuid };
        deletePublic({ variables: { input: fileToDel } });
      }
    } else {
      const newSelectedFiles = allFiles.filter((item) => item.id !== fileId);
      setAllFiles(newSelectedFiles);

      const fileToDel = { path: url, name: uuid };
      deletePublic({ variables: { input: fileToDel } });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const dropFiles = event.dataTransfer.files;

    handleFileSelect(dropFiles);

    setIsDraggingOver(false);
  };

  const uploadArticleCover = (file) => {
    setArticleCoverFile(file);
    setArticleCoverError(false);
    setArticleCoverLoading(true);

    const fileName = `${uuidv1()}.png`;
    const fileType = 'image';
    const path = `${userID}/post/image_file`;

    const toUpload = { path: path, name: fileName };

    getPostFileUploadLink(toUpload)
      .then((response) => {
        const urlToUpload = response.data?.url;

        if (urlToUpload) {
          axios
            .put(urlToUpload, file, {
              headers: {
                'Content-type': file.type,
                'Access-Control-Allow-Origin': '*',
              },
            })
            .then((uploadResponse) => {
              if (uploadResponse.statusText === 'OK') {
                const updatedFile = {
                  id: articleCover.id,
                  file: file,
                  uuid: fileName,
                  url: `${'https://'}${response.data?.download_url}`,
                  loading: false,
                  fileType: fileType,
                  preview: URL.createObjectURL(file),
                };

                setArticleCover(updatedFile);
                setArticleCoverLoading(false);
                setArticleCoverFile(null);
              }
            })
            .catch(() => {
              setErrorMessage('Error while uploading file');
              setArticleCoverError(true);
              setArticleCoverLoading(false);
            });
        } else {
          setErrorMessage('Error while uploading file');
          setArticleCoverError(true);
          setArticleCoverLoading(false);
        }
      })
      .catch(() => {
        setErrorMessage('Error while uploading file');
        setArticleCoverError(true);
        setArticleCoverLoading(false);
      });
  };

  const handleDropArticleCover = (event) => {
    event.preventDefault();
    const dropFile = event.dataTransfer.files[0];

    uploadArticleCover(dropFile);

    setIsDraggingOver(false);
  };

  const isValidPost = () => postStringData.length > 0 || allFiles.length > 0;

  const isValidArticleStep = (step) => {
    let passed = true;

    if (step === 1) {
      passed = articleTitle.length > 0 && postStringData.length > 0;
    }

    if (step === 2) {
      passed = articlePreviewText.length > 0;
    }

    return passed;
  };

  useEffect(() => {
    if (linkPreview?.link) {
      if (!postStringData.includes(linkPreview?.link)) {
        setLinkPreview(null);
      }
    }
  }, [postStringData, linkPreview]);

  const getPreviewFromLink = async (link) => {
    setLinkPreview({
      link: link,
      loading: true,
      image: '',
      title: '',
      description: '',
    });

    fetchPostLinkPreview(link)
      .then((response) => {
        if (response.status === 200) {
          const { data } = response.data;

          const result = {
            title: data.ogTitle || data.twitterTitle || '',
            description: data.ogDescription || data.twitterDescription || '',
            image: data.ogImage || data.twitterImage || '',
          };

          setLinkPreview({
            link: link,
            loading: false,
            image: result.image,
            title: result.title,
            description: result.description,
          });
        }
      })
      .catch(() => {
        setLinkPreview({
          link: link,
          loading: false,
          image: '',
          title: '',
          description: '',
        });
      });
  };

  const openCropModal = (file) => {
    if (file) {
      const fileSize = (file.size / 1024 / 1024).toFixed(2);
      if (fileSize <= 2) {
        setAvatarFile(file);
        setIsCropImageModal(true);
      } else {
        openNotification({
          message: 'Allowed files with 2MB or below.',
          type: 'info',
        });
      }
    }
  };

  const handleUpdateAvatar = (file) => {
    uploadArticleCover(file);
  };

  return (
    <Modal
      open={visible}
      onClose={closeModal}
      title={`Edit ${editData?.type || 'post'}`}
      onClick={(event) => event.stopPropagation()}
      fullScreenOnResponse
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      closeOnlyByControls
    >
      <div className={styles.modal_body} id="modalBody">
        {isLoading ? (
          <LoaderOn />
        ) : editData ? (
          <>
            <div className={styles.modal_option}>
              <div className={styles.inputOptions}>
                <Select
                  placeholder="Select user"
                  variant="standard"
                  className={styles.select}
                  value={selectUser}
                  label="Select user"
                  onChange={onChangeUserSelect}
                  size="medium"
                  defaultValue={1}
                  MenuProps={{
                    classes: {
                      paper: styles.selectBody,
                    },
                  }}
                >
                  <MenuItem value={user.id} className={styles.menuItem}>
                    <div className={styles.selectItem}>
                      <Avatar src={user?.avatar} className={styles.avatar}>
                        <>{initials}</>
                      </Avatar>
                      <span>
                        {user.first_name} {user.last_name}
                      </span>
                    </div>
                  </MenuItem>
                </Select>

                <Select
                  placeholder="Suggest a role"
                  variant="standard"
                  className={styles.select}
                  value={selectVision}
                  label="Suggest a role"
                  onChange={onChangeVisionSelect}
                  size="medium"
                  defaultValue={1}
                  MenuProps={{
                    classes: {
                      paper: styles.selectBody,
                    },
                  }}
                >
                  {selectVisionData.map((element) => (
                    <MenuItem value={element.id} className={styles.menuItem}>
                      <div className={styles.selectItem}>
                        {element.icon}
                        <span>{element.value}</span>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>

            {editData.type === 'article' ? (
              <>
                {articleStep === 1 ? (
                  <div>
                    <TextInput
                      placeholder="Headline"
                      className={styles.articleInput}
                      value={articleTitle}
                      onChange={(e) => setArticleTitle(e.target.value)}
                      required={false}
                    />
                    <div
                      className={styles.dndArticleCover}
                      style={{
                        border: articleCoverError ? '1px solid red' : 'none',
                        height: '200px',
                      }}
                      onDrop={handleDropArticleCover}
                    >
                      <input
                        id="article-cover-upload"
                        type="file"
                        hidden
                        onChange={(e) => {
                          openCropModal(e.target.files[0]);
                        }}
                        accept={imageFileTypes}
                      />
                      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                      <label
                        htmlFor="article-cover-upload"
                        style={{ width: '100%', height: '100%' }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className={styles.dndCoverContent}>
                          {articleCover && !articleCover.to_delete ? (
                            <>
                              <img
                                src={articleCover?.preview || ''}
                                alt=""
                                style={{ borderRadius: 12, height: 190 }}
                              />
                              <button
                                type="button"
                                className={styles.uploadImgClose}
                                style={{
                                  display: 'flex',
                                  zIndex: 2000,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setArticleCover((prevVal) => ({ ...prevVal, to_delete: true }));
                                  setArticleCoverError(false);
                                  setArticleCoverFile(null);
                                }}
                              >
                                <CloseCrossIcon
                                  style={{ width: 16, height: 16 }}
                                  className="icon-white-fill"
                                />
                              </button>
                            </>
                          ) : articleCoverLoading ? (
                            <LoaderOn />
                          ) : articleCoverError ? (
                            <>
                              <img
                                src={articleCoverFile ? URL.createObjectURL(articleCoverFile) : ''}
                                alt=""
                                style={{ borderRadius: 12, height: 190 }}
                                className="blur-content"
                              />
                              <IconButton
                                style={{ position: 'absolute' }}
                                onClick={() => {
                                  uploadArticleCover(articleCoverFile);
                                }}
                              >
                                <ReloadIcon
                                  style={{
                                    width: 20,
                                    height: 20,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                />
                              </IconButton>
                              <button
                                type="button"
                                className={styles.uploadImgClose}
                                style={{
                                  display: 'flex',
                                  zIndex: 2000,
                                }}
                                onClick={() => {
                                  setArticleCover(null);
                                  setArticleCoverError(false);
                                  setArticleCoverFile(null);
                                }}
                              >
                                <CloseCrossIcon
                                  style={{ width: 16, height: 16 }}
                                  className="icon-white-fill"
                                />
                              </button>
                            </>
                          ) : (
                            <>
                              <div className={styles.dndCoverContentHeader}>
                                <ImageIcon className={styles.dndIcon} />
                                <span>No cover image uploaded</span>
                              </div>
                              <div className={styles.dndCoverContentDesc}>
                                Consider adding a cover image that complements your article to
                                attract more readers. We recommend uploading an image with a pixel
                                size of 1280 x 720.
                              </div>
                            </>
                          )}
                        </div>
                      </label>
                    </div>

                    <ContentEditor
                      value={postStringData}
                      saveDataMethod={saveDataFromEditor}
                      placeholder="Share your story..."
                      className={styles.articleEditor}
                      imageUploadPath={`${userID}/post/image_file`}
                    />
                  </div>
                ) : (
                  <div>
                    <TextInput
                      placeholder="Tell your network what your article is about."
                      className={styles.articleTextArea}
                      value={articlePreviewText}
                      onChange={(e) => setArticlePreviewText(e.target.value)}
                      required={false}
                      rows={3}
                      multiline
                    />

                    <div className={styles.articlePreviewBlock}>
                      {((articleCover && articleCoverFile) || articleCover?.url) &&
                      !articleCover?.to_delete ? (
                        <div>
                          <img
                            src={
                              articleCoverFile
                                ? URL.createObjectURL(articleCoverFile)
                                : articleCover?.url || ''
                            }
                            alt=""
                            className={styles.articleCover}
                          />
                        </div>
                      ) : null}

                      <div className={styles.articleLink}>
                        <div className={styles.articleLinkContent}>
                          <div className={styles.articleTitle}>{articleTitle}</div>
                        </div>

                        <div>
                          <RightIcon style={{ width: 20, height: 20 }} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className={styles.modal_textArea}>
                <div
                  className={styles.dndCover}
                  style={{ display: isDraggingOver ? 'flex' : 'none' }}
                >
                  <div className={styles.dndCoverContent}>
                    <div className={styles.dndCoverContentHeader}>
                      <FileAddIcon className={styles.dndIcon} />
                      <span>Drag files here</span>
                    </div>
                    <div className={styles.dndCoverContentDesc}>
                      One post cannot contain more than 5 attachments or more than 1 video.
                    </div>
                  </div>
                </div>
                <ContentEditor
                  value={postStringData}
                  saveDataMethod={saveDataFromEditor}
                  getPreviewFromLink={getPreviewFromLink}
                  placeholder="Share your story..."
                  maxLength={maxPostBodyLength}
                  disableControls
                />

                {linkPreview && !linkPreview.loading && (
                  <LinkPreview
                    url={linkPreview.link}
                    title={linkPreview.title}
                    description={linkPreview.description}
                    imageUrl={linkPreview.image}
                    onRemovePreview={() => setLinkPreview(null)}
                    isEdit
                  />
                )}
              </div>
            )}

            {/* Files preview */}
            <FilePreview
              selectedFiles={allFiles}
              handleRemoveFile={handleRemoveFile}
              handleFileUpload={uploadFile}
              updateFileMethod={updateFileInList}
              isEdit
            />

            <div>
              {editData.type === 'article' && articleStep === 2 ? null : (
                <FormControlLabel
                  value={!allowComments}
                  control={<MUICheckBox onChange={() => checked()} />}
                  label="Turn off comments"
                  labelPlacement="end"
                />
              )}
            </div>

            <div className={styles.buttonField}>
              {editData.type === 'article' && articleStep === 2 ? (
                <Button
                  type="default"
                  className={styles.backButton}
                  onClick={() => setArticleStep(1)}
                  disabled={isLoading}
                >
                  BACK
                </Button>
              ) : (
                <div className={styles.optionButton}>
                  {/* image upload */}
                  <ButtonBase
                    variant="contained"
                    component="label"
                    id="openImageModalButtons"
                    color="primary"
                    disabled={disableImage}
                  >
                    <input
                      id="post-img-upload"
                      type="file"
                      hidden
                      multiple
                      onChange={(e) => handleFileSelect(e.target.files, 'image')}
                      accept={imageFileTypes}
                    />
                    <label
                      htmlFor="post-img-upload"
                      className={disableImage ? styles.disabledUploadButton : ''}
                    >
                      <ImageIcon style={{ width: 24, height: 24 }} />
                    </label>
                  </ButtonBase>

                  {/* video upload */}
                  <ButtonBase
                    id="openVideoModalButtons"
                    variant="contained"
                    component="label"
                    disabled={disableVideo}
                    color="primary"
                  >
                    <input
                      id="post-video-upload"
                      type="file"
                      hidden
                      onChange={(e) => handleFileSelect(e.target.files, 'video')}
                      accept={videoFileTypes}
                    />
                    <label
                      htmlFor="post-video-upload"
                      className={disableVideo ? styles.disabledUploadButton : ''}
                    >
                      <VideoIcon style={{ width: 24, height: 24 }} />
                    </label>
                  </ButtonBase>

                  {/* audio upload */}
                  <ButtonBase
                    variant="contained"
                    id="openAudioModalButtons"
                    component="label"
                    disabled={disableAudio}
                    color="primary"
                  >
                    <input
                      id="post-audio-upload"
                      type="file"
                      hidden
                      multiple
                      onChange={(e) => handleFileSelect(e.target.files, 'audio')}
                      accept={audioFileTypes}
                    />
                    <label
                      htmlFor="post-audio-upload"
                      className={disableAudio ? styles.disabledUploadButton : ''}
                    >
                      <AudioIcon style={{ width: 24, height: 24 }} />
                    </label>
                  </ButtonBase>

                  {/* doc upload */}
                  <ButtonBase
                    variant="contained"
                    id="openDocModalButtons"
                    component="label"
                    disabled={disableDoc}
                    color="primary"
                  >
                    <input
                      id="post-file-upload"
                      type="file"
                      hidden
                      multiple
                      onChange={(e) => handleFileSelect(e.target.files, 'document')}
                      accept={docFileTypes}
                    />
                    <label
                      htmlFor="post-file-upload"
                      className={disableDoc ? styles.disabledUploadButton : ''}
                    >
                      <FileIcon style={{ width: 24, height: 24 }} />
                    </label>
                  </ButtonBase>
                </div>
              )}

              <div>
                {editData.type === 'article' ? (
                  <>
                    {articleStep === 1 ? (
                      <Button
                        type="primary"
                        className={styles.submitButton}
                        onClick={() => {
                          if (isValidArticleStep(1)) {
                            setArticleStep(2);
                          } else {
                            openNotification({
                              type: 'info',
                              message: 'Please, fill article title and article body',
                            });
                          }
                        }}
                        disabled={submitDisable}
                        loading={isLoading}
                      >
                        NEXT
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        className={styles.submitButton}
                        onClick={() => {
                          if (isValidArticleStep(2)) {
                            sendPost();
                          } else {
                            openNotification({
                              type: 'info',
                              message: 'Please, fill article preview field',
                            });
                          }
                        }}
                        disabled={submitDisable}
                        loading={isLoading}
                      >
                        SAVE
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    type="primary"
                    className={styles.submitButton}
                    onClick={() => {
                      if (isValidPost()) {
                        sendPost();
                      } else {
                        openNotification({
                          type: 'info',
                          message: 'Please, fill post body',
                        });
                      }
                    }}
                    disabled={
                      safeStripHtml(postStringData).length >= maxPostBodyLength || submitDisable
                    }
                    loading={isLoading}
                  >
                    SAVE
                  </Button>
                )}
              </div>
            </div>
          </>
        ) : (
          <div>
            <span>No data...</span>
          </div>
        )}
      </div>

      <AvatarCropModal
        open={isCropImageModal}
        setOpen={setIsCropImageModal}
        avatarFile={avatarFile}
        setAvatar={handleUpdateAvatar}
        width={620}
        height={317}
        borderRadius={4}
      />
    </Modal>
  );
};
