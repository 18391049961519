import {
  AddIcon,
  Button,
  ConfirmModal,
  DownIcon,
  EditIcon,
  openNotification,
  TrashIcon,
} from 'tt-ui-kit';
import styles from '../ManualTab.module.scss';
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Table } from 'tt-ui-lib/core';
import moment from 'moment/moment';
import { styled, Box, CircularProgress } from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_ADMIN_PRESENTATIONS,
  DELETE_PRESENTATION,
  CREATE_SECTION,
  UPDATE_SECTION,
  DELETE_SECTION,
} from '../../../../../../../api';
import { useEffect, useState } from 'react';
import AddOrEditPresentation from './AddOrEditPresentation';
import AddOrEditSectionModal from '../AddOrEditSectionModal';

const CustomizedAccordion = styled(Accordion)(() => ({
  '&.MuiAccordion-root': {
    borderRadius: '16px',
    width: '100%',
    padding: '10px',
    margin: 0,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
    '&:before': {
      display: 'none',
    },
  },
  '.MuiAccordionDetails-root': {
    padding: 0,
  },
  '.MuiAccordionSummary-content': {
    justifyContent: 'space-between',
    paddingRight: '24px',
    maxWidth: '-webkit-fill-available',
  },
  boxShadow: '0px 4px 24px 0px #0000000A',
}));

const PresentationsTab = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [presentations, setPresentations] = useState([]);
  const [presentationAction, setPresentationAction] = useState('');
  const [activeSection, setActiveSection] = useState(false);
  const [openSectionModal, setOpenSectionModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteSectionModal, setShowDeleteSectionModal] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [getAllPresentations] = useLazyQuery(GET_ADMIN_PRESENTATIONS, {
    fetchPolicy: 'network-only',
  });
  const [deletePresentation] = useMutation(DELETE_PRESENTATION);
  const [deleteSection] = useMutation(DELETE_SECTION);
  const [createSection] = useMutation(CREATE_SECTION, {
    variables: { input: {} },
    fetchPolicy: 'network-only',
  });
  const [updateSection] = useMutation(UPDATE_SECTION, {
    variables: { input: {} },
    fetchPolicy: 'network-only',
  });

  const handleDeletePresentation = async () => {
    setLoadingAction(true);
    setShowDeleteModal(false);
    const res = await deletePresentation({ variables: { id: showDeleteModal } });
    if (res.data && res.data.deletePresentation && res.data.deletePresentation.success) {
      setPresentations((prevState) =>
        prevState.map((group) => ({
          ...group,
          presentations: group.presentations.filter(
            (presentation) => presentation.id !== showDeleteModal
          ),
        }))
      );
    }
    setLoadingAction(false);
  };

  const handleDeleteSection = async () => {
    setLoadingAction(true);
    setShowDeleteSectionModal(false);
    const res = await deleteSection({ variables: { id: showDeleteSectionModal } });
    if (res.data && res.data.deleteSection && res.data.deleteSection.success) {
      setPresentations((prevState) =>
        prevState.filter((group) => group.section.id !== showDeleteSectionModal)
      );
      openNotification({
        message: 'Section deleted successfully',
        type: 'success',
      });
    }
    setLoadingAction(false);
  };

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Document name',
      align: 'left',
      width: 130,
      fixed: 'left',
    },
    {
      key: 'type',
      dataIndex: 'type',
      title: 'File',
      align: 'left',
      width: 150,
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: 'Creation date',
      align: 'left',
      width: 100,
      render: (value, _) => (value ? moment(value).format('DD.MM.YYYY') : '---'),
    },
    {
      key: 'updated_at',
      dataIndex: 'updated_at',
      title: 'Edit Date',
      align: 'left',
      width: 100,
      render: (value, _) => (value ? moment(value).format('DD.MM.YYYY') : '---'),
    },
    {
      dataIndex: 'actions',
      align: 'left',
      width: 100,
      fixed: 'right',
      render: (_, val) => (
        <Box className={styles.tableActions}>
          <Button className={styles.actionBtn} onClick={() => setPresentationAction(val.id)}>
            <EditIcon style={{ width: 24, height: 24 }} />
          </Button>
          <Button className={styles.actionBtn} onClick={() => setShowDeleteModal(val.id)}>
            <TrashIcon style={{ width: 24, height: 24 }} />
          </Button>
        </Box>
      ),
    },
  ];

  const getPresentations = async () => {
    setPageLoading(true);
    const response = await getAllPresentations({ variables: { type: 'presentation' } });
    if (response && response.data && response.data.showAllAdminPresentations) {
      setPresentations(response.data.showAllAdminPresentations);
    }
    setPageLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getPresentations();
    })();
  }, [presentationAction]);

  const addPresentation = (section) => {
    setPresentationAction('add');
    setActiveSection(section);
  };

  const handleSubmitSectionModal = async (newSection) => {
    try {
      setLoadingAction(true);
      let response;
      let notificationMessage;
      let updatedPresentations;

      if (activeSection?.id) {
        response = await updateSection({
          variables: {
            input: {
              id: activeSection.id,
              name: newSection,
            },
          },
        });

        if (response?.data?.updateSection) {
          updatedPresentations = presentations.map((group) =>
            group.section.id === response.data.updateSection.id
              ? { ...group, section: response.data.updateSection }
              : group
          );
          notificationMessage = 'Section updated successfully';
        } else {
          throw new Error('Update failed');
        }
      } else {
        response = await createSection({
          variables: {
            input: {
              name: newSection,
              type: 'presentation',
            },
          },
        });

        if (response?.data?.createSection) {
          updatedPresentations = [...presentations, { section: response.data.createSection }];
          notificationMessage = 'Section created successfully';
        } else {
          throw new Error('Creation failed');
        }
      }

      setPresentations(updatedPresentations);
      openNotification({
        message: notificationMessage,
        type: 'success',
      });
    } catch (error) {
      openNotification({
        message: 'Something went wrong, try again',
        type: 'error',
      });
    } finally {
      setOpenSectionModal(false);
      setLoadingAction(false);
    }
  };

  const handleEditSectionModal = (section) => {
    setActiveSection(section);
    setOpenSectionModal(true);
  };

  return (
    <>
      {pageLoading ? (
        <Box className={styles.loadingContainer}>
          <CircularProgress className={styles.loaderOn} disableShrink />
        </Box>
      ) : (
        <>
          {!presentationAction ? (
            <Box className={styles.tabContent}>
              {presentations.map((item) => (
                <CustomizedAccordion>
                  <AccordionSummary
                    expandIcon={<DownIcon style={{ width: 24, height: 24 }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={styles.accordionTitle}>{item?.section?.name}</Typography>
                    <Box className={styles.accordionActions}>
                      <Button
                        className={styles.actionBtn}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditSectionModal(item.section);
                        }}
                      >
                        <EditIcon style={{ width: 24, height: 24 }} />
                      </Button>
                      <Button
                        className={styles.actionBtn}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDeleteSectionModal(item?.section?.id);
                          setActiveSection(item.section);
                        }}
                      >
                        <TrashIcon style={{ width: 24, height: 24 }} />
                      </Button>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className={styles.accordionDetails}>
                      <Table columns={columns} data={item.presentations} scroll={{ x: 1300 }} />
                      <Button
                        type="mainButton"
                        className={styles.addBtn}
                        onClick={() => addPresentation(item.section)}
                      >
                        <AddIcon style={{ width: 24, height: 24 }} />
                        <Typography className={styles.btnText}>Add new presentation</Typography>
                      </Button>
                    </Box>
                  </AccordionDetails>
                </CustomizedAccordion>
              ))}
              <Button
                type="mainButton"
                className={styles.addBtn}
                onClick={() => {
                  setActiveSection(null);
                  setOpenSectionModal(true);
                }}
              >
                <AddIcon style={{ width: 24, height: 24 }} />
                <Typography className={styles.btnText}>Add new Section</Typography>
              </Button>
            </Box>
          ) : (
            <AddOrEditPresentation
              presentationActionOrId={presentationAction}
              activeSection={activeSection}
              setPresentationAction={setPresentationAction}
            />
          )}
          <AddOrEditSectionModal
            openSectionModal={openSectionModal}
            setOpenSectionModal={setOpenSectionModal}
            activeSection={activeSection}
            loadingAction={loadingAction}
            handleSubmit={handleSubmitSectionModal}
            setActiveSection={setActiveSection}
          />
          <ConfirmModal
            open={showDeleteModal}
            setOpen={setShowDeleteModal}
            title="Do you really want to remove the presentation?"
            okText="Delete"
            loading={loadingAction}
            onSubmit={() => handleDeletePresentation()}
            onClose={() => setShowDeleteModal(false)}
          />
          <ConfirmModal
            open={showDeleteSectionModal}
            setOpen={setShowDeleteSectionModal}
            title={`Delete ${activeSection?.name}`}
            subTitle="You really want to delete this section. All entries inside will also be deleted"
            okText="Delete"
            loading={loadingAction}
            onSubmit={() => handleDeleteSection()}
            onClose={() => setShowDeleteSectionModal(false)}
          />
        </>
      )}
    </>
  );
};
export default PresentationsTab;
