import { TITLES } from 'constants/pagesTitles';
import { useState, useEffect } from 'react';
import PageContainerImgBg from 'components/layout/PageContainer/PageContainerImgBg';
import SignUpPage from 'core/views/JoinPage/SignUpPage';
import { ROLES_LIST } from 'constants/joinPage';
import { useParams } from 'react-router-dom';

const SignUp = () => {
  const [roleData, setRoleData] = useState(null);
  const { role } = useParams();

  useEffect(() => {
    if (role) {
      const data = ROLES_LIST.find((r) => r.url === role);
      setRoleData(data);
    }
  }, []);

  return (
    <>
      {roleData && (
        <PageContainerImgBg
          title={TITLES.signUpPage}
          imageBg={`/images/${roleData.index}-body-bg.jpg`}
          isHeaderNav
          isSignUpPage
        >
          <SignUpPage role={roleData} />
        </PageContainerImgBg>
      )}
    </>
  );
};
export default SignUp;
