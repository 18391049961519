import { Image } from 'tt-ui-kit';
import styles from './Error.module.scss';

const Error = ({ text }) => (
  <div className={styles.error}>
    <div className={styles.iconWrapper}>
      <Image src="/images/templates/warning-red.svg" layout="fill" />
    </div>
    <p>{text}</p>
  </div>
);

export default Error;
