// import Link from 'next/link';
import VideoBg from 'components/layout/VideoBg';
import Header from 'components/layout/Header';
import { useWindowSize } from 'utils/useWindowSize';
import { ROLES_LIST } from 'constants/joinPage';
import RoleItem from './RoleItem';
import styles from './JoinPage.module.scss';
import { useState } from 'react';
import SupportChat from '../../../modules/tt-concierge';

const MAX_MOBILE_WIDTH = 678;
const { REACT_APP_CONCIERGE_URL, REACT_APP_CONCIERGE_SECRETS } = process.env;

const JoinPage = () => {
  const windowSize = useWindowSize();
  const [showSupportChat, setShowSupportChat] = useState(false);
  const laSalleGreenTheme = {
    headerGradient: 'linear-gradient(99.65deg, #143471 100%, #1550BD 100%)',
    sendButtonColor: '#143471',
    messageBackgroundColor: '#E3EDFF',
    supportButton: '#143471',
  };

  return (
    <>
      <Header isHeaderNav />
      <VideoBg
        src="/video/start-bg.mp4"
        srcImg="/images/start-body-bg.jpg"
        isAutoPlay={windowSize.width > MAX_MOBILE_WIDTH}
      />
      <SupportChat
        them={laSalleGreenTheme}
        showSupportChat={showSupportChat}
        setShowSupportChat={setShowSupportChat}
        companyName="TT Concierge"
        apiUrl={REACT_APP_CONCIERGE_URL} // API url
        apiSecret={REACT_APP_CONCIERGE_SECRETS} // API Authorization token
        supportMail="support@transparenterra.com"
        user=""
      />
      <section className={styles.roles}>
        <h1 className={styles.rolesTitle}>Choose Your Role to Sign Up</h1>
        <div className={styles.rolesWrapper}>
          {ROLES_LIST.map((role) => (
            <RoleItem role={role} key={role.index} />
          ))}
        </div>
      </section>
    </>
  );
};

export default JoinPage;
